/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { UploadedFileDisplay } from "@/components";
import UploadFileModal from "@/components/files/UploadFileModal";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { useCreateProjectStore } from "@/store";
import { ProcessType } from "@/types";
import { Document, DocumentType } from "@/types/project";
import React, { useEffect, useMemo, useState } from "react";
import { CreateStepGroup } from ".";

interface ApplicationOfficeActionStepProps {
  startExpanded: boolean;
  onCompletionChange: (isComplete: boolean) => void;
}

/**
 * @description Office Action step in create application project
 * @param {boolean} startExpanded - Whether the step is expanded.
 */
const ApplicationOfficeActionStep: React.FC<ApplicationOfficeActionStepProps> = ({
  startExpanded,
  onCompletionChange,
}) => {
  const {
    supportingDocuments,
    processes,
    updateSupportingDocuments,
    removeProcesses,
    updateSubjectNumbers,
  } = useCreateProjectStore();

  const infoPopoverContent = "Upload the office action PDF from the patent office.";

  // Upload file modal
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleContextAddClick = (selectedFiles: File[]) => {
    if (selectedFiles && selectedFiles.length > 0) {
      const newDocument: Document = {
        documentType: DocumentType.OFFICE_ACTION,
        file: selectedFiles[0],
        fileName: selectedFiles[0].name,
      };
      // Remove previous file
      if (supportingDocuments.length > 0) {
        handleRemoveFile();
      }
      updateSupportingDocuments([newDocument]);
    }
  };

  const handleRemoveFile = () => {
    updateSupportingDocuments(
      supportingDocuments.filter(
        (doc) => doc.documentType !== DocumentType.OFFICE_ACTION,
      ),
    );
    removeProcesses(ProcessType.ADD_OFFICE_ACTION);
    updateSubjectNumbers([]);
  };

  // Tracking OAs
  const getOfficeActionDocument = () => {
    return supportingDocuments.find(
      (doc) => doc.documentType === DocumentType.OFFICE_ACTION,
    );
  };

  const isOfficeActionErrored = useMemo(() => {
    return processes.some(
      (process) =>
        process.type === ProcessType.ADD_OFFICE_ACTION && process.status === "error",
    );
  }, [processes]);

  // Step completion
  const isStepCompleted = useMemo(() => {
    return getOfficeActionDocument() !== undefined && !isOfficeActionErrored;
  }, [getOfficeActionDocument, isOfficeActionErrored]);

  // Update completion status when it changes
  useEffect(() => {
    onCompletionChange(isStepCompleted);
  }, [isStepCompleted, onCompletionChange]);

  return (
    <CreateStepGroup
      stepName="Office Action"
      isCompleted={isStepCompleted}
      startExpanded={startExpanded}
      infoPopoverContent={infoPopoverContent}
    >
      <div className="space-y-4">
        <div>
          <Button onClick={handleOpenUploadModal} disabled={isStepCompleted}>
            {"Upload File"}
          </Button>
          <UploadFileModal
            open={isUploadModalOpen}
            handleClose={handleCloseUploadModal}
            inContext={true}
            handleContextAddClick={handleContextAddClick}
          />
        </div>
        {getOfficeActionDocument() && (
          <div>
            <UploadedFileDisplay
              file={getOfficeActionDocument().file}
              onRemove={handleRemoveFile}
              fullWidth={true}
            />
            {isOfficeActionErrored && (
              <Alert variant="destructive" className="mt-2">
                <AlertDescription>
                  {
                    "Uploaded file is not a valid office action file. Please upload a new file."
                  }
                </AlertDescription>
              </Alert>
            )}
          </div>
        )}
      </div>
    </CreateStepGroup>
  );
};

export default ApplicationOfficeActionStep;
