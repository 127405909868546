import { Spinner } from "@/components/ui/spinner";

// Base Button Interfaces and Component
interface DraftingButtonProps {
  onClick: () => Promise<void>;
  isGenerating: boolean;
  label: string;
  disabled?: boolean;
}

const DraftingButton = ({
  onClick,
  isGenerating,
  label,
  disabled = false,
}: DraftingButtonProps) => (
  <button
    className={`${
      isGenerating ? "" : "w-24"
    } px-4 py-1 text-xs font-small text-white bg-black rounded-md hover:bg-gray-800 focus:outline-none disabled:opacity-50 flex items-center justify-center gap-2`}
    onClick={onClick}
    disabled={disabled || isGenerating}
  >
    {isGenerating ? (
      <>
        <Spinner className="h-3 w-3 text-white" />
        <span>Generating...</span>
      </>
    ) : (
      label
    )}
  </button>
);

interface ExportButtonProps {
  onExport: () => Promise<void>;
  isExporting: boolean;
}

export const ExportButton = ({ onExport, isExporting }: ExportButtonProps) => (
  <DraftingButton
    onClick={onExport}
    isGenerating={isExporting}
    label="Export"
    disabled={isExporting}
  />
);

// Main Container Component
interface DraftingButtonsProps {
  generatingAmendment: boolean;
  generatingArgument: boolean;
  handleGenerateAmendments: () => Promise<void>;
  handleGenerateArgument: () => Promise<void>;
  hasContent: boolean;
  onSave?: () => Promise<void>;
}

export const DraftingButtons = ({
  generatingAmendment,
  generatingArgument,
  handleGenerateAmendments,
  handleGenerateArgument,
  hasContent,
  onSave,
}: DraftingButtonsProps) => (
  <div className="flex justify-center gap-2 mt-3 mb-6">
    {!generatingAmendment && (
      <DraftingButton
        onClick={handleGenerateArgument}
        isGenerating={generatingArgument}
        label="Argue"
        disabled={generatingAmendment}
      />
    )}
    {!generatingArgument && (
      <DraftingButton
        onClick={handleGenerateAmendments}
        isGenerating={generatingAmendment}
        label="Amend"
        disabled={generatingArgument}
      />
    )}
    {hasContent && onSave && (
      <DraftingButton
        onClick={onSave}
        isGenerating={false}
        label="Save"
        disabled={generatingAmendment || generatingArgument}
      />
    )}
  </div>
);
