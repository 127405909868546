import { OfficeAction } from "@/types/project";
import { memo } from "react";
import { OfficeActionInfoRow } from "./OfficeActionInfoRow";

interface OfficeActionSummaryRowProps {
  document: OfficeAction;
}

export const OfficeActionSummaryRow = memo(
  ({ document }: OfficeActionSummaryRowProps) => {
    return (
      <OfficeActionInfoRow
        items={[
          {
            label: "Notified On",
            content: new Date(document.notificationDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            }),
          },
          { label: "Primary Examiner", content: document.primaryExaminer },
          {
            label: "Supervisory Examiner",
            content: document.supervisoryExaminer ?? "-",
          },
        ]}
        gridCols="grid-cols-[40%_30%_30%]"
      />
    );
  },
);

OfficeActionSummaryRow.displayName = "OfficeActionSummaryRow";
