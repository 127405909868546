/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { TooltipProvider } from "@/components/ui/tooltip";
import { H4, Paragraph } from "@/components/ui/typography";
import ProtectedRoute from "@/routes/ProtectedRoute";
import { ThemeProvider } from "@/styled/ThemeProvider";
import { AuthProvider } from "@propelauth/react";
import React from "react";
import { isMobile } from "react-device-detect";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";

// Admin Pages
import AdminBillingPage from "@/features/admin/admin/AdminBillingPage";
import AdminPage from "@/features/admin/admin/AdminPage";
import AdminUsersPage from "@/features/admin/admin/AdminUsersPage";
import SuperAdminBillingPage from "@/features/admin/superadmin/SuperAdminBillingPage";
import SuperAdminOrganizationDetailsPage from "@/features/admin/superadmin/SuperAdminOrganizationDetailsPage";
import SuperAdminOrganizationPage from "@/features/admin/superadmin/SuperAdminOrganizationPage";
import SuperAdminPage from "@/features/admin/superadmin/SuperAdminPage";

// Explore Pages
import ExploreChatPage from "@/features/explore/chat/ExploreChatPage";
import ExplorePage from "@/features/explore/ExplorePage";
import ExploreSearchPage from "@/features/explore/search/ExploreSearchPage";

// Create Pages
import CreateApplicationProjectPage from "@/features/project/create/CreateApplicationProjectPage";
import CreatePortfolioProjectPage from "@/features/project/create/CreatePortfolioProjectPage";
import CreateProjectPage from "@/features/project/create/CreateProjectPage";
import CreateStandardsProjectPage from "@/features/project/create/CreateStandardsProjectPage";

// Project Pages
import ProjectChatPage from "@/features/explore/chat/ProjectChatPage";
import ProjectSearchPage from "@/features/explore/search/ProjectSearchPage";
import ElementChartsPage from "@/features/project/charts/ElementChartsPage";
import ContextPage from "@/features/project/context/ContextPage";
import CoverPleadingPage from "@/features/project/cover/CoverPleadingPage";
import ReferencesPage from "@/features/project/references/ReferencesPage";
import SubjectPage from "@/features/project/subject/SubjectPage";
import ApplicationDocumentsPage from "./features/project/application/ApplicationDocumentsPage";

// Portfolio Pages
import PortfolioOverviewPage from "@/features/project/portfolio/PortfolioOverviewPage";
import PortfolioPage from "@/features/project/portfolio/PortfolioPage";
import PortfolioReferencesPage from "@/features/project/portfolio/PortfolioReferencesPage";

// Misc. Pages
import LogInPage from "@/features/auth/LogInPage";
import UserFilesPage from "@/features/files/UserFilesPage";
import HomePage from "@/features/home/HomePage";
import PageNotFound from "@/features/PageNotFound";
import UnauthorizedPage from "@/features/project/UnauthorizedPage";
import { usePostHogIdentify } from "./hooks/usePostHogIdentity";
import { useSentryReplay } from "./hooks/useSentryReplay";

const AppContent: React.FC = () => {
  usePostHogIdentify();
  useSentryReplay();
  return (
    <Router>
      <Routes>
        {/* Auth Routes */}
        <Route path="/signin" element={<LogInPage />} />
        <Route path="/" element={<Navigate to="/signin" />} />
        <Route path="*" element={<Navigate to="/signin" />} />

        <Route element={<ProtectedRoute />}>
          {/* Admin Routes */}
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/admin/users" element={<AdminUsersPage />} />
          <Route path="/admin/billing" element={<AdminBillingPage />} />

          {/* Super Admin Routes */}
          <Route path="/superadmin" element={<SuperAdminPage />} />
          <Route path="/superadmin/billing" element={<SuperAdminBillingPage />} />
          <Route
            path="/superadmin/organizations"
            element={<SuperAdminOrganizationPage />}
          />
          <Route
            path="/superadmin/organizations/:organizationId"
            element={<SuperAdminOrganizationDetailsPage />}
          />

          {/* Explore Routes */}
          <Route path="/explore/search" element={<ExploreSearchPage />} />
          <Route path="/explore/chat" element={<ExploreChatPage />} />
          <Route path="/explore" element={<ExplorePage />} />

          {/* Project Routes */}
          <Route path="/project/:projectId/subject" element={<SubjectPage />} />
          <Route path="/project/:projectId/context" element={<ContextPage />} />
          <Route path="/project/:projectId/references" element={<ReferencesPage />} />
          <Route path="/project/:projectId/charts" element={<ElementChartsPage />} />
          <Route path="/project/:projectId/search" element={<ProjectSearchPage />} />
          <Route path="/project/:projectId/chat" element={<ProjectChatPage />} />
          <Route
            path="/project/:projectId/documents"
            element={<ApplicationDocumentsPage />}
          />
          <Route path="/project/:projectId/cover" element={<CoverPleadingPage />} />
          {/* Portfolio Routes */}
          <Route path="/portfolio/:portfolioId/subjects" element={<PortfolioPage />} />
          <Route
            path="/portfolio/:portfolioId/references"
            element={<PortfolioReferencesPage />}
          />
          <Route
            path="/portfolio/:portfolioId/overview"
            element={<PortfolioOverviewPage />}
          />
          <Route
            path="/portfolio/:portfolioId/project/:projectId/subject"
            element={<SubjectPage />}
          />
          <Route
            path="/portfolio/:portfolioId/project/:projectId/context"
            element={<ContextPage />}
          />
          <Route
            path="/portfolio/:portfolioId/project/:projectId/references"
            element={<ReferencesPage />}
          />
          <Route
            path="/portfolio/:portfolioId/project/:projectId/charts"
            element={<ElementChartsPage />}
          />
          <Route
            path="/portfolio/:portfolioId/project/:projectId/search"
            element={<ProjectSearchPage />}
          />
          <Route
            path="/portfolio/:portfolioId/project/:projectId/chat"
            element={<ProjectChatPage />}
          />
          <Route
            path="/portfolio/:portfolioId/project/:projectId/documents"
            element={<ApplicationDocumentsPage />}
          />
          <Route
            path="/portfolio/:portfolioId/project/:projectId/cover"
            element={<CoverPleadingPage />}
          />

          {/* Create Routes */}
          <Route path="/create-project" element={<CreateProjectPage />} />
          <Route
            path="/create-application-project"
            element={<CreateApplicationProjectPage />}
          />
          <Route path="/create-portfolio" element={<CreatePortfolioProjectPage />} />
          <Route
            path="/create-standards-project"
            element={<CreateStandardsProjectPage />}
          />

          {/* Unauthorized Route */}
          <Route
            path="/project/:projectId/unauthorized"
            element={<UnauthorizedPage />}
          />

          {/* Home Route */}
          <Route path="/home" element={<HomePage />} />
          <Route path="/files" element={<UserFilesPage />} />
          {/* Page Not Found Route */}
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

const App: React.FC = () => {
  if (isMobile) {
    return (
      <div
        style={{
          width: "100vw",
          padding: 20,
          textAlign: "center",
          marginTop: 50,
        }}
      >
        <H4>&AI</H4>
        <Paragraph>Mobile use is not supported.</Paragraph>
        <Paragraph>Please access on a computer.</Paragraph>
      </div>
    );
  }

  return (
    <AuthProvider authUrl={process.env.REACT_APP_AUTH_URL!}>
      <ThemeProvider defaultTheme="system" storageKey="ui-theme">
        <TooltipProvider>
          <AppContent />
        </TooltipProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
