import {
  AutosizeTextarea,
  AutosizeTextAreaRef,
} from "@/components/ui/autosize-textarea";
import { Button } from "@/components/ui/button";
import { CheckboxWithText } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Label } from "@/components/ui/label";
import { LoadingButton } from "@/components/ui/loading-button";
import { useDataTable } from "@/hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useProjectStore } from "@/store";
import { ElementType } from "@/types";

interface Variable {
  id: string;
  name: string;
  current: string;
}

interface CustomInputProps {
  id: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  variables: Variable[];
}

const CustomInput: React.FC<CustomInputProps> = React.memo(
  ({ id, label, value, onChange, variables }) => {
    const textareaRef = useRef<AutosizeTextAreaRef>(null);
    const currentProject = useProjectStore((state) => state.currentProject);
    const selectedReferences = useProjectStore((state) => state.selectedReferences);

    // Ensure value exists before trying to replace
    const previewText = value
      ? value.replace(/{{(\w+)}}/g, (match, variable) => {
          switch (variable) {
            case "element_number":
              return variables.find((v) => v.id === "element_number")?.current ?? match;
            case "element_language":
              return (
                variables.find((v) => v.id === "element_language")?.current ?? match
              );
            case "reference_name": {
              // Extra defensive checks for reference name
              if (!currentProject || !currentProject.documentsToNicknames) {
                return match;
              }
              const nickname =
                currentProject.documentsToNicknames[selectedReferences[0].id];
              return nickname || match;
            }
            default:
              return match;
          }
        })
      : "";

    useEffect(() => {
      // This effect runs after every render
      // It will maintain focus on the input if it already had focus
      if (document.activeElement === textareaRef.current?.textArea) {
        textareaRef.current?.textArea.focus();
      }
    });

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.target.value);
      },
      [onChange],
    );

    const insertVariable = useCallback(
      (variable: string) => {
        if (textareaRef.current) {
          const textarea = textareaRef.current.textArea;
          const start = textarea.selectionStart || 0;
          const end = textarea.selectionEnd || 0;
          const currentValue = value || "";
          const newValue =
            currentValue.substring(0, start) +
            ` {{${variable}}} ` +
            currentValue.substring(end);
          onChange(newValue);

          requestAnimationFrame(() => {
            const newPosition = start + variable.length + 6; // 6 accounts for {{ }} and spaces
            textarea.setSelectionRange(newPosition, newPosition);
            textarea.focus();
          });
        }
      },
      [value, onChange],
    );

    const showPreview = value?.includes("{{") ?? false;

    return (
      <div className="space-y-2">
        <Label htmlFor={id} className="text-left">
          {label}
        </Label>
        <div className="flex items-center">
          <AutosizeTextarea
            ref={textareaRef}
            id={id}
            value={value}
            onChange={handleChange}
            className="flex-grow"
          />
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button type="button" variant="outline" className="ml-2">
                Insert Variable
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-48">
              <div className="grid gap-2">
                {variables.map((variable) => (
                  <DropdownMenuItem
                    key={variable.id}
                    onClick={() => insertVariable(variable.id)}
                  >
                    {variable.name}
                  </DropdownMenuItem>
                ))}
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        {showPreview && (
          <div className="mt-2 p-2 bg-gray-100 rounded">
            <Label className="text-sm font-medium">Preview:</Label>
            <p className="text-sm">{previewText}</p>
          </div>
        )}
      </div>
    );
  },
);

interface HeaderFooterEditorProps {
  initialHeader: string;
  initialFooter: string;
  isOpen: boolean;
  onClose: () => void;
  index: number;
  defaultUseFormatForAllElements?: boolean;
  currentElementNumber?: string;
  currentElementLanguage?: string;
}

const HeaderFooterEditor: React.FC<HeaderFooterEditorProps> = ({
  initialHeader,
  initialFooter,
  isOpen,
  onClose,
  index,
  defaultUseFormatForAllElements = false,
  currentElementNumber = "1",
  currentElementLanguage = "Example element language",
}) => {
  const [header, setHeader] = useState(initialHeader);
  const [footer, setFooter] = useState(initialFooter);
  const [useFormatForAllElements, setUseFormatForAllElements] = useState(
    defaultUseFormatForAllElements,
  );
  const { updateInvalidityBoilerplate } = useDataTable();
  const [isSaving, setIsSaving] = useState(false);
  const currentProject = useProjectStore((state) => state.currentProject);
  const selectedElementType = useProjectStore((state) => state.selectedElementType);
  // Reset state when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setHeader(initialHeader);
      setFooter(initialFooter);
      setUseFormatForAllElements(defaultUseFormatForAllElements);
    }
  }, [isOpen, initialHeader, initialFooter]);

  const variables: Variable[] = [
    {
      id: "reference_name",
      name: "Document Name",
      current: "",
    },
    {
      id: "element_number",
      name: "Element Number",
      current: currentElementNumber,
    },
    {
      id: "element_language",
      name: "Element Language",
      current: currentElementLanguage,
    },
  ];

  const handleSave = useCallback(async () => {
    setIsSaving(true);

    try {
      // Pass the raw header/footer with variables and let backend handle substitution
      await handleUpdateInvalidityBoilerplate(
        currentProject.id,
        variables.find((v) => v.id === "element_number")?.current,
        selectedElementType === ElementType.FEATURE,
        header,
        footer,
        useFormatForAllElements,
      );
      onClose();
    } catch (error) {
      console.error("Error updating invalidity boilerplate:", error);
    } finally {
      setIsSaving(false);
    }
  }, [
    header,
    footer,
    currentProject.id,
    variables,
    selectedElementType,
    useFormatForAllElements,
    onClose,
  ]);

  const handleUpdateInvalidityBoilerplate = async (
    projectId: string,
    claimNumber: string,
    isFeature: boolean,
    header: string,
    footer: string,
    updateAllElements: boolean,
  ) => {
    await updateInvalidityBoilerplate(
      projectId,
      claimNumber,
      isFeature,
      header,
      footer,
      updateAllElements,
      index,
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>
            Edit {selectedElementType === ElementType.FEATURE ? "Feature" : "Claim"}{" "}
            Header and Footer
          </DialogTitle>
          <DialogDescription>
            The header and footer will be applied to the{" "}
            {selectedElementType === ElementType.FEATURE ? "feature(s)" : "claim(s)"}{" "}
            for all documents in the project.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4 pb-4">
          <div>
            <div className="flex items-center gap-4">
              <Label htmlFor="header" className="mb-0">
                Header
              </Label>
            </div>
            <CustomInput
              id="header"
              label=""
              value={header}
              onChange={setHeader}
              variables={variables}
            />
          </div>
          <div className="space-y-2">
            <div className="flex items-center gap-4">
              <Label htmlFor="footer" className="mb-0">
                Footer
              </Label>
            </div>
            <CustomInput
              id="footer"
              label=""
              value={footer}
              onChange={setFooter}
              variables={variables}
            />
          </div>
        </div>
        <DialogFooter>
          <CheckboxWithText
            label={`Use format for all ${
              selectedElementType === ElementType.FEATURE ? "features" : "claims"
            }`}
            checked={useFormatForAllElements}
            onCheckedChange={(checked) =>
              setUseFormatForAllElements(checked as boolean)
            }
          />
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton loading={isSaving} type="button" onClick={handleSave}>
            Save changes
          </LoadingButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
export default HeaderFooterEditor;
