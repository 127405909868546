/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DocumentViewer, ImageContainer, ReferenceImage } from "@/components";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { CheckboxWithText } from "@/components/ui/checkbox";
import { CitationTextarea } from "@/components/ui/citation-textarea";
import { FloatingLabelInput } from "@/components/ui/floating-input";
import { LoadingButton } from "@/components/ui/loading-button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useDataTable, useViz } from "@/hooks";
import { useFeedbackChartDataStore } from "@/hooks/useFeedbackChartDataStore";
import { cn } from "@/lib/utils";
import { useProjectStore } from "@/store";
import { ChartColor, InvalidityCitation } from "@/types";
import { isLikelyPatentNumber } from "@/utils/dataUtils";
import { formatCitationLocation } from "@/utils/projectUtils";
import { Cross2Icon, EyeOpenIcon, FileIcon, Pencil1Icon } from "@radix-ui/react-icons";
import { ThumbsDownIcon, ThumbsUpIcon } from "lucide-react";
import React, {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { AddImageMenu, ImageUploadModal, ReferenceFigureSelectModal } from "./index";

interface ReferenceTableCitationProps {
  citationKey: string;
  citation: InvalidityCitation;
  index: number;
  refId: string;
  claimNumber: string;
  citationIndex: number;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  addingCitationKey: string;
  setShowNoMoreCitations: (show: boolean) => void;
}

/**
 * @description Reference table citation to display a citation in the table
 * @param {string} citationKey - The key of the citation.
 * @param {InvalidityCitation} citation - The citation to display.
 * @param {number} index - The index of the citation.
 * @param {string} refId - The id of the reference.
 * @param {string} claimNumber - The claim number of the citation.
 * @param {number} citationIndex - The index of the citation.
 * @param {boolean} isEditing - Whether the citation is being edited.
 * @param {function} setIsEditing - Function to set the editing state.
 * @param {string} addingCitationKey - The key of the citation being added.
 * @param {function} setShowNoMoreCitations - Function to set the show no more citations state.
 */
const ReferenceTableCitation: React.FC<ReferenceTableCitationProps> = ({
  citationKey,
  citation,
  index,
  refId,
  claimNumber,
  citationIndex,
  isEditing,
  setIsEditing,
  addingCitationKey,
  setShowNoMoreCitations,
}) => {
  const { getFullDocument, uploadImageToS3 } = useViz();
  const {
    currentProjectId,
    selectedReferences,
    selectedElementType,
    chartData,
    currentProject,
    updateChartData,
  } = useProjectStore();
  const { addFeedbackCitation, removeFeedbackCitation, feedbackChartData } =
    useFeedbackChartDataStore();

  const { saveCitationUpdate, handleSourceDeleteToggle, handleSourceColorUpdate } =
    useDataTable();

  const [citationTextEdit, setCitationTextEdit] = useState<string>(citation.text);
  const [isEditingCitation, setIsEditingCitation] = useState<boolean>(false);
  const [isChangingColor, setIsChangingColor] = useState<boolean>(false);
  const [imageUploadModalOpen, setImageUploadModalOpen] = useState<boolean>(false);
  const [figureSelectModalOpen, setFigureSelectModalOpen] = useState<boolean>(false);
  const [imageMenuState, setImageMenuState] = useState<{
    mouseX: number | null;
    mouseY: number | null;
  }>({
    mouseX: null,
    mouseY: null,
  });
  const [referenceFiguresToAttach, setReferenceFiguresToAttach] = useState<string[]>(
    [],
  );
  const [showDocumentViewer, setShowDocumentViewer] = useState<boolean>(false);
  const [documentViewerDocument, setDocumentViewerDocument] = useState<any>(null);
  const [documentViewerCitationText, setDocumentViewerCitationText] = useState<
    string | null
  >(null);
  const [isCitationSaving, setIsCitationSaving] = useState<boolean>(false);
  const [propagateChanges, setPropagateChanges] = useState<boolean>(false);
  const [urlsToRemove, setUrlsToRemove] = useState<string[]>([]);

  const getCitationFeedback = () => {
    return feedbackChartData[currentProjectId]?.[refId]?.[claimNumber]?.find(
      (c) => c.id === citation.id,
    )?.feedback;
  };

  const [citationFeedback, setCitationFeedback] = useState<string | null>(
    getCitationFeedback(),
  );

  const isPatent = isLikelyPatentNumber(
    currentProject.references.find((r) => r.id === refId)?.number,
  );

  const handleAttachImageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setImageMenuState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleCloseAddImageMenu = () => {
    setImageMenuState({ mouseX: null, mouseY: null });
  };

  const [contextMenuPosition, setContextMenuPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const handleRightClick = useCallback((event: ReactMouseEvent) => {
    event.preventDefault();
    const clickX = event.clientX;
    const clickY = event.clientY;
    const screenW = window.innerWidth;
    const screenH = window.innerHeight;
    const menuW = 100; // Approximate width of your context menu
    const menuH = 100; // Approximate height of your context menu

    const left = clickX + menuW > screenW ? clickX - menuW : clickX;
    const top = clickY + menuH > screenH ? clickY - menuH : clickY;

    setContextMenuPosition({ left, top });
  }, []);

  const handleCloseContextMenu = useCallback(() => {
    setContextMenuPosition(null);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (contextMenuPosition && !(event.target as Element).closest(".context-menu")) {
        handleCloseContextMenu();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [contextMenuPosition, handleCloseContextMenu]);

  const [pageEdit, setPageEdit] = useState<number>(citation.page || null);
  const [paragraphEdit, setParagraphEdit] = useState<number>(
    citation.paragraph || null,
  );
  const [lineFromEdit, setLineFromEdit] = useState<number>(citation.lines[0] || null);
  const [lineToEdit, setLineToEdit] = useState<number>(citation.lines[1] || null);
  const [columnFromEdit, setColumnFromEdit] = useState<number>(
    citation.columns[0] || null,
  );
  const [columnToEdit, setColumnToEdit] = useState<number>(citation.columns[1] || null);

  const [citationLocationError, setCitationLocationError] = useState<boolean>(false);
  const [lineColumnError, setLineColumnError] = useState<boolean>(false);
  const [columnRangeError, setColumnRangeError] = useState<boolean>(false);
  const [lineRangeError, setLineRangeError] = useState<boolean>(false);

  const resetEditValues = () => {
    setCitationTextEdit(citation.text);
    setPageEdit(citation.page || null);
    setParagraphEdit(citation.paragraph || null);
    setLineFromEdit(citation.lines[0] || null);
    setLineToEdit(citation.lines[1] || null);
    setColumnFromEdit(citation.columns[0] || null);
    setColumnToEdit(citation.columns[1] || null);
    setUrlsToRemove([]);
    setReferenceFiguresToAttach([]);
    setPropagateChanges(false);
    setIsEditingCitation(false);
    setIsEditing(false);
    setIsCitationSaving(false);
  };

  const handleSaveCitationClick = () => {
    setIsCitationSaving(true);
    const trimmedCitationText = citationTextEdit.trim();

    // Validate that at least one location field is filled
    if (!pageEdit && !paragraphEdit && !columnFromEdit && !lineFromEdit) {
      setCitationLocationError(true);
      setLineColumnError(false);
      setIsCitationSaving(false);
      return;
    }

    // Check if any column or line field is filled
    const hasColumnFields = columnFromEdit || columnToEdit;
    const hasLineFields = lineFromEdit || lineToEdit;

    // If any column/line field is provided, ensure all related fields are filled
    if (
      (hasColumnFields || hasLineFields) &&
      (!columnFromEdit || !columnToEdit || !lineFromEdit || !lineToEdit)
    ) {
      setLineColumnError(true);
      setCitationLocationError(false);
      setIsCitationSaving(false);
      return;
    }

    // Validate ranges
    if (
      (columnFromEdit && columnToEdit && columnToEdit < columnFromEdit) ||
      (lineFromEdit && lineToEdit && lineToEdit < lineFromEdit)
    ) {
      setColumnRangeError(columnToEdit < columnFromEdit);
      setLineRangeError(lineToEdit < lineFromEdit);
      setIsCitationSaving(false);
      return;
    }

    // Clear errors and proceed with save
    setCitationLocationError(false);
    setLineColumnError(false);
    setColumnRangeError(false);
    setLineRangeError(false);
    setIsEditingCitation(false);

    saveCitationUpdate(
      index,
      refId,
      citationIndex,
      trimmedCitationText,
      {
        page: pageEdit,
        paragraph: paragraphEdit,
        lines: [lineFromEdit, lineToEdit],
        columns: [columnFromEdit, columnToEdit],
      },
      {
        newFigureUrls: referenceFiguresToAttach,
        oldFigureUrls: urlsToRemove,
      },
      citation,
      setIsEditing,
      propagateChanges,
    );

    resetEditValues();
  };

  const handleCancelClick = () => {
    resetEditValues();
  };

  const handleEditCitation = () => {
    handleCloseContextMenu();
    setIsEditing(true);
    setIsEditingCitation(true);
  };

  const handleAttachImagesToCitation = async (files: File[]) => {
    for (const file of files) {
      if (file) {
        const response = await uploadImageToS3(file);
        const imageUrl = response.data.url;
        setReferenceFiguresToAttach([...referenceFiguresToAttach, imageUrl]);
      }
    }
  };

  const handleAttachFigures = (figureUrls: string[]) => {
    setReferenceFiguresToAttach([...referenceFiguresToAttach, ...figureUrls]);
  };

  const handleRemoveImageClick = (figureUrl: string) => {
    if (citation.figureUrls?.includes(figureUrl)) {
      setUrlsToRemove([...urlsToRemove, figureUrl]);
    }
    const newChartData = [...chartData];
    newChartData[index][refId]["citations"][citationIndex].figureUrls = newChartData[
      index
    ][refId]["citations"][citationIndex].figureUrls.filter((url) => url !== figureUrl);
    updateChartData(newChartData);
  };

  const removeFromReferenceFiguresToAttach = (imageUrl: string) => {
    setReferenceFiguresToAttach(
      referenceFiguresToAttach.filter((url) => url !== imageUrl),
    );
  };

  const resetCitationCellState = () => {
    setIsEditingCitation(false);
    setIsEditing(false);
    setShowNoMoreCitations(false);
  };

  const handleDeleteClick = () => {
    resetCitationCellState();
    const isAddingCitation = addingCitationKey === citationKey;
    handleSourceDeleteToggle(
      index,
      refId,
      citation.id,
      citation,
      isAddingCitation,
      true,
    );
  };

  const handleFeedbackClick = (type: "positive" | "negative") => {
    resetCitationCellState();
    if (citationFeedback === type) {
      removeFeedbackCitation(currentProjectId, refId, claimNumber, citation.id);
      setCitationFeedback(null);
    } else {
      addFeedbackCitation(currentProjectId, refId, claimNumber, {
        ...citation,
        feedback: type,
      });
      setCitationFeedback(type);
    }
  };

  const handleViewInPatent = async () => {
    const patentResponse = await getFullDocument(refId);
    const patent = patentResponse.data;
    setDocumentViewerDocument(patent);
    if (citation.text.startsWith('"') && citation.text.endsWith('"')) {
      const strippedText = citation.text.substring(1, citation.text.length - 1);
      setDocumentViewerCitationText(strippedText);
    } else {
      setDocumentViewerCitationText(citation.text);
    }
    handleCloseContextMenu();
    setShowDocumentViewer(true);
  };

  const handleClosePreview = () => {
    setDocumentViewerDocument(null);
    setDocumentViewerCitationText(null);
    setShowDocumentViewer(false);
  };

  const handleColorChange = (color: string) => {
    handleSourceColorUpdate(
      index,
      citationIndex,
      color,
      refId,
      citation.id,
      addingCitationKey === citationKey,
    );
    setIsChangingColor(false);
  };

  const disableFeedbackButtons =
    isEditing && !isEditingCitation && addingCitationKey !== citationKey;

  // Remove validation from the change handlers
  const handleColumnToChange = (value: string) => {
    const newValue = parseInt(value);
    if (!value) {
      setColumnToEdit(null);
      return;
    }
    setColumnToEdit(newValue);
  };

  const handleLineToChange = (value: string) => {
    const newValue = parseInt(value);
    if (!value) {
      setLineToEdit(null);
      return;
    }
    setLineToEdit(newValue);
  };

  return (
    <>
      <div className="flex items-start pb-2 " onContextMenu={handleRightClick}>
        <div className="flex flex-col items-center gap-2 pr-3">
          <Popover open={isChangingColor} onOpenChange={setIsChangingColor}>
            <PopoverTrigger asChild>
              <div>
                <Button
                  variant="outline"
                  size="icon"
                  style={{
                    backgroundColor: citation.color || "defaultBackgroundColor",
                  }}
                  onClick={() => setIsChangingColor(true)}
                />
              </div>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-1">
              <div className="flex flex-row">
                {Object.values(ChartColor)
                  .filter((color) => color !== ChartColor.GRAY)
                  .map(
                    (color) =>
                      citation.color !== color && (
                        <div
                          key={color}
                          className="w-9 h-9 m-1 cursor-pointer rounded-md"
                          style={{ backgroundColor: color }}
                          onClick={() => handleColorChange(color)}
                        />
                      ),
                  )}
              </div>
            </PopoverContent>
          </Popover>
        </div>

        <div className="flex-1 mr-4">
          {isEditingCitation || addingCitationKey === citationKey ? (
            <div className="space-y-2">
              <div className="flex gap-2">
                <div className="grid grid-cols-2 gap-2">
                  <FloatingLabelInput
                    value={pageEdit || ""}
                    placeholder="Page"
                    onChange={(e) => setPageEdit(parseInt(e.target.value))}
                    className="h-9"
                    label="Page"
                  />
                  <FloatingLabelInput
                    value={paragraphEdit || ""}
                    placeholder="Paragraph"
                    onChange={(e) => setParagraphEdit(parseInt(e.target.value))}
                    className="h-9"
                    label="Paragraph"
                  />
                </div>
                <div className="flex flex-col gap-2 mb-2 items-center">
                  <div className="grid grid-cols-2 gap-2">
                    <div className="flex gap-2">
                      <FloatingLabelInput
                        value={columnFromEdit || ""}
                        placeholder="Column From"
                        onChange={(e) => setColumnFromEdit(parseInt(e.target.value))}
                        className={cn(
                          "h-9",
                          (lineColumnError || columnRangeError) && "border-red-500",
                        )}
                        label="Column From"
                        error={
                          lineColumnError
                            ? "All or none required"
                            : columnRangeError
                              ? "Column To must be greater than or equal to Column From"
                              : ""
                        }
                      />
                      <FloatingLabelInput
                        value={columnToEdit || ""}
                        placeholder="Column To"
                        onChange={(e) => handleColumnToChange(e.target.value)}
                        className={cn(
                          "h-9",
                          (lineColumnError || columnRangeError) && "border-red-500",
                        )}
                        label="Column To"
                        error={
                          lineColumnError
                            ? "All or none required"
                            : columnRangeError
                              ? "Column To must be greater than or equal to Column From"
                              : ""
                        }
                      />
                    </div>
                    <div className="flex gap-2">
                      <FloatingLabelInput
                        value={lineFromEdit || ""}
                        placeholder="Line From"
                        onChange={(e) => setLineFromEdit(parseInt(e.target.value))}
                        className={cn(
                          "h-9",
                          (lineColumnError || lineRangeError) && "border-red-500",
                        )}
                        label="Line From"
                        error={
                          lineColumnError
                            ? "All or none required"
                            : lineRangeError
                              ? "Line To must be greater than or equal to Line From"
                              : ""
                        }
                      />
                      <FloatingLabelInput
                        value={lineToEdit || ""}
                        placeholder="Line To"
                        onChange={(e) => handleLineToChange(e.target.value)}
                        className={cn(
                          "h-9",
                          (lineColumnError || lineRangeError) && "border-red-500",
                        )}
                        label="Line To"
                        error={
                          lineColumnError
                            ? "All or none required"
                            : lineRangeError
                              ? "Line To must be greater than or equal to Line From"
                              : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <CitationTextarea
                value={citationTextEdit}
                placeholder="Text"
                onChange={(e) => setCitationTextEdit(e.target.value)}
              />
              <div className="flex flex-wrap items-center gap-2">
                {citation.figureUrls &&
                  citation.figureUrls.map((imageUrl) => (
                    <ImageContainer key={imageUrl}>
                      <ReferenceImage
                        imageUrl={imageUrl}
                        imageName={imageUrl.split("/").pop() || ""}
                      />
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleRemoveImageClick(imageUrl)}
                          >
                            <Cross2Icon className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>Remove Image</TooltipContent>
                      </Tooltip>
                    </ImageContainer>
                  ))}
                {referenceFiguresToAttach &&
                  referenceFiguresToAttach.map((imageUrl) => (
                    <ImageContainer key={imageUrl}>
                      <ReferenceImage
                        imageUrl={imageUrl}
                        imageName={imageUrl.split("/").pop() || ""}
                      />
                      <Tooltip>
                        <TooltipTrigger>
                          <Button
                            variant="destructive"
                            size="icon"
                            onClick={() => removeFromReferenceFiguresToAttach(imageUrl)}
                          >
                            <Cross2Icon className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>Remove Image</TooltipContent>
                      </Tooltip>
                    </ImageContainer>
                  ))}
              </div>
            </div>
          ) : (
            <div>
              <p className="font-bold mb-1">
                {formatCitationLocation(citation, isPatent)}
              </p>
              <p className="mb-1">{citation.text}</p>

              {citation.iprs && citation.iprs.length > 0 && (
                <Badge className="mb-2">
                  Cited in IPR(s): {Array.from(new Set(citation.iprs)).join(", ")}
                </Badge>
              )}

              <div className="flex flex-wrap items-center gap-2 mt-2">
                {citation.figureUrls &&
                  citation.figureUrls.map((imageUrl) => (
                    <ImageContainer key={imageUrl}>
                      <ReferenceImage
                        imageUrl={imageUrl}
                        imageName={imageUrl.split("/").pop() || ""}
                      />
                    </ImageContainer>
                  ))}
              </div>
            </div>
          )}
        </div>
        <div>
          <Tooltip>
            <TooltipTrigger asChild>
              <div>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => handleFeedbackClick("positive")}
                  disabled={disableFeedbackButtons}
                >
                  <ThumbsUpIcon
                    className={cn(
                      "h-4 w-4",
                      citationFeedback === "positive" && "text-blue-500",
                    )}
                  />
                </Button>
              </div>
            </TooltipTrigger>
            <TooltipContent>Positive feedback</TooltipContent>
          </Tooltip>
        </div>
        <div>
          <Tooltip>
            <TooltipTrigger asChild>
              <div>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => handleFeedbackClick("negative")}
                  disabled={disableFeedbackButtons}
                >
                  <ThumbsDownIcon
                    className={cn(
                      "h-4 w-4",
                      citationFeedback === "negative" && "text-red-500",
                    )}
                  />
                </Button>
              </div>
            </TooltipTrigger>
            <TooltipContent>Negative feedback</TooltipContent>
          </Tooltip>
        </div>
        <div>
          <Tooltip>
            <TooltipTrigger>
              <Button
                variant="ghost"
                size="icon"
                onClick={handleDeleteClick}
                disabled={disableFeedbackButtons}
              >
                <Cross2Icon className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Remove citation</TooltipContent>
          </Tooltip>
        </div>
      </div>

      {(isEditingCitation || addingCitationKey === citationKey) && (
        <>
          <div className="flex flex-col items-center gap-2 mb-3">
            {citationLocationError && (
              <p className="text-red-500 text-sm">
                At least one location field (page, paragraph, or columns/lines) is
                required
              </p>
            )}
            {lineColumnError && (
              <p className="text-red-500 text-sm">
                Both 'from' and 'to' values are required for columns/lines
              </p>
            )}
          </div>
          <div className="flex items-center justify-center gap-2 mb-3">
            <Tooltip>
              <TooltipTrigger>
                <Button variant="outline" size="icon" onClick={handleAttachImageClick}>
                  <FileIcon className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Attach Images</p>
              </TooltipContent>
            </Tooltip>

            <Button
              type="button"
              variant="outline"
              className="h-9"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isCitationSaving}
              onClick={handleSaveCitationClick}
              className="h-9"
            >
              Save Citation
            </LoadingButton>
            <CheckboxWithText
              label="Propagate changes to all citation instances"
              checked={propagateChanges}
              onCheckedChange={(checked) => setPropagateChanges(checked as boolean)}
            />
          </div>
        </>
      )}

      {contextMenuPosition && (
        <div
          style={{
            position: "fixed",
            top: contextMenuPosition.top,
            left: contextMenuPosition.left,
            zIndex: 1000,
          }}
          className="flex flex-col bg-background shadow-md rounded-md p-2 context-menu"
        >
          <Button
            variant="ghost"
            className="justify-start"
            onClick={() => {
              handleEditCitation();
              handleCloseContextMenu();
            }}
            disabled={isEditingCitation || isEditing}
          >
            <Pencil1Icon className="mr-2 h-4 w-4" /> Edit Citation
          </Button>
          <Button
            variant="ghost"
            className="justify-start"
            onClick={() => {
              handleViewInPatent();
              handleCloseContextMenu();
            }}
          >
            <EyeOpenIcon className="mr-2 h-4 w-4" /> View in Reference
          </Button>
        </div>
      )}

      <ImageUploadModal
        open={imageUploadModalOpen}
        handleClose={() => setImageUploadModalOpen(false)}
        handleSubmitFiles={handleAttachImagesToCitation}
      />

      <ReferenceFigureSelectModal
        open={figureSelectModalOpen}
        handleClose={() => setFigureSelectModalOpen(false)}
        handleAttachFigures={handleAttachFigures}
        refId={refId}
        existingFigureUrls={citation.figureUrls || []}
        isAddingCitation={addingCitationKey === citationKey}
        setReferenceFiguresToAttach={setReferenceFiguresToAttach}
      />

      <AddImageMenu
        mouseX={imageMenuState.mouseX}
        mouseY={imageMenuState.mouseY}
        handleClose={handleCloseAddImageMenu}
        onUploadClick={() => {
          setImageUploadModalOpen(true);
          handleCloseAddImageMenu();
        }}
        onBrowseClick={() => {
          setFigureSelectModalOpen(true);
          handleCloseAddImageMenu();
        }}
        displayBrowse={
          selectedReferences.find((ref) => ref.id === refId)?.figureUrls?.length > 0 &&
          selectedReferences.find((ref) => ref.id === refId)?.figureUrls?.length !==
            citation.figureUrls?.length
        }
      />

      <DocumentViewer
        open={showDocumentViewer}
        handleClose={handleClosePreview}
        referenceId={documentViewerDocument?.id}
        citationText={documentViewerCitationText || ""}
        selectedElement={chartData[index].claim_number}
        selectedElementType={selectedElementType}
        citations={chartData[index][refId].citations}
        startInChartMode={true}
      />
    </>
  );
};

export default ReferenceTableCitation;
