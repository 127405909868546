/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

export enum StatusType {
  ERROR = "error",
  SUCCESS = "success",
  UPLOADING = "uploading",
  PROCESSING = "processing",
  WARNING = "warning",
  INFO = "info",
  CHARTING = "charting",
  CONTEXT = "generating context",
}

export interface AlertMessage {
  key: string;
  message: string;
  severity: StatusType;
}

export interface ApiResponse<T = any> {
  success: boolean;
  data?: T;
  message?: string;
  error?: any;
  status?: number;
}

export enum EnvironmentTypes {
  PROD = "PROD",
  TEST = "TEST",
  DEV = "DEV",
}

export interface LoadingGroup {
  key: string; // id
  name: string;
  processType: string;
  messages: LoadingGroupItem[];
  // status: StatusType;
}

export interface LoadingGroupItem {
  name: string;
  status: StatusType;
  detail?: string;
}

// Standalone loading message
export interface LoadingMessage {
  key: string;
  message: string;
}

export interface Process {
  id: string;
  type: string;
  status: "pending" | "completed" | "error";
  projectId?: string;
  portfolioId?: string;
}

export enum ProcessType {
  ADD_REFERENCE = "add reference",
  GENERATE_CONTEXT = "generate context",
  CREATE_PROJECT = "create project",
  RECHART = "rechart",
  ADD_SUBJECT = "add subject",
  DOWNLOAD_CHART = "download chart",
  CREATE_PORTFOLIO = "create portfolio",
  ADD_OFFICE_ACTION = "add office action",
}

export enum BlockType {
  TEXT = "Text",
  HEADER = "Header",
  SECTION_HEADER = "Section Header",
  LIST = "List",
  LIST_ITEM = "ListItem",
  TITLE = "Title",
  FOOTER = "Footer",
  FIGURE = "Figure",
  TABLE = "Table",
  CAPTION = "Caption",
  UNKNOWN = "Unknown",
}
