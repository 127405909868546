/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DocumentBody, SubjectAbstractImages } from "@/components";
import { H4 } from "@/components/ui/typography";
import { Patent } from "@/types";
import he from "he";
import React from "react";

interface StandardLayoutProps {
  patent: Patent | null;
  citationText?: string;
  highlightedRef?: React.RefObject<HTMLDivElement>;
}

const StandardLayout: React.FC<StandardLayoutProps> = ({
  patent,
  citationText,
  highlightedRef,
}) => {
  if (!patent) return null;

  return (
    <>
      {/* Large screen layout */}
      <div className="hidden lg:flex lg:h-[calc(100vh-55px)]">
        <div className="custom-scrollbar px-3 py-2">
          {patent.title && <H4 className="mb-4">{he.decode(patent.title)}</H4>}
          <SubjectAbstractImages details={patent} />
          <DocumentBody document={patent} citationText={citationText} />
        </div>
      </div>
    </>
  );
};

export default StandardLayout;
