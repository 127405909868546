import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useDataTable } from "@/hooks";
import { useFeedbackChartDataStore } from "@/hooks/useFeedbackChartDataStore";
import { cn } from "@/lib/utils";
import { useProjectStore } from "@/store";
import { InvalidityCitation } from "@/types";
import { isLikelyPatentNumber } from "@/utils/dataUtils";
import { formatCitationLocation } from "@/utils/projectUtils";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@radix-ui/react-collapsible";
import { PlusIcon } from "@radix-ui/react-icons";
import { format, isValid } from "date-fns";
import { ThumbsDownIcon, ThumbsUpIcon } from "lucide-react";
import React, { useState } from "react";

interface ReferenceTableCollapsibleFeedbackModalProps {
  index: number;
  positiveFeedbackData: InvalidityCitation[];
  negativeFeedbackData: InvalidityCitation[];
  removedCitations: InvalidityCitation[];
  refId: string;
  claimNumber: string;
  currentProjectId: string;
}

const ReferenceTableCollapsibleFeedbackModal: React.FC<
  ReferenceTableCollapsibleFeedbackModalProps
> = ({
  index,
  positiveFeedbackData,
  negativeFeedbackData,
  removedCitations,
  refId,
  claimNumber,
  currentProjectId,
}) => {
  const { feedbackChartData, removeFeedbackCitation, addFeedbackCitation } =
    useFeedbackChartDataStore();
  const { handleSourceDeleteToggle } = useDataTable();
  const { currentProject } = useProjectStore();
  const [showPositiveCitations, setShowPositiveCitations] = useState(false);
  const [showNegativeCitations, setShowNegativeCitations] = useState(false);
  const [showRemovedCitations, setShowRemovedCitations] = useState(false);

  const isPatent = isLikelyPatentNumber(
    currentProject.references.find((r) => r.id === refId)?.number,
  );

  const formatDate = (date: string | Date | undefined) => {
    // TODO @christina: move to some utils
    if (!date) return "N/A";
    const parsedDate = new Date(date);
    return isValid(parsedDate)
      ? format(parsedDate, "MMM d, yyyy HH:mm")
      : "Invalid Date";
  };

  function pluralString(count: number, text: string): string {
    // TODO @christina: move to some utils
    return count > 1 ? `${text}s` : text;
  }

  const getCitationFeedback = (citation: InvalidityCitation) => {
    return feedbackChartData[currentProjectId]?.[refId]?.[claimNumber]?.find(
      (c) => c.id === citation.id,
    )?.feedback;
  };

  const handleFeedbackClick = (
    citation: InvalidityCitation,
    type: "positive" | "negative",
  ) => {
    const currentFeedback = getCitationFeedback(citation);
    if (currentFeedback === type) {
      removeFeedbackCitation(currentProjectId, refId, claimNumber, citation.id);
    } else {
      addFeedbackCitation(currentProjectId, refId, claimNumber, {
        ...citation,
        feedback: type,
      });
    }
  };

  const handleAddRemovedCitationBack = (item: InvalidityCitation) => {
    handleSourceDeleteToggle(index, refId, item.id, item, false, false);
  };

  return (
    <div className="flex pb-2">
      <Collapsible
        open={showNegativeCitations || showRemovedCitations || showPositiveCitations}
        onOpenChange={(open) => {
          if (!open) {
            setShowPositiveCitations(false);
            setShowNegativeCitations(false);
            setShowRemovedCitations(false);
          }
        }}
        className="space-y-2"
      >
        <div className="flex w-[250px] space-x-2">
          {positiveFeedbackData.length > 0 && (
            <CollapsibleTrigger asChild>
              <Button
                variant="ghost"
                size="sm"
                className={cn(
                  "justify-between",
                  "border border-gray-200 hover:border-gray-300",
                  "transition-all duration-10",
                  showPositiveCitations && "bg-accent",
                )}
                onClick={() => setShowPositiveCitations(!showPositiveCitations)}
              >
                <h4 className="text-sm font-semibold">
                  {pluralString(positiveFeedbackData.length, "Positive citation")} (
                  {positiveFeedbackData.length})
                </h4>
              </Button>
            </CollapsibleTrigger>
          )}
          {negativeFeedbackData.length > 0 && (
            <CollapsibleTrigger asChild>
              <Button
                variant="ghost"
                size="sm"
                className={cn(
                  "justify-between",
                  "border border-gray-200 hover:border-gray-300",
                  "transition-all duration-10",
                  showNegativeCitations && "bg-accent",
                )}
                onClick={() => setShowNegativeCitations(!showNegativeCitations)}
              >
                <h4 className="text-sm font-semibold">
                  {pluralString(negativeFeedbackData.length, "Negative citation")} (
                  {negativeFeedbackData.length})
                </h4>
              </Button>
            </CollapsibleTrigger>
          )}
          {removedCitations.length > 0 && (
            <CollapsibleTrigger asChild>
              <Button
                variant="ghost"
                size="sm"
                className={cn(
                  "justify-between",
                  "border border-gray-200 hover:border-gray-300",
                  "transition-all duration-10",
                  showRemovedCitations && "bg-accent",
                )}
                onClick={() => setShowRemovedCitations(!showRemovedCitations)}
              >
                <h4 className="text-sm font-semibold">
                  Removed citations ({removedCitations.length})
                </h4>
              </Button>
            </CollapsibleTrigger>
          )}
        </div>
        <CollapsibleContent className="space-y-2 pb-2">
          {showPositiveCitations &&
            positiveFeedbackData.map((item, index) => (
              <div
                key={`positive-${index}`}
                className="rounded-md border px-4 py-2 text-sm"
              >
                <p className="font-bold mb-1">
                  {formatCitationLocation(item, isPatent)}
                </p>
                <p className="mb-1">{item.text}</p>
              </div>
            ))}
          {showNegativeCitations &&
            negativeFeedbackData.map((item, index) => (
              <div key={index} className="rounded-md border px-4 py-2 text-sm">
                <p className="font-bold mb-1">
                  {formatCitationLocation(item, isPatent)}
                </p>
                <p className="mb-1">{item.text}</p>
              </div>
            ))}
          {showRemovedCitations &&
            removedCitations.map((item, index) => {
              const citationFeedback = getCitationFeedback(item);

              return (
                <div
                  key={index}
                  className="rounded-md border px-4 py-2 gap-3 text-sm flex justify-between items-start"
                >
                  <div className="flex-grow">
                    <div className="flex justify-between items-center mb-1">
                      <p className="font-bold">
                        {formatCitationLocation(item, isPatent)}
                      </p>
                      <p className="text-xs text-gray-500 ml-2 italic">
                        {item.updatedAt
                          ? "Removed at " + formatDate(item.updatedAt)
                          : ""}
                        {item.updatedById ? " by " + item.updatedById : ""}
                      </p>
                    </div>
                    <p className="mb-1">{item.text}</p>
                  </div>

                  <div className="flex-shrink-0">
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant={item.feedback === "positive" ? "default" : "ghost"}
                          size="icon"
                          onClick={() => handleFeedbackClick(item, "positive")}
                          // disabled={disableFeedbackButtons}
                          className={cn(
                            item.feedback === "positive" &&
                              "bg-blue-500 hover:bg-blue-600",
                          )}
                        >
                          <ThumbsUpIcon
                            className={cn(
                              "h-4 w-4",
                              citationFeedback === "positive" && "text-blue-500",
                            )}
                          />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>Positive feedback</TooltipContent>
                    </Tooltip>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleFeedbackClick(item, "negative")}
                        >
                          <ThumbsDownIcon
                            className={cn(
                              "h-4 w-4",
                              citationFeedback === "negative" && "text-red-500",
                            )}
                          />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>Negative feedback</TooltipContent>
                    </Tooltip>
                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          size="icon"
                          variant="ghost"
                          className="ml-2 flex-shrink-0"
                          onClick={() => handleAddRemovedCitationBack(item)}
                        >
                          <PlusIcon />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>Add citation back to chart</TooltipContent>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
};

export default ReferenceTableCollapsibleFeedbackModal;
