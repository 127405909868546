/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { ElementList, Loader, ProjectPage } from "@/components";
import { useLlm, useProject } from "@/hooks";
import { cn } from "@/lib/utils";
import { useAppStateStore, useProjectStore } from "@/store";
import { ElementType, ProjectType } from "@/types";
import React, { useEffect } from "react";
import Abstract from "./components/Abstract";
import StandardLayout from "./components/StandardLayout";
import SubjectPatentLayout from "./components/SubjectPatentLayout";

const SubjectPage: React.FC = () => {
  const { currentProject, currentSubject, currentPortfolio } = useProjectStore();
  const { isLoading } = useAppStateStore();
  const { getProjectReferences } = useProject();
  const { getPatentContext } = useLlm();

  // Fetch data in the background after subject data is loaded
  useEffect(() => {
    if (currentProject?.id && !isLoading) {
      const fetchData = async () => {
        await Promise.all([
          getProjectReferences(currentProject.id),
          getPatentContext(currentProject.id),
        ]);
      };

      fetchData();
    }
  }, [currentProject?.id, isLoading]);

  // Page name
  const pageName = currentProject.name ? `${currentProject.name} - Subject` : "Subject";

  const NonPatentSubjectContent = () => (
    <div className="flex h-[calc(100vh-55px)]">
      <div
        className={cn("w-[100%] overflow-y-auto overflow-x-hidden hide-scrollbar px-3")}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            {currentProject.type === ProjectType.APP && <Abstract />}
            <ElementList
              list={currentProject.features}
              itemType={ElementType.FEATURE}
              startCollapsed={false}
              editingBool={true}
            />
          </div>
          <div>
            <ElementList
              list={currentProject.claims}
              itemType={ElementType.CLAIM}
              startCollapsed={false}
              editingBool={true}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const StandardSubjectContent = () => <StandardLayout patent={currentSubject} />;

  return (
    <ProjectPage pageName={pageName}>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {currentPortfolio?.type === ProjectType.SEP ? (
            <StandardSubjectContent />
          ) : currentSubject?.number ? (
            <SubjectPatentLayout patent={currentSubject} />
          ) : (
            <NonPatentSubjectContent />
          )}
        </div>
      )}
    </ProjectPage>
  );
};

export default SubjectPage;
