/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi, useViz } from "@/hooks";
import { useClearFeedbackDataByID } from "@/hooks/useFeedbackData";
import { useAppStateStore, useProjectStore } from "@/store";
import { ApiResponse, ElementType, ParentType } from "@/types";
import { ApplicationDocument, DocumentType, OfficeAction } from "@/types/project";
import { toCamelCase } from "@/utils/dataUtils";
import { filterDocumentSections } from "@/utils/projectUtils";

/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useProject = () => {
  const { postRequest, getRequest, handleError, postRequestFile } = useApi();
  const {
    updateCurrentProject,
    selectedReferences,
    removeSelectedReferences,
    updateCurrentSubject,
    updateChartData,
    updateSummaryChartData,
    updateSelectedReferences,
    updateSelectedElements,
    updateSelectedColors,
    updateSelectedElementType,
    updateElementEditIndex,
    updateApplicationDocuments,
    updateCurrentProjectId,
    currentParent,
    currentProject,
    currentProjectId,
    currentPortfolioId,
    currentPortfolio,
  } = useProjectStore();
  const { updateIsLoading, updateIsReferencesLoading } = useAppStateStore();
  const { getPortfolioMetadata } = useViz();
  const clearFeedbackChartDataForReferences = useClearFeedbackDataByID();

  /**
   * @description Adds a user to a project
   * @param {string} projectId - The id of the project to add the user to
   * @param {string} userEmail - The email of the user to add to the project
   */
  const addUserToProject = async (
    projectId: string,
    userId: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await postRequest("post_add_user_to_project", {
        user_id: userId,
        project_id: projectId,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, `Error adding users to the project`);
    }
  };

  /**
   * @description Removes a user from a project
   * @param {string} projectId - The id of the project to remove the user from
   * @param {string} userEmail - The email of the user to remove from the project
   */
  const removeUserFromProject = async (
    projectId: string,
    userId: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await postRequest("post_remove_user_from_project", {
        user_id: userId,
        project_id: projectId,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, `Failed to remove users from the project`);
    }
  };

  /**
   * @description Fetches all users for a project
   * @param {string} projectId - The id of the project to fetch users for
   */
  const getUsersForProject = async (projectId: string): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_users_for_project", {
        project_id: projectId,
      });
      return {
        success: true,
        data: {
          users_on_project: response.data.users_on_project,
          shareable_users: response.data.shareable_users,
        },
      };
    } catch (error) {
      return handleError(error, "Error fetching users for project");
    }
  };

  /**
   * @description Checks if a user has access to a project
   * @param {string} projectId - The id of the project to check access for
   */
  const getUserProjectAccessCheck = async (
    projectId: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_user_project_access_check", {
        project_id: projectId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error fetching user project access check");
    }
  };

  /**
   * @description Fetches the owner of a project
   * @param {string} projectId - The id of the project to fetch the owner for
   */
  const getProjectOwnerData = async (projectId: string): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_project_owner_data", {
        project_id: projectId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error fetching project owner data");
    }
  };

  /**
   * @description Requests access to a project
   * @param {string} projectId - The id of the project to request access to
   * @param {string} userEmail - The email of the user to request access to
   * @param {string} projectNickname - The nickname of the project
   */
  const requestProjectAccess = async (
    projectId: string,
    userEmail: string,
    projectNickname: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_request_project_access_email", {
        project_nickname: projectNickname,
        owner_email: userEmail,
        project_id: projectId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error requesting access to project");
    }
  };

  /**
   * @description Sends an email to grant access to a project
   * @param {string} email - The email of the user to grant access to
   * @param {string} projectName - The name of the project
   * @param {string} projectId - The id of the project
   */
  const sendProjectAccessGrantedEmail = async (
    email: string,
    projectName: string,
    projectId: string,
  ): Promise<ApiResponse<any>> => {
    try {
      const response = await getRequest("get_granted_project_access_email", {
        granted_email: email,
        project_nickname: projectName,
        project_id: projectId,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error getting granted project access email");
    }
  };

  /**
   * @description Deletes a reference from a project
   * @param {string} projectId - The id of the project to delete the references from
   * @param {string[]} referenceIds - The ids of the references to delete
   */
  const deleteReferencesFromProject = async (
    projectId: string,
    referenceIds: string[],
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("delete_references_from_project", {
        project_id: projectId,
        reference_ids: referenceIds,
      });
      clearFeedbackChartDataForReferences(projectId, referenceIds);

      // Update current project if it is the current project
      if (currentProjectId === projectId) {
        const newReferenceIds = currentProject.referenceIds.filter(
          (id) => !referenceIds.includes(id),
        );
        const newReferences = currentProject.references.filter(
          (ref) => !referenceIds.includes(ref.id),
        );
        const newDocumentIds = currentProject.documentIds.filter(
          (id) => !referenceIds.includes(id),
        );
        const newDocumentsToNumbers = Object.entries(
          currentProject.documentsToNumbers || {},
        )
          .filter(([id]) => !referenceIds.includes(id))
          .reduce((acc, [id, num]) => ({ ...acc, [id]: num }), {});

        const newDocumentsToNicknames = Object.entries(
          currentProject.documentsToNicknames || {},
        )
          .filter(([id]) => !referenceIds.includes(id))
          .reduce((acc, [id, nickname]) => ({ ...acc, [id]: nickname }), {});

        const newSummaries = Object.entries(currentProject.summaries || {})
          .filter(([id]) => !referenceIds.includes(id))
          .reduce((acc, [id, summary]) => ({ ...acc, [id]: summary }), {});

        updateCurrentProject({
          ...currentProject,
          referenceIds: newReferenceIds,
          references: newReferences,
          documentIds: newDocumentIds,
          documentsToNumbers: newDocumentsToNumbers,
          documentsToNicknames: newDocumentsToNicknames,
          summaries: newSummaries,
        });
      }

      // Remove reference from selected references if it is selected
      if (selectedReferences !== null) {
        removeSelectedReferences(referenceIds);
      }

      // Update portfolio if the project is in the current portfolio
      if (
        currentParent === ParentType.PORTFOLIO &&
        currentPortfolio.projects?.map((p) => p.id).includes(currentProjectId)
      ) {
        await getPortfolioMetadata(currentPortfolioId);
      }

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      console.error("Error details:", error);
      return handleError(error, "Error deleting reference from project");
    }
  };

  /**
   * @description Fetches the project metadata
   * @param {string} projectId - The id of the project to fetch the metadata for
   */
  const getProjectMetadata = async (
    projectId: string,
    projectName: string = "",
    includeReferences: boolean = false,
  ): Promise<ApiResponse> => {
    try {
      updateIsLoading(projectId);
      if (includeReferences) {
        updateIsReferencesLoading(true);
      }
      updateCurrentProjectId(projectId);
      updateCurrentProject({
        id: projectId,
        name: projectName,
      });

      const payload: any = {
        project_id: projectId,
      };
      if (includeReferences) {
        payload.include_references = "true";
      }
      const response = await getRequest("get_project_metadata", payload);
      const projectMetadata = response.data;
      const name = projectName || projectMetadata.name;

      // Update project details in the store
      const projectUpdate = {
        id: projectMetadata.id,
        name: projectMetadata.name,
        subjectId: projectMetadata.subject?.id, // Added optional chaining
        referenceIds: projectMetadata?.documents
          ? projectMetadata?.documents?.map((reference: any) => reference.id)
          : null,
        references: projectMetadata?.documents || null,
        documentIds: projectMetadata?.documents
          ? [
              projectMetadata.subject?.id, // Added optional chaining
              ...projectMetadata.documents.map((reference: any) => reference.id),
            ]
          : null,
        priorityDate: projectMetadata.priority_date,
        keywords: projectMetadata.keywords,
        type: projectMetadata.type,
        owner: projectMetadata.created_by,
        documentsToNicknames: projectMetadata.documents_to_nicknames,
        introductionLanguage: projectMetadata.introduction_language,
        features: projectMetadata.features,
        claims: projectMetadata.claims,
        documentsToNumbers: projectMetadata.documents_to_numbers || null,
        context: null,
        summaries: projectMetadata.summaries,
        enablement: projectMetadata.enablement,
        indefiniteness: projectMetadata.indefiniteness,
        writtenDescription: projectMetadata.written_description,
      };
      updateCurrentProject(projectUpdate);

      if (projectMetadata.subject) {
        const subjectDetails = toCamelCase(projectMetadata.subject);
        const strippedSubjectBody = filterDocumentSections(subjectDetails.body);

        const subjectUpdate = {
          ...subjectDetails,
          body: strippedSubjectBody,
        };
        updateCurrentSubject(subjectUpdate);

        // Update the subject in currentProject as well
        updateCurrentProject({
          ...projectUpdate,
          subject: subjectUpdate,
        });

        const storeState = useProjectStore.getState();
      } else {
        console.warn("No subject data found in project metadata");
      }

      updateSelectedElements([]);
      updateSelectedColors([]);
      updateSelectedElementType(ElementType.CLAIM);
      updateSelectedReferences([]);
      updateElementEditIndex(-1);
      updateSummaryChartData([]);
      updateChartData([]);
      return { success: true, data: projectMetadata };
    } catch (error) {
      console.error("Error in getProjectMetadata:", error);
      return handleError(error, "Error fetching project metadata");
    } finally {
      updateIsLoading(null);
      if (includeReferences) {
        updateIsReferencesLoading(false);
      }
    }
  };

  const getOfficeActions = async (projectId: string): Promise<ApiResponse> => {
    try {
      const response = await getRequest("get_office_actions", {
        project_id: projectId,
      });
      const officeActionsResponse = response.data;
      const officeActions: ApplicationDocument[] = officeActionsResponse.map(
        (oa: any) => ({
          id: oa.id,
          document_type: DocumentType.OFFICE_ACTION,
          document: toCamelCase(oa) as OfficeAction,
        }),
      );
      updateApplicationDocuments(officeActions);

      return { success: true, data: officeActionsResponse };
    } catch (error) {
      return handleError(error, "Error fetching office actions");
    }
  };

  /**
   * @description Fetches the project metadata
   * @param {string} projectId - The id of the project to fetch the metadata for
   */
  const getProjectReferences = async (
    projectId: string,
    // currentProjectIdString: string = "",
    showLoading: boolean = true,
  ): Promise<ApiResponse> => {
    try {
      const effectiveCurrentProjectId = projectId;
      if (showLoading) {
        updateIsReferencesLoading(true);
      }
      const response = await getRequest("get_project_references", {
        project_id: effectiveCurrentProjectId,
      });
      const projectReferences = response.data;
      console.log("projectReferences", projectReferences);

      if (projectId === currentProjectId) {
        updateCurrentProject({
          ...currentProject,
          referenceIds: projectReferences.documents.map(
            (reference: any) => reference.id,
          ),
          references: toCamelCase(projectReferences.documents, true),
          documentIds: [
            projectReferences.subject.id,
            ...projectReferences.documents.map((reference: any) => reference.id),
          ],
          documentsToNumbers: projectReferences.documents_to_numbers,
          documentsToNicknames: projectReferences.documents_to_nicknames,
        });
      }
      return { success: true, data: projectReferences };
    } catch (error) {
      return handleError(error, "Error fetching project references");
    } finally {
      if (showLoading) {
        updateIsReferencesLoading(false);
      }
    }
  };

  /**
   * @description Get project metadata and summary chart data
   * @param {string} projectId - The id of the project to fetch the data for
   */
  const fetchProjectData = async (projectId: string): Promise<ApiResponse> => {
    try {
      const response = await getProjectMetadata(projectId);
      // if (!response.success) {
      //   addErrorMessage(response.message);
      // }
      const projectReferences = await getProjectReferences(projectId);
      // if (!projectReferences.success) {
      //   addErrorMessage(projectReferences.message);
      // }
    } catch (error) {
      return handleError(error, "Error fetching project data");
    }
  };

  /**
   * @description Adds a PDF to a project
   * @param {string} projectId - The id of the project to add the PDF to
   * @param {File} file - The file to add to the project
   * @param {object} options - Additional options to pass to the request
   */
  const addPDFToProject = async (
    projectId: string,
    file: File,
    is_portfolio_creation: boolean,
  ): Promise<ApiResponse> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("project_id", projectId);

    try {
      const response = await postRequestFile("post_sync_reducto_job", formData);
      if (!is_portfolio_creation && response.data) {
        await addToDocumentsAdded(
          currentParent === ParentType.PORTFOLIO
            ? currentPortfolioId
            : currentProjectId,
          [response.data.reference_id],
          currentParent === ParentType.PORTFOLIO,
        );
      }

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Failed to add reference to project");
    }
  };

  /**
   * @description Adds multiple references to a project
   * @param {string} projectId - The id of the project to add the references to
   * @param {array} reference_numbers - The numbers of the references to add to the project
   * @param {string} is_citation - Whether the references are citations or not
   */
  const addReferencesToProject = async (
    projectId: string,
    referenceNumbers: string[],
    isCitation: boolean = false,
    isPortfolioCreation: boolean = false,
    isCurrentParentPortfolio: boolean = false,
    portfolioId: string = "",
    preprocessed: boolean = false,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_add_references_to_project", {
        project_id: projectId,
        reference_numbers: referenceNumbers,
        is_citation: isCitation,
        is_portfolio_creation: isPortfolioCreation,
        preprocessed,
      });

      const referenceIds = response.data.map((doc: any) => doc.reference_id);

      console.log("portfolioId", portfolioId);
      await addToDocumentsAdded(
        isCurrentParentPortfolio ? portfolioId : projectId,
        referenceIds,
        isCurrentParentPortfolio,
      );

      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(
        error,
        `Failed to add references ${referenceNumbers} to the project`,
      );
    }
  };

  const deleteProject = async (projectId: string): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_delete_project", {
        project_id: projectId,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error deleting project");
    }
  };

  /**
   * @description Updates documents to added documents for billing
   * @param {string} id - The id of the document to update the details for
   * @param {array} referenceIds - The ids of the references to update the details for
   * @param {boolean} is_portfolio - Whether the documents are in a portfolio
   */
  const addToDocumentsAdded = async (
    id: string,
    referenceIds: string[],
    is_portfolio: boolean,
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_add_to_documents_added", {
        id: id,
        reference_ids: referenceIds.filter((id) => id && id.trim() !== ""),
        is_portfolio: is_portfolio,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error adding to documents added");
    }
  };

  /**
   * @description Updates the project details
   * @param {string} project_id - The id of the project to update the details for
   * @param {object} options - Additional options to pass to the request
   */
  const updateProjectDetails = async (
    project_id: string,
    options: any,
  ): Promise<ApiResponse> => {
    const payload: { [key: string]: any } = {
      project_id: project_id,
    };

    // Append additional options to payload if they exist
    Object.keys(options).forEach((key) => {
      if (options[key]) {
        payload[key] = options[key];
      }
    });

    try {
      const response = await postRequest("post_update_project_details", payload);
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error updating project details");
    }
  };

  /**
   * @description Preprocesses references
   * @param {array} referenceNumbers - The numbers of the references to preprocess
   */
  const preprocessReferences = async (
    referenceNumbers: string[],
  ): Promise<ApiResponse> => {
    try {
      const response = await postRequest("post_preprocess_references", {
        reference_numbers: referenceNumbers,
      });
      return { success: true, data: response.data };
    } catch (error) {
      return handleError(error, "Error preprocessing references");
    }
  };

  return {
    addUserToProject,
    removeUserFromProject,
    getUsersForProject,
    getUserProjectAccessCheck,
    getProjectOwnerData,
    requestProjectAccess,
    sendProjectAccessGrantedEmail,
    getProjectMetadata,
    addPDFToProject,
    addReferencesToProject,
    deleteProject,
    deleteReferencesFromProject,
    addToDocumentsAdded,
    fetchProjectData,
    preprocessReferences,
    updateProjectDetails,
    getProjectReferences,
    getOfficeActions,
  };
};

export default useProject;
