/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import MultiDocumentSelect from "@/components/files/MultiDocumentSelect";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { LoadingButton } from "@/components/ui/loading-button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useLlm } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { ElementType } from "@/types";
import React, { useEffect, useState } from "react";

interface PruneInvalidityScoreModalProps {
  open: boolean;
  handleClose: () => void;
  fetchElementChartPageData: () => void;
}

/**
 * @description Prune invalidity charts by citation score
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {function} fetchElementChartPageData - Function to fetch the element chart page data.
 */
const PruneInvalidityScoreModal: React.FC<PruneInvalidityScoreModalProps> = ({
  open,
  handleClose,
  fetchElementChartPageData,
}) => {
  const { pruneInvalidityScore } = useLlm();
  const { addErrorMessage } = useAppStateStore();
  const { currentProjectId, selectedElementType } = useProjectStore();

  const [selectedReferences, setSelectedReferences] = useState<string[]>([]);
  const [isPruneLoading, setIsPruneLoading] = useState(false);
  const [score, setScore] = useState(50);

  useEffect(() => {
    resetModal();
  }, [open]);

  const resetModal = () => {
    setSelectedReferences([]);
    setScore(50);
  };

  const onClose = () => {
    resetModal();
    handleClose();
  };

  const handleConfirmPrune = async () => {
    setIsPruneLoading(true);

    const pruneResponse = await pruneInvalidityScore(
      currentProjectId,
      selectedReferences,
      score,
      selectedElementType == ElementType.FEATURE,
    );
    if (!pruneResponse.success) {
      addErrorMessage(pruneResponse.message);
      return;
    }

    fetchElementChartPageData();
    setIsPruneLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Prune citations by score</DialogTitle>
          <DialogDescription>
            Remove the weakest selected percentile citations for the selected
            reference(s). This action cannot be undone.
          </DialogDescription>
        </DialogHeader>

        <div className="flex items-center gap-2">
          <Select
            value={score.toString()}
            onValueChange={(value) => setScore(Number(value))}
            disabled={isPruneLoading}
          >
            <SelectTrigger className="w-[100px]">
              <SelectValue placeholder="Select percentile" />
            </SelectTrigger>
            <SelectContent>
              {[90, 80, 70, 60, 50, 40, 30, 20, 10].map((val) => (
                <SelectItem key={val} value={val.toString()}>
                  {val} %
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <MultiDocumentSelect
            handleChange={setSelectedReferences}
            general={true}
            disabled={isPruneLoading}
          />
        </div>

        <DialogFooter>
          <div className="flex items-center justify-end gap-2">
            <Button
              variant="outline"
              onClick={() => onClose()}
              disabled={isPruneLoading}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="destructive"
              onClick={handleConfirmPrune}
              loading={isPruneLoading}
              disabled={isPruneLoading}
            >
              {isPruneLoading ? "Pruning..." : "Prune"}
            </LoadingButton>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PruneInvalidityScoreModal;
