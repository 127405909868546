import {
  Table,
  TableBody,
  TableCellCondensed,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import React from "react";

interface ParseHtmlOptions {
  TableComponent?: React.ComponentType<any>;
  TableHeaderComponent?: React.ComponentType<any>;
  TableBodyComponent?: React.ComponentType<any>;
  TableRowComponent?: React.ComponentType<any>;
  TableHeadComponent?: React.ComponentType<any>;
  TableCellComponent?: React.ComponentType<any>;
}

/**
 * Converts HTML string content into React elements
 * @param {string} html - The HTML string to parse
 * @param {ParseHtmlOptions} options - Optional component overrides for table elements
 * @returns {React.ReactNode} - The parsed content as React elements
 */
export const parseHtml = (
  html: string,
  options: ParseHtmlOptions = {},
  citationText?: string,
): React.ReactNode => {
  const {
    TableComponent = Table,
    TableHeaderComponent = TableHeader,
    TableBodyComponent = TableBody,
    TableRowComponent = TableRow,
    TableHeadComponent = TableHead,
    TableCellComponent = TableCellCondensed,
  } = options;

  const parser = new DOMParser();
  const content = parser.parseFromString(html, "text/html");

  const highlightText = (text: string): React.ReactNode => {
    if (!citationText || !text.trim()) return text;

    const normalizedText = text.toLowerCase();
    const normalizedSearch = citationText.toLowerCase();
    const index = normalizedText.indexOf(normalizedSearch);

    if (index === -1) return text;

    return (
      <>
        {text.slice(0, index)}
        <span className="bg-blue-100">
          {text.slice(index, index + citationText.length)}
        </span>
        {text.slice(index + citationText.length)}
      </>
    );
  };

  const processNode = (node: Node): React.ReactNode => {
    if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim()) {
      return <span key={crypto.randomUUID()}>{highlightText(node.textContent)}</span>;
    }

    if (node instanceof HTMLTableElement) {
      const rows = Array.from(node.querySelectorAll("tr"));
      const hasHeader = rows[0]?.querySelectorAll("th").length > 0;

      return (
        <TableComponent key={crypto.randomUUID()} className="my-4 border">
          {hasHeader && (
            <TableHeaderComponent>
              <TableRowComponent>
                {Array.from(rows[0].querySelectorAll("th")).map((cell, i) => (
                  <TableHeadComponent key={i}>{cell.textContent}</TableHeadComponent>
                ))}
              </TableRowComponent>
            </TableHeaderComponent>
          )}
          <TableBodyComponent>
            {rows.slice(hasHeader ? 1 : 0).map((row, i) => (
              <TableRowComponent key={i}>
                {Array.from(row.querySelectorAll("td")).map((cell, j) => (
                  <TableCellComponent key={j}>{cell.textContent}</TableCellComponent>
                ))}
              </TableRowComponent>
            ))}
          </TableBodyComponent>
        </TableComponent>
      );
    }

    if (node instanceof HTMLElement) {
      const children = Array.from(node.childNodes).map(processNode);
      return (
        <div key={crypto.randomUUID()} className="inline">
          {children}
        </div>
      );
    }

    return null;
  };

  return processNode(content.body);
};

export const showBoldText = (text: string) => {
  // If text is in array format, take the first element
  const cleanText = text.startsWith("[") ? JSON.parse(text)[0] : text;

  // Split with a more forgiving regex that handles newlines
  const parts = cleanText.split(/(<bold>[\s\S]*?<\/bold>)/g);

  return parts.map((part, index) => {
    const isBold = part.match(/<bold>([\s\S]*?)<\/bold>/);
    if (isBold) {
      // Extract just the text between the tags
      const boldText = isBold[1];
      return <strong key={index}>{boldText}</strong>;
    }
    return part;
  });
};
