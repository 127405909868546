/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { CondensedParagraph } from "@/components/ui/typography";
import { useDataTable } from "@/hooks";
import { useProjectStore } from "@/store";
import { ChartColor, DocumentBodySection, InvalidityCitation } from "@/types";
import { isLikelyPatentNumber } from "@/utils/dataUtils";
import { formatCitationLocation } from "@/utils/projectUtils";
import { Cross2Icon, PlusIcon } from "@radix-ui/react-icons";
import { PaintBucketIcon } from "lucide-react";
import React, { useEffect, useState } from "react";

// Add this type definition
type CitationElement = {
  label: string;
  color: string;
};

interface DocumentBodyCitationProps {
  citation: any;
  index: number;
  refId: string;
  claimNumber: string;
  citationIndex: number;
  invalidityId: string;
  onCitationUpdate: (citation: InvalidityCitation) => void;
  onCitationRemove: (citation: InvalidityCitation) => void;
  disabled?: boolean;
  citationsElements?: CitationElement[];
  viewCitationElements?: boolean;
  scrollToClaim?: (claimLabel: string) => void;
  citationFormat?: string;
}

/**
 * @description Reference table citation to display a citation in the table
 * @param {string} citationKey - The key of the citation.
 * @param {any} citation - The citation to display.
 * @param {number} index - The index of the citation.
 * @param {string} refId - The id of the reference.
 * @param {string} claimNumber - The claim number of the citation.
 * @param {number} citationIndex - The index of the citation.
 * @param {string} invalidityId - The id of the invalidity.
 * @param {boolean} isEditing - Whether the citation is being edited.
 * @param {function} setIsEditing - Function to set the editing state.
 * @param {string} addingCitationKey - The key of the citation being added.
 * @param {function} setAddingCitationKey - Function to set the adding citation key.
 * @param {string} color - The color of the citation.
 * @param {boolean} isHighlighted - Whether the citation is highlighted.
 * @param {boolean} enableEditing - Whether the citation is editable.
 * @param {boolean} viewCitationElements - Whether to view the citation elements.
 */
const DocumentBodyCitation: React.FC<DocumentBodyCitationProps> = ({
  citation,
  index,
  refId,
  claimNumber,
  citationIndex,
  invalidityId,
  onCitationUpdate,
  onCitationRemove,
  disabled,
  citationsElements,
  viewCitationElements,
  scrollToClaim,
  citationFormat,
}) => {
  const {
    addCitationFromPatentViewer,
    handleSourceDeleteToggle,
    handleSourceColorUpdate,
  } = useDataTable();
  const { chartData, currentProject } = useProjectStore();
  const [isChangingColor, setIsChangingColor] = useState<boolean>(false);
  const [rowIndex, setRowIndex] = useState<number>(0);

  const isPatent = isLikelyPatentNumber(
    currentProject.references.find((r) => r.id === refId)?.number,
  );

  useEffect(() => {
    const rowIndex = chartData.findIndex((row) => row.claim_number === claimNumber);

    setRowIndex(rowIndex);
  }, [chartData, claimNumber]);

  const handleAddClick = async (citation: DocumentBodySection) => {
    const citationWithColor = {
      ...citation,
      color: ChartColor.GREEN,
      page: citation.location.page,
      paragraph: citation.location.paragraph,
      lines: citation.location.lines,
      columns: citation.location.columns,
      removed: false,
      updatedAt: new Date().toISOString(),
    } as InvalidityCitation;

    let newCitation: InvalidityCitation;
    newCitation = await addCitationFromPatentViewer(
      rowIndex,
      refId,
      citationIndex,
      citation.text,
      {
        page: citationWithColor.page,
        paragraph: citationWithColor.paragraph,
        lines: citationWithColor.lines,
        columns: citationWithColor.columns,
      },
      true, // isAddingCitation
      [],
      invalidityId,
      [],
      () => {},
      () => {},
      claimNumber,
    );

    onCitationUpdate(newCitation);
  };

  const handleDeleteClick = () => {
    const isAddingCitation = false;
    handleSourceDeleteToggle(
      rowIndex,
      refId,
      citation.id,
      citation,
      isAddingCitation,
      true,
    );
    onCitationRemove(citation);
  };

  const handleColorChange = (color: string) => {
    handleSourceColorUpdate(rowIndex, citationIndex, color, refId, citation.id, false);
    setIsChangingColor(false);
    onCitationUpdate({ ...citation, color });
  };

  const getCitation = () => {
    const formattedCitation = formatCitationLocation(
      citation.location,
      isPatent,
      citationFormat,
    );
    if (!formattedCitation) {
      return citation.location.paragraph || "";
    }
    return formattedCitation;
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const getCitationText = () => {
    return citation.text || citation.html?.replace(/<[^>]*>/g, "");
  };

  const getTextWithCitations = () => {
    return `${getCitationText()} ${getCitation()}`;
  };

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <div
          className="w-full flex flex-row "
          data-paragraph={citation.paragraph || citation.para_num}
        >
          <div className="w-[75px] shrink-0 pl-2 pt-2">
            <CondensedParagraph className="text-xs text-left">
              {getCitation()}
            </CondensedParagraph>
          </div>

          <div className="flex-1 px-4 min-w-0">
            <div className="w-full">
              <div className="min-w-0">
                <div
                  className="rounded-md p-2"
                  style={{
                    backgroundColor: citation.isCited ? citation.color : "transparent",
                  }}
                >
                  <CondensedParagraph>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: citation.html || citation.text,
                      }}
                    />
                  </CondensedParagraph>
                </div>
                {citationsElements && viewCitationElements && (
                  <div className="flex flex-wrap gap-2 mt-2">
                    {citationsElements.map((citation, index) => (
                      <span
                        key={`${citation.label}-${index}`}
                        className="px-2 py-1 text-xs rounded-full text-black cursor-pointer hover:opacity-80 border border-gray-300"
                        onClick={() => scrollToClaim?.(citation.label)}
                      >
                        {citation.label}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-end ">
            {citation.isCited ? (
              <div className="flex flex-col gap-2">
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={handleDeleteClick}
                      disabled={disabled}
                    >
                      <Cross2Icon className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Remove citation from chart</TooltipContent>
                </Tooltip>
                <Popover open={isChangingColor} onOpenChange={setIsChangingColor}>
                  <PopoverTrigger asChild>
                    <Button variant="ghost" size="icon" disabled={disabled}>
                      <PaintBucketIcon className="h-4 w-4" />
                    </Button>
                  </PopoverTrigger>
                  {citation.isCited && (
                    <PopoverContent className="w-auto p-1">
                      <div className="flex flex-row">
                        {[ChartColor.GREEN, ChartColor.YELLOW, ChartColor.RED].map(
                          (color) =>
                            citation.color !== color && (
                              <div
                                className="w-9 h-9 m-1 cursor-pointer rounded-md"
                                style={{ backgroundColor: color }}
                                onClick={() => handleColorChange(color)}
                              />
                            ),
                        )}
                      </div>
                    </PopoverContent>
                  )}
                </Popover>
              </div>
            ) : (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleAddClick(citation)}
                    disabled={disabled}
                  >
                    <PlusIcon className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Add citation to chart</TooltipContent>
              </Tooltip>
            )}
          </div>
        </div>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem onClick={() => copyToClipboard(getCitationText())}>
          Copy
        </ContextMenuItem>
        <ContextMenuItem onClick={() => copyToClipboard(getTextWithCitations())}>
          Copy with Citation
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
};

export default DocumentBodyCitation;
