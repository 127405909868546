import { ChartColor, CitationLocation, InvalidityCitation } from "@/types";
import { isLikelyPatentNumber } from "@/utils/dataUtils";

/**
 * @param {string} subjectId - the subjectId to be formatted
 * @description Formats the subjectId based on its length
 */
export const splitId = (subjectId: string) => {
  // if the subjectId is "No Reference Documents" return "No Reference Documents"
  if (!subjectId || subjectId === "No Reference Documents") {
    return subjectId;
  }

  // If the subjectId is less than 3 characters, return the subjectId as is
  if (subjectId.length < 3) {
    return subjectId;
  }

  // if the subject id ends with a file extension, return it as is
  if (subjectId.endsWith(".pdf")) {
    return subjectId;
  }

  // Check if the first two characters are letters
  let prefix = "";
  if (/^[A-Za-z]{2}/.test(subjectId)) {
    prefix = subjectId.slice(0, 2); // Extract first two letters as prefix
    subjectId = subjectId.slice(2); // Remove the extracted prefix from the original subjectId
  }
  let withoutPrefix = subjectId;

  // Check and remove the suffix 'A1' or 'B2' if present
  let suffix = "";
  const suffixMatch = withoutPrefix.match(/[A-Z]\d{0,2}$/);
  if (suffixMatch) {
    suffix = suffixMatch[0];
    withoutPrefix = withoutPrefix.slice(0, -suffix.length);
  }

  // Formatting the number part based on prefix
  let formattedNumber;

  switch (prefix) {
    case "US":
      if (withoutPrefix.length > 8) {
        // Publication number format (e.g., 2010/0234553)
        formattedNumber = `${withoutPrefix.slice(0, 4)}/${withoutPrefix.slice(4)}`;
      } else {
        // Regular patent number format
        formattedNumber = withoutPrefix.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      break;
    case "JP":
      if (/^[A-Z]/.test(withoutPrefix)) {
        // Publication number format (e.g., S63-2956)
        formattedNumber = `${withoutPrefix.slice(0, 3)}-${withoutPrefix.slice(3)}`;
      } else if (withoutPrefix.length > 8) {
        // Publication number format (e.g., 2010-0234553)
        formattedNumber = `${withoutPrefix.slice(0, 4)}-${withoutPrefix.slice(4)}`;
      } else {
        // Regular patent number format
        formattedNumber = withoutPrefix.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      break;
    case "KR":
      if (withoutPrefix.slice(0, 2) === "10") {
        // Publication number format (e.g., 10-1054626)
        formattedNumber = `${withoutPrefix.slice(0, 2)}-${withoutPrefix.slice(2)}`;
      } else if (withoutPrefix.length > 8) {
        // Publication number format (e.g., 2010-0234553)
        formattedNumber = `${withoutPrefix.slice(0, 4)}-${withoutPrefix.slice(4)}`;
      } else {
        // Regular patent number format
        formattedNumber = withoutPrefix.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      break;
    default: //includes EP, CN
      formattedNumber = withoutPrefix.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      break;
  }

  // Reassemble the full formatted ID with prefix and suffix if they were removed
  return `${prefix} ${formattedNumber}`.trim();
};

/**
 * @param {array} data - The data to get the column headers from
 * @returns {array} - The column headers
 */
export const getColumnHeaders = (data: any[], validReferenceIds?: Set<string>) => {
  const headers: Set<string> = new Set();
  data.forEach((row) => {
    Object.keys(row).forEach((key) => {
      // Only check validReferenceIds if it exists
      if (
        key !== "claim_number" &&
        (!validReferenceIds || validReferenceIds.has(key))
      ) {
        headers.add(key);
      }
    });
  });
  return Array.from(headers);
};

/**
 * @param {string} id - The id to get the shortened number from
 * @returns {string} - The shortened number
 */
export const getShortenedNum = (id: string) => {
  if (!id) {
    return null;
  }

  // if id ends in pdf, remove pdf and return the first 5 characters + "...pdf"
  if (id.endsWith(".pdf")) {
    return id.length < 8 ? id : id.slice(0, 5) + "...pdf";
  }

  // Match the last 3 digits before any trailing letters and optional letter-number combination
  const match = id.match(/\d{3}(?=[A-Z]*(?:[A-Z]\d)?$)/);
  return match ? `'${match[0]}` : null;
};

/**
 * @param {string} bgColor - The background color to get the text color from
 * @returns {string} - The text color
 */
export const getColorForText = (bgColor: string) => {
  switch (bgColor) {
    case ChartColor.GREEN:
      return "#1A3300";
    case ChartColor.YELLOW:
      return "#4B3700";
    case ChartColor.RED:
      return "#4D0000";
    default:
      return "black";
  }
};

export const colorRank: { [key: string]: number } = {
  [ChartColor.GREEN]: 4,
  [ChartColor.YELLOW]: 3,
  [ChartColor.RED]: 2,
  [ChartColor.GRAY]: 1,
};

/**
 * @param {array} colors - The colors to get the highest rank from
 * @returns {string} - The highest rank
 */
export const getHighestRank = (colors: string[]) => {
  const num = Math.max(...colors.map((color) => colorRank[color] || 0));
  return Object.keys(colorRank).find((key) => colorRank[key] === num);
};

/**
 * @param {number} width - The width of the image
 * @param {number} height - The height of the image
 * @param {number} rotationAngle - The rotation angle
 * @returns {object} - The new width and height
 */
export const calculateRotatedImageSize = (
  width: number,
  height: number,
  rotationAngle: number,
) => {
  const radians = (rotationAngle * Math.PI) / 180;
  const sin = Math.sin(radians);
  const cos = Math.cos(radians);

  // Calculate the new bounding box width and height
  const newWidth = Math.abs(width * cos) + Math.abs(height * sin);
  const newHeight = Math.abs(width * sin) + Math.abs(height * cos);

  return { width: newWidth, height: newHeight };
};

/**
 * Chunks an array into smaller arrays, helper function for processReferences
 * @param {string[]} array - The array to chunk
 * @param {number} chunkSize - The size of the chunk to process
 * @returns An array of chunks
 */
export const chunkProjects = (projects: any[], chunkSize: number) => {
  const chunks = [];
  for (let i = 0; i < projects.length; i += chunkSize) {
    chunks.push(projects.slice(i, i + chunkSize));
  }
  return chunks;
};

/**
 * Filters out unwanted sections from document body or metadata
 * @param sections - Array of document sections to filter
 * @param options - Optional configuration for filtering
 * @returns Filtered array of sections
 */
export const filterDocumentSections = (
  sections: any[],
  options: {
    excludeStrings?: string[];
  } = {},
) => {
  const { excludeStrings = ["IS", "IS.", "U.S.", "(Continued)"] } = options;

  return (
    sections?.filter((section: any) => {
      // For body sections, apply text-based filtering
      const text = section.text || "";

      // Must contain letters
      const hasLetters = /[a-zA-Z]/.test(text);

      // Strip spaces and commas for patent number check
      const strippedText = text.replace(/[\s,]/g, "");

      // Check for excluded strings
      const isExcludedString = excludeStrings.includes(text.trim());

      return hasLetters && !isExcludedString && !isLikelyPatentNumber(strippedText);
    }) || []
  );
};

export const formatCitationLocation = (
  citation: InvalidityCitation | CitationLocation,
  is_patent: boolean,
  format?: string,
) => {
  if (citation?.claimSection) {
    return citation.claimSection;
  }
  const paragraph = citation?.paragraph;
  const page = citation?.page;
  const columns = citation?.columns;
  const lines = citation?.lines;

  // Helper function to format ranges
  const formatRange = (start: number, end: number) => {
    return start === end ? `${start}` : `${start}-${end}`;
  };

  // Format column-lines
  const formatColumnLines = () => {
    if (
      !columns?.length ||
      !lines?.length ||
      columns.some((n) => n < 0) ||
      lines.some((n) => n < 0)
    ) {
      return "Unknown";
    }

    // Same column case
    if (columns[0] === columns[1]) {
      return `${columns[0]}:${formatRange(lines[0], lines[1])}`;
    }

    // Different columns case
    return `${columns[0]}:${lines[0]}-${columns[1]}:${lines[1]}`;
  };

  switch (format) {
    case "column-lines":
      return formatColumnLines();

    case "page":
      return page ? `Page ${page}` : "Unknown";

    case "paragraph":
      return paragraph ? `[${paragraph}]` : "Unknown";

    case "page-paragraph":
      return page ? `Page ${page}` : paragraph ? `[${paragraph}]` : "Unknown";

    default:
      // If column-line data exists, only show that
      if (columns?.length && lines?.length) {
        const columnLineFormat = formatColumnLines();
        if (columnLineFormat !== "Unknown") return columnLineFormat;
      }

      // For patents without column-line data, show paragraph
      if (is_patent) {
        return paragraph ? `[${paragraph}]` : "Unknown";
      }

      // Otherwise show page
      return page ? `Page ${page}` : "Unknown";
  }
};
