/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, ProjectPage } from "@/components";
import { Button } from "@/components/ui/button";
import { H4 } from "@/components/ui/typography";
import { usePortfolio, useProject, useViz } from "@/hooks";
import { useAppStateStore, useProcessStore, useProjectStore } from "@/store";
import { DocumentType, Patent, ProcessType, ProjectType } from "@/types";
import { PlusIcon } from "@radix-ui/react-icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DocumentsTable from "../references/components/DocumentsTable";
import {
  AddPatentsToPortfolioModal,
  AddReferencesToPortfolioModal,
  GenerateContextAndChartModal,
  PrunePortfolioModal,
  UploadFilesToPortfolioModal,
} from "./components";
import ConfirmRemovePatentModal from "./components/ConfirmRemovePatentModal";
import StandardsTable from "./components/StandardsTable";

/**
 * @description Portfolio subjects page
 */
const PortfolioPage: React.FC = () => {
  const navigate = useNavigate();
  const {
    currentPortfolio,
    currentPortfolioId,
    updateCurrentPortfolio,
    clearCurrentProject,
  } = useProjectStore();
  const { isLoading } = useAppStateStore();
  const { areProcessesPending, processes } = useProcessStore();
  const { updateProjectDetails, getProjectMetadata } = useProject();
  const { addErrorMessage } = useAppStateStore();
  const { removeProjectsFromPortfolio } = usePortfolio();
  const { getPortfolioReferences } = useViz();

  const [confirmRemovePatentModalOpen, setConfirmRemovePatentModalOpen] =
    useState(false);
  const [selectedPatentToRemove, setSelectedPatentToRemove] = useState<Patent | null>(
    null,
  );

  const projectsAsPatents: Patent[] = currentPortfolio.projects?.map((project) => ({
    id: project.subject.id,
    projectId: project.id,
    name: project.name,
    number: project.subject.number,
    prefix: project.subject.prefix,
    title: project.subject.name,
    assignee: project.subject.assignee,
    filingDate: project.subject.filingDate,
    publicationDate: project.subject.publicationDate,
    inventors: project.subject.inventors,
    type: DocumentType.PATENT,
    tags: [],
  }));

  // Page title
  const pageName = currentPortfolio?.name
    ? `${currentPortfolio?.name} - Portfolio`
    : "Portfolio";

  // Rerenders when processes change
  useEffect(() => {}, [processes]);

  useEffect(() => {
    clearCurrentProject();
  }, []);

  // Fetch reference data in the background after subject data is loaded
  useEffect(() => {
    const fetchPortfolioReferences = async () => {
      getPortfolioReferences(currentPortfolioId);
    };

    if (currentPortfolioId && !currentPortfolio.references && !isLoading) {
      fetchPortfolioReferences();
    }
  }, [currentPortfolioId, isLoading]);

  // Disable conflicting actions if in progress
  const isAddSubjectDisabled = areProcessesPending({
    types: [ProcessType.GENERATE_CONTEXT, ProcessType.ADD_REFERENCE],
    portfolioId: currentPortfolioId,
  });

  const isPruneSubjectDisabled = areProcessesPending({
    types: [
      ProcessType.GENERATE_CONTEXT,
      ProcessType.ADD_REFERENCE,
      ProcessType.ADD_SUBJECT,
    ],
    portfolioId: currentPortfolioId,
  });

  const isGenerateContextDisabled = areProcessesPending({
    types: [
      ProcessType.ADD_SUBJECT,
      ProcessType.ADD_REFERENCE,
      ProcessType.GENERATE_CONTEXT,
    ],
    portfolioId: currentPortfolioId,
  });

  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showPruneModal, setShowPruneModal] = useState(false);
  const [showAddReferencesModal, setShowAddReferencesModal] = useState(false);
  const [showRechartModal, setShowRechartModal] = useState(false);

  // Save project name
  const handleSaveProjectName = async (reference: Patent, newName: string) => {
    const finalName = newName.trim();
    if (finalName !== "" && currentPortfolio.name !== finalName) {
      updateCurrentPortfolio({
        ...currentPortfolio,
        projects: currentPortfolio.projects?.map((item) =>
          item.id === reference.projectId ? { ...item, name: finalName } : item,
        ),
        references: currentPortfolio.references?.map((reference) => ({
          ...reference,
          subjects: reference.subjects?.map((subject) =>
            subject.projectId === reference.projectId
              ? { ...subject, projectName: finalName }
              : subject,
          ),
        })),
      });
      await updateProjectDetails(reference.id, { name: finalName });
    }
  };

  const handleRemovePatentClick = (reference: Patent) => {
    setSelectedPatentToRemove(reference);
    setConfirmRemovePatentModalOpen(true);
  };

  const handleCloseConfirmRemovePatentModal = () => {
    setConfirmRemovePatentModalOpen(false);
    setSelectedPatentToRemove(null);
  };

  const handleRemovePatentFromPortfolio = async (projectIds: string[]) => {
    const repsonse = await removeProjectsFromPortfolio(currentPortfolio.id, projectIds);
    if (repsonse.success) {
      const newProjectList = currentPortfolio.projects.filter(
        (project) => !projectIds.includes(project.id),
      );
      updateCurrentPortfolio({
        ...currentPortfolio,
        projects: newProjectList,
      });
    } else {
      addErrorMessage("Error removing project from portfolio");
    }
  };

  const handleDeleteSelected = async (selectedReferences: Patent[]) => {
    const projectIds = selectedReferences?.map((reference) => reference.projectId);
    await handleRemovePatentFromPortfolio(projectIds);
  };

  const handleSelectProject = async (reference: Patent) => {
    navigate(`/portfolio/${currentPortfolioId}/project/${reference.projectId}/subject`);
    await getProjectMetadata(reference.projectId, reference.name);
  };

  return (
    <ProjectPage pageName={pageName}>
      <div>
        {isLoading ? (
          <Loader message="Loading..." />
        ) : (
          <>
            {currentPortfolio.projects?.length > 0 ? (
              <>
                {currentPortfolio.type === ProjectType.SEP ? (
                  <StandardsTable
                    references={projectsAsPatents}
                    isPortfolio={true}
                    isReference={false}
                    onRowClick={handleSelectProject}
                  >
                    <div className="flex gap-2 justify-between">
                      {currentPortfolio.projects?.length > 0 && (
                        <Button
                          variant="outline"
                          onClick={() => setShowRechartModal(true)}
                          disabled={isGenerateContextDisabled}
                        >
                          Generate Context
                        </Button>
                      )}
                      <div className="flex gap-2">
                        {currentPortfolio.projects?.length < 100 && (
                          <Button
                            onClick={() => setShowAddNewModal(true)}
                            disabled={isAddSubjectDisabled}
                          >
                            Add Standards
                            <PlusIcon className="w-4 h-4 md:ml-0 lg:ml-2" />
                          </Button>
                        )}
                      </div>
                    </div>
                  </StandardsTable>
                ) : (
                  <DocumentsTable
                    references={projectsAsPatents}
                    isPortfolio={true}
                    isReference={false}
                    onSaveName={handleSaveProjectName}
                    onDeleteRow={handleRemovePatentClick}
                    onDeleteSelected={handleDeleteSelected}
                    onRowClick={handleSelectProject}
                  >
                    <div className="flex gap-2 justify-between">
                      {currentPortfolio.projects?.length > 0 && (
                        <Button
                          variant="outline"
                          onClick={() => setShowRechartModal(true)}
                          disabled={isGenerateContextDisabled}
                        >
                          Generate Context
                        </Button>
                      )}
                      <div className="flex gap-2">
                        {/* {currentPortfolio.projects?.length > 1 && (
                          <Button
                            variant="outline"
                            onClick={() => setShowPruneModal(true)}
                            disabled={isPruneSubjectDisabled}
                          >
                            Prune Subjects
                          </Button>
                        )} */}
                        {currentPortfolio.projects?.length < 100 && (
                          <Button
                            onClick={() => setShowAddNewModal(true)}
                            disabled={isAddSubjectDisabled}
                          >
                            Add Subjects
                            <PlusIcon className="w-4 h-4 md:ml-0 lg:ml-2" />
                          </Button>
                        )}
                      </div>
                    </div>
                  </DocumentsTable>
                )}
              </>
            ) : (
              <div className="flex justify-center items-center flex-col mt-12">
                <H4>This portfolio has no subjects.</H4>

                <Button
                  onClick={() => setShowAddNewModal(true)}
                  disabled={isAddSubjectDisabled}
                  className="mt-3"
                  variant="outline"
                >
                  Add Subjects
                  <PlusIcon className="w-4 h-4 md:ml-0 lg:ml-2" />
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <PrunePortfolioModal
        open={showPruneModal}
        handleClose={() => setShowPruneModal(false)}
      />
      <AddPatentsToPortfolioModal
        open={showAddNewModal}
        handleClose={() => setShowAddNewModal(false)}
      />
      <AddReferencesToPortfolioModal
        open={showAddReferencesModal}
        handleClose={() => setShowAddReferencesModal(false)}
      />
      <UploadFilesToPortfolioModal
        open={showUpdateModal}
        handleClose={() => setShowUpdateModal(false)}
      />
      <GenerateContextAndChartModal
        open={showRechartModal}
        handleClose={() => setShowRechartModal(false)}
      />

      <ConfirmRemovePatentModal
        open={confirmRemovePatentModalOpen}
        onClose={handleCloseConfirmRemovePatentModal}
        name={selectedPatentToRemove?.name}
        removePatentFromPortfolio={handleRemovePatentFromPortfolio}
        projectId={selectedPatentToRemove?.id}
      />
    </ProjectPage>
  );
};

export default PortfolioPage;
