import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ClaimStatusReason } from "@/types/project";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { memo } from "react";

interface TypesSelectorProps {
  status: string;
  reasons: ClaimStatusReason[];
  selectedReason: (ClaimStatusReason & { index: number }) | null;
  onReasonSelect: (index: number) => void;
  onDropdownClick: (e: React.MouseEvent) => void;
}

export const ClaimReasonTypesSelector = memo(
  ({
    status,
    reasons,
    selectedReason,
    onReasonSelect,
    onDropdownClick,
  }: TypesSelectorProps) => {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild onClick={onDropdownClick}>
          <Button variant="outline" className="h-6 px-2 text-sm">
            {selectedReason ? selectedReason.type : "Select a reason"}
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {reasons.map((reason: ClaimStatusReason, index: number) => (
            <DropdownMenuItem
              key={index}
              className="text-sm py-1"
              onClick={(e) => {
                e.stopPropagation();
                onReasonSelect(index);
              }}
            >
              {reason.type ?? "-"}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  },
);

ClaimReasonTypesSelector.displayName = "TypesSelector";
