/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useProjectStore } from "@/store";
import { ParentType } from "@/types";
import { toCamelCase } from "@/utils/dataUtils";
import useApi from "./useApi";

interface ApiResponse {
  data: any;
  status: number;
}

interface Result {
  success: boolean;
  data?: any;
  message?: string;
  error?: any;
  status?: number;
}

/**
 * @description Hook for handling vector operations
 * @returns {object} - The vector operations
 */
const useVector = () => {
  const { postRequest, handleError } = useApi();
  const { currentProjectId, currentProject, updateCurrentProject } = useProjectStore();

  /**
   * @description Uploads documents to VDB
   * @param {string} project_id - The id of the project to upload documents to
   * @param {array} document_ids - The ids of the documents to upload
   * @param {boolean} skip_flag - Whether to skip
   * @returns Result object with success status and data or error message.
   */
  const uploadToVDB = async (
    project_id: string,
    document_ids: string[],
    skip_flag: boolean,
    rerank: boolean,
    is_standard: boolean = false,
  ): Promise<Result> => {
    try {
      const response: ApiResponse = await postRequest("post_upload_documents_to_vdb", {
        project_id: project_id,
        documents_ids: document_ids,
        skip_flag: skip_flag,
        rerank: rerank,
        is_standard: is_standard,
      });

      return {
        success: true,
        data: toCamelCase(response.data, true),
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error uploading documents to VDB");
    }
  };

  const uploadToVDBWithRetry = async (
    project_id: string,
    document_ids: string[],
    skip_flag: boolean,
    rerank: boolean,
    is_standard: boolean = false,
    maxRetries: number = 3,
    interval: number = 500,
  ): Promise<Result> => {
    try {
      let failedIds = document_ids;
      let allSuccessful: string[] = [];
      let attempt = 0;

      while (failedIds.length > 0 && attempt < maxRetries) {
        attempt++;

        const response = await uploadToVDB(
          project_id,
          failedIds,
          skip_flag,
          rerank,
          is_standard,
        );

        // Collect successful and failed IDs
        allSuccessful = [...allSuccessful, ...(response.data?.successfulIds || [])];
        failedIds = response.data?.failedIds || [];

        // If there are failed IDs and retries left, wait before retrying
        if (failedIds.length > 0 && attempt < maxRetries) {
          if (interval > 0) {
            await new Promise((resolve) => setTimeout(resolve, interval));
          }
        }
      }

      if (failedIds.length > 0) {
        console.warn(
          `Failed to upload the following documents after ${maxRetries} attempts:`,
          failedIds.join(", "),
        );
      }

      return {
        success: true,
        data: {
          successfulIds: allSuccessful,
          failedIds: failedIds,
        },
        status: 200,
      };
    } catch (error) {
      return handleError(error, "Error uploading documents to VDB");
    }
  };

  const uploadToVDBOnly = async (document_ids: string[]): Promise<Result> => {
    try {
      const response: ApiResponse = await postRequest(
        "post_upload_documents_to_vdb_only",
        {
          documents_ids: document_ids,
        },
      );
      return {
        success: true,
        data: toCamelCase(response.data, true),
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error uploading documents to VDB only");
    }
  };

  const uploadToVDBOnlyWithRetry = async (
    document_ids: string[],
    maxRetries: number = 3,
    interval: number = 500,
  ): Promise<Result> => {
    try {
      let failedIds = document_ids;
      let allSuccessful: string[] = [];
      let attempt = 0;

      while (failedIds.length > 0 && attempt < maxRetries) {
        attempt++;
        const response = await uploadToVDBOnly(failedIds);
        allSuccessful = [...allSuccessful, ...(response.data?.successfulIds || [])];
        failedIds = response.data?.failedIds || [];

        if (failedIds.length > 0 && attempt < maxRetries) {
          if (interval > 0) {
            await new Promise((resolve) => setTimeout(resolve, interval));
          }
        }
      }

      if (failedIds.length > 0) {
        console.warn(
          `Failed to upload the following documents after ${maxRetries} attempts:`,
          failedIds.join(", "),
        );
      }

      return {
        success: true,
        data: { successfulIds: allSuccessful, failedIds: failedIds },
        status: 200,
      };
    } catch (error) {
      return handleError(error, "Error uploading documents to VDB only");
    }
  };

  const processReferences = async (
    project_id: string,
    document_ids: string[],
  ): Promise<Result> => {
    try {
      const response: ApiResponse = await postRequest("post_process_references", {
        project_id: project_id,
        documents_ids: document_ids,
      });
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error processing references");
    }
  };

  const rerankReferences = async (
    project_id: string,
    documents_ids?: string[],
    is_portfolio?: boolean,
  ): Promise<Result> => {
    try {
      const payload: {
        project_id: string;
        documents_ids?: string[];
        is_portfolio?: boolean;
      } = {
        project_id: project_id,
      };
      if (documents_ids) {
        payload.documents_ids = documents_ids;
      }
      if (is_portfolio) {
        payload.is_portfolio = is_portfolio;
      }

      const response: ApiResponse = await postRequest(
        "post_rerank_invalidities",
        payload,
      );

      return {
        success: true,
        data: toCamelCase(response.data, true),
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error recharting references");
    }
  };

  /**
   * @description Uploads documents to VDB search chat
   * @param {array} document_ids - The ids of the documents to upload
   * @returns Result object with success status and data or error message.
   */
  const uploadToVDBSearchChat = async (document_ids: string[]): Promise<Result> => {
    try {
      const response: ApiResponse = await postRequest(
        "post_upload_documents_to_vdb_search_chat",
        {
          documents_ids: document_ids,
        },
      );
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error uploading documents to VDB search chat");
    }
  };

  /**
   * @description Retrieves the invalidity for a claim
   * @param {string} projectId - The id of the project to retrieve the invalidity for
   * @param {string} claim_number - The claim number to retrieve the invalidity for
   * @param {string} referenceId - The reference id to retrieve the invalidity for
   * @param {string} claim_language - The language of the element
   * @param {boolean} is_feature - Whether the element is a feature
   * @param {array[string]} positive_examples- The positive invalidities to retrieve
   * @param {array[string]} negative_examples - The negative invalidities to retrieve
   * @returns Result object with success status and data or error message.
   */
  const retrieveInvalidityForClaim = async (
    projectId: string,
    claim_number: string,
    referenceId: string,
    claim_language: string,
    is_feature: boolean,
    positive_examples: string[],
    negative_examples: string[],
  ): Promise<Result> => {
    try {
      const response: ApiResponse = await postRequest(
        "post_retrieve_invalidity_for_claim",
        {
          project_id: projectId,
          claim_number: claim_number,
          reference_id: referenceId,
          claim_language: claim_language,
          is_feature: is_feature,
          positive: positive_examples,
          negative: negative_examples,
        },
      );
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error retrieving invalidity for claim");
    }
  };

  /**
   * @description Retrieves the invalidity for a claim
   * @param {string} projectId - The id of the project to retrieve the invalidity for
   * @param {string} claim_number - The claim number to retrieve the invalidity for
   * @param {string} referenceId - The reference id to retrieve the invalidity for
   * @param {string} claim_language - The language of the element
   * @param {boolean} is_feature - Whether the element is a feature
   * @returns Result object with success status and data or error message.
   */
  const retrieveInvalidityForClaimForAllReferences = async (
    projectId: string,
    claim_number: string,
    claim_language: string,
    is_feature: boolean,
  ): Promise<Result> => {
    try {
      const response: ApiResponse = await postRequest(
        "post_retrieve_invalidity_for_claim_all_references",
        {
          project_id: projectId,
          claim_number: claim_number,
          claim_language: claim_language,
          is_feature: is_feature,
        },
      );
      return { success: true, data: response.data, status: response.status };
    } catch (error) {
      return handleError(error, "Error retrieving invalidity for claim");
    }
  };

  /**
   * @description Performs a semantic search for documents
   * @param {string} projectId - The id of the project to perform the search for
   * @param {string} searchQuery - The query to search for
   * @param {array} documentsIds - The ids of the documents to search in
   * @param {string} searchMode - The mode of the search (semantic or keyword)
   * @returns Result object with success status and data or error message.
   */
  const semanticSearchDocuments = async (
    projectId: string,
    searchQuery: string,
    documentsIds: string[],
    searchMode: string,
  ): Promise<Result> => {
    try {
      const response: ApiResponse = await postRequest(
        "post_semantic_search_documents",
        {
          project_id: projectId,
          text: searchQuery,
          documents_ids: documentsIds,
          results: 25,
          search_mode: searchMode,
        },
      );
      return {
        success: true,
        data: toCamelCase(response.data, true),
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error fetching semantic search results");
    }
  };

  const analyzeSection112 = async (projectId: string): Promise<Result> => {
    try {
      const response: ApiResponse = await postRequest("post_analyze_section_112", {
        project_id: projectId,
      });
      if (currentProjectId === projectId) {
        updateCurrentProject({
          ...currentProject,
          enablement: response.data.enablement,
          indefiniteness: response.data.indefiniteness,
          writtenDescription: response.data.written_description,
        });
      }
      return {
        success: true,
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error analyzing section 112");
    }
  };

  const processParagraphsAsInvalidities = async (
    projectId: string,
    invalidityId: string,
    referenceId: string,
    iprNumber: string,
    paragraphNumbers: string[],
  ): Promise<Result> => {
    try {
      const response = await postRequest("process_paragraphs_as_invalidities", {
        project_id: projectId,
        invalidity_id: invalidityId,
        reference_id: referenceId,
        paragraph_numbers: paragraphNumbers,
        ipr_number: iprNumber,
      });

      return {
        success: true,
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error processing paragraphs as invalidities");
    }
  };

  interface GenerateReferenceSummaryCitationsPayload {
    project_id?: string;
    document_ids: string[];
    portfolio_id?: string;
    is_reprocessing?: boolean;
  }

  const generateReferenceSummaryCitations = async (
    referenceIds: string[],
    id: string,
    parent: ParentType,
  ): Promise<Result> => {
    try {
      const payload: GenerateReferenceSummaryCitationsPayload = {
        document_ids: referenceIds,
      };
      if (parent === ParentType.PROJECT) {
        payload.project_id = id;
      } else {
        payload.portfolio_id = id;
      }

      const response = await postRequest(
        "post_generate_reference_summary_citations",
        payload,
      );
      return {
        success: true,
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return handleError(error, "Error generating reference summary citations");
    }
  };

  /**
   * @description Generic retry function for async operations
   * @param {Function} fn - The async function to retry
   * @param {number} retriesLeft - Number of retries left
   * @param {number} interval - Wait time between retries (optional)
   * @returns {Promise<any>}
   */
  const retryAsync = async (
    fn: () => Promise<Result>,
    retriesLeft: number,
    interval: number = 0,
  ): Promise<Result> => {
    try {
      return await fn();
    } catch (error) {
      if (retriesLeft > 0) {
        if (interval > 0) {
          await new Promise((resolve) => setTimeout(resolve, interval));
        }
        return await retryAsync(fn, retriesLeft - 1, interval);
      } else {
        throw error;
      }
    }
  };

  return {
    semanticSearchDocuments,
    retrieveInvalidityForClaim,
    uploadToVDBSearchChat,
    retrieveInvalidityForClaimForAllReferences,
    rerankReferences,
    uploadToVDBOnly,
    processReferences,
    processParagraphsAsInvalidities,
    analyzeSection112,
    generateReferenceSummaryCitations,
    uploadToVDB,
    uploadToVDBWithRetry,
    uploadToVDBOnlyWithRetry,
  };
};

export default useVector;
