/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button";
import { DataTableCell, DataTableRowOutline } from "@/components/ui/table";
import { useProjectStore } from "@/store";
import { ElementType } from "@/types";
import { parseHtml } from "@/utils/parseHtml";
import { PenLineIcon } from "lucide-react";
import React, { MouseEvent, useEffect, useState } from "react";
import HeaderFooterEditor from "./HeaderFooterEditor";
import { ReferenceTableCitationCell } from "./index";

// Define the types for the props
interface InvalidityTableRowProps {
  row: Record<string, any>;
  rowIndex: number;
  fetchReferenceChartData: () => void;
  setAddingCitationKey: (key: string) => void;
  addingCitationKey: string;
  setIsEditing: (isEditing: boolean) => void;
  isEditing: boolean;
  rowVisibility: string;
}

/**
 * @description Data table row
 * @param {Record<string, any>} row - The row to display.
 * @param {number} rowIndex - The index of the row.
 * @param {function} fetchReferenceChartData - Function to fetch the reference chart data.
 * @param {function} setAddingCitationKey - Function to set the adding citation key.
 * @param {string} addingCitationKey - The adding citation key.
 * @param {function} setIsEditing - Function to set the editing state.
 * @param {boolean} isEditing - Whether the row is editing.
 */
const InvalidityTableRow: React.FC<InvalidityTableRowProps> = ({
  row,
  rowIndex,
  fetchReferenceChartData,
  setAddingCitationKey,
  addingCitationKey,
  setIsEditing,
  isEditing,
  rowVisibility,
}) => {
  const { chartData, selectedReferences, selectedElementType } = useProjectStore();

  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [isBoilerplateOpen, setIsBoilerplateOpen] = useState<boolean>(false);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number | null;
    mouseY: number | null;
    cellType: string | null;
    showSplitText: boolean;
  }>({
    mouseX: null,
    mouseY: null,
    cellType: null,
    showSplitText: false,
  });

  const handleRightClick = (event: MouseEvent, rowIndex: number) => {
    event.preventDefault();

    let currentText = "";
    let selectedText = "";

    selectedText = window.getSelection()?.toString() || "";

    currentText = chartData[rowIndex].claim_text;

    setIsContextMenuOpen(true);
    setContextMenu({
      mouseX: event.pageX, // Change this line
      mouseY: event.pageY, // Change this line
      cellType: "claim",
      showSplitText: selectedText && currentText.endsWith(selectedText) ? true : false,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (contextMenu.mouseX !== null && contextMenu.mouseY !== null) {
        const contextMenuElement = document.querySelector(".context-menu");
        if (contextMenuElement && !contextMenuElement.contains(event.target as Node)) {
          handleCloseContextMenu();
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside as any);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside as any);
    };
  }, [contextMenu]);

  const handleOpenBoilerplate = () => {
    handleCloseContextMenu();
    setIsBoilerplateOpen(true);
  };

  const handleCloseContextMenu = () => {
    setContextMenu({
      mouseX: null,
      mouseY: null,
      cellType: null,
      showSplitText: false,
    });
    setIsContextMenuOpen(false);
  };

  const hasAnyCitations = () => {
    return Object.keys(row)
      .filter(
        (key) =>
          key !== "claim_number" && key !== "claim_text" && key !== "invalidity_id",
      )
      .some((refId) => row[refId]?.citations?.length > 0);
  };

  return (
    <>
      <DataTableRowOutline
        key={rowIndex}
        className={`${isContextMenuOpen ? "bg-muted" : ""} ${
          !hasAnyCitations() && rowVisibility === "collapse" ? "h-[40px]" : ""
        }`}
        style={{ position: "relative" }}
      >
        <DataTableCell
          key={`${rowIndex}-claim_number`}
          onContextMenu={(e) => handleRightClick(e, rowIndex)}
        >
          <span>{row.claim_number}</span>
        </DataTableCell>
        <DataTableCell
          key={`${rowIndex}-claim_text`}
          onContextMenu={(e) => handleRightClick(e, rowIndex)}
          className={`align-top max-w-[400px] ${
            !hasAnyCitations() && rowVisibility === "collapse" ? "truncate" : ""
          }`}
        >
          <div
            className={`overflow-x-auto ${
              !hasAnyCitations() && rowVisibility === "collapse" ? "truncate" : ""
            }`}
          >
            <span>{parseHtml(row.claim_text)}</span>
          </div>
        </DataTableCell>

        {Object.keys(row)
          .filter(
            (key) =>
              key !== "claim_number" && key !== "claim_text" && key !== "invalidity_id",
          )
          .map((refId) => (
            <ReferenceTableCitationCell
              key={`${rowIndex}-${refId}`}
              index={rowIndex}
              refId={refId}
              citations={row[refId].citations}
              claimNumber={row.claim_number}
              claimText={row.claim_text}
              fetchReferenceChartData={fetchReferenceChartData}
              addingCitationKey={addingCitationKey}
              setAddingCitationKey={setAddingCitationKey}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              header={row[refId]?.header ?? ""}
              footer={row[refId]?.footer ?? ""}
            />
          ))}
      </DataTableRowOutline>

      {contextMenu.mouseX && contextMenu.mouseY && (
        <div
          style={{
            position: "fixed",
            top: `${contextMenu.mouseY}px`,
            left: `${contextMenu.mouseX}px`,
            zIndex: 1000,
          }}
          className="flex flex-col bg-background shadow-md rounded-md p-2 context-menu"
        >
          {contextMenu.cellType === "claim" &&
            selectedElementType === ElementType.CLAIM &&
            !isEditing && (
              <>
                <Button
                  variant="ghost"
                  onClick={handleOpenBoilerplate}
                  className="justify-start w-full"
                >
                  <PenLineIcon className="mr-2 h-4 w-4" /> Edit Header/Footer
                </Button>
              </>
            )}

          {contextMenu.cellType === "claim" &&
            selectedElementType === ElementType.FEATURE &&
            !isEditing && (
              <>
                <Button
                  variant="ghost"
                  onClick={handleOpenBoilerplate}
                  className="justify-start w-full"
                >
                  <PenLineIcon className="mr-2 h-4 w-4" /> Edit Header/Footer
                </Button>
              </>
            )}
        </div>
      )}
      <HeaderFooterEditor
        initialHeader={
          selectedReferences[0]?.id ? row[selectedReferences[0].id]?.header || "" : ""
        }
        initialFooter={
          selectedReferences[0]?.id ? row[selectedReferences[0].id]?.footer || "" : ""
        }
        isOpen={isBoilerplateOpen}
        onClose={() => setIsBoilerplateOpen(false)}
        currentElementNumber={row.claim_number}
        currentElementLanguage={row.claim_language}
        index={rowIndex}
      />
    </>
  );
};

export default InvalidityTableRow;
