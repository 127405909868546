/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import {
  DocumentBody,
  DocumentMetadata,
  ElementList,
  SubjectAbstractImages,
} from "@/components";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { H4 } from "@/components/ui/typography";
import { useProjectStore } from "@/store";
import { ElementType, Patent } from "@/types";
import { splitId } from "@/utils/projectUtils";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import he from "he";
import React from "react";

interface SubjectPatentLayoutProps {
  patent: Patent | null;
  citationText?: string;
  highlightedRef?: React.RefObject<HTMLDivElement>;
}

interface PatentNumberDisplayProps {
  finalIdString: string;
}

const PatentNumberDisplay: React.FC<PatentNumberDisplayProps> = ({ finalIdString }) => {
  return (
    <div className="flex items-center gap-1 bg-[#BBD6FF] text-black whitespace-nowrap px-1 py-0.75 pl-3 rounded-full ">
      <span className="text-md">{splitId(finalIdString)}</span>
      <Button
        variant="ghost"
        size="icon"
        asChild
        className="text-black hover:bg-primary-foreground/10 flex-shrink-0"
      >
        <a
          href={`https://patents.google.com/patent/${finalIdString}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ExternalLinkIcon className="h-4 w-4" />
        </a>
      </Button>
    </div>
  );
};

const SubjectPatentLayout: React.FC<SubjectPatentLayoutProps> = ({
  patent,
  citationText,
  highlightedRef,
}) => {
  if (!patent) return null;

  const { currentProject } = useProjectStore();

  const finalIdString = /^[A-Za-z]/.test(patent.number)
    ? patent.number
    : (patent.prefix || "") + patent.number;

  return (
    <>
      {/* Large screen layout */}
      <div className="hidden lg:flex lg:h-[calc(100vh-55px)]">
        <div className="w-[50%] custom-scrollbar px-3 py-2">
          {patent.title && <H4 className="mb-4">{he.decode(patent.title)}</H4>}
          <SubjectAbstractImages details={patent} />
          <DocumentBody document={patent} citationText={citationText} />
        </div>

        <Separator orientation="vertical" className="h-auto" />

        <div className="w-[50%] custom-scrollbar px-3 py-2">
          <div className="flex justify-end mb-2">
            <PatentNumberDisplay finalIdString={finalIdString} />
          </div>

          <DocumentMetadata
            details={patent}
            showHead={true}
            subjectPage={true}
            projectPriorityDate={currentProject.priorityDate}
            enableEditPriorityDate={true}
          />
          <ElementList
            list={currentProject.features}
            itemType={ElementType.FEATURE}
            startCollapsed={false}
            editingBool={true}
          />

          <ElementList
            list={currentProject.claims}
            itemType={ElementType.CLAIM}
            startCollapsed={false}
            editingBool={true}
          />
        </div>
      </div>

      {/* Small screen layout */}
      <div className="lg:hidden h-[calc(100vh-55px)] custom-scrollbar">
        <div className="p-3">
          <div className="flex justify-between mb-2">
            {patent.title ? <H4 className="mb-4">{he.decode(patent.title)}</H4> : null}
            <PatentNumberDisplay finalIdString={finalIdString} />
          </div>

          <DocumentMetadata
            details={patent}
            showHead={true}
            subjectPage={true}
            projectPriorityDate={currentProject.priorityDate}
            enableEditPriorityDate={true}
          />
          <div className="mt-4">
            <SubjectAbstractImages details={patent} />
          </div>

          <ElementList
            list={currentProject.features}
            itemType={ElementType.FEATURE}
            startCollapsed={false}
            editingBool={true}
          />

          <ElementList
            list={currentProject.claims}
            itemType={ElementType.CLAIM}
            startCollapsed={false}
            editingBool={true}
          />

          <div className="mt-4">
            <DocumentBody document={patent} citationText={citationText} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SubjectPatentLayout;
