/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, ProjectPage } from "@/components";
import { Switch } from "@/components/ui/switch";
import {
  Table,
  TableBody,
  TableCellCondensed,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { H4 } from "@/components/ui/typography";
import { useDataTable, usePortfolio, useProject, useViz } from "@/hooks";
import { useAppStateStore, useProcessStore, useProjectStore } from "@/store";
import { ChartColor, ElementType } from "@/types";
import { ChevronDownIcon, ChevronRightIcon, Cross2Icon } from "@radix-ui/react-icons";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const adjustColorShade = (
  baseColor: string,
  percentage: number,
  matrixData: Record<string, any>,
): string => {
  if (!baseColor || baseColor === ChartColor.GRAY) return ChartColor.GRAY;
  if (baseColor === "NONE") return "transparent";

  // Find all percentages in the matrix
  const allPercentages = Object.values(matrixData).flatMap((row) =>
    row.references
      .filter((ref) => ref.color === baseColor)
      .map((ref) => ref.percentage),
  );

  // Calculate relative position
  const min = Math.min(...allPercentages);
  const max = Math.max(...allPercentages);
  const range = max - min;

  // Convert to relative scale (0.4 to 1.0)
  // If range is 0, use middle value (0.7)
  const relativeScale = range === 0 ? 0.7 : 0.4 + ((percentage - min) / range) * 0.6;

  // For hex colors
  if (baseColor.startsWith("#")) {
    const r = parseInt(baseColor.slice(1, 3), 16);
    const g = parseInt(baseColor.slice(3, 5), 16);
    const b = parseInt(baseColor.slice(5, 7), 16);

    const newR = Math.round(r + (255 - r) * (1 - relativeScale));
    const newG = Math.round(g + (255 - g) * (1 - relativeScale));
    const newB = Math.round(b + (255 - b) * (1 - relativeScale));

    return `rgb(${newR}, ${newG}, ${newB})`;
  }

  return baseColor;
};

const MatrixTable: React.FC<{
  matrixData: Record<
    string,
    {
      nickname: string;
      references: {
        id: string;
        color: string;
        nickname: string;
        number: number;
        percentage: number;
      }[];
    }
  >;
  onCellClick: (subjectId: string, referenceId: string) => void;
  hideEmptyRows: boolean;
  setHideEmptyRows: (hideEmptyRows: boolean) => void;
}> = ({ matrixData, onCellClick, hideEmptyRows, setHideEmptyRows }) => {
  if (!matrixData || Object.keys(matrixData).length === 0) {
    return null;
  }
  const { getSummaryChartData } = useDataTable();
  const patentIds = Array.from(
    new Set(
      Object.values(matrixData).flatMap((patents) =>
        patents.references.map((patent) => patent.id),
      ),
    ),
  );
  const { currentPortfolio } = useProjectStore();

  // Add state to track column widths
  const [columnWidths, setColumnWidths] = useState<Record<string, number>>({});

  // Add useEffect to handle resize
  useEffect(() => {
    const updateColumnWidths = () => {
      const newWidths: Record<string, number> = {};
      patentIds.forEach((patentId) => {
        const el = document.querySelector(`[data-patent-id="${patentId}"]`);
        if (el) {
          newWidths[patentId] = el.getBoundingClientRect().width;
        }
      });
      setColumnWidths(newWidths);
    };

    updateColumnWidths();
    window.addEventListener("resize", updateColumnWidths);
    return () => window.removeEventListener("resize", updateColumnWidths);
  }, [patentIds]);

  // Change the expandedRows state to store a single string instead of a Record
  const [expandedRows, setExpandedRows] = useState<string | null>(null);
  // Add state for summary data
  const [summaryData, setSummaryData] = useState<Array<any>>([]);
  const [summaryIsLoading, setSummaryIsLoading] = useState(false);
  // Add function to fetch summary data
  const fetchSummaryData = async (standardId: string) => {
    try {
      setSummaryIsLoading(true);
      // Get the project ID from the current portfolio projects
      const project = currentPortfolio.projects.find(
        (p) => p.subject.id === standardId,
      );
      if (!project) {
        console.error("Project not found for standard ID:", standardId);
        return;
      }

      // Replace this with your actual API call using the correct project ID
      const response = await getSummaryChartData(
        project.id, // Use the project ID associated with this standard/patent
        ElementType.CLAIM,
      );

      setSummaryData(response.data);
    } catch (error) {
      console.error("Error fetching summary data:", error);
    } finally {
      setSummaryIsLoading(false);
    }
  };

  // Update the handleRowToggle function
  const handleRowToggle = async (standardId: string) => {
    const isCurrentlyExpanded = expandedRows === standardId;

    // Check if all cells in the row are empty
    const allCellsAreEmpty = matrixData[standardId].references.every(
      (patentData) => patentData?.color === "NONE" || !patentData,
    );

    // Prevent expanding if all cells empty
    if (allCellsAreEmpty) {
      return;
    }

    // If we're expanding and don't have data yet, fetch it
    if (!isCurrentlyExpanded) {
      fetchSummaryData(standardId);
    }

    // Set to null if clicking the currently expanded row, otherwise set to the new standardId
    setExpandedRows(isCurrentlyExpanded ? null : standardId);
  };

  // Store the original indices before filtering
  const filteredSummaryData = hideEmptyRows
    ? summaryData.filter((row) =>
        Object.values(row).some(
          (value) =>
            value !== ChartColor.GRAY && value !== "NONE" && value !== row.claim_number,
        ),
      )
    : summaryData;

  return (
    <div className="w-full">
      <Table>
        <TableHeader className="sticky top-0 z-10 bg-background">
          <TableRow>
            <TableHead className="sticky left-0 z-20 bg-background w-[100px]">
              <div className="flex items-center gap-2 m-4">
                <Switch
                  id="hide-empty-rows"
                  checked={hideEmptyRows}
                  onCheckedChange={setHideEmptyRows}
                />
                <span className="text-sm text-muted-foreground whitespace-nowrap">
                  Hide empty
                </span>
              </div>
            </TableHead>
            {patentIds.map((patentId, index) => {
              const patent = currentPortfolio.references.find((p) => p.id === patentId);
              let headerText = patent?.name || "";

              // Check if ANY column needs rotation
              const anyNeedsRotation = patentIds.some((id) => {
                const width = columnWidths[id] || 0;
                const text =
                  currentPortfolio.references.find((p) => p.id === id)?.name || "";
                return text.length * 8 > width;
              });

              // Only truncate if headers are being rotated
              if (anyNeedsRotation && headerText) {
                const endsWithNumbers = /'[0-9]+$/.test(headerText);

                if (endsWithNumbers) {
                  // Split into main text and number portion
                  const [mainText, numberPart] = headerText.split(/'(?=[0-9]+$)/);

                  // If main text is longer than 12 chars, truncate it
                  if (mainText.length > 12) {
                    headerText = `${mainText.slice(0, 9)}... '${numberPart}`;
                  }
                } else {
                  // Simple truncation for text without numbers
                  if (headerText.length > 12) {
                    headerText = `${headerText.slice(0, 9)}...`;
                  }
                }
              }

              const columnWidth = columnWidths[patentId] || 0;
              const textWidth = headerText.length * 8;

              return (
                <TableHead
                  key={patentId}
                  data-patent-id={patentId}
                  className="relative h-auto min-w-[45px] pr-4"
                >
                  <div
                    className="absolute"
                    style={{
                      transformOrigin: "center left",
                      transform: anyNeedsRotation ? "rotate(-25deg)" : "none",
                      width: anyNeedsRotation ? "120px" : "100%",
                      whiteSpace: "nowrap",
                      top: anyNeedsRotation ? "65%" : "auto",
                      left: anyNeedsRotation ? "50%" : "0",
                      textAlign: anyNeedsRotation ? "left" : "center",
                      padding: anyNeedsRotation ? "0" : "8px",
                    }}
                  >
                    {headerText}
                  </div>
                </TableHead>
              );
            })}
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.entries(matrixData).map(([standardId, data]) => {
            return (
              <React.Fragment key={standardId}>
                <TableRow
                  className={
                    expandedRows === standardId
                      ? "sticky top-[60px] z-10 bg-background"
                      : ""
                  }
                >
                  <TableCellCondensed className="sticky left-0 bg-background font-medium whitespace-nowrap">
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => handleRowToggle(standardId)}
                        className="p-1 hover:bg-background rounded"
                      >
                        {expandedRows === standardId ? (
                          <ChevronDownIcon className="w-4 h-4" />
                        ) : (
                          <ChevronRightIcon className="w-4 h-4" />
                        )}
                      </button>
                      {data.nickname}
                    </div>
                  </TableCellCondensed>
                  {patentIds.map((patentId) => {
                    const patentData = data.references.find((p) => p.id === patentId);
                    return (
                      <TableCellCondensed
                        key={`${standardId}-${patentId}`}
                        className={`p-2 min-w-[45px] ${
                          patentData && patentData.color !== "NONE"
                            ? "cursor-pointer"
                            : ""
                        }`}
                        onClick={
                          patentData && patentData.color !== "NONE"
                            ? () => onCellClick(standardId, patentId)
                            : undefined
                        }
                      >
                        <div
                          className="h-7 rounded mx-auto text-center flex items-center justify-center"
                          style={{
                            backgroundColor:
                              !patentData || patentData?.color === "NONE"
                                ? "transparent"
                                : adjustColorShade(
                                    patentData?.color,
                                    patentData?.percentage,
                                    matrixData,
                                  ),
                          }}
                        >
                          {patentData?.color !== ChartColor.GRAY &&
                            patentData?.color !== "NONE" && (
                              <div className="text-xs font-medium text-black">
                                {patentData?.number}
                              </div>
                            )}
                          {(!patentData || patentData?.color === "NONE") && (
                            <Cross2Icon className="w-4 h-4 text-gray-500" />
                          )}
                        </div>
                      </TableCellCondensed>
                    );
                  })}
                </TableRow>
                {expandedRows === standardId &&
                  (summaryIsLoading ? (
                    <TableRow className="bg-accent">
                      <TableCellCondensed
                        colSpan={patentIds.length + 1}
                        className="text-center"
                      >
                        <Loader message="Loading..." />
                      </TableCellCondensed>
                    </TableRow>
                  ) : (
                    filteredSummaryData?.map((row, index) => (
                      <TableRow
                        key={`${standardId}-summary-${index}`}
                        className="bg-accent"
                      >
                        <TableCellCondensed className="sticky left-0 font-medium pl-8 bg-accent">
                          <div className="sticky left-0 z-50  text-right">
                            {row.claim_number?.match(/^[\d.]+[A-Za-z]?(?=\s|$)/)?.[0] ||
                              row.claim_number}
                          </div>
                        </TableCellCondensed>
                        {patentIds.map((patentId) => (
                          <TableCellCondensed
                            key={`${standardId}-${patentId}-${index}`}
                            className="p-2"
                          >
                            <div
                              className="w-full h-6 rounded"
                              style={{ backgroundColor: row[patentId] }}
                            />
                          </TableCellCondensed>
                        ))}
                      </TableRow>
                    ))
                  ))}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

const PortfolioOverviewPage: React.FC = () => {
  const navigate = useNavigate();
  const {
    currentPortfolio,
    currentPortfolioId,

    clearCurrentProject,
  } = useProjectStore();
  const { isLoading, updateIsLoading } = useAppStateStore();
  const { processes } = useProcessStore();
  const { getPortfolioMatrix } = usePortfolio();
  const { getPortfolioReferences } = useViz();
  const { getProjectMetadata } = useProject();
  const [isLoadingMatrix, setIsLoadingMatrix] = useState(false);
  const [hideEmptyRows, setHideEmptyRows] = useState(true);
  const [isNavigating, setIsNavigating] = useState(false);

  // Page title
  const pageName = currentPortfolio?.name
    ? `${currentPortfolio?.name} - Portfolio`
    : "Portfolio";

  // Rerenders when processes change
  useEffect(() => {}, [processes]);

  useEffect(() => {
    clearCurrentProject();
  }, []);

  useEffect(() => {
    const fetchPortfolioMatrix = async () => {
      setIsLoadingMatrix(true);
      const response = await getPortfolioMatrix(currentPortfolioId);
      setPortfolioMatrix(response);
      setIsLoadingMatrix(false);
    };
    if (
      currentPortfolioId &&
      currentPortfolio.projects?.length > 0 &&
      currentPortfolio.references?.length > 0
    ) {
      fetchPortfolioMatrix();
    }
  }, [currentPortfolioId]);

  // Fetch reference data in the background after subject data is loaded
  useEffect(() => {
    const fetchPortfolioReferences = async () => {
      getPortfolioReferences(currentPortfolioId);
    };

    if (currentPortfolioId && !currentPortfolio.references && !isLoading) {
      fetchPortfolioReferences();
    }
  }, [currentPortfolioId, isLoading]);

  const handleCellClick = useCallback(
    async (subjectId: string, referenceId: string) => {
      if (!currentPortfolio?.projects || isNavigating) {
        console.warn("No projects found in portfolio or navigation in progress");
        return;
      }

      const currentProject = currentPortfolio.projects.find(
        (p) => p.subject.id === subjectId,
      );

      const projectId = currentProject?.id;

      if (projectId) {
        try {
          setIsNavigating(true);
          updateIsLoading(projectId);

          // Fetch data first
          await getProjectMetadata(projectId, currentProject.name, true);
          const selectedReference = currentPortfolio.references.find(
            (r) => r.id === referenceId,
          );

          // Pass the selectedReference and selectedElementType via navigate state
          navigate(`/portfolio/${currentPortfolioId}/project/${projectId}/charts`, {
            state: {
              selectedReference, // Pass the selected reference
              selectedElementType: ElementType.CLAIM, // Or whatever type you need
            },
          });
        } catch (error) {
          console.error("Error loading project metadata:", error);
        } finally {
          updateIsLoading(null);
          setIsNavigating(false);
        }
      }
    },
    [currentPortfolio?.projects, isNavigating],
  );

  const [portfolioMatrix, setPortfolioMatrix] = useState<
    Record<
      string,
      {
        nickname: string;
        references: {
          id: string;
          color: string;
          nickname: string;
          number: number;
          percentage: number;
        }[];
      }
    >
  >();

  return (
    <ProjectPage pageName={pageName}>
      <div className="bg-background">
        {isLoadingMatrix ? (
          <div className="mt-10">
            <Loader message="Loading..." />
          </div>
        ) : isNavigating ? (
          <div className="mt-10">
            <Loader message="Fetching invalidity chart and navigating..." />
          </div>
        ) : (
          <>
            {currentPortfolio.projects?.length > 0 &&
            currentPortfolio.references?.length > 0 ? (
              <div className="h-[calc(100vh-55px)] overflow-auto">
                <MatrixTable
                  matrixData={portfolioMatrix}
                  onCellClick={handleCellClick}
                  hideEmptyRows={hideEmptyRows}
                  setHideEmptyRows={setHideEmptyRows}
                />
              </div>
            ) : (
              <div className="flex justify-center items-center flex-col mt-12">
                <H4>This portfolio has no references.</H4>
              </div>
            )}
          </>
        )}
      </div>
    </ProjectPage>
  );
};

export default PortfolioOverviewPage;
