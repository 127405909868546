/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState } from "react";
import { useAppStateStore } from "@/store";
import { useAdminManagement } from "@/hooks";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { LoadingButton } from "@/components/ui/loading-button";
import { useForm, SubmitHandler } from "react-hook-form";
import { CheckboxWithText } from "@/components/ui/checkbox";
import { FloatingLabelInput } from "@/components/ui/floating-input";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { User } from "@/types";

interface AddUserModalProps {
  open: boolean;
  onClose: () => void;

  users: User[];
  orgName: string;
  orgId: string;
}

// Initial form state
const userSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  email: z.string().email("Invalid email address"),
  role: z.enum(["User", "Admin"]),
});

type UserFormData = z.infer<typeof userSchema>;

function AddUserModal({
  open,
  onClose,

  users,
  orgName,
  orgId,
}: AddUserModalProps) {
  const { addNewUser } = useAdminManagement();
  const { addErrorMessage, addSuccessMessage } = useAppStateStore();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<UserFormData>({
    resolver: zodResolver(userSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      role: "User",
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<UserFormData> = async (data) => {
    if (!users.some((user) => user.email === data.email)) {
      setIsLoading(true);
      const response = await addNewUser(
        {
          ...data,
          status: "PassChange",
          id: "",
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          role: data.role,
        },
        orgId,
      );
      if (response.success) {
        addSuccessMessage(`User ${data.email} added successfully`);
      } else {
        addErrorMessage(`Error adding user: ${data.email}`);
      }
      setIsLoading(false);
    }
    handleClose();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={handleClose} aria-label="Add a new user">
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Add a new user</DialogTitle>
          <DialogDescription>
            The user will receive an email with instructions to log in.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-4 pb-4">
            <FloatingLabelInput
              {...register("firstName")}
              className="col-span-3"
              label="First Name"
              error={errors.firstName?.message}
            />
            <FloatingLabelInput
              {...register("lastName")}
              className="col-span-3"
              label="Last Name"
              error={errors.lastName?.message}
            />
            <FloatingLabelInput
              {...register("email")}
              className="col-span-3"
              label="Email"
              error={errors.email?.message}
            />
            <div className="flex items-start space-x-3">
              <CheckboxWithText
                id="admin"
                onCheckedChange={(checked) =>
                  setValue("role", checked ? "Admin" : "User")
                }
                label="Make this user an admin"
              />
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={handleClose} disabled={isLoading}>
              Cancel
            </Button>
            <LoadingButton type="submit" loading={isLoading} disabled={isLoading}>
              {isLoading ? "Adding..." : "Add User"}
            </LoadingButton>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default AddUserModal;
