/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useAppStateStore } from "@/store";
import { ProcessType } from "@/types";
import { AlertCircle, AlertTriangle, CheckCircle, Info, X } from "lucide-react";
import React, { useEffect } from "react";
import { Spinner } from "../ui/spinner";
import LoadingGroupAlert from "./LoadingGroupAlert";
const getTimestamp = () => new Date().getTime();

/**
 * @description Status/loading messages component
 */
const AlertMessages: React.FC = () => {
  // State for the error messages
  const {
    statusMessages,
    removeStatusMessage,
    loadingGroups,
    loadingMessages,
    removeLoadingGroup,
  } = useAppStateStore();

  useEffect(() => {
    // Set up timers for each message
    const timers = statusMessages.map((message) =>
      setTimeout(() => removeStatusMessage(message.key), 5000),
    );

    // Clean up timers on component unmount or when messages change
    return () => timers.forEach(clearTimeout);
  }, [statusMessages, removeStatusMessage]);

  const getIcon = (severity: string) => {
    switch (severity) {
      case "error":
        return <AlertCircle className="h-4 w-4 text-red-500" />;
      case "info":
        return <Info className="h-4 w-4 text-blue-500" />;
      case "success":
        return <CheckCircle className="h-4 w-4 text-blue-500" />;
      case "warning":
        return <AlertTriangle className="h-4 w-4 text-yellow-500" />;
      default:
        return null;
    }
  };

  // Dismiss button
  const DismissButton = ({ messageKey }: { messageKey: string }) => (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => removeStatusMessage(messageKey)}
          className="ml-2"
        >
          <X className="h-4 w-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Dismiss</TooltipContent>
    </Tooltip>
  );

  // Alert wrapper component
  const AlertWrapper = ({
    message,
    children,
    variant = "default",
  }: {
    message: { key: string; severity?: string };
    children: React.ReactNode;
    variant?: "default" | "destructive";
  }) => (
    <Alert key={message.key} variant={variant} className="relative pr-8 py-4">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-2">{children}</div>
        <DismissButton messageKey={message.key} />
      </div>
    </Alert>
  );

  return (
    <div className="fixed bottom-4 right-4 z-50 max-h-[calc(100vh-2rem)] overflow-y-auto">
      <div className="space-y-2">
        {loadingMessages.map((message) => (
          <AlertWrapper key={message.key} message={message}>
            <Spinner className="mr-2 flex-shrink-0 h-4 w-4" />
            <AlertTitle className="mb-0 ml-2">{message.message}</AlertTitle>
          </AlertWrapper>
        ))}

        {loadingGroups.map((loadingGroup) => (
          <LoadingGroupAlert
            key={loadingGroup.key + getTimestamp()}
            loadingGroup={loadingGroup}
            removeLoadingGroup={removeLoadingGroup}
            processType={loadingGroup.processType as ProcessType}
          />
        ))}

        {[...statusMessages.filter((m) => m.severity)].map((message) => (
          <AlertWrapper
            key={message.key}
            message={message}
            variant={message.severity === "error" ? "destructive" : "default"}
          >
            <div className="flex-shrink-0 flex items-center mr-3">
              {getIcon(message.severity)}
            </div>
            <div className="flex flex-col">
              <AlertTitle className="mb-0">
                {message.severity.charAt(0).toUpperCase() + message.severity.slice(1)}
              </AlertTitle>
              <AlertDescription>{message.message}</AlertDescription>
            </div>
          </AlertWrapper>
        ))}
      </div>
    </div>
  );
};

export default AlertMessages;
