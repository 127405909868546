/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { MultiDocumentSelect } from "@/components";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { Button } from "@/components/ui/button";
import { CheckboxWithText } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupWithText } from "@/components/ui/radio-group";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import ColorSelect from "@/features/project/charts/components/ColorSelect";
import ElementSelect from "@/features/project/charts/components/ElementSelect";
import { useDataTable, useProject } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { ChartColor, ElementType } from "@/types";
import { ChevronDown, Variable } from "lucide-react";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";

interface ExportChartsModalProps {
  open: boolean;
  handleClose: () => void;
}

// Add citation variables
const CITATION_VARIABLES = [
  { id: "inventor", name: "Inventor Name" },
  { id: "paragraph", name: "Paragraph" },
  { id: "page", name: "Page" },
  { id: "column", name: "Column" },
  { id: "lines", name: "Lines" },
  { id: "nickname", name: "Document Nickname" },
];

const FIGURE_VARIABLES = [
  { id: "figure_number", name: "Figure Number" },
  { id: "index", name: "Index" },
];

interface VariableInputProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  variables: { id: string; name: string }[];
  placeholder?: string;
}

const VariableInput: React.FC<VariableInputProps> = ({
  label,
  value,
  onChange,
  variables,
  placeholder,
}) => {
  return (
    <div className="space-y-1">
      <Label className="text-sm">{label}</Label>
      <div className="relative">
        <AutosizeTextarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="pr-8"
          placeholder={placeholder}
        />
        <DropdownMenu>
          <Tooltip>
            <TooltipTrigger asChild>
              <DropdownMenuTrigger asChild>
                <Button
                  type="button"
                  size="icon"
                  variant="ghost"
                  className="h-8 w-8 absolute right-2 top-1"
                >
                  <Variable className="h-5 w-5" />
                </Button>
              </DropdownMenuTrigger>
            </TooltipTrigger>
            <TooltipContent>Insert variable</TooltipContent>
          </Tooltip>
          <DropdownMenuContent>
            {variables.map((variable) => (
              <DropdownMenuItem
                key={variable.id}
                onClick={() => {
                  onChange(`${value}{{${variable.id}}}`);
                }}
              >
                {variable.name}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

/**
 * @description Exports charts to a Microsoft Word or Excel file
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 */
const ExportChartsModal: React.FC<ExportChartsModalProps> = ({ open, handleClose }) => {
  const { getElementChartExport } = useDataTable();
  const { updateProjectDetails } = useProject();

  // Global state from store
  const { currentProjectId, currentProject, updateCurrentProject } = useProjectStore();
  const { addErrorMessage, addLoadingMessage, removeLoadingMessage } =
    useAppStateStore();

  // Local state
  const [wordChecked, setWordChecked] = useState(false);
  const [excelChecked, setExcelChecked] = useState(false);
  const [selectedColors, setSelectedColors] = useState([]);
  const [includeSummaryCharts, setIncludeSummaryCharts] = useState(false);
  const [includeClaims, setIncludeClaims] = useState(false);
  const [includeFeatures, setIncludeFeatures] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [introductionLanguage, setIntroductionLanguage] = useState("");
  const [selectedClaims, setSelectedClaims] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [referenceIds, setReferenceIds] = useState([]);
  const [patentCitationFormat, setPatentCitationFormat] = useState(
    "{{nickname}} at {{column}}:{{lines}}",
  );
  const [applicationCitationFormat, setApplicationCitationFormat] = useState(
    "{{nickname}} at Paragraph {{paragraph}}",
  );
  const [documentCitationFormat, setDocumentCitationFormat] = useState(
    "{{nickname}} at Page {{page}}",
  );
  const [figureFormat, setFigureFormat] = useState(
    "Fig. {{figure_number}} at {{index}}",
  );
  const [citationPosition, setCitationPosition] = useState("start");
  const [figureCitationStyle, setFigureCitationStyle] = useState("embedded");
  const [isIntroExpanded, setIsIntroExpanded] = useState(true);

  const handleClaimSelect = (selectedClaims: string[]) => {
    setSelectedClaims(selectedClaims);
  };

  const handleFeatureSelect = (selectedFeatures: string[]) => {
    setSelectedFeatures(selectedFeatures);
  };

  useEffect(() => {
    setWordChecked(false);
    setExcelChecked(false);
    setIntroductionLanguage(currentProject.introductionLanguage || "");
    setIncludeSummaryCharts(false);
    setIncludeClaims(false);
    setIncludeFeatures(false);
    setAlertVisible(false);
    setAlertMessage("");
  }, [open]);

  /**
   * @description Exports charts to a Microsoft Word file
   * @param {boolean} includeFeatures - whether to include feature charts
   * @param {boolean} includeClaims - whether to include claim charts
   * @param {boolean} includeSummary - whether to include summary charts
   */
  const exportProject = async (
    finalColors: string[],
    finalFeatures: string[],
    finalClaims: string[],
    exportType: string,
  ) => {
    const response = await getElementChartExport(
      currentProjectId,
      referenceIds,
      includeFeatures,
      includeClaims,
      includeSummaryCharts,
      introductionLanguage,
      finalColors,
      finalFeatures,
      finalClaims,
      exportType,
      citationPosition,
      patentCitationFormat,
      applicationCitationFormat,
      documentCitationFormat,
      figureFormat,
      figureCitationStyle,
    );
    if (!response.success) {
      addErrorMessage("Error downloading file. Try again later.");
      return;
    }
    if (!response.data) {
      if (process.env.NODE_ENV !== "production") {
        console.error("No download URL found");
      }
      addErrorMessage("Error downloading file. Try again later.");
      return;
    }
  };

  // Handles the download button click and prepares payload
  const handleDownloadClick = async () => {
    // Validate user input on required selections
    const fileFormatSelected = wordChecked || excelChecked;
    const chartOptionsSelected =
      includeSummaryCharts || includeClaims || includeFeatures;
    if (!fileFormatSelected && !chartOptionsSelected) {
      setAlertMessage("Please select at least one file format and chart option.");
      setAlertMessage("Please select at least one file format and chart option.");
      setAlertVisible(true);
      return;
    } else if (!fileFormatSelected && chartOptionsSelected) {
      setAlertMessage("Please select at least one file format.");
      setAlertVisible(true);
      return;
    }
    if (fileFormatSelected && !chartOptionsSelected) {
      setAlertMessage("Please select at least one chart option.");
      setAlertVisible(true);
      return;
    }
    setAlertVisible(false);
    const key = nanoid();
    addLoadingMessage(`Downloading charts for ${currentProject.name}...`, key);
    handleClose();

    const finalColors =
      selectedColors?.length === 0 || selectedColors?.length === 3
        ? []
        : selectedColors;
    selectedColors?.length === 0 || selectedColors?.length === 3 ? [] : selectedColors;

    let finalFeatures = [];
    let finalClaims = [];

    if (
      includeFeatures &&
      selectedFeatures?.length > 0 &&
      selectedFeatures?.length !== currentProject.features?.length
    ) {
      finalFeatures = selectedFeatures;
    }
    if (
      includeClaims &&
      selectedClaims?.length > 0 &&
      selectedClaims?.length !== currentProject.claims?.length
    ) {
      finalClaims = selectedClaims;
    }

    await updateProjectDetails(currentProjectId, {
      introduction_language: introductionLanguage,
      // citation_format: citationFormat,
      // figure_format: figureFormat,
      // citation_position: citationPosition,
      // figure_citation_style: figureCitationStyle,
    });
    updateCurrentProject({
      ...currentProject,
      introductionLanguage: introductionLanguage,
      // citationFormat,
      // figureFormat,
      // citationPosition,
      // figureCitationStyle,
    });

    let exportType = "";

    if (wordChecked && excelChecked) {
      exportType = "both";
    } else if (wordChecked) {
      exportType = "word";
    } else if (excelChecked) {
      exportType = "excel";
    }

    await exportProject(finalColors, finalFeatures, finalClaims, exportType);
    removeLoadingMessage(key);
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[1200px] flex flex-col max-h-[90vh]">
        <DialogHeader>
          <DialogTitle>Download charts</DialogTitle>
        </DialogHeader>

        <div className="grid gap-4 py-2 overflow-y-auto flex-1">
          <div className="space-y-2">
            <h4 className="font-medium pb-1">Chart Types</h4>
            <div className="flex flex-wrap gap-4">
              <div className="flex items-center space-x-2">
                <CheckboxWithText
                  id="summary"
                  checked={includeSummaryCharts}
                  onCheckedChange={(checked) =>
                    setIncludeSummaryCharts(checked as boolean)
                  }
                  label="Summary Chart"
                />
              </div>
              {currentProject.claims?.length > 0 && (
                <div className="flex items-center space-x-2">
                  <CheckboxWithText
                    id="claims"
                    checked={includeClaims}
                    onCheckedChange={(checked) => setIncludeClaims(checked as boolean)}
                    label="Claim Charts"
                  />
                </div>
              )}
              {currentProject.features?.length > 0 && (
                <div className="flex items-center space-x-2">
                  <CheckboxWithText
                    id="features"
                    checked={includeFeatures}
                    onCheckedChange={(checked) =>
                      setIncludeFeatures(checked as boolean)
                    }
                    label="Feature Charts"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="space-y-2">
            <h4 className="font-medium pb-1">Chart Options</h4>
            <div className="flex flex-wrap gap-2">
              <div className="max-w-[500px]">
                <MultiDocumentSelect handleChange={setReferenceIds} general={true} />
              </div>
              <ColorSelect
                colors={[ChartColor.GREEN, ChartColor.YELLOW, ChartColor.RED]}
                selectedColors={selectedColors}
                setSelectedColors={setSelectedColors}
              />
              {includeClaims && currentProject.claims?.length > 0 && (
                <ElementSelect
                  onElementSelect={handleClaimSelect}
                  elementType={ElementType.CLAIM}
                />
              )}
              {includeFeatures && currentProject.features?.length > 0 && (
                <ElementSelect
                  onElementSelect={handleFeatureSelect}
                  elementType={ElementType.FEATURE}
                />
              )}
            </div>
          </div>

          <div className="space-y-2">
            <h4 className="font-medium pb-1">File format</h4>
            <div className="flex flex-wrap gap-4">
              <div className="flex items-center space-x-2">
                <CheckboxWithText
                  id="word"
                  checked={wordChecked}
                  onCheckedChange={(checked) => setWordChecked(checked as boolean)}
                  label="Microsoft Word (.docx)"
                />
              </div>
              <div className="flex items-center space-x-2">
                <CheckboxWithText
                  id="excel"
                  checked={excelChecked}
                  onCheckedChange={(checked) => setExcelChecked(checked as boolean)}
                  label="Microsoft Excel (.xlsx)"
                />
              </div>
            </div>
          </div>

          {wordChecked && (
            <div className="space-y-2">
              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => setIsIntroExpanded(!isIntroExpanded)}
              >
                <Label htmlFor="introduction" className="cursor-pointer">
                  Introduction Language
                </Label>
                <ChevronDown
                  className={`h-4 w-4 transition-transform ${
                    isIntroExpanded ? "transform rotate-180" : ""
                  }`}
                />
              </div>
              {isIntroExpanded && (
                <AutosizeTextarea
                  id="introduction"
                  value={introductionLanguage}
                  onChange={(e) => setIntroductionLanguage(e.target.value)}
                  maxHeight={300}
                />
              )}
            </div>
          )}

          <div className="space-y-4">
            <h4 className="font-medium pb-1">Citation Settings</h4>
            {wordChecked && (
              <div className="mt-2">
                <Label className="text-sm">Position</Label>
                <RadioGroup
                  value={citationPosition}
                  onValueChange={setCitationPosition}
                  className="flex gap-4 mt-2"
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupWithText value="start" id="start" label="Before Text" />
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupWithText value="end" id="end" label="After Text" />
                  </div>
                </RadioGroup>
              </div>
            )}

            <div className="grid grid-cols-3 gap-4">
              <VariableInput
                label="Patent Format"
                value={patentCitationFormat}
                onChange={setPatentCitationFormat}
                variables={CITATION_VARIABLES}
                placeholder="e.g., {{author}} at {{column}}:{{lines}}"
              />

              <VariableInput
                label="Patent Application Format"
                value={applicationCitationFormat}
                onChange={setApplicationCitationFormat}
                variables={CITATION_VARIABLES}
                placeholder="e.g., {{author}} at [{{paragraph}}]"
              />

              <VariableInput
                label="Uploaded Document Format"
                value={documentCitationFormat}
                onChange={setDocumentCitationFormat}
                variables={CITATION_VARIABLES}
                placeholder="e.g., {{author}} at p. {{page}}"
              />
            </div>

            {/* Figure Settings */}
            {wordChecked && (
              <div className="space-y-4 mt-4">
                <h4 className="font-medium pb-1">Figure Settings</h4>
                <div className="flex gap-8 items-start">
                  <div>
                    <Label className="text-sm">Figure Placement</Label>
                    <RadioGroup
                      value={figureCitationStyle}
                      onValueChange={setFigureCitationStyle}
                      className="flex gap-4 mt-2"
                    >
                      <div className="flex items-center space-x-2">
                        <RadioGroupWithText
                          value="embedded"
                          id="embedded"
                          label="Embedded with each citation"
                        />
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupWithText
                          value="separate"
                          id="separate"
                          label="Grouped for all element citations"
                        />
                      </div>
                    </RadioGroup>
                  </div>

                  {/* <div className="flex-1">
                    <VariableInput
                      label="Format"
                      value={figureFormat}
                      onChange={setFigureFormat}
                      variables={FIGURE_VARIABLES}
                      placeholder="e.g., Fig. {{figure_number}} at {{index}}"
                    />
                  </div> */}
                </div>
              </div>
            )}
          </div>
        </div>

        {alertVisible && (
          <Alert variant="destructive" className="mt-2">
            <AlertDescription>{alertMessage}</AlertDescription>
          </Alert>
        )}

        <DialogFooter className="mt-2 pt-2">
          <div className="flex flex-row justify-between gap-3">
            <div className="flex items-center gap-2">
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                onClick={handleDownloadClick}
                disabled={
                  (!wordChecked && !excelChecked) ||
                  (!includeSummaryCharts && !includeClaims && !includeFeatures)
                }
              >
                Download
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ExportChartsModal;
