/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect, useState, CSSProperties } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Expand, X, RotateCcw, RotateCw } from "lucide-react";

interface ImageViewerProps {
  open: boolean;
  imageUrl: string;
  imageName: string;
  handleClose: () => void;
}

interface ImageSize {
  width: number | string;
  height: number | string;
}

/**
 * @description Renders a modal for viewing an image
 */
const ImageViewer: React.FC<ImageViewerProps> = ({
  open,
  imageUrl,
  imageName,
  handleClose,
}) => {
  const [image, setImage] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [rotation, setRotation] = useState<number>(0);
  const [imageSize, setImageSize] = useState<ImageSize>({
    width: 0,
    height: 0,
  });
  const [originalSize, setOriginalSize] = useState<ImageSize>({
    width: 0,
    height: 0,
  });
  const [dialogSize, setDialogSize] = useState<ImageSize>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (imageUrl && imageUrl !== "null") {
      setImage(imageUrl);
      setName(imageName);
      setRotation(0);
      resetImageSize(imageUrl);
    }
  }, [imageUrl, imageName]);

  const resetImageSize = (url: string) => {
    const img = new Image();
    img.onload = () => {
      const size = { width: img.width, height: img.height };
      setOriginalSize(size);
      adjustSizes(size, 0);
    };
    img.src = url;
  };

  const adjustSizes = (size: ImageSize, newRotation: number) => {
    const maxWidth = window.innerWidth * 0.9;
    const maxHeight = window.innerHeight * 0.9;

    let width = Number(size.width);
    let height = Number(size.height);

    const aspectRatio = width / height;

    // Calculate the maximum square size that fits within the viewport
    const maxSquareSize = Math.min(maxWidth, maxHeight);

    // Scale the image to fit within the square
    if (width > height) {
      width = maxSquareSize;
      height = width / aspectRatio;
    } else {
      height = maxSquareSize;
      width = height * aspectRatio;
    }

    setImageSize({ width, height });
    // Set dialog size to be square based on the longest dimension
    const dialogSize = Math.max(width, height) + 100; // Add padding
    setDialogSize({ width: dialogSize, height: dialogSize });
  };

  const handleRotateLeft = () => {
    const newRotation = (rotation - 90 + 360) % 360;
    setRotation(newRotation);
    adjustSizes(originalSize, newRotation);
  };

  const handleRotateRight = () => {
    const newRotation = (rotation + 90) % 360;
    setRotation(newRotation);
    adjustSizes(originalSize, newRotation);
  };

  const handleExpand = () => {
    if (imageRef.current && imageRef.current.requestFullscreen) {
      imageRef.current.requestFullscreen();
    }
  };

  const imageRef = React.useRef<HTMLImageElement>(null);

  const imageStyle: CSSProperties = {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    transform: `rotate(${rotation}deg)`,
    width: imageSize.width,
    height: imageSize.height,
  };

  const dialogContentClass = cn(
    "p-6",
    "flex items-center justify-center",
    "w-auto h-auto",
    "max-w-[90vw] max-h-[90vh]",
    "min-w-[300px] min-h-[200px]",
  );

  const dialogStyle: CSSProperties = {
    width: `${dialogSize.width}px`,
    height: `${dialogSize.height}px`,
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className={dialogContentClass} style={dialogStyle} hideCloseButton>
        <div className="relative flex items-center justify-center w-full h-full">
          <img
            ref={imageRef}
            src={image ?? undefined}
            alt={name || "Image"}
            style={imageStyle}
            className="max-w-full max-h-full object-contain"
          />
          <div className="absolute top-0 right-0 flex space-x-2 mt-2">
            <Button
              size="icon"
              variant="ghost"
              aria-label="Rotate left"
              onClick={handleRotateLeft}
            >
              <RotateCcw size={16} />
            </Button>
            <Button
              size="icon"
              variant="ghost"
              aria-label="Rotate right"
              onClick={handleRotateRight}
            >
              <RotateCw size={16} />
            </Button>
            <Button
              size="icon"
              variant="ghost"
              aria-label="Expand image"
              onClick={handleExpand}
            >
              <Expand size={16} />
            </Button>
            <Button
              size="icon"
              variant="ghost"
              aria-label="Close image viewer"
              onClick={handleClose}
            >
              <X size={16} />
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ImageViewer;
