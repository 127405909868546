/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { usePortfolio, useViz } from "@/hooks";
import { useAppStateStore, useCreateProjectStore } from "@/store";
import React, { useEffect, useState } from "react";
import {
  CreateProjectPageLayout,
  ProjectDetailsStep,
  TechnicalSpecsStep,
} from "./components";

/**
 * @description Renders the create portfolio page
 */
const CreateStandardsProjectPage: React.FC = () => {
  const { createStandardEssentialPortfolio } = usePortfolio();
  const { uploadFile } = useViz();

  // Global state from store
  const { addErrorMessage } = useAppStateStore();

  const {
    isProjectCreationInProgress,
    projectName,
    resetCreateProjectStore,
    updateSpinnerText,
    updateIsProjectCreationInProgress,
  } = useCreateProjectStore();

  const [isSubjectCompleted, setIsSubjectCompleted] = useState<boolean>(false);
  const [isDetailsCompleted, setIsDetailsCompleted] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const resetState = () => {
    resetCreateProjectStore();
  };

  // Reset state on mount if create not in progress
  useEffect(() => {
    if (!isProjectCreationInProgress) {
      resetState();
    }
  }, []);

  // Check if details step is completed
  useEffect(() => {
    if (projectName !== "") {
      setIsDetailsCompleted(true);
    }
  }, [projectName]);

  // Check if subject step is completed
  useEffect(() => {
    if (uploadedFiles.length > 0) {
      setIsSubjectCompleted(true);
    } else {
      setIsSubjectCompleted(false);
    }
  }, [uploadedFiles]);

  const handlePortfolioCreation = async () => {
    updateSpinnerText(`Creating project, this may take a few minutes...`);
    updateIsProjectCreationInProgress(true);

    const uploadPromises = uploadedFiles.map((file) => uploadFile(file, true));
    const document_ids = (await Promise.all(uploadPromises)).map(
      (response) => response.data[0].document_id,
    );

    const response = await createStandardEssentialPortfolio(document_ids);
    if (!response.success) {
      addErrorMessage(
        response.message ||
          "An error occurred while creating this project. Try again later.",
      );
      return;
    }

    // await fetchAndNavigateToPortfolio(response.data.id);
    updateIsProjectCreationInProgress(false);
  };

  return (
    <CreateProjectPageLayout
      disabled={!isDetailsCompleted || !isSubjectCompleted}
      handleCreate={handlePortfolioCreation}
      isPortfolio={false}
      title="New Standards Project"
      resetState={resetState}
      isConfirmed={false}
    >
      <ProjectDetailsStep isPortfolio={false} startExpanded={true} />

      {/* Subjects step */}
      <TechnicalSpecsStep
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
      />
    </CreateProjectPageLayout>
  );
};

export default CreateStandardsProjectPage;
