/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { useAppStateStore, useProjectStore } from "@/store";
import useViz from "@/hooks/useViz";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Pencil1Icon,
  CheckIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@radix-ui/react-icons";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Paragraph } from "@/components/ui/typography";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { Label } from "@/components/ui/label";

/**
 * @description Abstract component
 */
const Abstract: React.FC = () => {
  const { currentSubject, currentProjectId } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();
  const { updateDocumentDetails } = useViz();

  const [isEditing, setIsEditing] = useState(false);
  const [abstract, setAbstract] = useState(currentSubject.abstract);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleEditButton = () => {
    setIsEditing(true);
    setIsCollapsed(false);
  };

  const handleSave = async () => {
    const response = await updateDocumentDetails(currentProjectId, {
      abstract,
    });
    setIsEditing(false);
    if (!response.success) {
      addErrorMessage("Failed to save abstract. Please try again later.");
    }
  };

  return (
    <Card className="mt-3 p-2 pl-2 w-full">
      <Collapsible open={!isCollapsed} onOpenChange={(open) => setIsCollapsed(!open)}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 pl-2">
            <Label className="mb-0">Abstract</Label>
            {isEditing ? (
              <Button
                variant="ghost"
                size="icon"
                onClick={handleSave}
                aria-label="save"
              >
                <CheckIcon className="h-4 w-4" />
              </Button>
            ) : (
              <Button
                variant="ghost"
                size="icon"
                onClick={handleEditButton}
                aria-label="edit"
              >
                <Pencil1Icon className="h-4 w-4" />
              </Button>
            )}
          </div>
          {currentSubject.abstract && (
            <CollapsibleTrigger asChild>
              <Button variant="ghost" size="icon">
                {isCollapsed ? (
                  <ChevronDownIcon className="h-4 w-4" />
                ) : (
                  <ChevronUpIcon className="h-4 w-4" />
                )}
              </Button>
            </CollapsibleTrigger>
          )}
        </div>
        <CollapsibleContent>
          {isEditing ? (
            <div className="space-y-2 mt-2 pl-2">
              <AutosizeTextarea
                value={abstract}
                onChange={(e) => setAbstract(e.target.value)}
                placeholder="Enter abstract"
                className="min-h-[100px]"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSave();
                  }
                }}
              />
            </div>
          ) : (
            currentSubject.abstract && (
              <div className="px-2">
                <Paragraph className="my-2 text-sm break-words">
                  {currentSubject.abstract}
                </Paragraph>
              </div>
            )
          )}
        </CollapsibleContent>
      </Collapsible>
    </Card>
  );
};

export default Abstract;
