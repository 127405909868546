/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { LoadingButton } from "@/components/ui/loading-button";
import React, { useState } from "react";

interface DeleteProjectModalProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => Promise<void>;
  name: string;
}

/**
 * @description Delete project modal
 *
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {function} handleDelete - Function to delete the project.
 * @param {string} name - The name of the project to delete.
 */
const DeleteProjectModal: React.FC<DeleteProjectModalProps> = ({
  open,
  handleClose,
  handleDelete,
  name = "",
}) => {
  const [confirmation, setConfirmation] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteClick = async () => {
    setIsLoading(true);
    try {
      await handleDelete();
      setConfirmation("");
      handleClose();
    } catch (error) {
      console.error("Error deleting project:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmation(e.target.value);
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Delete project {name ? `"${name}"` : ""}?</DialogTitle>
          <DialogDescription>
            This action cannot be undone. All users on this project will immediately
            lose access. All project data will be deleted and cannot be recovered.
          </DialogDescription>
        </DialogHeader>
        <Input
          value={confirmation}
          onChange={handleInputChange}
          placeholder={`Enter '${name}' to confirm`}
        />
        <DialogFooter>
          <Button variant="outline" onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton
            variant="destructive"
            onClick={handleDeleteClick}
            disabled={confirmation !== name || isLoading}
            loading={isLoading}
          >
            {isLoading ? "Deleting..." : "Delete"}
          </LoadingButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteProjectModal;
