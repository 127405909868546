import { DragAndDropFileArea, UploadedFileDisplay } from "@/components";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { AlertTriangle } from "lucide-react";
import React, { useState } from "react";
import { CheckboxWithText } from "../ui/checkbox";

interface BaseUploadFilesModalProps {
  open: boolean;
  handleCloseModal: () => void;
  handleAddFilesClick: (files: File[]) => Promise<void>;
  title: string;
  buttonLabel: string;
  showCheckbox?: boolean;
  checkboxLabel?: string;
  onCheckboxChange?: (checked: boolean) => void;
  isCheckboxChecked?: boolean;
}

const BaseUploadFilesModal: React.FC<BaseUploadFilesModalProps> = ({
  open,
  handleCloseModal,
  handleAddFilesClick,
  title,
  buttonLabel,
  showCheckbox = false,
  checkboxLabel = "",
  onCheckboxChange,
  isCheckboxChecked = false,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [fileTypeError, setFileTypeError] = useState<string | null>(null);

  const handleFileChange = (selectedFiles: File[]) => {
    const newValidFiles: File[] = [];
    let errorMessage = "";

    for (let selectedFile of selectedFiles) {
      const isFileTypeValid =
        selectedFile.type === "application/pdf" || selectedFile.type === "text/plain";

      if (isFileTypeValid) {
        newValidFiles.push(selectedFile);
      } else {
        errorMessage += `${selectedFile.name}: File type is not supported. Please upload a PDF or text file. `;
      }
    }

    setFiles((prevFiles) => [...prevFiles, ...newValidFiles]);
    setFileTypeError(errorMessage || null);
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleStartUploadClick = async () => {
    await handleAddFilesClick(files);
    handleResetAndCloseModal();
  };

  const handleResetAndCloseModal = () => {
    setFileTypeError(null);
    setFiles([]);
    handleCloseModal();
  };

  return (
    <Dialog open={open} onOpenChange={handleResetAndCloseModal}>
      <DialogContent className="w-[50vw] min-w-[300px] max-w-[800px]">
        <DialogHeader>
          <DialogTitle className="flex items-center">{title}</DialogTitle>
        </DialogHeader>

        <div className="flex-grow flex flex-col space-y-4 overflow-hidden">
          <DragAndDropFileArea
            handleFiles={handleFileChange}
            supportedFileTypes={[".pdf", ".txt"]}
          />

          {files.length > 0 && (
            <ScrollArea className="flex-grow max-h-[200px]">
              <div className="space-y-2">
                {files
                  .slice()
                  .reverse()
                  .map((file, index) => (
                    <UploadedFileDisplay
                      file={file}
                      key={files.length - 1 - index}
                      onRemove={() => handleRemoveFile(files.length - 1 - index)}
                      fullWidth={true}
                    />
                  ))}
              </div>
            </ScrollArea>
          )}

          {fileTypeError && (
            <Alert variant="destructive">
              <AlertTriangle className="h-4 w-4" />
              <AlertDescription>{fileTypeError}</AlertDescription>
            </Alert>
          )}
        </div>

        <DialogFooter className="mt-4">
          <div className="flex items-center justify-between space-x-2 w-full">
            {showCheckbox && (
              <div className="flex items-center space-x-2">
                <CheckboxWithText
                  id="complete-charts"
                  checked={isCheckboxChecked}
                  onCheckedChange={onCheckboxChange}
                  label={checkboxLabel}
                />
              </div>
            )}
            <Button onClick={handleStartUploadClick} disabled={files.length === 0}>
              {buttonLabel}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default BaseUploadFilesModal;
