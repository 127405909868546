/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import qs from "qs";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useAuthInfo, useLogoutFunction } from "@propelauth/react";
import { ApiResponse, EnvironmentTypes } from "@/types";
import { useAppStateStore } from "@/store";

/**
 * @description Hook to handle the API requests
 */
const useApi = () => {
  const { accessToken, isLoggedIn } = useAuthInfo();
  const logout = useLogoutFunction();
  const { addErrorMessage } = useAppStateStore();

  const apiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials: true,
    timeout: 300000,
  });

  // Interceptor to add the access token to each request
  apiInstance.interceptors.request.use((config) => {
    if (isLoggedIn && accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  // Handle 401 errors
  apiInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error) => {
      if (error.response?.status === 401) {
        // If we get a 401, log the user out, their session has expired
        await logout(true);
        addErrorMessage("Your session has expired. Please sign in again.");
      }
      return Promise.reject(error);
    },
  );

  /**
   * @description Makes a POST request to the API
   * @param {string} url - The URL to make the request to
   * @param {object} body - The body of the request
   * @param {object} params - The parameters to send with the request
   * @param {object} customConfig - The custom configuration for the request
   */
  const postRequest = async (
    url: string,
    body: object = {},
    params: object = {},
    customConfig: AxiosRequestConfig = {},
  ) => {
    try {
      const config: AxiosRequestConfig = {
        ...customConfig,
        headers: {
          ...customConfig.headers,
          Authorization: `Bearer ${accessToken}`,
        },
        params,
        // withCredentials: true, // for http only cookie
      };

      const response = await apiInstance.post(url, body, config);
      return { data: response.data, status: response.status };
    } catch (error) {
      throw error;
    }
  };

  function joinUrl(baseUrl: string, urlPath: string): string {
    if (!baseUrl.endsWith("/")) {
      baseUrl += "/";
    }
    if (urlPath.startsWith("/")) {
      urlPath = urlPath.substring(1);
    }
    return baseUrl + urlPath;
  }

  /**
   * @description Makes a POST request to the API with a file
   * @param {string} url - The URL to make the request to
   * @param {object} formData - The form data to send with the request
   * @param {object} params - The parameters to send with the request
   * @param {object} customConfig - The custom configuration for the request
   * @param {number} timeout - The timeout for the request in milliseconds
   */
  const postRequestFile = async (
    url: string,
    formData: FormData,
    params: object = {},
    customConfig: RequestInit = {},
    timeout: number = 300000,
  ) => {
    // Use the utility function to construct fullUrl
    let fullUrl = joinUrl(process.env.REACT_APP_API_BASE_URL, url);

    const config: RequestInit = {
      method: "POST",
      body: formData, // Directly using FormData here
      headers: {
        // Do not set 'Content-Type': 'multipart/form-data' here. The browser will do it for you, including the boundary parameter.
        ...customConfig.headers,
        Authorization: `Bearer ${accessToken}`,
      },
      credentials: "include", // For CORS
      ...customConfig,
    };

    if (params && Object.keys(params).length > 0) {
      const queryParams = new URLSearchParams(
        params as Record<string, string>,
      ).toString();
      fullUrl += `?${queryParams}`;
    }

    config.signal = AbortSignal.timeout(timeout);

    try {
      const response = await fetch(fullUrl, config);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json(); // Assuming JSON response

      return { data: data, status: response.status };
    } catch (error: any) {
      if (error.name === "AbortError") {
        throw new Error(`Request timed out after ${timeout} ms`);
      } else {
        throw new Error("Request failed: " + error.message);
      }
    }
  };

  /**
   * @description Makes a GET request to the API
   * @param {string} url - The URL to make the request to
   * @param {object} params - The parameters to send with the request
   * @param {object} customConfig - The custom configuration for the request
   */
  const getRequest = async (
    url: string,
    params: object = {},
    customConfig: AxiosRequestConfig = {},
  ) => {
    try {
      const config: AxiosRequestConfig = {
        ...customConfig,
        headers: {
          ...customConfig.headers,
          Authorization: `Bearer ${accessToken}`,
        },
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
        // withCredentials: true, // for http only cookie
      };
      const response = await apiInstance.get(url, config);
      return { data: response.data, status: response.status };
    } catch (error) {
      throw error;
    }
  };

  /**
   * @description Makes a POST request to the API with streaming
   * @param {string} url - The URL to make the request to
   * @param {object} body - The body of the request
   * @param {object} params - The parameters to send with the request
   * @param {object} config - The configuration for the request
   */
  const postStreamRequest = async (
    url: string,
    body: object = {},
    params: object = {},
    config: RequestInit = {},
  ) => {
    try {
      let fullUrl = joinUrl(process.env.REACT_APP_API_BASE_URL, url);
      const response = await fetch(fullUrl, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          ...config.headers,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleError = (error: any, defaultMessage: string): ApiResponse => {
    if (process.env.NODE_ENV !== EnvironmentTypes.PROD) {
      console.error(defaultMessage, error);
    }

    let errorMessage = defaultMessage;
    let errorStatus;
    let errorDetail;

    if (axios.isAxiosError(error)) {
      // Handle Axios errors
      errorStatus = error.response?.status;
      errorDetail = error.response?.data?.detail || error.response?.data?.error;
      errorMessage = errorDetail || error.response?.statusText || defaultMessage;
    } else if (error instanceof Error) {
      // Handle fetch API errors
      const match = error.message.match(/HTTP error! Status: (\d+)/);
      if (match) {
        errorStatus = parseInt(match[1], 10);
        errorMessage = `HTTP error! Status: ${errorStatus}`;
      } else {
        errorMessage = error.message || defaultMessage;
      }
    }

    const response = {
      success: false,
      message: errorMessage,
      error,
      status: errorStatus,
      detail: errorDetail,
    };
    return response;
  };

  return {
    postRequest,
    postRequestFile,
    getRequest,
    postStreamRequest,
    handleError,
  };
};

export default useApi;
