import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { DocumentStatus } from "@/types";
interface DismissDocumentStatusModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onConfirm: () => void;
  documentNames?: string[];
  actions?: DocumentStatus[];
}

/**
 * @description Dismiss document status modal
 * @param {boolean} isOpen - Whether the modal is open.
 * @param {function} onOpenChange - Function to open/close the modal.
 * @param {function} onConfirm - Function to confirm the modal.
 */
const DismissDocumentStatusModal: React.FC<DismissDocumentStatusModalProps> = ({
  isOpen,
  onOpenChange,
  onConfirm,
  documentNames,
  actions,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] max-h-[80vh]">
        <DialogHeader>
          <DialogTitle>Dismiss statuses</DialogTitle>
          <DialogDescription>
            This action will dismiss the warning status for the selected documents and
            reset the status to Processed.
          </DialogDescription>
        </DialogHeader>
        {documentNames?.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-2">
            {documentNames.map((name) => (
              <Badge variant="outline" key={name}>
                {name}
              </Badge>
            ))}
          </div>
        )}
        <DialogFooter>
          <div className="flex gap-2">
            <Button variant="outline" onClick={() => onOpenChange(false)}>
              Cancel
            </Button>
            <Button onClick={onConfirm}>Dismiss Statuses</Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DismissDocumentStatusModal;
