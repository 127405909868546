/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import ExploreNavigation from "@/features/explore/components/ExploreNavigation";

/**
 * @description Renders the explore header
 */
const ExploreHeader: React.FC = () => {
  return (
    <div className="flex items-center justify-between px-4 py-2 border-b">
      <div className="flex items-center space-x-2">
        <div className="flex items-center space-x-2 mr-4">
          <div className="text-base font-medium p-1 h-auto whitespace-nowrap">
            Explore
          </div>
        </div>
        <ExploreNavigation />
      </div>
    </div>
  );
};

export default ExploreHeader;
