/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { usePortfolio, useProject, useViz } from "@/hooks";
import { useProjectStore } from "@/store";
import { ParentType } from "@/types";
import { useAuthInfo } from "@propelauth/react";
import { ChevronRightIcon, PersonIcon } from "@radix-ui/react-icons";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PortfolioNavigationTabs, ProjectNavigation, ShareProjectModal } from "./index";

/**
 * @description Project header containing title, navigation tabs, and sharing
 */
function ProjectHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  const { updateProjectDetails } = useProject();
  const { getUserProjects } = useViz();
  const { updatePortfolioDetails } = usePortfolio();
  const { user } = useAuthInfo();

  // Global state from store
  const {
    currentProjectId,
    currentProject,
    updateCurrentProject,
    currentParent,
    currentPortfolio,
    currentPortfolioId,
    updateCurrentPortfolio,
    clearCurrentProject,
  } = useProjectStore();
  const projectOwner =
    currentParent === ParentType.PROJECT
      ? currentProject?.owner
      : currentPortfolio?.owner;

  // Check if the current URL contains '/project'
  const isProjectPage = location.pathname.includes("/project");
  const isPortfolioPage = location.pathname.includes("/portfolio") && !isProjectPage;
  // Local state set up
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>(
    currentParent === ParentType.PROJECT
      ? currentProject?.name || "Untitled"
      : currentPortfolio?.name || "Untitled",
  );
  const [subProjectName, setSubProjectName] = useState<string>(
    currentParent === ParentType.PORTFOLIO && currentProject ? currentProject.name : "",
  );
  const [isEditingSubProject, setIsEditingSubProject] = useState<boolean>(false);

  // Handle project name change
  const handleRename = (e: ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);
  };

  const handlePortfolioClick = () => {
    if (isProjectPage && currentParent === ParentType.PORTFOLIO) {
      clearCurrentProject();
      navigate(`/portfolio/${currentPortfolioId}/subjects`);
    } else {
      setIsEditing(true);
    }
  };

  // Save project name
  const handleSaveProjectName = async () => {
    setIsEditing(false);
    const finalName = projectName.trim() === "" ? "Untitled" : projectName;
    if (currentParent === ParentType.PROJECT && currentProject) {
      if (currentProject.name !== finalName) {
        updateCurrentProject({
          ...currentProject,
          name: finalName,
        });
        await updateProjectDetails(currentProjectId, { name: finalName });
      }
    } else if (currentParent === ParentType.PORTFOLIO && currentPortfolio) {
      if (currentPortfolio.name !== finalName) {
        updateCurrentPortfolio({
          ...currentPortfolio,
          name: finalName,
        });
        await updatePortfolioDetails(currentPortfolioId, { name: finalName });
      }
    }
    if (user) {
      await getUserProjects();
    }
  };

  const handleSubProjectRename = (e: ChangeEvent<HTMLInputElement>) => {
    setSubProjectName(e.target.value);
  };

  const handleSaveSubProjectName = async () => {
    setIsEditingSubProject(false);
    const finalName = subProjectName.trim() === "" ? "Untitled" : subProjectName;
    if (currentProject && currentPortfolio) {
      if (currentProject.name !== finalName) {
        updateCurrentProject({
          ...currentProject,
          name: finalName,
        });
        const updatedProjects = currentPortfolio.projects.map((project) =>
          project.id === currentProjectId ? { ...project, name: finalName } : project,
        );

        updateCurrentPortfolio({
          ...currentPortfolio,
          projects: updatedProjects,
        });
        await updateProjectDetails(currentProjectId, { name: finalName });
      }
    }
  };

  useEffect(() => {
    if (currentProject?.name) {
      setProjectName(currentProject.name);
    }
  }, [currentProject?.name]);

  useEffect(() => {
    if (currentParent === ParentType.PORTFOLIO && currentProject?.name) {
      setSubProjectName(currentProject.name);
    }
  }, [currentParent, currentProject?.name]);

  return (
    <div className="flex items-center justify-between px-4 py-2 border-b">
      <div className="flex items-center space-x-2">
        <div className="flex items-center space-x-2 mr-4">
          {!isEditing ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <div
                  className={`text-base font-medium p-1 h-auto whitespace-nowrap ${
                    currentParent === ParentType.PORTFOLIO ? "cursor-pointer" : ""
                  }`}
                  onClick={handlePortfolioClick}
                >
                  {currentParent === ParentType.PROJECT
                    ? currentProject?.name || "Untitled"
                    : currentPortfolio?.name || "Untitled"}
                </div>
              </TooltipTrigger>

              {!isProjectPage && (
                <TooltipContent>
                  Rename{" "}
                  {currentParent === ParentType.PROJECT ? "project" : "portfolio"}
                </TooltipContent>
              )}
            </Tooltip>
          ) : (
            <Input
              className="h-8 text-sm"
              autoFocus
              value={projectName}
              onChange={handleRename}
              onBlur={handleSaveProjectName}
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  handleSaveProjectName();
                }
              }}
            />
          )}
          {currentParent === ParentType.PORTFOLIO &&
            currentProject?.name &&
            isProjectPage && (
              <>
                <ChevronRightIcon className="w-4 h-4" />
                {!isEditingSubProject ? (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div
                        className="text-base font-medium p-1 h-auto whitespace-nowrap"
                        onClick={() => setIsEditingSubProject(true)}
                      >
                        {currentProject?.name}
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>Rename project</TooltipContent>
                  </Tooltip>
                ) : (
                  <Input
                    autoFocus
                    value={subProjectName}
                    onChange={handleSubProjectRename}
                    onBlur={handleSaveSubProjectName}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === "Enter") {
                        handleSaveSubProjectName();
                      }
                    }}
                  />
                )}
              </>
            )}
        </div>
        {isProjectPage && <ProjectNavigation />}
        {isPortfolioPage && <PortfolioNavigationTabs />}
      </div>
      {projectOwner === user?.userId && (
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setShowShareModal(true)}
          aria-label="Share project"
        >
          <PersonIcon className="w-5 h-5" />
        </Button>
      )}
      {showShareModal && (
        <ShareProjectModal
          open={showShareModal}
          handleClose={() => setShowShareModal(false)}
          projectTitle={
            currentParent === ParentType.PROJECT && currentProject
              ? currentProject.name
              : currentPortfolio?.name || "Untitled"
          }
        />
      )}
    </div>
  );
}

export default ProjectHeader;
