/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect } from "react";
import { useProjectStore, useAppStateStore } from "@/store";
import { useLlm, useProject } from "@/hooks";
import { Patent } from "@/types";
import { DocumentsTable } from ".";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Loader } from "@/components";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { LoadingButton } from "@/components/ui/loading-button";

interface PruneReferencesModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

/**
 * @description Prune references modal
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} onOpenChange - Function to close the modal
 */
const PruneReferencesModal: React.FC<PruneReferencesModalProps> = ({
  open,
  onOpenChange,
}) => {
  const { pruneReferences } = useLlm();
  const { getProjectMetadata, updateProjectDetails } = useProject();

  const { currentProjectId } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();

  const [error, setError] = useState<string | boolean>(false);
  const [isSourcesLoading, setIsSourcesLoading] = useState(false);
  const [foundSources, setFoundSources] = useState<Patent[]>([]);
  const [selectedReferences, setSelectedReferences] = useState<Patent[]>([]);
  const [isPruneLoading, setIsPruneLoading] = useState(false);

  // Fetch sources to prune on modal open
  useEffect(() => {
    const pruneResponse = async () => {
      setIsSourcesLoading(true);
      const response = await pruneReferences(currentProjectId);
      if (response.success) {
        setFoundSources(response.data);
      } else {
        addErrorMessage(response.message);
      }
      setIsSourcesLoading(false);
    };

    if (open) {
      resetModal();
      pruneResponse();
    }
  }, [open]);

  const handlePruneReferences = async (references: string[]) => {
    setIsPruneLoading(true);
    const response = await updateProjectDetails(currentProjectId, {
      old_references: references,
    });
    if (!response.success) {
      addErrorMessage("Failed to prune references.");
      setError("Failed to prune references.");

      return;
    }
    await getProjectMetadata(currentProjectId);
    setIsPruneLoading(false);
    handleClose();
  };

  const resetModal = () => {
    setError(false);
    setFoundSources([]);
    setSelectedReferences([]);
    setIsSourcesLoading(false);
    setIsPruneLoading(false);
  };

  const handleClose = () => {
    resetModal();
    onOpenChange(false);
  };

  const handleRowSelection = (selectedRows: Patent[]) => {
    setSelectedReferences(selectedRows);
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[85vw] max-h-[85vh] w-full flex flex-col min-w-[600px]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            Prune references
          </DialogTitle>
        </DialogHeader>

        {isSourcesLoading ? (
          <Loader message={"Finding sources to prune..."} />
        ) : (
          <div className="flex flex-col h-[calc(85vh-200px)]">
            {foundSources?.length > 0 ? (
              <DocumentsTable
                references={foundSources}
                height="calc(85vh-175px)"
                onRowSelection={handleRowSelection}
                isSearch={true}
              />
            ) : (
              <div className="mt-4 text-center">
                <p>
                  No references found to prune. Prune manually from the table or charts.
                </p>
              </div>
            )}
          </div>
        )}

        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <DialogFooter>
          {foundSources?.length > 0 && (
            <>
              {!isPruneLoading && (
                <Button
                  onClick={() =>
                    handlePruneReferences(foundSources.map((source) => source.id))
                  }
                  variant={selectedReferences.length === 0 ? "default" : "outline"}
                  disabled={isPruneLoading}
                >
                  Prune All
                </Button>
              )}
              <LoadingButton
                onClick={() =>
                  handlePruneReferences(selectedReferences.map((ref) => ref.id))
                }
                disabled={selectedReferences.length === 0 || isPruneLoading}
              >
                {isPruneLoading ? "Pruning..." : "Prune Selected"}
              </LoadingButton>
            </>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PruneReferencesModal;
