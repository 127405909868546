/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect } from "react";
import { useProjectStore } from "@/store";
import { ProjectsTable } from "@/features/home/components";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

interface ArchivedProjectsModalProps {
  open: boolean;
  handleClose: () => void;
}

/**
 * @description Archived projects modal
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 */
const ArchivedProjectsModal: React.FC<ArchivedProjectsModalProps> = ({
  open,
  handleClose,
}) => {
  const { archivedProjectsList } = useProjectStore();

  useEffect(() => {
    if (archivedProjectsList.length === 0) {
      handleClose();
    }
  }, [archivedProjectsList, handleClose]);

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Archived projects</DialogTitle>
        </DialogHeader>
        <ProjectsTable
          tableType="org"
          isInArchive={true}
          height="h-[calc(100vh-200px)]"
        />
      </DialogContent>
    </Dialog>
  );
};

export default ArchivedProjectsModal;
