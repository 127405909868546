/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useMemo, useEffect } from "react";
import { useUserStore, useAdminStore, useAppStateStore } from "@/store";
import { useAdminManagement } from "@/hooks";
import { RoleType } from "@/types";
import {
  OrganizationDetailsTable,
  AdminPageLayout,
  NoAccessMessage,
} from "@/features/admin/components";
import { Loader } from "@/components";

/**
 * @description Super admin page component
 */
function SuperAdminOrganizationPage() {
  const { superAdminOrganizations } = useAdminStore();
  const { addErrorMessage } = useAppStateStore();
  const { getSuperAdminDetails } = useAdminManagement();

  // Global state from store
  const { role } = useUserStore();

  const isSuperAdmin = useMemo(() => role === RoleType.OWNER, [role]);

  useEffect(() => {
    if (!superAdminOrganizations) {
      getSuperAdminDetails().catch(() =>
        addErrorMessage("Error fetching organization details"),
      );
    }
  }, [superAdminOrganizations, getSuperAdminDetails, addErrorMessage]);

  return (
    <AdminPageLayout isSuperAdmin={true} pageName="Super Admin - Organizations">
      {isSuperAdmin ? (
        superAdminOrganizations === null ? (
          <Loader />
        ) : (
          <div className="space-y-8 mt-2">
            <OrganizationDetailsTable />
          </div>
        )
      ) : (
        <NoAccessMessage />
      )}
    </AdminPageLayout>
  );
}

export default SuperAdminOrganizationPage;
