/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect, useState } from "react";
import { useCreateProject } from "@/hooks";
import {
  ProjectDetailsStep,
  InvaliditySubjectStep,
  CreateProjectPageLayout,
} from "@/features/project/create/components";
import { ProjectType } from "@/types";
import { useCreateProjectStore } from "@/store";

/**
 * @description Renders the create project page
 */
const CreateProjectPage: React.FC = () => {
  const { createProjectFromNumber } = useCreateProject();

  const {
    updateIsProjectCreationInProgress,
    isProjectCreationInProgress,
    resetCreateProjectStore,
    projectName,
    updateSpinnerText,
    subjectNumbers,
  } = useCreateProjectStore();

  // Local state setup
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [isSubjectCompleted, setIsSubjectCompleted] = useState<boolean>(false);
  const [isDetailsCompleted, setIsDetailsCompleted] = useState<boolean>(false);

  const resetState = () => {
    resetCreateProjectStore();
    setIsConfirmed(false);
    setIsDetailsCompleted(false);
    setIsSubjectCompleted(false);
  };

  // Check if subject step is completed
  useEffect(() => {
    if (subjectNumbers.length > 0) {
      setIsSubjectCompleted(true);
    } else {
      setIsSubjectCompleted(false);
    }
  }, [subjectNumbers]);

  // Check if details step is completed
  useEffect(() => {
    if (projectName !== "") {
      setIsDetailsCompleted(true);
    }
  }, [projectName]);

  // Reset state on mount if create not in progress
  useEffect(() => {
    if (!isProjectCreationInProgress) {
      resetState();
    }
  }, []);

  const handleCreateProject = async () => {
    setIsConfirmed(true);
    updateIsProjectCreationInProgress(true);
    updateSpinnerText("Creating project...");
    await createProjectFromNumber(ProjectType.INV);
    updateIsProjectCreationInProgress(false);
  };

  return (
    <CreateProjectPageLayout
      isConfirmed={isConfirmed}
      resetState={resetState}
      disabled={!isDetailsCompleted || !isSubjectCompleted}
      handleCreate={handleCreateProject}
      isPortfolio={false}
      title="New Invalidity Project"
    >
      <ProjectDetailsStep startExpanded={true} isPortfolio={false} />
      <InvaliditySubjectStep isCompleted={isSubjectCompleted} startExpanded={false} />
    </CreateProjectPageLayout>
  );
};

export default CreateProjectPage;
