/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { ErrorMessage, Loader, ProjectPage } from "@/components";
import SemanticSearchHeader from "@/features/explore/search/components/SearchHeader";
import SearchResultsTable from "@/features/explore/search/components/SearchResultsTable";
import { useVector } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { useState } from "react";

/**
 * @description Renders the project search page
 */
const ProjectSearchPage = () => {
  const { semanticSearchDocuments } = useVector();

  // Page title
  const { currentProject } = useProjectStore();
  const { isReferencesLoading } = useAppStateStore();
  const pageName = currentProject.name ? `${currentProject.name} - Search` : "Search";

  // Global state from store
  const projectId = useProjectStore((state) => state.currentProjectId);
  const subjectId = useProjectStore((state) => state.currentProject.subjectId);
  const referenceIds = useProjectStore(
    (state) => state.currentProject.referenceIds ?? [],
  );
  const projectIdArray = [
    subjectId,
    ...(Array.isArray(referenceIds) ? referenceIds : []),
  ];

  // Local state setup
  const [showError, setShowError] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sentSearchQuery, setSentSearchQuery] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);
  const [searchMode, setSearchMode] = useState("semantic");

  const handleDocumentChange = (selectedIds: string[]) => {
    setSelectedDocumentIds(selectedIds);
  };

  // Handles the semantic search
  const handleSemanticSearch = async () => {
    setSearchResults(null);
    setShowError(false);

    if (selectedDocumentIds.length === 0) {
      setSelectedDocumentIds(projectIdArray as string[]);
    }

    const trimmedSearchQuery = searchQuery.trim();
    setSentSearchQuery(trimmedSearchQuery);

    if (trimmedSearchQuery !== "" && trimmedSearchQuery !== null) {
      setShowSpinner(true);
      const response = await semanticSearchDocuments(
        projectId,
        trimmedSearchQuery,
        selectedDocumentIds,
        searchMode,
      );
      if (response.success) {
        setShowSpinner(false);
        setShowError(false);
        setSelectedDocumentIds([]);
        setSearchResults(response.data.sources[0]);
      } else {
        setShowError(true);
        setShowSpinner(false);
      }
    }
  };

  return (
    <ProjectPage pageName={pageName}>
      {isReferencesLoading ? (
        <div className="flex justify-center items-center flex-col mt-12">
          <Loader message="Loading..." />
        </div>
      ) : (
        <>
          <div className="sticky top-0 bg-background z-10">
            <SemanticSearchHeader
              handleSemanticSearch={handleSemanticSearch}
              searchMode={searchMode}
              setSearchMode={setSearchMode}
              handleDocumentChange={handleDocumentChange}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              general={false}
              disabled={false}
            />
            {showSpinner && <Loader message={"Searching the documents..."} />}
            {showError && <ErrorMessage />}
          </div>
          {searchResults && !isReferencesLoading && (
            <SearchResultsTable
              searchResults={searchResults}
              sentSearchQuery={sentSearchQuery}
              general={false}
              searchChatProjectId={projectId}
              height="h-[calc(100vh-125px)]"
            />
          )}
        </>
      )}
    </ProjectPage>
  );
};
export default ProjectSearchPage;
