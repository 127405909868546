/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { useAppStateStore, useProjectStore } from "@/store";
import { SidebarPage } from "@/components";
import ExploreHeader from "@/features/explore/components/ExploreHeader";
import { BaseChat } from "@/features/explore/chat/components";

/**
 * @description Explore chat page
 */
const ExploreChatPage: React.FC = () => {
  const { searchChatProjectId } = useAppStateStore();

  const referenceIds = useProjectStore(
    (state: any) => state.currentProject.referenceIds,
  );

  return (
    <SidebarPage pageName="Explore - Chat">
      <ExploreHeader />
      <BaseChat
        generalBool={true}
        projectId={searchChatProjectId}
        disabledBool={referenceIds.length === 0}
        documents={referenceIds}
      />
    </SidebarPage>
  );
};

export default ExploreChatPage;
