/*
 * Copyright AndAI, Inc. 2024. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc.
 * and is protected as a trade secret.
 */
import { Loader } from "@/components";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { useProjectStore } from "@/store";
import { ChartColor, SummaryChartData } from "@/types";
import { splitId } from "@/utils/projectUtils";
import React, { useEffect, useMemo, useState } from "react";

interface SummaryTableProps {
  handleSelect: (isExpanded: boolean, claimNumber: string | null, key: string) => void;
  isExpanded: boolean;
  hideEmptyRows: boolean;
}

/**
 * @description Summary table
 * @param {function} handleSelect - Function to handle the selection of a claim.
 * @param {boolean} isExpanded - Whether the table is expanded.
 */
const SummaryTable: React.FC<SummaryTableProps> = ({
  handleSelect,
  isExpanded,
  hideEmptyRows,
}) => {
  // Global state from store
  const {
    selectedReferences,
    summaryChartData,
    summaryChartHeaders,
    summaryChartRowNames,
    updateSummaryChartRowNames,
    summaryChartRowHeightCollapsed,
  } = useProjectStore();
  const documentsToNumbers = useProjectStore(
    (state) => state.currentProject.documentsToNumbers,
  );
  const documentsToNicknames = useProjectStore(
    (state) => state.currentProject.documentsToNicknames,
  );

  // Local state setup
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      summaryChartData &&
      summaryChartHeaders &&
      summaryChartData.length > 0 &&
      summaryChartHeaders.length > 0
    ) {
      setIsLoading(false);
      updateSummaryChartRowNames(
        summaryChartData.map((row: SummaryChartData) => row.claim_number),
      );
    }
  }, [summaryChartData, summaryChartHeaders]);

  const memoizedSummaryChartData = useMemo(() => {
    // Filter out rows where all entries are gray if hideEmptyRows is true
    const filteredData = hideEmptyRows
      ? summaryChartData.filter((row) => {
          return summaryChartHeaders.some((key) => {
            if (key === "claim_number") return false;
            return row[key] !== ChartColor.GRAY;
          });
        })
      : summaryChartData;

    // Store the original indices before filtering
    const filteredWithIndices = hideEmptyRows
      ? summaryChartData
          .map((row, index) => ({ row, originalIndex: index }))
          .filter(({ row }) =>
            summaryChartHeaders.some((key) => {
              if (key === "claim_number") return false;
              return row[key] !== ChartColor.GRAY;
            }),
          )
      : summaryChartData.map((row, index) => ({ row, originalIndex: index }));

    const heightMultiplier = hideEmptyRows
      ? summaryChartData.length / filteredData.length
      : 1;

    return filteredWithIndices.map(({ row, originalIndex }, rowIndex) => (
      <div key={originalIndex} className={`contents m-1 ${isExpanded ? "mb-2" : ""}`}>
        {/* For Standard sections only dislay 1.1.2 without title */}
        {isExpanded && (
          <div className="sticky left-0 z-50 w-[75px] bg-background align-right truncate">
            {summaryChartRowNames[originalIndex]?.match(/^[\d.]+(?=\s|$)/)?.[0] ||
              summaryChartRowNames[originalIndex]}
          </div>
        )}
        {summaryChartHeaders.map((key, colIndex) => (
          <div
            key={`${originalIndex}-${colIndex}`}
            className="px-0.75 bg-background"
            style={{
              minWidth: "75px",
              flex: "1",
              margin: "0",
              padding: "0 2px",
            }}
          >
            <div
              className={`w-full h-full flex items-center justify-center cursor-pointer ${
                isExpanded ? "rounded m-1" : ""
              } transition-colors duration-200`}
              style={{
                backgroundColor: row[key] || "lightgray",
                height: isExpanded
                  ? "25px"
                  : `${summaryChartRowHeightCollapsed * heightMultiplier}px`,
              }}
              onClick={() => handleSelect(isExpanded, row.claim_number, key)}
            />
          </div>
        ))}
      </div>
    ));
  }, [
    summaryChartData,
    isExpanded,
    summaryChartRowHeightCollapsed,
    summaryChartRowNames,
    summaryChartHeaders,
    handleSelect,
    hideEmptyRows,
  ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col relative">
      <div
        className={cn(
          "overflow-x-auto w-[calc(100vw-130px)]",
          isExpanded ? "h-[50vh] overflow-y-auto" : "h-[220px] overflow-y-hidden",
        )}
      >
        <div>
          <div
            className="grid gap-x-0 bg-background w-full"
            style={{
              gridTemplateColumns: isExpanded
                ? `75px repeat(${summaryChartHeaders.length}, minmax(75px, 1fr))`
                : `repeat(${summaryChartHeaders.length}, minmax(75px, 1fr))`,
              columnGap: "4px",
            }}
          >
            {isExpanded && (
              <div className="sticky bg-background left-0 top-0 z-50 w-[75px] pt-2" />
            )}

            {summaryChartHeaders.map((key, idx) => (
              <div
                key={idx}
                className="sticky top-0 z-10 bg-background"
                style={{
                  minWidth: "75px",
                  flex: "1",
                  padding: "0 2px",
                }}
              >
                <div className="flex flex-col items-center">
                  <Tooltip>
                    <TooltipTrigger className="w-full focus:outline-none focus:ring-0">
                      <div
                        className={cn(
                          "w-full overflow-hidden bg-background",
                          isExpanded ? "m-1" : "m-0.75",
                          "cursor-pointer rounded-md mb-2 px-2 py-1 font-medium flex items-center justify-between",
                          selectedReferences?.some((detail) => detail.id === key) &&
                            "border-blue-500 border-2",
                        )}
                        onClick={() => handleSelect(false, null, key)}
                      >
                        <span className="text-xs truncate mr-2 flex-grow">
                          {documentsToNicknames?.[key] ||
                            (documentsToNumbers?.[key] &&
                              splitId(documentsToNumbers[key])) ||
                            ""}
                        </span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        {documentsToNicknames?.[key] ??
                          splitId(documentsToNumbers?.[key] ?? "")}
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </div>
              </div>
            ))}

            {memoizedSummaryChartData}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryTable;
