/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import SearchFilters from "@/features/project/components/SearchFilters";
import { useLlm, useProcessReferences } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { ParentType, Patent, PATENT_STATUSES, PATENT_TYPES } from "@/types";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import React, { useEffect, useState } from "react";
import DocumentsTable from "./DocumentsTable";

interface SearchReferencesModalProps {
  open: boolean;
  handleClose: () => void;
}

/**
 * @description Search for references modal
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} handleClose - Function to close the modal
 */
const SearchReferencesModal: React.FC<SearchReferencesModalProps> = ({
  open,
  handleClose,
}) => {
  const { searchPriorArt } = useLlm();
  const { addAndProcessReferences } = useProcessReferences();

  // Global state from store
  const {
    currentProjectId,
    currentProject,
    currentSubject,
    currentParent,
    currentPortfolioId,
  } = useProjectStore((state) => state);
  const { addErrorMessage } = useAppStateStore();

  // Search filters
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [cpcCodes, setCpcCodes] = useState<string[]>(currentSubject?.cpcCodes || []);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [countryCodes, setCountryCodes] = useState<string[]>([]);
  const [assignees, setAssignees] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>(PATENT_TYPES);
  const [statuses, setStatuses] = useState<string[]>(PATENT_STATUSES);
  const [maxResults, setMaxResults] = useState<number>(25);
  const [dedupeFamilyId, setDedupeFamilyId] = useState<boolean>(true);

  const [dateError, setDateError] = useState<string>("");
  const [isSourcesLoading, setIsSourcesLoading] = useState<boolean>(false); // displays loader on search
  const [foundSources, setFoundSources] = useState<Patent[]>([]);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [selectedReferences, setSelectedReferences] = useState<Patent[]>([]);

  const [priorArtSearchInstructions, setPriorArtSearchInstructions] =
    useState<string>("");
  const [priorArtFilterInstructions, setPriorArtFilterInstructions] =
    useState<string>("");

  useEffect(() => {
    resetModal();
    if (currentProject.priorityDate) {
      const priorityDate = currentProject.priorityDate;
      // Check if priorityDate is a valid Date object
      if (priorityDate instanceof Date) {
        setToDate(priorityDate);
      } else if (typeof priorityDate === "string") {
        const parsedDate = new Date(priorityDate);
        if (!isNaN(parsedDate.getTime())) {
          setToDate(parsedDate);
        }
      }
    } else if (currentSubject?.filingDate) {
      setToDate(new Date(currentSubject?.filingDate));
    }
  }, [open, currentSubject?.filingDate]);

  // Reset modal state
  const resetModal = () => {
    setKeywords(currentProject.keywords || []);
    setDateError("");
    setHasSubmitted(false);
    setIsSourcesLoading(false);
    setFoundSources([]);
    setError(false);
    setSelectedReferences([]);
    setFromDate(null);
    setToDate(null);
    setCpcCodes([]);
    setKeywords([]);
    setCountryCodes([]);
    setAssignees([]);
    setTypes(PATENT_TYPES);
    setStatuses(PATENT_STATUSES);
    setMaxResults(25);
  };

  // Add references to project
  const handleAddReferences = async (addAll: boolean = false) => {
    handleClose();
    const referencesToAdd = addAll
      ? foundSources.map((source) => source.number)
      : selectedReferences.map((source) => source.number);

    await addAndProcessReferences(
      currentProjectId,
      currentProject.name,
      referencesToAdd,
      true, // isCheckboxChecked
      true, // displayLoadingMessages
      currentParent === ParentType.PORTFOLIO ? currentPortfolioId : undefined, // portfolioId
    );
  };

  /**
   * @description Searches for prior art based on the given keywords and search settings
   * @returns the prior art sources
   */
  const handleSearchClick = async () => {
    try {
      setHasSubmitted(true);
      setIsSourcesLoading(true);

      const payload: {
        max_results: number;
        to_date?: string;
        from_date?: string;
        types?: string[];
        statuses?: string[];
        cpc_codes?: string[];
        country_codes?: string[];
        assignees?: string[];
        search_instructions?: string;
        filter_instructions?: string;
        dedupe_family_id: boolean;
      } = {
        max_results: maxResults || 10,
        dedupe_family_id: dedupeFamilyId,
      };

      if (toDate) payload.to_date = toDate.toISOString().split("T")[0];
      if (fromDate) payload.from_date = fromDate.toISOString().split("T")[0];
      if (types.length > 0) payload.types = types;
      if (statuses.length > 0) payload.statuses = statuses;
      if (cpcCodes.length > 0) payload.cpc_codes = cpcCodes;
      if (countryCodes.length > 0) payload.country_codes = countryCodes;
      if (assignees.length > 0) payload.assignees = assignees;
      if (priorArtSearchInstructions)
        payload.search_instructions = priorArtSearchInstructions;
      if (priorArtFilterInstructions)
        payload.filter_instructions = priorArtFilterInstructions;

      const priorArt = await searchPriorArt(currentProjectId, keywords, payload);
      if (Array.isArray(priorArt.data)) {
        setFoundSources(priorArt.data);
      } else {
        addErrorMessage("Invalid data format received.");
      }
      setIsSourcesLoading(false);
    } catch (error) {
      setHasSubmitted(false);
      setError(true);
    } finally {
      setIsSourcesLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[85vw] max-h-[85vh] w-full flex flex-col min-w-[600px]">
        <DialogHeader>
          <DialogTitle>Search for references</DialogTitle>
          <VisuallyHidden asChild>
            <DialogDescription>
              Search for prior art to add to the project.
            </DialogDescription>
          </VisuallyHidden>
        </DialogHeader>

        {!hasSubmitted ? (
          <div className="flex-grow overflow-y-auto gap-6">
            <SearchFilters
              fromDate={fromDate}
              toDate={toDate}
              setFromDate={setFromDate}
              setToDate={setToDate}
              disabled={isSourcesLoading}
              keywords={keywords}
              setKeywords={setKeywords}
              countryCodes={countryCodes}
              setCountryCodes={setCountryCodes}
              maxResults={maxResults}
              setMaxResults={setMaxResults}
              setAssignees={setAssignees}
              assignees={assignees}
              setCpcCodes={setCpcCodes}
              cpcCodes={cpcCodes}
              types={types}
              setTypes={setTypes}
              statuses={statuses}
              setStatuses={setStatuses}
              dedupeFamilyId={dedupeFamilyId}
              setDedupeFamilyId={setDedupeFamilyId}
            />
            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <PriorArtSearchInstructions
                instructions={priorArtSearchInstructions}
                onChange={setPriorArtSearchInstructions}
                isEditing={true}
              />
              <PriorArtFilterInstructions
                instructions={priorArtFilterInstructions}
                onChange={setPriorArtFilterInstructions}
                isEditing={true}
              />
            </div> */}
          </div>
        ) : (
          <>
            {isSourcesLoading ? (
              <div className="flex items-center justify-center h-64">
                <Loader message="Finding references..." />
              </div>
            ) : (
              <div>
                {foundSources?.length > 0 ? (
                  <div className="flex flex-col h-[calc(85vh-100px)]">
                    <DocumentsTable
                      references={foundSources}
                      onRowSelection={setSelectedReferences}
                      // height="calc(85vh-120px)"
                      isSearch={true}
                    >
                      <div className="flex gap-2 justify-between">
                        <Button variant="outline" onClick={resetModal} className="h-9">
                          Clear Results
                        </Button>
                        <div className="flex gap-2">
                          <Button
                            className="h-9"
                            variant={
                              selectedReferences.length === 0 ? "default" : "outline"
                            }
                            onClick={() => handleAddReferences(true)}
                          >
                            Add All
                          </Button>
                          <Button
                            variant="default"
                            className="h-9"
                            onClick={() => handleAddReferences(false)}
                            disabled={selectedReferences.length === 0}
                          >
                            Add Selected
                          </Button>
                        </div>
                      </div>
                    </DocumentsTable>
                  </div>
                ) : (
                  <div className="flex flex-col items-center mt-8">
                    <p className="mb-4">
                      No references found. Try searching with different parameters.
                    </p>
                    <Button variant="outline" onClick={resetModal}>
                      Search Again
                    </Button>
                  </div>
                )}
              </div>
            )}
          </>
        )}

        {error && <p className="text-red-500">An error occurred. Please try again.</p>}
        <DialogFooter>
          {!hasSubmitted && (
            <div className="flex justify-end mt-4 gap-2">
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                disabled={dateError !== "" || toDate === null}
                onClick={handleSearchClick}
                variant="default"
              >
                Search
              </Button>
            </div>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SearchReferencesModal;
