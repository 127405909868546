/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { usePortfolio } from "@/hooks";
import { useProjectStore } from "@/store";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { DragAndDropFileArea, UploadedFileDisplay } from "@/components";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "@/components/ui/select";
import { CheckboxWithText } from "@/components/ui/checkbox";

interface GenerateContextAndChartModalProps {
  open: boolean;
  handleClose: () => void;
}

/**
 * @description Modal for adding patents to a portfolio
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} handleClose - Function to close the modal
 */
const GenerateContextAndChartModal: React.FC<GenerateContextAndChartModalProps> = ({
  open,
  handleClose,
}) => {
  const [modalType, setModalType] = useState<string>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [inputContext, setInputContext] = useState<string>("");
  const [rechart, setRechart] = useState<boolean>(false);

  const { currentPortfolio } = useProjectStore();
  const { generateContextForPortfolio } = usePortfolio();

  const handleFileChange = (selectedFiles: File[]) => {
    setFiles(selectedFiles);
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const onClose = () => {
    setModalType(null);
    setFiles([]);
    setInputContext("");
    setRechart(true);
    handleClose();
  };

  const handleGenerateClick = async () => {
    handleClose();

    await generateContextForPortfolio(
      currentPortfolio.projects,
      modalType as "document" | "input" | "subject",
      inputContext,
      files,
      rechart,
    );
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Generate context for portfolio</DialogTitle>
          <DialogDescription>
            Generate context for all patents in the portfolio. If recharting is
            selected, any citations you have added or edited will be overwritten. This
            action cannot be undone.
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4">
          <Select onValueChange={(value) => setModalType(value)}>
            <SelectTrigger>
              <SelectValue placeholder="Select a context source" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="subject">Subject patents</SelectItem>
              <SelectItem value="document">Upload file</SelectItem>
              <SelectItem value="input">Input text</SelectItem>
            </SelectContent>
          </Select>

          {modalType === "document" && (
            <div className="space-y-4">
              {files.length === 0 && (
                <DragAndDropFileArea
                  handleFiles={handleFileChange}
                  supportedFileTypes={[".pdf", ".txt"]}
                />
              )}
              {files.map((file, index) => (
                <UploadedFileDisplay
                  key={index}
                  file={file}
                  onRemove={() => handleRemoveFile(index)}
                  fullWidth={true}
                />
              ))}
            </div>
          )}
          {modalType === "input" && (
            <div className="space-y-4">
              <AutosizeTextarea
                value={inputContext}
                onChange={(e) => setInputContext(e.target.value)}
                maxHeight={500}
              />
            </div>
          )}
        </div>
        <CheckboxWithText
          checked={rechart}
          onCheckedChange={(checked) => setRechart(checked as boolean)}
          label="Rechart all references with respect to new context."
          description="This action will overwrite any citations you have added or edited."
        />

        <DialogFooter>
          <Button variant="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleGenerateClick}
            disabled={
              !modalType ||
              (modalType === "document" && files.length === 0) ||
              (modalType === "input" && inputContext === "")
            }
          >
            {rechart ? "Generate Context and Rechart" : "Generate Context"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default GenerateContextAndChartModal;
