/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import DocumentViewer from "@/components/patents/DocumentViewer";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useViz } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { ParentType } from "@/types";
import { Pencil1Icon } from "@radix-ui/react-icons";
import React, { useState } from "react";

interface DocumentSummaryProps {
  documentId: string;
  summary: string;
}

const DocumentSummary: React.FC<DocumentSummaryProps> = ({ documentId, summary }) => {
  const { currentProject, currentParent, currentPortfolio } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();
  const { updateDocumentSummary } = useViz();
  const [editedSummary, setEditedSummary] = useState("");

  const [documentViewerCitationText, setDocumentViewerCitationText] = useState<
    string | null
  >(null);
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = (summary: string) => {
    setIsEditing(true);
    setEditedSummary(summary);
  };

  const handleSaveSummary = async () => {
    if (!isEditing) return;

    const response = await updateDocumentSummary(
      currentParent === ParentType.PORTFOLIO ? currentPortfolio?.id : currentProject.id,
      documentId,
      editedSummary,
      currentParent === ParentType.PORTFOLIO,
    );
    if (response.success) {
      // Update local state
      const updatedSummaries = {
        ...currentProject.summaries,
        [documentId]: editedSummary,
      };
      useProjectStore.setState((state) => ({
        currentProject: {
          ...state.currentProject,
          summaries: updatedSummaries,
        },
      }));
    } else {
      addErrorMessage("Error saving summary. Try again later.");
    }

    setIsEditing(false);
    setEditedSummary("");
  };

  const handleCitationClick = async (
    e: React.MouseEvent,
    text: string,
    refId: string,
  ) => {
    e.preventDefault();
    const strippedText =
      text.startsWith('"') && text.endsWith('"')
        ? text.substring(1, text.length - 1)
        : text;

    setDocumentViewerCitationText(strippedText);
  };

  const handleCloseDocumentViewer = () => {
    setDocumentViewerCitationText(null);
  };

  const formatSummaryText = (text: string, referenceId: string) => {
    const sentences = text.split(/(?<=\.")|(?<=\.)(?=\s+[A-Z]|\s+at)/i);

    // Group sentences with their citations
    const groupedSentences = [];
    for (let i = 0; i < sentences.length; i++) {
      let group = { index: i, sentence: "", citation: null, isPair: false };
      const currentSentence = sentences[i].trim();
      const nextSentence = i + 1 < sentences.length ? sentences[i + 1].trim() : null;

      if (currentSentence.includes('"') && nextSentence?.startsWith("at")) {
        group.sentence = currentSentence;
        group.citation = nextSentence.replace("at ", "");
        group.isPair = true;
        i++;
      } else {
        group.sentence = currentSentence;
        group.isPair = false;
        group.citation = null;
      }
      groupedSentences.push(group);
    }

    return (
      <span>
        {groupedSentences.map((group, groupIndex) => {
          if (group.isPair) {
            const parts = group.sentence.split(/(".*?")/);
            const quotes = parts.filter(
              (part) => part.startsWith('"') || part.startsWith("'"),
            );
            const citations = group.citation
              ?.replace(" at ", "")
              .split(",")
              .map((c) => c.trim());

            return (
              <span key={groupIndex}>
                {groupIndex > 0 && " "}
                {parts.map((part, partIndex) => {
                  if (part.startsWith('"') || part.startsWith("'")) {
                    const quoteIndex = quotes.indexOf(part);
                    return (
                      <span
                        key={`quote-${partIndex}`}
                        className="hover:bg-blue-100 cursor-pointer"
                        data-quote-group={`${referenceId}-${groupIndex}-${quoteIndex}`}
                        onMouseEnter={(e) => {
                          document
                            .querySelector(
                              `[data-citation-group="${referenceId}-${groupIndex}-${quoteIndex}"]`,
                            )
                            ?.classList.add("bg-blue-100");
                        }}
                        onMouseLeave={(e) => {
                          document
                            .querySelector(
                              `[data-citation-group="${referenceId}-${groupIndex}-${quoteIndex}"]`,
                            )
                            ?.classList.remove("bg-blue-100");
                        }}
                        onClick={(e) => handleCitationClick(e, part, referenceId)}
                      >
                        {part}
                      </span>
                    );
                  }
                  return <span key={`text-${partIndex}`}>{part}</span>;
                })}{" "}
                <span>
                  at{" "}
                  {citations?.map((citation, citationIndex) => (
                    <React.Fragment key={citationIndex}>
                      <span
                        className="hover:bg-blue-100 cursor-pointer"
                        data-citation-group={`${referenceId}-${groupIndex}-${citationIndex}`}
                        onMouseEnter={(e) => {
                          document
                            .querySelector(
                              `[data-quote-group="${referenceId}-${groupIndex}-${citationIndex}"]`,
                            )
                            ?.classList.add("bg-blue-100");
                        }}
                        onMouseLeave={(e) => {
                          document
                            .querySelector(
                              `[data-quote-group="${referenceId}-${groupIndex}-${citationIndex}"]`,
                            )
                            ?.classList.remove("bg-blue-100");
                        }}
                      >
                        {citation}
                      </span>
                      {citationIndex < citations.length - 1 ? ", " : ""}
                    </React.Fragment>
                  ))}
                </span>
              </span>
            );
          }
          return (
            <span key={groupIndex}>
              {groupIndex > 0 && " "}
              {group.sentence}{" "}
            </span>
          );
        })}
      </span>
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSaveSummary();
    }
  };

  const handleReset = () => {
    setIsEditing(false);
    setEditedSummary("");
  };

  return (
    <>
      <div key={documentId} className="px-4 py-3 border rounded-md">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Label className="mb-0">
              {currentProject.documentsToNicknames[documentId]}
            </Label>
            {isEditing ? (
              <>
                <Button variant="outline" onClick={handleReset} className="h-9">
                  Reset
                </Button>
                <Button className="h-9" onClick={handleSaveSummary}>
                  Save
                </Button>
              </>
            ) : (
              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleEditClick(summary)}
              >
                <Pencil1Icon className="h-4 w-4" />
              </Button>
            )}
          </div>
        </div>
        {isEditing ? (
          <AutosizeTextarea
            value={editedSummary}
            onChange={(e) => setEditedSummary(e.target.value)}
            onKeyDown={handleKeyDown}
            className="w-full mt-2"
          />
        ) : (
          <p className="text-sm">{formatSummaryText(summary, documentId)}</p>
        )}
      </div>
      <DocumentViewer
        open={documentViewerCitationText !== null}
        handleClose={handleCloseDocumentViewer}
        referenceId={documentId}
        citationText={documentViewerCitationText}
        startInChartMode={false}
      />
    </>
  );
};

export default DocumentSummary;
