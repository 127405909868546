/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState, useEffect } from "react";
import { useProjectStore, useAppStateStore } from "@/store";
import { useLlm, useProcessReferences } from "@/hooks";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle";
import { ErrorMessage, ElementPill, Loader } from "@/components";
import DocumentsTable from "./DocumentsTable";
import { Patent, Element, ElementType, ParentType, StatusType } from "@/types";
import {
  PriorArtSearchInstructions,
  PriorArtFilterInstructions,
} from "@/features/project/context/components/ContextSections";

interface PatchReferencesModalProps {
  open: boolean;
  handleClose: () => void;
}

interface ResponseData {
  claims: { weak: Element[]; strong: Element[] };
  features: { weak: Element[]; strong: Element[] };
}

/**
 * @description Patch elemgaps modal
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} handleClose - Function to close the modal
 */
function PatchReferencesModal({ open, handleClose }: PatchReferencesModalProps) {
  const { addAndProcessReferences } = useProcessReferences();
  const { searchPriorArt, getElementStrengths } = useLlm();

  // Global state from store
  const { currentProjectId, currentProject, currentParent, currentPortfolioId } =
    useProjectStore();
  const { addErrorMessage } = useAppStateStore();

  // Local state setup
  const [claims, setClaims] = useState<ResponseData["claims"]>({
    weak: [],
    strong: [],
  });
  const [features, setFeatures] = useState<ResponseData["features"]>({
    weak: [],
    strong: [],
  });
  const [isSourcesLoading, setIsSourcesLoading] = useState(false);
  const [foundSources, setFoundSources] = useState<Patent[]>([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [selectedReferences, setSelectedReferences] = useState<Patent[]>([]);
  const [mode, setMode] = useState(
    (currentProject.claims?.length ?? 0) > 0 ? ElementType.CLAIM : ElementType.FEATURE,
  );
  const [selected, setSelected] = useState<Element[]>([]);
  const [notSelected, setNotSelected] = useState<Element[]>([]);
  const [priorArtSearchInstructions, setPriorArtSearchInstructions] = useState("");
  const [priorArtFilterInstructions, setPriorArtFilterInstructions] = useState("");

  // Reset modal state on open
  useEffect(() => {
    resetModal();
  }, [open]);

  // Fetch the element strengths
  useEffect(() => {
    const fetchData = async () => {
      const response = await getElementStrengths(currentProjectId);
      if (response.success && response.data) {
        const { claims, features } = response.data;
        setClaims(claims || { weak: [], strong: [] });
        setFeatures(features || { weak: [], strong: [] });
        setSelected(
          mode === ElementType.CLAIM ? claims.weak || [] : features.weak || [],
        );
        setNotSelected(
          mode === ElementType.CLAIM ? claims.strong || [] : features.strong || [],
        );
      }
    };
    if (currentProjectId) {
      fetchData();
    }
  }, [currentProjectId]);

  // Set the selected and not selected elements when mode changes
  useEffect(() => {
    if (mode === ElementType.CLAIM) {
      setSelected(claims.weak);
      setNotSelected(claims.strong);
    } else {
      setSelected(features.weak);
      setNotSelected(features.strong);
    }
  }, [mode, claims, features]);

  // Reset modal state
  const resetModal = () => {
    setHasSubmitted(false);
    setIsSourcesLoading(false);
    setFoundSources([]);
    setError(false);
    setSelectedReferences([]);
    setPriorArtSearchInstructions("");
    setPriorArtFilterInstructions("");
  };

  // Add references to project
  const handleAddReferences = async (addAll: boolean = false) => {
    handleClose();

    const referencesToAdd = addAll
      ? foundSources.map((source) => source.number)
      : selectedReferences.map((source) => source.number);

    await addAndProcessReferences(
      currentProjectId,
      currentProject.name,
      referencesToAdd,
      true, // isCheckboxChecked
      true, // displayLoadingMessages
      currentParent === ParentType.PORTFOLIO ? currentPortfolioId : undefined, // portfolioId
    );
  };

  // Searches for prior art based on the given keywords and search settings
  const handleSearchClick = async () => {
    setHasSubmitted(true);
    setIsSourcesLoading(true);
    let payload;
    if (mode === "claim") {
      payload = {
        max_results: 10,
        claim_numbers: selected.map((item) => item.label),
      };
    } else {
      payload = {
        max_results: 10,
        feature_numbers: selected.map((item) => item.label),
      };
    }
    const keywords: string[] = [];
    if (priorArtSearchInstructions)
      payload.search_instructions = priorArtSearchInstructions;
    if (priorArtFilterInstructions)
      payload.filter_instructions = priorArtFilterInstructions;
    payload.dedupe_family_id = false;
    const priorArtResponse = await searchPriorArt(currentProjectId, keywords, payload);
    if (!priorArtResponse.success) {
      handleError();
      return;
    }
    const priorArt = priorArtResponse.data;
    if (priorArt) {
      setFoundSources(priorArt);
    }
    setIsSourcesLoading(false);
  };

  // Handle error
  const handleError = () => {
    resetModal();
    addErrorMessage("Search failed.");
  };

  // Handle element selection
  const handleAddElement = (element: Element) => {
    const updatedSelected = [...selected, element];
    const updatedNotSelected = notSelected.filter((item) => item !== element);
    setSelected(updatedSelected);
    setNotSelected(updatedNotSelected);
  };

  const handleRemoveElement = (element: Element) => {
    const updatedSelected = selected.filter((item) => item !== element);
    const updatedNotSelected = [...notSelected, element];
    setSelected(updatedSelected);
    setNotSelected(updatedNotSelected);
  };

  const handleTypeSelect = (newType: string) => {
    if (newType !== mode && newType !== null && newType !== "") {
      setMode(newType as ElementType);
    }
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[85vw] max-h-[85vh] w-full flex flex-col min-w-[600px]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            Patch references
          </DialogTitle>

          {!hasSubmitted && (
            <p className="text-sm text-muted-foreground">
              Search for references that cover the selected claims or features in the
              set.
            </p>
          )}
        </DialogHeader>

        <div>
          {!hasSubmitted ? (
            <div className="flex-grow overflow-y-auto">
              <ToggleGroup
                type="single"
                value={mode}
                onValueChange={(value) => handleTypeSelect(value)}
                className="mb-2"
              >
                <ToggleGroupItem
                  value={ElementType.CLAIM}
                  disabled={
                    !currentProject.claims || currentProject.claims.length === 0
                  }
                  className={`px-3 py-2 ${
                    mode === ElementType.CLAIM
                      ? "bg-tertiary text-primary-foreground"
                      : ""
                  }`}
                >
                  Claims
                </ToggleGroupItem>
                <ToggleGroupItem
                  value={ElementType.FEATURE}
                  disabled={
                    !currentProject.features || currentProject.features.length === 0
                  }
                  className={`px-3 py-2 ${
                    mode === ElementType.FEATURE
                      ? "bg-tertiary text-primary-foreground"
                      : ""
                  }`}
                >
                  Features
                </ToggleGroupItem>
              </ToggleGroup>

              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Selected</TableHead>
                    <TableHead>Unselected</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell className="align-top bg-muted">
                      <div className="flex flex-wrap gap-1">
                        {selected.map((element, index) => (
                          <ElementPill
                            key={index}
                            handleClick={() => handleRemoveElement(element)}
                            color={element.color}
                            label={element.label}
                            isSelected={true}
                          />
                        ))}
                      </div>
                    </TableCell>
                    <TableCell className="align-top">
                      <div className="flex flex-wrap gap-1">
                        {notSelected.map((element, index) => (
                          <ElementPill
                            key={index}
                            handleClick={() => handleAddElement(element)}
                            color={element.color}
                            label={element.label}
                            isSelected={false}
                          />
                        ))}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                <PriorArtSearchInstructions
                  instructions={priorArtSearchInstructions}
                  onChange={setPriorArtSearchInstructions}
                  isEditing={true}
                />
                <PriorArtFilterInstructions
                  instructions={priorArtFilterInstructions}
                  onChange={setPriorArtFilterInstructions}
                  isEditing={true}
                />
              </div>
            </div>
          ) : (
            <>
              {isSourcesLoading ? (
                <Loader message="Finding references..." />
              ) : (
                <div>
                  <div>
                    {foundSources?.length > 0 ? (
                      <div className="flex flex-col h-[calc(85vh-100px)]">
                        <DocumentsTable
                          references={foundSources}
                          onRowSelection={setSelectedReferences}
                          height="calc(85vh-120px)"
                          isSearch={true}
                        />
                      </div>
                    ) : (
                      <div className="mt-4 flex flex-col items-center">
                        <p>
                          No references found. Try searching with different parameters.
                        </p>
                        <Button className="mt-2" variant="outline" onClick={resetModal}>
                          Search Again
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {error && <ErrorMessage />}
        <DialogFooter>
          <>
            {!hasSubmitted && (
              <>
                <Button variant="outline" onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={handleSearchClick} disabled={selected?.length === 0}>
                  Search
                </Button>
              </>
            )}
            {foundSources?.length > 0 && (
              <>
                <Button
                  onClick={() => resetModal()}
                  aria-label="clear results"
                  variant="outline"
                >
                  Clear Results
                </Button>
                <Button
                  onClick={() => handleAddReferences(true)}
                  variant={selectedReferences.length === 0 ? "default" : "outline"}
                  aria-label="add all"
                >
                  Add All
                </Button>
                <Button
                  onClick={() => handleAddReferences(false)}
                  disabled={selectedReferences.length === 0}
                  aria-label="add selected"
                >
                  Add Selected
                </Button>
              </>
            )}
          </>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default PatchReferencesModal;
