/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useCallback, ChangeEvent, useState, useEffect } from "react";
import { useForm, Controller, Control, FieldErrors } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { LoadingButton } from "@/components/ui/loading-button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { FloatingLabelInput } from "@/components/ui/floating-input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { CheckboxWithText } from "@/components/ui/checkbox";
import { Organization } from "@/types";

interface OrganizationModalProps {
  open: boolean;
  onClose: () => void;
  org?: Organization;
  onSave: (org: Organization) => Promise<void>;
  isEdit?: boolean;
}

const organizationSchema = z.object({
  organizationName: z.string().min(1, "Organization name is required"),
  invoiceType: z.enum(["NONE", "MONTHLY", "YEARLY"]),
  monthlyLicensePrice: z.number().nullable(),
  yearlyLicensePrice: z.number().nullable(),
  monthlySeatPrice: z.number().nullable(),
  yearlySeatPrice: z.number().nullable(),
  documentPrice: z.number().nullable(),
  documentsAddedPrice: z.number().nullable(),
  documentsExportedPrice: z.number().nullable(),
  uniqueDocumentsPricing: z.boolean(),
  invProjectPrice: z.number().nullable(),
  pfaProjectPrice: z.number().nullable(),
  appProjectPrice: z.number().nullable(),
});

type OrganizationFormData = z.infer<typeof organizationSchema>;

const initialState: OrganizationFormData = {
  organizationName: "",
  invoiceType: "NONE",
  monthlyLicensePrice: null,
  yearlyLicensePrice: null,
  monthlySeatPrice: null,
  yearlySeatPrice: null,
  documentPrice: null,
  documentsAddedPrice: null,
  documentsExportedPrice: null,
  uniqueDocumentsPricing: false,
  invProjectPrice: null,
  pfaProjectPrice: null,
  appProjectPrice: null,
};

function OrganizationModal({
  open,
  onClose,
  org,
  onSave,
  isEdit,
}: OrganizationModalProps) {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting: formIsSubmitting },
  } = useForm<OrganizationFormData>({
    resolver: zodResolver(organizationSchema),
    defaultValues: isEdit && org ? org : initialState,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const invoiceType = watch("invoiceType");

  useEffect(() => {
    if (open) {
      if (isEdit && org) {
        reset(org);
      } else {
        reset(initialState);
      }
      setIsSubmitting(false);
    }
  }, [isEdit, org, reset, open]);

  const onSubmit = useCallback(
    async (data: OrganizationFormData) => {
      try {
        setIsSubmitting(true);
        await onSave(data as Organization);
        onClose();
      } catch (error) {
        console.error("Error saving organization:", error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [onSave, onClose],
  );

  const handleClose = useCallback(() => {
    reset(initialState);
    setIsSubmitting(false);
    onClose();
  }, [reset, onClose]);

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>{isEdit ? "Edit" : "Add"} organization</DialogTitle>
          <VisuallyHidden asChild>{org?.organizationName}</VisuallyHidden>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)} className="pb-4">
          <ScrollArea className="max-h-[calc(100vh-200px)] mx-2">
            <Controller
              name="organizationName"
              control={control}
              render={({ field }) => (
                <FloatingLabelInput
                  id="organizationName"
                  label="Organization Name"
                  {...field}
                  className="mt-2 mb-2"
                  error={errors.organizationName?.message}
                  disabled={isSubmitting}
                />
              )}
            />

            <Controller
              name="invoiceType"
              control={control}
              render={({ field }) => (
                <Select
                  onValueChange={field.onChange}
                  value={field.value}
                  disabled={isSubmitting}
                >
                  <SelectTrigger className="mb-2">
                    <SelectValue placeholder="Select Invoice Type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="NONE">None</SelectItem>
                    <SelectItem value="MONTHLY">Monthly</SelectItem>
                    <SelectItem value="YEARLY">Yearly</SelectItem>
                  </SelectContent>
                </Select>
              )}
            />

            {invoiceType === "MONTHLY" && (
              <PricingInputs
                fields={[
                  {
                    name: "monthlyLicensePrice",
                    label: "Monthly License Price",
                  },
                  { name: "monthlySeatPrice", label: "Monthly Seat Price" },
                ]}
                control={control}
                errors={errors}
                disabled={isSubmitting}
              />
            )}

            {invoiceType === "YEARLY" && (
              <PricingInputs
                fields={[
                  { name: "yearlyLicensePrice", label: "Yearly License Price" },
                  { name: "yearlySeatPrice", label: "Yearly Seat Price" },
                ]}
                control={control}
                errors={errors}
                disabled={isSubmitting}
              />
            )}

            <PricingInputs
              fields={[
                { name: "invProjectPrice", label: "INV Project Price" },
                { name: "pfaProjectPrice", label: "PFA Project Price" },
                { name: "appProjectPrice", label: "APP Project Price" },
              ]}
              control={control}
              errors={errors}
              disabled={isSubmitting}
            />

            <PricingInputs
              fields={[
                { name: "documentPrice", label: "Document Price" },
                { name: "documentsAddedPrice", label: "Doc Added Price" },
                {
                  name: "documentsExportedPrice",
                  label: "Doc Exported Price",
                },
              ]}
              control={control}
              errors={errors}
              disabled={isSubmitting}
            />

            <Controller
              name="uniqueDocumentsPricing"
              control={control}
              render={({ field }) => (
                <CheckboxWithText
                  id="uniqueDocumentsPricing"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  label="Unique Documents Pricing"
                  className="mb-2"
                  disabled={isSubmitting}
                />
              )}
            />
          </ScrollArea>
          <DialogFooter>
            <Button
              variant="outline"
              disabled={isSubmitting}
              onClick={handleClose}
              type="button"
            >
              Cancel
            </Button>
            <LoadingButton type="submit" loading={isSubmitting || formIsSubmitting}>
              {isEdit
                ? isSubmitting || formIsSubmitting
                  ? "Saving..."
                  : "Save Changes"
                : isSubmitting || formIsSubmitting
                  ? "Adding..."
                  : "Add Organization"}
            </LoadingButton>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

interface PricingInputProps {
  fields: Array<{ name: keyof OrganizationFormData; label: string }>;
  control: Control<OrganizationFormData>;
  errors: FieldErrors<OrganizationFormData>;
  disabled: boolean;
}

const PricingInputs = ({ fields, control, errors, disabled }: PricingInputProps) => (
  <div className="flex gap-2 mb-2 w-full">
    {fields.map(({ name, label }) => (
      <Controller
        key={name}
        name={name}
        control={control}
        render={({ field }) => (
          <FloatingLabelInput
            id={name}
            label={label}
            {...field}
            value={field.value === null ? "" : field.value.toString()}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value === "" ? null : Number(e.target.value);
              field.onChange(value);
            }}
            className="w-full"
            error={errors[name]?.message}
            disabled={disabled}
          />
        )}
      />
    ))}
  </div>
);

export default OrganizationModal;
