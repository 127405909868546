/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { useDataTable, useVector } from "@/hooks";
import { useProjectStore } from "@/store";
import {
  ChartColor,
  DocumentBodySection,
  ElementType,
  InvalidityCitation,
  Patent,
} from "@/types";
import { BlockType } from "@/types/types";
import { isLikelyPatentNumber } from "@/utils/dataUtils";
import { formatCitationLocation } from "@/utils/projectUtils";
import { Loader2, Search } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { CheckboxWithText } from "../ui/checkbox";
import { CondensedParagraph } from "../ui/typography";
import DocumentBodyCitation from "./DocumentBodyCitation";

interface ChartViewerLayoutProps {
  document: Patent | null;
  citationText?: string;
  citations?: InvalidityCitation[];
  selectedElement?: string;
  selectedElementType?: ElementType;
}

type ClaimCitations = {
  [claimNumber: string]: {
    citations: InvalidityCitation[];
    invalidityId: string;
  };
};

type CitationElementMap = {
  [paragraph: string]: {
    elements: Array<{
      label: string;
      color: string;
    }>;
  };
};

const ChartViewerLayout: React.FC<ChartViewerLayoutProps> = ({
  document,
  citationText,
  citations: initialCitations = [],
  selectedElement: initialSelectedElement,
  selectedElementType: initialElementType,
}) => {
  const [leftPanelSize, setLeftPanelSize] = useState(50);
  const { currentProject, currentSubject } = useProjectStore();
  const [selectedElementType, setSelectedElementType] = useState<ElementType | "">(
    initialElementType || "",
  );
  const [selectedElement, setSelectedElement] = useState<string>(
    initialSelectedElement || "",
  );
  const [elementOptions, setElementOptions] = useState<string[]>([]);
  const [bodyCitations, setBodyCitations] = useState<
    Array<{
      location: {
        paragraph: number;
        lines: number[];
        columns: number[];
        page: number;
        section: number;
      };
      color: string;
      isCited: boolean;
      id?: string;
      text?: string;
      type?: "text" | "header" | "section_header";
      bbox: {
        left: number;
        top: number;
        width: number;
        height: number;
      };
    }>
  >([]);
  const { getReferenceChartData } = useDataTable();
  const { currentProjectId } = useProjectStore();
  const [citations, setCitations] = useState<InvalidityCitation[]>(initialCitations);
  const [invalidityId, setInvalidityId] = useState<string>("");
  const [showCitationsInElement, setShowCitationsInElement] = useState<boolean>(true);
  const [viewCitationElements, setViewCitationElements] = useState<boolean>(true);
  const [claimCitationsMap, setClaimCitationsMap] = useState<ClaimCitations>({});
  const [citationFilter, setCitationFilter] = useState<"all" | "cited" | "uncited">(
    "all",
  );
  const selectedElementRef = useRef<HTMLDivElement>(null);
  const [citationElementMap, setCitationElementMap] = useState<CitationElementMap>({});
  const paragraphRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const claimRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<DocumentBodySection[]>([]);
  const { semanticSearchDocuments } = useVector();
  const [isSearching, setIsSearching] = useState(false);

  // const [showSearch, setShowSearch] = useState(false);
  const [isPDFView, setIsPDFView] = useState(false);
  const pdfContainerRef = useRef<HTMLDivElement>(null);

  const isPatent = isLikelyPatentNumber(
    currentProject.references.find((r) => r.id === document?.id)?.number,
  );

  const determineCitationFormat = (doc: Patent | null) => {
    if (!doc) return "page";

    // If column-line data exists in any citation, use that
    const hasColumnLineData = doc.body?.some(
      (section) => section.location?.columns?.length && section.location?.lines?.length,
    );
    if (hasColumnLineData) return "column-lines";

    // For patents without column-line data (applications), use paragraph
    if (isLikelyPatentNumber(doc.number)) return "paragraph";

    // Else use page
    return "page";
  };

  const [citationFormat, setCitationFormat] = useState<string>(
    determineCitationFormat(document),
  );

  const handleSearch = () => {
    if (!searchQuery.trim() || !document?.id) return;
    setIsSearching(true);

    semanticSearchDocuments(currentProjectId, searchQuery, [document.id], "semantic")
      .then((result) => {
        if (result.success) {
          setSearchResults(result.data.sources[0]);
        }
      })
      .finally(() => {
        setIsSearching(false);
      });
  };

  const filterBodyCitations = (citations: typeof bodyCitations) => {
    let filtered = citations;

    if (searchResults.length > 0) {
      filtered = filtered.filter((citation) =>
        searchResults.some((result) => {
          // Ensure both texts exist before comparing
          const citationText = citation.text?.trim() || "";
          const resultText = result.text?.trim() || "";

          return resultText.includes(citationText) || citationText.includes(resultText);
        }),
      );
    }

    // Then apply the citation filter
    switch (citationFilter) {
      case "cited":
        return filtered.filter((citation) => citation.isCited);
      case "uncited":
        return filtered.filter((citation) => !citation.isCited);
      default:
        return filtered;
    }
  };

  const scrollToParagraph = (paragraph: string) => {
    // First try to find by ID
    let paragraphElement = paragraphRefs.current[paragraph];

    // If not found by ID, try to find by text content
    if (!paragraphElement && document?.body) {
      const matchingSection = document.body.find(
        (section) =>
          section.text?.includes(paragraph) || section.html?.includes(paragraph),
      );
      if (matchingSection) {
        paragraphElement = paragraphRefs.current[matchingSection.id];
      }
    }

    if (paragraphElement) {
      setTimeout(() => {
        try {
          // Find the closest scrollable container with overflow-y-auto
          const container = paragraphElement!.closest(".overflow-y-auto");

          if (container) {
            // Calculate scroll position to center the element within its scrollable container
            const containerRect = container.getBoundingClientRect();
            const elementRect = paragraphElement!.getBoundingClientRect();
            const relativeTop = elementRect.top - containerRect.top;
            const scrollPosition =
              container.scrollTop +
              relativeTop -
              containerRect.height / 2 +
              elementRect.height / 2;

            container.scrollTo({
              top: scrollPosition,
              behavior: "smooth",
            });

            // Visual feedback
            paragraphElement!.classList.add("bg-blue-100");
            setTimeout(() => {
              paragraphElement!.classList.remove("bg-blue-100");
            }, 1000);
          }
        } catch (error) {
          console.error("Error during scroll:", error);
        }
      }, 100);
    }
  };

  const scrollToClaim = (claimLabel: string) => {
    const claimElement = claimRefs.current[claimLabel];
    if (claimElement) {
      handleSetSelectedElements(claimLabel);

      // Find the closest scrollable container
      const container = claimElement.closest(".overflow-y-auto");
      if (container) {
        // Calculate scroll position to center the element within its scrollable container
        const containerRect = container.getBoundingClientRect();
        const elementRect = claimElement.getBoundingClientRect();
        const relativeTop = elementRect.top - containerRect.top;
        const scrollPosition =
          container.scrollTop +
          relativeTop -
          containerRect.height / 2 +
          elementRect.height / 2;

        container.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });

        // Visual feedback
        claimElement.classList.add("bg-blue-100");
        setTimeout(() => {
          claimElement.classList.remove("bg-blue-100");
        }, 1000);
      }
    }
  };

  useEffect(() => {
    if (selectedElement) {
      setSelectedElement(selectedElement);
    }
    if (selectedElementType === "") {
      setSelectedElementType(ElementType.CLAIM);
    }
  }, [selectedElement, selectedElementType]);

  useEffect(() => {
    if (selectedElement && selectedElementRef.current) {
      const container = selectedElementRef.current.closest(".overflow-y-auto");

      if (container) {
        const elementTop = selectedElementRef.current.offsetTop;
        const elementHeight = selectedElementRef.current.offsetHeight;
        const containerHeight = container.clientHeight;

        // Calculate the middle position
        const scrollPosition = elementTop - containerHeight / 2 + elementHeight / 2;

        container.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      }
    }
  }, [selectedElement]);

  useEffect(() => {
    if (selectedElementType === "") return;

    if (selectedElementType === ElementType.CLAIM) {
      const claimLabels =
        currentProject?.claims
          ?.map((claim) => Object.keys(claim)[0])
          .filter((label) => label !== "Preamble") || [];
      setElementOptions(claimLabels);
    } else {
      const featureLabels =
        currentProject?.features
          ?.map((feature) => Object.keys(feature)[0])
          .filter((label) => label !== "Preamble") || [];
      setElementOptions(featureLabels);
    }
  }, [selectedElementType, currentProject]);

  useEffect(() => {
    if (!document?.body) return;

    const newBodyCitations = document.body.map((section) => {
      const matchingCitation = citations?.find(
        (citation) =>
          section.text?.includes(citation.text) && citation.removed === false,
      );

      return {
        location: {
          ...section.location,
        },
        color: matchingCitation?.color || ChartColor.GREEN,
        isCited: !!matchingCitation,
        id: matchingCitation?.id,
        text: section.text,
        bbox: section.bbox,
      };
    });

    setBodyCitations(newBodyCitations);
  }, [document?.body, citations]);

  useEffect(() => {
    setCitations([]);
    setBodyCitations([]);

    async function loadElementData() {
      if (!selectedElementType || !selectedElement || !document?.id) return;

      const response = await getReferenceChartData(
        currentProjectId,
        [document.id],
        selectedElementType === ElementType.FEATURE,
        [selectedElement],
        [],
        false,
      );
      if (response.success) {
        const citationsArray = response.data[0]?.[document.id]?.citations || [];
        setInvalidityId(response.data[0]?.[document.id]?.invalidityId || "");
        setCitations(citationsArray);
      }
    }

    async function loadAllElements() {
      if (!document?.id) return;

      const response = await getReferenceChartData(
        currentProjectId,
        [document.id],
        selectedElementType === ElementType.FEATURE,
        [],
        [],
        false,
      );

      if (response.success) {
        const organizedCitations = organizeCitationsByClaim(response.data);
        setClaimCitationsMap(organizedCitations);
      }
    }

    loadElementData();
    loadAllElements();
  }, [selectedElementType, selectedElement, document?.id, currentProjectId]);

  useEffect(() => {
    const newMap: CitationElementMap = {};

    if (Object.keys(claimCitationsMap).length === 0) return;

    Object.entries(claimCitationsMap).forEach(([element, data]) => {
      data.citations.forEach((citation) => {
        if (!citation.removed) {
          if (!newMap[citation.paragraph]) {
            newMap[citation.paragraph] = {
              elements: [],
            };
          }
          newMap[citation.paragraph].elements.push({
            label: element,
            color: citation.color,
          });
        }
      });
    });

    setCitationElementMap(newMap);
  }, [claimCitationsMap]);

  const handleSetSelectedElements = async (element: string) => {
    setSelectedElement(element);

    // Fetch reference data for the newly selected element
    if (document?.id) {
      const response = await getReferenceChartData(
        currentProjectId,
        [document.id],
        selectedElementType === ElementType.FEATURE,
        [element],
        [],
        false,
      );

      if (response.success) {
        const citationsArray = response.data[0]?.[document.id]?.citations || [];
        setInvalidityId(response.data[0]?.[document.id]?.invalidityId || "");
        setCitations(citationsArray);

        // Update body citations with the new citation data
        setBodyCitations((prevBodyCitations) => {
          return prevBodyCitations.map((section) => {
            const matchingCitation = citationsArray?.find(
              (citation) =>
                section.text?.includes(citation.text) && citation.removed === false,
            );

            return {
              ...section,
              color: matchingCitation?.color || ChartColor.GREEN,
              isCited: !!matchingCitation,
            };
          });
        });
      }
    }
  };

  const handleElementTypeChange = (value: string) => {
    setSelectedElementType(value as ElementType);
    setSelectedElement("");
  };

  const handleCitationUpdate = (updatedCitation: InvalidityCitation) => {
    setCitations((prevCitations) => {
      const newCitations = [...prevCitations];
      const existingIndex = newCitations.findIndex(
        (c) => c.text === updatedCitation.text && !c.removed,
      );

      if (existingIndex !== -1) {
        newCitations[existingIndex] = {
          ...newCitations[existingIndex],
          ...updatedCitation,
          removed: false,
        };
      } else {
        newCitations.push({
          ...updatedCitation,
          removed: false,
        });
      }
      return newCitations;
    });

    setBodyCitations((prevBodyCitations) => {
      return prevBodyCitations.map((citation) => {
        if (citation.text === updatedCitation.text) {
          return {
            ...citation,
            color: updatedCitation.color,
            isCited: true,
          };
        }
        return citation;
      });
    });

    // Update claimCitationsMap
    if (selectedElement) {
      setClaimCitationsMap((prevMap) => {
        const currentClaim = prevMap[selectedElement] || {
          citations: [],
          invalidityId,
        };
        const existingCitationIndex = currentClaim.citations.findIndex(
          (c) => c.text === updatedCitation.text,
        );
        let newCitations;
        if (existingCitationIndex !== -1) {
          // Update existing citation
          newCitations = currentClaim.citations.map((citation) =>
            citation.text === updatedCitation.text
              ? { ...citation, ...updatedCitation, removed: false }
              : citation,
          );
        } else {
          // Add new citation
          newCitations = [
            ...currentClaim.citations,
            { ...updatedCitation, removed: false },
          ];
        }
        return {
          ...prevMap,
          [selectedElement]: {
            ...currentClaim,
            citations: newCitations,
          },
        };
      });
    }
  };

  const handleCitationRemove = (citation: InvalidityCitation) => {
    setCitations((prevCitations) => {
      return prevCitations.map((c) => {
        if (c.text === citation.text) {
          return { ...c, removed: true };
        }
        return c;
      });
    });

    setBodyCitations((prevBodyCitations) => {
      return prevBodyCitations.map((c) => {
        if (c.text === citation.text) {
          return {
            ...c,
            color: ChartColor.GREEN,
            isCited: false,
            id: undefined,
          };
        }
        return c;
      });
    });

    // Update claimCitationsMap
    if (selectedElement) {
      setClaimCitationsMap((prevMap) => ({
        ...prevMap,
        [selectedElement]: {
          ...prevMap[selectedElement],
          citations:
            prevMap[selectedElement]?.citations.map((c) =>
              c.text === citation.text ? { ...c, removed: true } : c,
            ) || [],
        },
      }));
    }
  };

  // Update the organize function to handle bulk data
  const organizeCitationsByClaim = (data: any[]): ClaimCitations => {
    if (!Array.isArray(data) || data.length === 0) {
      return {};
    }

    const citationsMap: ClaimCitations = {};

    data.forEach((item) => {
      if (!item || typeof item !== "object") return;

      // Get the document ID (first key that's not claim_number or claim_text)
      const documentId = Object.keys(item).find(
        (key) => key !== "claim_number" && key !== "claim_text",
      );

      const claimNumber = item.claim_number;

      if (documentId && claimNumber && item[documentId]) {
        citationsMap[claimNumber] = {
          citations: item[documentId].citations || [],
          invalidityId: item[documentId].invalidityId || "",
        };
      }
    });

    return citationsMap;
  };

  if (!document) return null;

  useEffect(() => {
    if (selectedElement) {
      scrollToClaim(selectedElement);
    }
  }, [selectedElement]);

  // Add new useEffect for citation text
  useEffect(() => {
    if (citationText) {
      const matchingParagraph = document?.body?.find(
        (section) =>
          section.html?.includes(citationText) || section.text?.includes(citationText),
      );

      if (matchingParagraph) {
        // Try multiple times with increasing delays
        [100, 300, 500].forEach((delay) => {
          setTimeout(() => {
            scrollToParagraph(matchingParagraph.id);
          }, delay);
        });
      }
    }
  }, [citationText, document?.body]);

  // Modify the renderClaimCard function to include citation pills
  const renderClaimCard = (label: string, text: string) => {
    const claimCitations = claimCitationsMap[label];
    const isSelected = selectedElement === label;

    return (
      <div
        ref={(el) => {
          if (isSelected) {
            selectedElementRef.current = el;
          }
          claimRefs.current[label] = el;
        }}
        className={`w-full px-4 py-3 mb-2 border rounded-lg cursor-pointer hover:bg-gray-50 ${
          isSelected ? "border-blue-500 bg-blue-50" : ""
        }`}
        onClick={() => handleSetSelectedElements(label)}
      >
        <div className="w-16 flex-shrink-0 text-muted-foreground mb-1">
          <CondensedParagraph>{label}</CondensedParagraph>
        </div>
        <CondensedParagraph>{text}</CondensedParagraph>

        {/* Citation Pills */}
        {showCitationsInElement && claimCitations?.citations.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-2">
            {claimCitations.citations
              .filter((citation) => !citation.removed)
              .map((citation, index) => {
                const formattedCitation = formatCitationLocation(citation, isPatent);

                return (
                  <span
                    key={`${citation.paragraph}-${index}`}
                    className="px-2 py-1 text-xs rounded-full text-black cursor-pointer"
                    style={{ backgroundColor: citation.color }}
                    onClick={async (e) => {
                      e.stopPropagation(); // Prevent the outer div's click handler

                      // First select the claim
                      await handleSetSelectedElements(label);

                      // Then find and scroll to the matching section
                      const matchingSection = document.body?.find(
                        (section) => section.text?.includes(citation.text),
                      );
                      if (matchingSection) {
                        // Small delay to ensure state updates have completed
                        setTimeout(() => {
                          scrollToParagraph(matchingSection.id);
                        }, 50);
                      }
                    }}
                  >
                    {formattedCitation || citation.paragraph}
                  </span>
                );
              })}
          </div>
        )}
      </div>
    );
  };

  const renderBodyPDF = () => {
    return (
      <div className="relative w-full h-full" ref={pdfContainerRef}>
        <iframe
          src={document.pdfUrl}
          title="PDF Viewer"
          className="w-full h-[calc(100vh-200px)] border-none"
          style={{ minHeight: "600px" }}
        />

        {/* Overlay container for bounding boxes */}
        {/* <div
          className="absolute top-0 left-0 w-full h-full pointer-events-none"
          style={{ zIndex: 10 }}
        >
          {bodyCitations.map((citation, index) => {
            if (!citation.bbox) return null;

            const style = {
              position: "absolute",
              left: `${citation.bbox.left * 100}%`,
              top: `${citation.bbox.top * 100}%`,
              width: `${citation.bbox.width * 100}%`,
              height: `${citation.bbox.height * 100}%`,
              border: "2px solid",
              borderColor: citation.isCited ? citation.color : "transparent",
              backgroundColor: citation.isCited
                ? `${citation.color}33`
                : "transparent",
              cursor: "pointer",
              pointerEvents: "auto",
              zIndex: 20,
            };

            return (
              <div key={`bbox-${index}`} style={style as React.CSSProperties} />
            );
          })}
        </div> */}
      </div>
    );
  };

  // Modify the renderBody function to use the filter
  const renderBody = () => {
    const filteredCitations = filterBodyCitations(bodyCitations);

    return filteredCitations
      .map((bodyCitation, index: number) => {
        const section = document.body?.find((s) => s.text === bodyCitation.text);
        if (!section) return null;

        const citationIndex = citations.findIndex((citation) =>
          (citation.text || "")
            .toLowerCase()
            .includes((searchQuery || "").toLowerCase()),
        );

        const isSearchResult = searchResults.some((result) =>
          result.text.includes(bodyCitation.text || ""),
        );

        const renderContent = () => {
          switch (section.type) {
            case BlockType.TITLE:
              return null;
            case BlockType.LIST:
              return <ul className="list-disc ml-6">{section.text}</ul>;
            case BlockType.LIST_ITEM:
              return <li>{section.text}</li>;
            case BlockType.HEADER:
              return <h4 className="text-lg font-semibold">{section.text}</h4>;
            case BlockType.SECTION_HEADER:
              return <h3 className="text-lg font-semibold">{section.text}</h3>;
            case BlockType.TEXT:
            default:
              return (
                <div className="w-full pr-3">
                  <DocumentBodyCitation
                    citation={bodyCitation}
                    index={index}
                    refId={document.id}
                    claimNumber={selectedElement}
                    citationIndex={citationIndex}
                    invalidityId={invalidityId}
                    onCitationUpdate={handleCitationUpdate}
                    onCitationRemove={handleCitationRemove}
                    disabled={!selectedElement}
                    citationsElements={citationElementMap?.[section.id]?.elements}
                    viewCitationElements={viewCitationElements}
                    scrollToClaim={scrollToClaim}
                    citationFormat={citationFormat}
                  />
                </div>
              );
          }
        };

        return (
          <div
            key={index}
            ref={(el) => (paragraphRefs.current[section.id] = el)}
            className={`flex gap-4 ${isSearchResult ? "bg-yellow-50 rounded-lg" : ""}`}
          >
            {renderContent()}
          </div>
        );
      })
      .reduce((acc, curr, index) => {
        return acc.concat(curr, <div key={`spacer-${index}`} className="h-4" />);
      }, [] as React.ReactNode[]);
  };

  const clearSearch = () => {
    setSearchQuery("");
    setSearchResults([]);
  };

  const searchInput = (
    <div className={"transition-all duration-200 h-9 overflow-hidden mb-1 "}>
      <div className="flex gap-2">
        <div className="relative flex-1">
          <Input
            type="text"
            placeholder="Search document..."
            value={searchQuery}
            className="h-9 pr-16"
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <Button
            variant="ghost"
            size="sm"
            className="absolute right-2 top-1/2 -translate-y-1/2 h-6 w-6 p-0 bg-background"
            onClick={handleSearch}
          >
            <Search className="h-4 w-4" />
          </Button>
        </div>
        {searchResults.length > 0 && (
          <Button
            variant="outline"
            size="sm"
            className="h-9 px-3"
            onClick={clearSearch}
          >
            Clear
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <div className="flex h-full">
      <ResizablePanelGroup direction="horizontal" className="w-full p-0">
        <ResizablePanel
          defaultSize={leftPanelSize}
          onResize={setLeftPanelSize}
          className="p-0"
        >
          <div className="h-[calc(100vh-120px)] flex flex-col">
            <div className="flex-none px-3 py-2">
              {searchInput}

              <div className="flex items-center gap-2 border-b pb-2">
                <Select value={citationFormat} onValueChange={setCitationFormat}>
                  <SelectTrigger className="w-full h-9 flex-1">
                    <SelectValue placeholder="Citation" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="column-lines">Column:Lines</SelectItem>
                    <SelectItem value="page">Page</SelectItem>
                    <SelectItem value="paragraph">Paragraph</SelectItem>
                    {/* <SelectItem value="page-paragraph">
                      Page, Paragraph
                    </SelectItem> */}
                  </SelectContent>
                </Select>
                <Select
                  value={citationFilter}
                  onValueChange={(value) =>
                    setCitationFilter(value as typeof citationFilter)
                  }
                >
                  <SelectTrigger className="w-full h-9 flex-1">
                    <SelectValue placeholder="Filter citations" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All paragraphs</SelectItem>
                    <SelectItem value="cited">Cited only</SelectItem>
                    <SelectItem value="uncited">Uncited only</SelectItem>
                  </SelectContent>
                </Select>

                <div className="flex items-center h-9 flex-1">
                  <CheckboxWithText
                    label="View elements"
                    checked={viewCitationElements}
                    onCheckedChange={() =>
                      setViewCitationElements(!viewCitationElements)
                    }
                    className="w-full"
                  />
                </div>
              </div>
            </div>

            <div className="flex-1 overflow-y-auto custom-scrollbar px-3 pt-3 pb-3">
              {isSearching ? (
                <div className="flex items-center justify-center h-full">
                  <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
                </div>
              ) : (
                <div className="w-full mb-3">{renderBody()}</div>
              )}
            </div>
          </div>
        </ResizablePanel>

        <ResizableHandle withHandle={true} />

        <ResizablePanel defaultSize={100 - leftPanelSize}>
          <div className="h-[calc(100vh-120px)] flex flex-col px-3 py-2">
            <div className="flex-none flex items-center justify-between pb-2 border-b">
              <Label>
                {currentSubject?.id
                  ? currentProject?.documentsToNicknames[currentSubject.id]
                  : "Subject"}
              </Label>
              <div className="flex items-center gap-2">
                <CheckboxWithText
                  label="View citations"
                  checked={showCitationsInElement}
                  onCheckedChange={() =>
                    setShowCitationsInElement(!showCitationsInElement)
                  }
                />
                <Select
                  value={selectedElementType}
                  onValueChange={handleElementTypeChange}
                >
                  <SelectTrigger className="w-[130px] h-9">
                    <SelectValue placeholder="Select type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="claim">Claims</SelectItem>
                    <SelectItem value="feature">Features</SelectItem>
                  </SelectContent>
                </Select>
                <Select
                  value={selectedElement}
                  onValueChange={handleSetSelectedElements}
                >
                  <SelectTrigger className="w-[150px] h-9">
                    <SelectValue placeholder="Select element" />
                  </SelectTrigger>
                  <SelectContent>
                    {elementOptions.map((element) => (
                      <SelectItem key={element} value={element}>
                        {element}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {document.pdfUrl && (
                  <div className="flex items-center gap-2">
                    <Switch
                      checked={isPDFView}
                      onCheckedChange={() => setIsPDFView(!isPDFView)}
                    />
                    <span className="text-sm text-muted-foreground">View PDF</span>
                  </div>
                )}
              </div>
            </div>

            {isPDFView ? (
              renderBodyPDF()
            ) : (
              <div className="flex-1 overflow-y-auto custom-scrollbar pt-2 pb-3 mb-3">
                {selectedElementType === ElementType.CLAIM
                  ? currentProject?.claims
                      ?.filter((claim) => !Object.keys(claim)[0].includes("Preamble"))
                      .map((claim) => {
                        const [label, text] = Object.entries(claim)[0] as [
                          string,
                          string,
                        ];
                        return renderClaimCard(label, text);
                      })
                  : currentProject?.features?.map((feature) => {
                      const [label, text] = Object.entries(feature)[0] as [
                        string,
                        string,
                      ];
                      return renderClaimCard(label, text);
                    })}
              </div>
            )}
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
};

export default ChartViewerLayout;
