/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, ProjectPage } from "@/components";
import { BaseChat } from "@/features/explore/chat/components";
import { useAppStateStore, useProjectStore } from "@/store";
import React from "react";

/**
 * @description Project-level chat page
 */
const ProjectChatPage: React.FC = () => {
  const { isReferencesLoading } = useAppStateStore();
  const { currentProject } = useProjectStore();
  const subjectId = useProjectStore((state) => state.currentProject.subjectId);
  const referenceIds = useProjectStore(
    (state) => state.currentProject.referenceIds ?? [],
  );
  const projectIdArray = [
    subjectId,
    ...(Array.isArray(referenceIds) ? referenceIds : []),
  ];

  // Set page title
  const pageName = `${currentProject.name} - Chat` || "Chat";

  return (
    <ProjectPage pageName={pageName}>
      {isReferencesLoading ? (
        <div className="flex justify-center items-center flex-col mt-12">
          <Loader message="Loading..." />
        </div>
      ) : (
        <BaseChat
          generalBool={false}
          projectId={currentProject.id}
          documents={projectIdArray}
          disabledBool={false}
        />
      )}
    </ProjectPage>
  );
};

export default ProjectChatPage;
