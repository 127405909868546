/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { usePortfolio } from "@/hooks";
import { useProjectStore } from "@/store";
import React from "react";

import BaseUploadFilesModal from "@/components/files/BaseUploadFilesModal";

interface UploadFilesToPortfolioModalProps {
  open: boolean;
  handleClose: () => void;
}

/**
 * @description Modal for uploading files to a portfolio
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} handleClose - Function to close the modal
 */
const UploadFilesToPortfolioModal: React.FC<UploadFilesToPortfolioModalProps> = ({
  open,
  handleClose,
}) => {
  const { uploadFilesToPortfolio } = usePortfolio();
  const { currentPortfolioId, currentPortfolio } = useProjectStore();

  // Handle the add to project button click
  const handleAddFiles = async (files: File[]) => {
    handleClose();
    await uploadFilesToPortfolio(files, currentPortfolioId, currentPortfolio.name);
  };

  return (
    <BaseUploadFilesModal
      open={open}
      handleCloseModal={handleClose}
      handleAddFilesClick={handleAddFiles}
      title="Add references to all subjects"
      buttonLabel="Add References AHHHHHH"
      showCheckbox={false}
    />
  );
};

export default UploadFilesToPortfolioModal;
