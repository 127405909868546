/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, ProjectPage, UploadFilesModal } from "@/components";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { H4 } from "@/components/ui/typography";
import { ProcessReferencesModal } from "@/features/project/components/ProcessReferencesModal";
import { usePortfolio, useProcessReferences, useProject, useViz } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { DocumentStatus, Patent } from "@/types";
import { PlusIcon } from "@radix-ui/react-icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DismissDocumentStatusModal } from "../components";
import DocumentsTable from "../references/components/DocumentsTable";
import { AddReferencesToPortfolioModal } from "./components";

const PortfolioReferencesPage: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    currentPortfolio,
    updateCurrentPortfolio,
    currentPortfolioId,
    updateCurrentProjectId,
    updateCurrentProject,
  } = useProjectStore();
  const {
    updateProjectReferenceNickname,
    updateProjectReferenceNote,
    getPortfolioReferences,
    updateDocumentStatuses,
  } = useViz();
  const { reprocessDocuments } = useProcessReferences();
  const { getProjectMetadata } = useProject();
  const { updateIsLoading } = useAppStateStore();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showAddReferencesModal, setShowAddReferencesModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showProcessReferencesModal, setShowProcessReferencesModal] = useState(false);
  const [selectedReferencesToReprocess, setSelectedReferencesToReprocess] = useState<
    Patent[]
  >([]);
  const [showDismissStatusesModal, setShowDismissStatusesModal] = useState(false);
  const [selectedReferencesToDismiss, setSelectedReferencesToDismiss] = useState<
    Patent[]
  >([]);
  const [selectedReferences, setSelectedReferences] = useState<Patent[]>([]);

  const { deleteReferenceFromPortfolio, updateReferencesTagsInPortfolio } =
    usePortfolio();
  const {
    addErrorMessage,
    addSuccessMessage,
    addLoadingMessage,
    removeLoadingMessage,
  } = useAppStateStore();
  const pageName = currentPortfolio.name
    ? `${currentPortfolio.name} - Portfolio - &AI`
    : "Portfolio - &AI";

  // Updated to fetch on each page load
  useEffect(() => {
    const fetchPortfolioReferences = async () => {
      setIsLoading(true);
      const response = await getPortfolioReferences(currentPortfolioId);
      if (!response.success) {
        addErrorMessage(response.message || "Failed to fetch references");
      }
      setIsLoading(false);
    };

    if (currentPortfolioId) {
      fetchPortfolioReferences();
    }
  }, [currentPortfolioId]);

  const handleTagUpdate = async (selectedRows: Patent[], selectedTags: string[]) => {
    if (selectedTags) {
      const referenceIds = selectedRows.map((row) => row.id);
      const response = await updateReferencesTagsInPortfolio(
        referenceIds,
        selectedTags,
      );
      if (!response.success) {
        addErrorMessage(
          response.message || "Failed to update tags for selected references.",
        );
      } else {
        addSuccessMessage("Tags updated successfully.");
      }
    }
  };

  const handleDeleteSelected = async (selectedReferences: Patent[]) => {
    const referenceIds = selectedReferences.map((reference) => reference.id);
    addLoadingMessage(
      `Removing ${selectedReferences.length} reference(s) from portfolio`,
      "delete-reference-portfolio",
    );
    try {
      await deleteReferenceFromPortfolio(
        referenceIds.map((referenceId) => ({
          referenceId,
          projectId: currentPortfolioId,
          isPortfolio: true,
        })),
      );
      removeLoadingMessage("delete-reference-portfolio");
      addSuccessMessage(
        `Successfully deleted ${selectedReferences.length} reference(s).`,
      );
    } catch (error) {
      removeLoadingMessage("delete-reference-portfolio");
      console.error(`Error deleting reference ${referenceIds}:`, error);
      addErrorMessage(
        "An unexpected error occurred while deleting references. Please try again.",
      );
    }
  };

  const handleSaveName = async (reference: Patent, newName: string) => {
    try {
      const renamePromises = reference.subjects.map(async (projectReference) => {
        const response = await updateProjectReferenceNickname(
          projectReference.projectId,
          reference.id,
          newName,
        );
        if (!response.success) {
          throw new Error(
            response.message || "An error occurred while saving the name.",
          );
        }
      });

      await Promise.all(renamePromises);

      updateCurrentPortfolio({
        ...currentPortfolio,
        references: currentPortfolio.references.map((ref) =>
          ref.id === reference.id ? { ...ref, name: newName } : ref,
        ),
      });
    } catch (error) {
      addErrorMessage(error.message || "An error occurred while saving the name.");
    }
  };

  const handleSaveNote = async (reference: Patent, newNote: string) => {
    try {
      const renamePromises = reference.subjects.map(async (projectReference) => {
        const response = await updateProjectReferenceNote(
          projectReference.projectId,
          reference.id,
          newNote,
        );
        if (!response.success) {
          throw new Error(
            response.message || "An error occurred while saving the note.",
          );
        }
      });

      await Promise.all(renamePromises);

      updateCurrentPortfolio({
        ...currentPortfolio,
        references: currentPortfolio.references.map((ref) =>
          ref.id === reference.id ? { ...ref, note: newNote } : ref,
        ),
      });
    } catch (error) {
      addErrorMessage(error.message || "An error occurred while saving the name.");
    }
  };

  const handleDeleteReference = async (reference: Patent) => {
    const ids = reference.subjects.map((subject) => ({
      referenceId: reference.id,
      projectId: subject.projectId,
    }));

    addLoadingMessage(
      `Removing reference ${reference.name || reference.referenceId} from portfolio`,
      "delete-reference",
    );

    const response = await deleteReferenceFromPortfolio(
      ids.map((id) => ({
        ...id,
        isPortfolio: true,
      })),
    );
    const failedDeletions = response.data?.failedDeletions || [];
    removeLoadingMessage("delete-reference");
    if (failedDeletions.length > 0) {
      addErrorMessage("Failed to remove reference from portfolio");
    } else {
      addSuccessMessage(
        `Reference ${reference.name || reference.referenceId} removed from portfolio`,
      );
      // Update the references state to remove the deleted reference
      updateCurrentPortfolio({
        ...currentPortfolio,
        references: currentPortfolio.references.filter(
          (ref) => ref.id !== reference.id,
        ),
      });
    }
  };

  const handleDismissReferenceStatuses = async () => {
    const selectedIds = selectedReferencesToDismiss.map((reference) => reference.id);
    await updateDocumentStatuses(selectedIds, DocumentStatus.PROCESSED, true);
    setSelectedReferencesToReprocess([]);
    setSelectedReferencesToDismiss([]);
    setShowDismissStatusesModal(false);
  };

  const handleReprocessReferences = async () => {
    setShowProcessReferencesModal(false);
    await reprocessDocuments(selectedReferencesToReprocess, true);
    setSelectedReferencesToReprocess([]);
  };

  const handleReprocessClick = (references: Patent[]) => {
    setSelectedReferencesToReprocess(
      selectedReferences.filter(
        (ref) =>
          ref.status === DocumentStatus.RECHART ||
          ref.status === DocumentStatus.REPROCESS,
      ),
    );
    setShowProcessReferencesModal(true);
  };

  const handleSelectProject = async (reference: Patent) => {
    try {
      const id = reference.projectId;
      updateCurrentProject({
        id: id,
        name: reference.name,
      });
      updateCurrentProjectId(id);
      navigate(`/portfolio/${currentPortfolioId}/project/${id}/subject`);
      updateIsLoading(id);
      await getProjectMetadata(id);
      updateIsLoading(null);
    } catch (error) {
      console.error("Error selecting project:", error);
      addErrorMessage("Failed to fetch data. Please try again.");
    }
  };

  const handleDismissSelected = () => {
    setSelectedReferencesToDismiss(
      selectedReferences.filter(
        (ref) =>
          ref.status === DocumentStatus.RECHART ||
          ref.status === DocumentStatus.REPROCESS,
      ),
    );
    setShowDismissStatusesModal(true);
  };

  const handleRowSelection = (selectedRows: Patent[]) => {
    setSelectedReferences(selectedRows);
  };

  return (
    <ProjectPage pageName={pageName}>
      {isLoading ? (
        <div className="flex justify-center items-center h-[calc(100vh-100px)]">
          <Loader />
        </div>
      ) : currentPortfolio.references && currentPortfolio.references.length > 0 ? (
        <DocumentsTable
          references={currentPortfolio.references}
          onDeleteRow={handleDeleteReference}
          height="calc(100vh - 100px)"
          onRowSelection={handleRowSelection}
          onDeleteSelected={handleDeleteSelected}
          enableRowDelete={true}
          onReprocessSelected={handleReprocessClick}
          onDismissSelected={handleDismissSelected}
          isDeleteSelectedLoading={false}
          onTagUpdate={handleTagUpdate}
          onSaveName={handleSaveName}
          onSaveNote={handleSaveNote}
          isPortfolio={true}
          isReference={true}
          isSearch={false}
          onSelectProject={handleSelectProject}
        >
          <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
            <DropdownMenuTrigger asChild>
              <Button variant="default" onClick={() => setDropdownOpen(true)}>
                Add References
                <PlusIcon className="w-4 h-4 md:ml-0 lg:ml-2" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onClick={() => {
                  setShowAddReferencesModal(true);
                  setDropdownOpen(false);
                }}
              >
                Add Published Patents/Applications
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  setShowUploadModal(true);
                  setDropdownOpen(false);
                }}
              >
                Add Other Documents
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </DocumentsTable>
      ) : (
        <div className="flex justify-center items-center flex-col mt-12">
          <H4>This portfolio has no references.</H4>

          <div className="flex flex-row gap-2">
            <Button
              className="mt-3"
              variant="outline"
              onClick={() => setShowAddReferencesModal(true)}
            >
              Add Published Patents/Applications
            </Button>
            <Button
              className="mt-3"
              variant="outline"
              onClick={() => setShowUploadModal(true)}
            >
              Upload Other Documents
            </Button>
          </div>
        </div>
      )}

      {/* Modals */}
      <AddReferencesToPortfolioModal
        open={showAddReferencesModal}
        handleClose={() => setShowAddReferencesModal(false)}
      />
      <UploadFilesModal
        open={showUploadModal}
        handleClose={() => setShowUploadModal(false)}
        isPortfolio={true}
      />
      <ProcessReferencesModal
        isOpen={showProcessReferencesModal}
        onOpenChange={setShowProcessReferencesModal}
        onConfirm={handleReprocessReferences}
        documentNames={selectedReferencesToReprocess
          .filter(
            (reference) =>
              reference.status === DocumentStatus.RECHART ||
              reference.status === DocumentStatus.REPROCESS,
          )
          .map((reference) => reference.name)}
        actions={selectedReferencesToReprocess.map((reference) => reference.status)}
      />
      <DismissDocumentStatusModal
        isOpen={showDismissStatusesModal}
        onOpenChange={setShowDismissStatusesModal}
        onConfirm={handleDismissReferenceStatuses}
        documentNames={selectedReferencesToDismiss
          .filter(
            (reference) =>
              reference.status === DocumentStatus.RECHART ||
              reference.status === DocumentStatus.REPROCESS,
          )
          .map((reference) => reference.name)}
      />
    </ProjectPage>
  );
};

export default PortfolioReferencesPage;
