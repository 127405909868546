/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import {
  CopyToClipboardIconButton,
  DocumentViewer,
  HighlightedText,
  ReferenceImage,
} from "@/components";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCellCondensed,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useViz } from "@/hooks";
import { cn } from "@/lib/utils";
import { useAppStateStore, useProjectStore } from "@/store";
import { SearchResult } from "@/types";
import { formatCitationLocation } from "@/utils/projectUtils";
import { EyeOpenIcon } from "@radix-ui/react-icons";
import { useState } from "react";

interface SearchResultsTableProps {
  searchResults: SearchResult[];
  sentSearchQuery: string;
  general: boolean;
  searchChatProjectId: string;
  height?: string;
}

/**
 * @description Search results table component
 */
export default function SearchResultsTable({
  searchResults,
  sentSearchQuery,
  height,
}: SearchResultsTableProps) {
  const { getFullDocument } = useViz();

  // Global state from store
  const { addErrorMessage } = useAppStateStore();
  const documentsToNicknames = useProjectStore(
    (state) => state.currentProject.documentsToNicknames,
  );

  // Local state setup
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [patent, setPatent] = useState<any>(null);
  const [citationText, setCitationText] = useState<string | null>(null);

  const handlePreviewClick = async (refId: string, text: string) => {
    const response = await getFullDocument(refId);

    if (response.success) {
      setPatent(response.data);
      if (text.startsWith('"') && text.endsWith('"')) {
        const strippedText = text.substring(1, text.length - 1);
        setCitationText(strippedText);
      } else {
        setCitationText(text);
      }
      setShowPreview(true);
    } else {
      addErrorMessage("Error fetching reference details.");
    }
  };

  const handleClosePreview = () => {
    setPatent(null);
    setCitationText(null);
    setShowPreview(false);
  };

  return (
    <>
      {searchResults.length > 0 ? (
        <ScrollArea
          className={cn("flex-grow border rounded-md mx-2 overflow-x-auto", height)}
        >
          <Table className="px-4">
            <TableHeader>
              <TableRow>
                <TableHead>Document</TableHead>
                <TableHead>Citation</TableHead>
                <TableHead>Text</TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableBody>
              {searchResults.map((result, index) => {
                return (
                  <TableRow key={index}>
                    <TableCellCondensed>
                      {documentsToNicknames?.[result.referenceId]}
                    </TableCellCondensed>
                    <TableCellCondensed className="whitespace-nowrap">
                      {result.location?.claimSection && "Claim "}
                      {formatCitationLocation(result.location, true)}
                    </TableCellCondensed>
                    <TableCellCondensed>
                      <div className="flex items-center">
                        <div
                          className="w-4 flex-shrink-0 mr-3 self-stretch min-h-[45px] rounded"
                          style={{
                            backgroundColor: result.color || "lightgray",
                          }}
                        />
                        <div>
                          <div className="mb-2.5">
                            <HighlightedText
                              text={result.text}
                              highlight={sentSearchQuery}
                              highlightColor="#e1e9ff"
                            />
                          </div>
                          {result.figureUrls?.map((imageUrl) => (
                            <div key={imageUrl} className="mt-2">
                              <ReferenceImage
                                imageUrl={imageUrl}
                                imageName={imageUrl}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </TableCellCondensed>
                    <TableCellCondensed>
                      <div className="flex items-center space-x-2">
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <CopyToClipboardIconButton
                              index={index}
                              text={result.text}
                            />
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Copy to Clipboard</p>
                          </TooltipContent>
                        </Tooltip>

                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() =>
                                handlePreviewClick(result.referenceId, result.text)
                              }
                            >
                              <EyeOpenIcon className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>View in Document</p>
                          </TooltipContent>
                        </Tooltip>
                      </div>
                    </TableCellCondensed>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </ScrollArea>
      ) : (
        <div className="text-center p-8">
          <h3 className="text-lg font-semibold mb-2">No results found.</h3>
          <p>
            Expand your search to include more sources or try a different search query.
          </p>
        </div>
      )}
      <DocumentViewer
        open={showPreview}
        handleClose={handleClosePreview}
        referenceId={patent?.id}
        citationText={citationText || ""}
        startInChartMode={false}
      />
    </>
  );
}
