/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { splitId } from "@/utils/projectUtils";
import { OpenInNewWindowIcon } from "@radix-ui/react-icons";
import React from "react";

/**
 * @description Source number button component
 * @param {string} sourceNumber - The source number
 */
interface PatentSourceNumberButtonProps {
  sourceNumber: string;
  prefix?: string;
  link?: string;
}

const PatentSourceNumberButton: React.FC<PatentSourceNumberButtonProps> = ({
  sourceNumber,
  link = "https://patents.google.com/patent/",
  prefix = "",
}) => {
  // Return null if sourceNumber is not provided or exceeds 20 characters
  if (!sourceNumber || sourceNumber.length > 20) {
    return null;
  }

  // Check if sourceNumber already includes the prefix
  let finalString = sourceNumber;

  if (!sourceNumber.startsWith(link)) {
    if (!sourceNumber.startsWith(prefix)) {
      finalString = link + prefix + sourceNumber;
    } else {
      finalString = link + sourceNumber;
    }
  }

  return (
    <div className="flex items-center gap-1 whitespace-nowrap">
      {splitId(sourceNumber)}
      <a
        href={finalString}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <OpenInNewWindowIcon className="w-3 h-3" />
      </a>
    </div>
  );
};

export default PatentSourceNumberButton;
