/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect, useState } from "react";
import { useAppStateStore, useCreateProjectStore } from "@/store";
import { usePortfolio } from "@/hooks";
import {
  ProjectDetailsStep,
  PortfolioPatentsStep,
  CreateProjectPageLayout,
} from "./components";

/**
 * @description Renders the create portfolio page
 */
const CreatePortfolioProjectPage: React.FC = () => {
  const { createPortfolioFromPatentNumbers, fetchAndNavigateToPortfolio } =
    usePortfolio();

  // Global state from store
  const { addErrorMessage } = useAppStateStore();

  const {
    isProjectCreationInProgress,
    subjectNumbers,
    projectName,
    resetCreateProjectStore,
    updateSpinnerText,
    updateSubjectNumbers,
    updateIsProjectCreationInProgress,
    subjectMode,
    subjectDetails,
  } = useCreateProjectStore();

  const [isSubjectCompleted, setIsSubjectCompleted] = useState<boolean>(false);
  const [isDetailsCompleted, setIsDetailsCompleted] = useState<boolean>(false);

  const resetState = () => {
    resetCreateProjectStore();
  };

  // Reset state on mount if create not in progress
  useEffect(() => {
    if (!isProjectCreationInProgress) {
      resetState();
    }
  }, []);

  // Check if details step is completed
  useEffect(() => {
    if (projectName !== "") {
      setIsDetailsCompleted(true);
    }
  }, [projectName]);

  // Check if subject step is completed
  useEffect(() => {
    if (
      (subjectMode === "numbers" && subjectDetails.length > 0) ||
      (subjectMode === "assignee" && subjectNumbers.length > 0)
    ) {
      setIsSubjectCompleted(true);
    } else {
      setIsSubjectCompleted(false);
    }
  }, [subjectMode, subjectDetails, subjectNumbers]);

  const handlePortfolioCreation = async () => {
    updateSpinnerText(`Creating portfolio, this may take a few minutes...`);
    updateIsProjectCreationInProgress(true);

    const response = await createPortfolioFromPatentNumbers();
    if (!response.success) {
      addErrorMessage(
        response.message ||
          "An error occurred while creating this project. Try again later.",
      );
      return;
    }

    // await fetchAndNavigateToPortfolio(response.data.id);
    updateIsProjectCreationInProgress(false);
  };

  return (
    <CreateProjectPageLayout
      disabled={!isDetailsCompleted || !isSubjectCompleted}
      handleCreate={handlePortfolioCreation}
      isPortfolio={true}
      title="New Portfolio"
      resetState={resetState}
      isConfirmed={false}
    >
      <ProjectDetailsStep isPortfolio={true} startExpanded={true} />

      {/* Subjects step */}
      <PortfolioPatentsStep
        setInputNumbers={updateSubjectNumbers}
        inputNumbers={subjectNumbers}
        isCompleted={isSubjectCompleted}
      />
    </CreateProjectPageLayout>
  );
};

export default CreatePortfolioProjectPage;
