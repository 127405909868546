import { ClaimAmendments } from "@/types/project";
import { showBoldText } from "@/utils/parseHtml";
import { DraftingButtons } from "./DraftingButtons";

interface ClaimReasonsCollapsibleRightHalfProps {
  isEditing: boolean;
  isCitationsSectionVisible: boolean;
  amendments: ClaimAmendments[];
  argument: string[];
  generatingAmendment: boolean;
  generatingArgument: boolean;
  citationsSection: React.ReactNode;
  handleGenerateAmendments: () => Promise<void>;
  handleGenerateArgument: () => Promise<void>;
  onSave?: () => Promise<void>;
}

export const ClaimReasonsCollapsibleRightHalf = ({
  isEditing,
  isCitationsSectionVisible,
  amendments,
  argument,
  generatingAmendment,
  generatingArgument,
  citationsSection,
  handleGenerateAmendments,
  handleGenerateArgument,
  onSave,
}: ClaimReasonsCollapsibleRightHalfProps) => {
  const hasContent = amendments.length > 0 || argument.length > 0;

  return (
    <div className="w-1/2 pl-2 space-y-0.5">
      {isEditing && (
        <>
          {hasContent && (
            <div className="rounded-md py-4 bg-gray-50">
              <div className="mx-4 space-y-5">
                {/* Show amendments if they exist */}
                {amendments.length > 0 && (
                  <div>
                    <h3 className="text-sm mb-2 font-bold">Suggested Amendment</h3>
                    <p className="text-sm text-justify whitespace-pre-line pb-3">
                      {showBoldText(amendments[0].text)}
                    </p>
                  </div>
                )}
                {/* Show arguments if they exist */}
                {argument.length > 0 && (
                  <div>
                    <h3 className="text-sm mb-2 font-bold">Suggested Arguments</h3>
                    <div className="space-y-4">
                      {argument.map((text, index) => (
                        <div key={index} className="flex">
                          <span className="mr-2">•</span>
                          <p className="text-sm text-justify whitespace-pre-line flex-1">
                            {text}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="rounded-md">
            <div className="mx-4">
              <DraftingButtons
                generatingAmendment={generatingAmendment}
                generatingArgument={generatingArgument}
                handleGenerateAmendments={handleGenerateAmendments}
                handleGenerateArgument={handleGenerateArgument}
                hasContent={hasContent}
                onSave={onSave}
              />
            </div>
          </div>
        </>
      )}

      {isCitationsSectionVisible && (
        <div className="border border-gray-200 rounded-md py-6">
          <div className="mx-6 space-y-6 text-justify">{citationsSection}</div>
        </div>
      )}
    </div>
  );
};
