/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DataTable } from "@/components/table/DataTable";
import { ApplicationDocument, DocumentType, OfficeAction } from "@/types/project";
import { ColumnDef } from "@tanstack/react-table";
import React, { useCallback } from "react";

interface ApplicationDocumentsProps {
  documents: ApplicationDocument[];
  height?: string;
  children?: React.ReactNode;
  onRowClick?: (documentId: string) => void;
  onDelete?: (officeActions: ApplicationDocument[]) => void;
  onDeleteSelected?: () => void;
}

/**
 * @description Table for displaying user uploaded files
 */
const ApplicationDocumentsTable: React.FC<ApplicationDocumentsProps> = ({
  documents,
  height,
  children,
  onRowClick,
}) => {
  // Common columns for all document types
  const commonColumns: ColumnDef<ApplicationDocument, any>[] = [
    {
      accessorKey: "document.documentType",
      header: "Document Type",
      cell: ({ row }) => {
        switch (row.original.document_type) {
          case DocumentType.OFFICE_ACTION:
            return "Office Action";
          default:
            return "—";
        }
      },
    },
  ];

  // Office Action specific columns
  const officeActionColumns: ColumnDef<ApplicationDocument, any>[] = [
    {
      accessorKey: "document.notificationDate",
      header: "Notification Date",
      cell: ({ row }) => {
        const doc = row.original.document as OfficeAction;
        if (doc && doc.notificationDate) {
          return doc.notificationDate
            ? new Date(doc.notificationDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            : "—";
        }
        return "—";
      },
    },
    {
      accessorKey: "document.primaryExaminer",
      header: "Primary Examiner",
      cell: ({ row }) => {
        const doc = row.original.document as OfficeAction;
        return doc.primaryExaminer ?? "—";
      },
    },
  ];

  // Get columns based on document types present in the data
  const columns = [...commonColumns];
  if (documents.some((doc) => doc.document_type === DocumentType.OFFICE_ACTION)) {
    columns.push(...officeActionColumns);
  }

  const handleRowClick = useCallback(
    (row: any) => {
      onRowClick?.(row.id);
    },
    [onRowClick],
  );

  return (
    <>
      <DataTable
        columns={columns}
        data={documents}
        onRowClick={handleRowClick}
        height={height}
        showActions={true}
        onDeleteSelected={null}
        enableRowDelete={true}
      >
        {children}
      </DataTable>
    </>
  );
};

export default React.memo(ApplicationDocumentsTable);
