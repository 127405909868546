/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { LoadingButton } from "@/components/ui/loading-button";

interface ConfirmRemovePatentModalProps {
  open: boolean;
  onClose: () => void;
  removePatentFromPortfolio: (projectIds: string[]) => Promise<void>;
  name: string;
  projectId: string;
}

/**
 * @description Remove patent from portfolio modal
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} onClose - Function to close the modal
 * @param {string} name - The name of the patent to remove
 * @param {string} projectId - The id of the portfolio to remove the patent from
 * @param {() => Promise<void>} removePatentFromPortfolio - Function to remove the patent from the portfolio
 */
function ConfirmRemovePatentModal({
  open,
  onClose,
  name,
  projectId,
  removePatentFromPortfolio,
}: ConfirmRemovePatentModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleRemovePatentFromPortfolio = async () => {
    setIsLoading(true);
    await removePatentFromPortfolio([projectId]);
    setIsLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Remove document from portfolio?</DialogTitle>
          <DialogDescription>
            Remove the document <strong>{name}</strong> from this portfolio? All data
            will be deleted. This action cannot be undone.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            variant="outline"
            onClick={onClose}
            aria-label="Cancel deletion"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="destructive"
            loading={isLoading}
            onClick={handleRemovePatentFromPortfolio}
            aria-label="Delete patent"
            disabled={isLoading}
          >
            {isLoading ? "Removing..." : "Remove"}
          </LoadingButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmRemovePatentModal;
