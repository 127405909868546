/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import { useAuthInfo } from "@propelauth/react";
import { Sidebar } from "@/components";
import { PageContainer, MainContentBox } from "@/styled";
import { H4 } from "@/components/ui/typography";
import { Button } from "@/components/ui/button";

/**
 * @description Renders the page not found page
 */
const PageNotFound: React.FC = () => {
  useDocumentTitle("Page Not Found");

  const { isLoggedIn } = useAuthInfo();
  const navigate = useNavigate();

  return (
    <PageContainer>
      <div>
        {isLoggedIn && <Sidebar />}
        <MainContentBox>
          <div className="flex flex-col items-center justify-center">
            <H4>The page you're looking for can't be found.</H4>

            {isLoggedIn ? (
              <Button onClick={() => navigate("/home")} aria-label="go home">
                Go Home
              </Button>
            ) : (
              <Button onClick={() => navigate("/signin")} aria-label="sign in">
                Sign In
              </Button>
            )}
          </div>
        </MainContentBox>
      </div>
    </PageContainer>
  );
};

export default PageNotFound;
