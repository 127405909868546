/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components";
import DocumentViewer from "@/components/patents/DocumentViewer";
import { Button } from "@/components/ui/button";
import { DataTableCell } from "@/components/ui/table";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useDataTable, useVector } from "@/hooks";
import { useFeedbackData } from "@/hooks/useFeedbackData";
import { useAppStateStore, useProjectStore } from "@/store";
import { ElementType, InvalidityCitation } from "@/types";
import { MagnifyingGlassIcon, PlusIcon } from "@radix-ui/react-icons";
import React, { useState } from "react";
import { ReferenceTableCitation } from "./index";
import ReferenceTableCollapsibleFeedbackModal from "./ReferenceTableCollapsibleFeedbackModal";
interface ReferenceTableCitationCellProps {
  index: number;
  refId: string;
  claimNumber: string;
  claimText: string;
  citations: InvalidityCitation[];
  fetchReferenceChartData: () => void;
  addingCitationKey: string;
  setAddingCitationKey: (key: string) => void;
  setIsEditing: (isEditing: boolean) => void;
  isEditing: boolean;
  header?: string;
  footer?: string;
}

/**
 * @description Reference table citation cell
 * @param {number} index - The index of the citation.
 * @param {string} refId - The id of the reference.
 * @param {string} claimNumber - The claim number.
 * @param {string} claimText - The claim text.
 * @param {string} invalidityId - The id of the invalidity.
 * @param {InvalidityCitation[]} citations - The citations.
 * @param {function} fetchReferenceChartData - Function to fetch the reference chart data.
 * @param {string} addingCitationKey - The key of the citation being added.
 * @param {function} setAddingCitationKey - Function to set the adding citation key.
 * @param {function} setIsEditing - Function to set the editing state.
 * @param {boolean} isEditing - Whether the citation is being edited.
 */
const ReferenceTableCitationCell: React.FC<ReferenceTableCitationCellProps> = ({
  index,
  refId,
  claimNumber,
  claimText,
  citations = [],
  fetchReferenceChartData,
  addingCitationKey,
  setAddingCitationKey,
  setIsEditing,
  isEditing,
  header,
  footer,
}) => {
  const { retrieveInvalidityForClaim } = useVector();
  const { currentProjectId, elementEditIndex, selectedElementType } = useProjectStore();
  const { addErrorMessage } = useAppStateStore();
  const { retrieveInvalidityForItem, handleAddCitation } = useDataTable();
  const { positiveFeedbackData, negativeFeedbackData } = useFeedbackData(
    currentProjectId,
    refId,
    claimNumber,
  );

  const [isInvalidityLoading, setIsInvalidityLoading] = useState(false);
  const [showNoMoreCitations, setShowNoMoreCitations] = useState(false);
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);

  const shownCitations = citations.filter((citation) => !citation.removed);

  const removedCitations = citations.filter((citation) => citation.removed === true);

  const retrieveInvalidity = async (index: number, refId: string) => {
    setIsInvalidityLoading(true);
    const response = await retrieveInvalidityForClaim(
      currentProjectId,
      claimNumber,
      refId,
      claimText,
      selectedElementType === ElementType.FEATURE,
      positiveFeedbackData.map((citation) => citation.text),
      negativeFeedbackData.map((citation) => citation.text),
    );
    if (!response.success && response.status === 404) {
      setShowNoMoreCitations(true);
      setIsInvalidityLoading(false);
      return;
    } else if (!response.success) {
      addErrorMessage(`Error retrieving invalidity for claim ${claimNumber}. `);
      setIsInvalidityLoading(false);
      setIsInvalidityLoading(false);
      return;
    }
    let itemResponse = response.data[refId];
    if (itemResponse && itemResponse.length > 0) {
      retrieveInvalidityForItem(index, refId, itemResponse);
    } else {
      setShowNoMoreCitations(true);
      setIsInvalidityLoading(false);
    }
    fetchReferenceChartData();
    setIsInvalidityLoading(false);
  };

  return (
    <DataTableCell className="gap-2">
      {header && <p className="text-sm text-muted-foreground mb-4">{header}</p>}
      {shownCitations.map((citation, citationIndex) => (
        <ReferenceTableCitation
          key={`${index}-${refId}-${citationIndex}`}
          citationKey={`${index}-${refId}-${citationIndex}`}
          citation={citation}
          index={index}
          refId={refId}
          claimNumber={claimNumber}
          citationIndex={citationIndex}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          addingCitationKey={addingCitationKey}
          setShowNoMoreCitations={setShowNoMoreCitations}
        />
      ))}

      {footer && <p className="text-sm text-muted-foreground my-4">{footer}</p>}
      <ReferenceTableCollapsibleFeedbackModal
        index={index}
        positiveFeedbackData={positiveFeedbackData}
        negativeFeedbackData={negativeFeedbackData}
        removedCitations={removedCitations}
        refId={refId}
        claimNumber={claimNumber}
        currentProjectId={currentProjectId}
      />
      {(isInvalidityLoading || elementEditIndex === index) && (
        <div className="mb-2">
          <Loader message="Retrieving invalidity..." />
        </div>
      )}

      {showNoMoreCitations && (
        <div className="mb-3 text-center">
          <p className="text-sm text-muted-foreground">
            No more citations found. Provide feedback to find more.
          </p>
        </div>
      )}
      <div className="flex items-center justify-center space-x-2 ">
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              disabled={isEditing || isInvalidityLoading || showNoMoreCitations}
              onClick={() => retrieveInvalidity(index, refId)}
            >
              <MagnifyingGlassIcon className="h-4 w-4" />
              <span className="sr-only">Find more citations</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Find more</p>
          </TooltipContent>
        </Tooltip>

        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              disabled={isEditing || isInvalidityLoading}
              onClick={() => setShowDocumentViewer(true)}
            >
              <PlusIcon className="h-4 w-4" />
              <span className="sr-only">Add citation</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Add from document</p>
          </TooltipContent>
        </Tooltip>

        <DocumentViewer
          open={showDocumentViewer}
          handleClose={() => setShowDocumentViewer(false)}
          referenceId={refId}
          citationText={""}
          selectedElement={claimNumber}
          selectedElementType={selectedElementType}
          startInChartMode={true}
        />
      </div>
    </DataTableCell>
  );
};

export default ReferenceTableCitationCell;
