import useProjectStore from "@/store/projectStore";
import { ClaimStatus, ClaimStatusColor } from "@/types/project";
import { ChevronDownIcon, ChevronUpIcon, PencilIcon } from "lucide-react";
import { memo } from "react";

interface OfficeActionInfoRowProps {
  items: Array<{
    label: string;
    content: string | React.ReactNode;
    isDropdown?: boolean;
  }>;
  status?: ClaimStatus;
  gridCols?: string;
  onEdit?: () => void;
  showCarat?: boolean;
  isCaratOpen?: boolean;
}

const getStatusDotColor = (status: ClaimStatus) => {
  const lowerStatus = status?.toLowerCase();
  switch (lowerStatus) {
    case ClaimStatus.REJECTED:
      return ClaimStatusColor.RED;
    case ClaimStatus.OBJECTED:
      return ClaimStatusColor.YELLOW;
    default:
      return "transparent";
  }
};

export const OfficeActionInfoRow = memo(
  ({
    items,
    status,
    gridCols = "grid-cols-3",
    onEdit = () => {},
    showCarat = true,
    isCaratOpen = false,
  }: OfficeActionInfoRowProps) => {
    const { draftingClaimID } = useProjectStore();
    const showAddOns = status;
    // Status dot
    const LeftAddOn = showAddOns && (
      <div className="pl-[1.5vw] sm:pl-[1.5vw] pr-[1.5vw] sm:pr-[1.5vw]">
        <div
          className="w-4 sm:w-3.5 h-4 sm:h-3.5 rounded-full"
          style={{ backgroundColor: getStatusDotColor(status) }}
        />
      </div>
    );
    const isEditing = draftingClaimID != null;

    // Action buttons
    const RightAddOn = showAddOns && (
      <div className="flex items-center pr-[1.5vw] sm:pr-[1.5vw]">
        <button
          className={`px-3 py-1.5 text-sm rounded-md flex items-center gap-1.5 focus:outline-none
            ${isEditing ? "bg-gray-100" : "hover:bg-gray-50"}`}
          onClick={(e) => {
            e.stopPropagation(); // Prevent event bubbling
            onEdit?.(); // Call onEdit if provided
          }}
        >
          <PencilIcon className="w-3.5 h-3.5" />
        </button>
        {showCarat &&
          (isCaratOpen ? (
            <ChevronUpIcon className="ml-[2vw] sm:ml-[2vw] w-4 h-4" />
          ) : (
            <ChevronDownIcon className="ml-[2vw] sm:ml-[2vw] w-4 h-4" />
          ))}
      </div>
    );

    // Main content
    const MainContent = (
      <div className={`grid gap-2 sm:gap-4 ${gridCols}`}>
        {items.map(({ label, content, isDropdown }) => (
          <div key={label} className="space-y-0.5">
            <p className="text-sm font-bold">{label}</p>
            {isDropdown ? content : <p className="text-med">{content as string}</p>}
          </div>
        ))}
      </div>
    );

    return (
      <div className="border border-gray-200 rounded-md py-3 w-full">
        <div className="flex items-center">
          {LeftAddOn}
          <div className="flex-1 px-[1.5vw] sm:px-[1.5vw]">{MainContent}</div>
          {RightAddOn}
        </div>
      </div>
    );
  },
);

OfficeActionInfoRow.displayName = "OfficeActionInfoRow";
