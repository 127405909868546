/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import ImageViewer from "@/components/images/ImageViewer";
import { useState } from "react";

interface ReferenceImageProps {
  imageUrl: string;
  imageName: string;
}

function ReferenceImage({ imageUrl, imageName }: ReferenceImageProps) {
  const [imageOpen, setImageOpen] = useState<boolean>(false);
  const [isValidAspectRatio, setIsValidAspectRatio] = useState<boolean>(true);

  const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const img = event.currentTarget;
    const aspectRatio = img.naturalWidth / img.naturalHeight;

    // Temporary barcode filter, any width:height ratio that's greater than 9
    if (aspectRatio > 9) {
      setIsValidAspectRatio(false);
    }
  };

  if (!isValidAspectRatio) {
    return <div></div>; // Return an empty div if the aspect ratio is invalid
  }

  return (
    <>
      <div className="h-[100px] cursor-pointer m-2">
        <img
          src={imageUrl}
          alt={`Figure: ${imageName}`}
          className="rounded-md object-contain h-full"
          onClick={() => setImageOpen(true)}
          onLoad={handleImageLoad}
        />
      </div>
      <ImageViewer
        imageUrl={imageUrl}
        imageName={imageName}
        open={imageOpen}
        handleClose={() => setImageOpen(false)}
      />
    </>
  );
}

export default ReferenceImage;
