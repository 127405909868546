import { useProcessReferences } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { ParentType } from "@/types";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import BaseUploadFilesModal from "./BaseUploadFilesModal";

interface UploadFileModalProps {
  open: boolean;
  handleClose: () => void;
  inContext?: boolean;
  handleContextAddClick?: (files: File[]) => void;
}

const UploadFileModal: React.FC<UploadFileModalProps> = ({
  open,
  handleClose,
  inContext,
  handleContextAddClick,
}) => {
  const { addAndProcessFilesForProject } = useProcessReferences();
  const location = useLocation();
  const inExplore = location.pathname.includes("explore");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);

  const { currentProjectId, currentProject } = useProjectStore();
  const { addWarningMessage } = useAppStateStore();

  const handleAddFilesClick = async (files: File[]) => {
    handleClose();
    if (files && files.length > 0) {
      if (inContext) {
        handleContextAddClick(files);
      } else {
        await handleProcessFiles(files);
      }
    }
  };

  const handleProcessFiles = async (files: File[]) => {
    const existingFileNames = currentProject.references.map((ref) => ref.name);
    const newFiles = files.filter((file) => !existingFileNames.includes(file.name));

    if (newFiles.length < files.length) {
      addWarningMessage(
        `The following file(s) already exist in the project: ${files
          .filter((file) => existingFileNames.includes(file.name))
          .map((file) => file.name)
          .join(", ")}`,
      );
    }

    await addAndProcessFilesForProject(
      currentProjectId,
      currentProject.name,
      newFiles,
      isCheckboxChecked,
      // true, // displayLoadingMessages
      ParentType.PROJECT,
    );
  };

  return (
    <BaseUploadFilesModal
      open={open}
      handleCloseModal={handleClose}
      handleAddFilesClick={handleAddFilesClick}
      title="Upload files"
      buttonLabel="Add"
      showCheckbox={!inContext && !inExplore}
      checkboxLabel="Complete claim and feature chart(s)"
      onCheckboxChange={(checked) => setIsCheckboxChecked(checked as boolean)}
      isCheckboxChecked={isCheckboxChecked}
    />
  );
};

export default UploadFileModal;
