/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { LoadingButton } from "@/components/ui/loading-button";
import { usePortfolio } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import React, { useState } from "react";

interface PrunePortfolioModalProps {
  open: boolean;
  handleClose: () => void;
}

/**
 * @description Prune references modal
 */
const PrunePortfolioModal: React.FC<PrunePortfolioModalProps> = ({
  open,
  handleClose,
}) => {
  const { removeProjectsFromPortfolio } = usePortfolio();

  const { currentPortfolioId, updateCurrentPortfolio, currentPortfolio } =
    useProjectStore();
  const { addErrorMessage } = useAppStateStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [pruneCount, setPruneCount] = useState<string>("");
  const onClose = () => {
    handleClose();
    resetModal();
  };

  const resetModal = () => {
    setIsLoading(false);
    setError("");
    setPruneCount("");
  };

  const handlePruneReferences = async () => {
    const projects = currentPortfolio.projects;
    if (projects.length < parseInt(pruneCount)) {
      setError(
        "Number of projects to keep is greater than the number of projects in portfolio.",
      );
      return;
    }

    if (projects.length === parseInt(pruneCount)) {
      setError(
        "Number of projects to keep is equal to the number of projects in portfolio.",
      );
      return;
    }

    setIsLoading(true);
    const allProjectIds = projects.map((project) => project.id);

    const topNProjectIds = projects
      .sort((a, b) => b.score - a.score)
      .slice(0, parseInt(pruneCount))
      .map((project) => project.id);

    // Get all projects with type PFP
    const pfpProjectIds = projects
      .filter((project) => project.type === "PFC")
      .map((project) => project.id);

    // Combine the two lists, ensuring no duplicates
    const projectsToKeepIds = Array.from(
      new Set([...topNProjectIds, ...pfpProjectIds]),
    );
    const projectsToRemoveIds = allProjectIds.filter(
      (id) => !projectsToKeepIds.includes(id),
    );

    const response = await removeProjectsFromPortfolio(
      currentPortfolioId,
      projectsToRemoveIds,
    );
    if (!response.success) {
      addErrorMessage("Failed to prune portfolio.");
      setError("Failed to prune portfolio.");
      setIsLoading(false);
      return;
    }
    const newPortfolioDetails = {
      ...currentPortfolio,
      projects: projects.filter((project) => !projectsToRemoveIds.includes(project.id)),
    };
    updateCurrentPortfolio(newPortfolioDetails);
    setIsLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Prune portfolio</DialogTitle>
          <DialogDescription>
            Enter the number of documents you want to keep in your portfolio. The
            weakest documents will be removed. This action cannot be undone.
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-4 py-2">
          <div className="grid gap-2">
            <Input
              id="pruneCount"
              type="number"
              value={pruneCount}
              onChange={(e) => setPruneCount(e.target.value)}
              className={error ? "border-red-500" : ""}
              placeholder="Number of documents to keep"
              disabled={isLoading}
            />
            {error && <p className="text-sm text-red-500">{error}</p>}
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handlePruneReferences}
            disabled={
              pruneCount === "" ||
              isNaN(Number(pruneCount)) ||
              Number(pruneCount) < 1 ||
              isLoading
            }
          >
            {isLoading ? "Pruning..." : "Prune Portfolio"}
          </LoadingButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PrunePortfolioModal;
