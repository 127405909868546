/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useMemo } from "react";
import { useUserStore } from "@/store";
import {
  UsersTable,
  AdminPageLayout,
  NoAccessMessage,
} from "@/features/admin/components";
import { RoleType } from "@/types";
/**
 * @description Admin page component
 */
function AdminUsersPage() {
  const { organizationId, organizationName, role } = useUserStore();

  const isAdmin = useMemo(
    () => role === RoleType.ADMIN || role === RoleType.OWNER,
    [role],
  );

  return (
    <AdminPageLayout isSuperAdmin={false} pageName="Admin - Users">
      {isAdmin ? (
        <div className="mt-2">
          <UsersTable orgName={organizationName} orgId={organizationId} />
        </div>
      ) : (
        <NoAccessMessage />
      )}
    </AdminPageLayout>
  );
}

export default AdminUsersPage;
