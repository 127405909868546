/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, SidebarPage } from "@/components";
import { Separator } from "@/components/ui/separator";
import { useProject, useViz } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { ParentType, Project, ProjectType } from "@/types";
import { useAuthInfo } from "@propelauth/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectTypeCard, ProjectsTable } from "./components";

/**
 * @description Renders the home page
 */
function HomePage() {
  const navigate = useNavigate();
  const { getProjectMetadata } = useProject();
  const { getUserProjects, getSearchChatProjectId, getPortfolioMetadata } = useViz();
  const { user } = useAuthInfo();

  // Global state from store
  const {
    currentProjectId,
    updateCurrentProjectId,
    updateCurrentPortfolioId,
    updateCurrentParent,
    updateCurrentPortfolio,
    currentPortfolio,
  } = useProjectStore();
  const { addErrorMessage, searchChatProjectId, updateIsLoading } = useAppStateStore();

  const pageContentRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchExploreProjectId = useCallback(async () => {
    await getSearchChatProjectId();
  }, [getSearchChatProjectId]);

  // Fetches user projects on the first home page load
  const fetchProjectsData = useCallback(async () => {
    if (!user?.email) return;
    setIsLoading(true);
    const projectsResponse = await getUserProjects();
    if (!projectsResponse.success) {
      addErrorMessage("Error fetching projects. Please try again later.");
    }
    setIsLoading(false);
  }, [user?.email, getUserProjects, addErrorMessage]);

  // Handle project selection
  const handleClickProject = useCallback(
    async (project: Project) => {
      const id = project.project_id || project.id;
      if (project.type !== ProjectType.PFA && project.type !== ProjectType.SEP) {
        updateCurrentParent(ParentType.PROJECT);
        if (id === currentProjectId) {
          navigate(`/project/${id}/subject`);
        } else {
          navigate(`/project/${id}/subject`);
          await getProjectMetadata(id);
        }
      } else {
        navigate(`/portfolio/${id}/subjects`);
        await getPortfolioMetadata(id, false, true, project.name);
      }
    },
    [
      currentProjectId,
      getProjectMetadata,
      updateCurrentProjectId,
      updateCurrentParent,
      updateCurrentPortfolioId,
      updateCurrentPortfolio,
      currentPortfolio,
      navigate,
      getPortfolioMetadata,
      updateIsLoading,
    ],
  );

  useEffect(() => {
    if (!searchChatProjectId) {
      fetchExploreProjectId();
    }
  }, [searchChatProjectId, fetchExploreProjectId]);

  useEffect(() => {
    fetchProjectsData();
  }, []);

  return (
    <SidebarPage pageName="Home">
      <div className="bg-background" ref={pageContentRef}>
        <div id="createNewProject" className="mb-6 flex flex-col gap-2 pt-4 px-3">
          <p className="text-base font-medium p-1 h-auto whitespace-nowrap">
            Start a new project
          </p>
          <div className="flex space-x-4 overflow-x-auto">
            <ProjectTypeCard
              onClick={() => navigate("/create-project")}
              type={ProjectType.INV}
            />
            <ProjectTypeCard
              onClick={() => navigate("/create-portfolio")}
              type={ProjectType.PFA}
            />
            <ProjectTypeCard
              onClick={() => navigate("/create-application-project")}
              type={ProjectType.APP}
            />
            {/* <ProjectTypeCard
              onClick={() => navigate("/create-standards-project")}
              type={ProjectType.SEP}
            /> */}
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="gap-2">
            <div className="px-3">
              <Separator />
            </div>
            <ProjectsTable
              tableType="active"
              handleClickProject={handleClickProject}
              isInArchive={false}
              height="calc(100vh - 200px)"
            />
          </div>
        )}
      </div>
    </SidebarPage>
  );
}

export default HomePage;
