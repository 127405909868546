/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { ErrorMessage, Loader, SidebarPage } from "@/components";
import ExploreHeader from "@/features/explore/components/ExploreHeader";
import SemanticSearchHeader from "@/features/explore/search/components/SearchHeader";
import SearchResultsTable from "@/features/explore/search/components/SearchResultsTable";
import { useVector } from "@/hooks";
import { useAppStateStore, useProjectStore } from "@/store";
import { PageContentBox } from "@/styled";
import { useState } from "react";

/**
 * @description Renders the explore search page
 */
const ExploreSearchPage = () => {
  const { semanticSearchDocuments } = useVector();

  // Global state from store
  const { searchChatProjectId } = useAppStateStore();
  const referenceIds = useProjectStore((state) => state.currentProject.referenceIds);

  // Local state setup
  const [showError, setShowError] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [searchMode, setSearchMode] = useState("semantic");
  const [sentSearchQuery, setSentSearchQuery] = useState("");
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);

  const handleDocumentChange = (selectedIds: string[]) => {
    setSelectedDocumentIds(selectedIds);
  };

  // @description Handles the semantic search
  const handleSemanticSearch = async () => {
    setSearchResults(null);
    setShowError(false);

    if (selectedDocumentIds?.length === 0) {
      return;
    }

    const trimmedSearchQuery = searchQuery.trim();
    setSentSearchQuery(trimmedSearchQuery);

    if (trimmedSearchQuery !== "" && trimmedSearchQuery !== null) {
      setShowSpinner(true);
      const response = await semanticSearchDocuments(
        searchChatProjectId,
        trimmedSearchQuery,
        selectedDocumentIds,
        searchMode,
      );
      if (response.success) {
        setShowSpinner(false);
        setShowError(false);
        setSearchResults(response.data.sources[0]);
      } else {
        setShowError(true);
        setShowSpinner(false);
      }
    }
  };
  return (
    <SidebarPage pageName="Explore - Search">
      <ExploreHeader />
      <PageContentBox overflowy="hidden">
        <div className="sticky top-0 bg-background z-10">
          <SemanticSearchHeader
            handleSemanticSearch={handleSemanticSearch}
            searchMode={searchMode}
            setSearchMode={setSearchMode}
            handleDocumentChange={handleDocumentChange}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            general={true}
            disabled={referenceIds?.length === 0}
          />
          {showSpinner && <Loader message={"Searching the documents..."} />}
          {showError && <ErrorMessage />}
        </div>
        {searchResults && (
          <SearchResultsTable
            searchResults={searchResults}
            sentSearchQuery={sentSearchQuery}
            general={true}
            searchChatProjectId={searchChatProjectId}
            height="h-[calc(100vh-125px)]"
          />
        )}
      </PageContentBox>
    </SidebarPage>
  );
};
export default ExploreSearchPage;
