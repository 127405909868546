/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DocumentMetadata, ElementList } from "@/components";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { Separator } from "@/components/ui/separator";
import { ElementType, Patent } from "@/types";
import React, { useEffect, useRef, useState } from "react";
import DocumentBody from "./DocumentBody";
import SubjectAbstractImages from "./SubjectAbstractImages";

interface DetailViewerLayoutProps {
  document: Patent | null;
  citationText?: string;
  highlightedRef?: React.RefObject<HTMLDivElement | HTMLTableRowElement>;
  mode: "viewer" | "editor";
  elements?: { type: ElementType; value: string }[];
}

const DetailViewerLayout: React.FC<DetailViewerLayoutProps> = ({
  document,
  citationText,
  highlightedRef,
  mode = "viewer",
  elements = [],
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [leftPanelSize, setLeftPanelSize] = useState(50);
  const [showPdfViewer, setShowPdfViewer] = useState(true);

  useEffect(() => {
    const checkPdfAccess = async () => {
      if (document?.url) {
        try {
          const response = await fetch(document.url);
          if (!response.ok) {
            setShowPdfViewer(false);
            setLeftPanelSize(100);
          }
        } catch (error) {
          console.error("Error checking PDF access:", error);
          setShowPdfViewer(false);
          setLeftPanelSize(100);
        }
      }
    };

    checkPdfAccess();
  }, [document?.url]);

  if (!document) return null;

  const isPatent = document.isPatent;

  // Check if any claim is highlighted (existing code)
  const isClaimHighlighted = document.claims?.some((claim) => {
    const claimText = Object.values(claim)[0];
    return (
      typeof claimText === "string" &&
      claimText.slice(0, 75) === citationText?.slice(0, 75)
    );
  });

  return (
    <div className="flex h-full">
      {isPatent ? (
        <>
          <div
            className="w-[50%] overflow-y-auto overflow-x-hidden custom-scrollbar p-3"
            style={{ height: "calc(100vh - 200px)" }}
            ref={containerRef}
          >
            {mode === "editor" && elements.length > 0 && (
              <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2">Selected Elements</h3>
                {elements.map((element, idx) => (
                  <div key={idx} className="p-2 bg-muted rounded-md mb-2">
                    <span className="font-medium">{element.type}: </span>
                    {element.value}
                  </div>
                ))}
                <Separator className="my-4" />
              </div>
            )}

            {mode === "viewer" && (
              <SubjectAbstractImages
                details={document}
                citationText={citationText}
                highlightedRef={highlightedRef}
              />
            )}

            <DocumentBody
              document={document}
              citationText={citationText}
              highlightedRef={highlightedRef}
            />
          </div>

          <Separator orientation="vertical" className="h-auto" />

          <div
            className="w-[50%] overflow-y-auto overflow-x-hidden custom-scrollbar p-3"
            style={{ height: "calc(100vh - 200px)" }}
          >
            {mode === "viewer" ? (
              <>
                <DocumentMetadata
                  details={document}
                  showHead={true}
                  subjectPage={true}
                  enableEditPriorityDate={false}
                />

                {document.claims?.length > 0 && (
                  <ElementList
                    list={document.claims}
                    itemType={ElementType.CLAIM}
                    startCollapsed={false}
                    editingBool={false}
                    citationText={isClaimHighlighted ? citationText : undefined}
                    highlightedRef={
                      isClaimHighlighted
                        ? (highlightedRef as React.RefObject<HTMLTableRowElement>)
                        : undefined
                    }
                  />
                )}
              </>
            ) : (
              // Editor mode right panel content
              <div className="p-4">
                {/* Add any editor-specific content for right panel */}
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <ResizablePanelGroup direction="horizontal">
            {/* Left Pane: Current Information */}
            <ResizablePanel defaultSize={leftPanelSize} onResize={setLeftPanelSize}>
              <div
                className="overflow-y-auto overflow-x-hidden p-3 "
                style={{ height: "calc(100vh - 120px)" }}
              >
                <div className="max-w-full">
                  <SubjectAbstractImages
                    details={document}
                    showTitle={false}
                    citationText={citationText}
                    highlightedRef={highlightedRef}
                  />
                </div>
                <DocumentBody
                  document={document}
                  citationText={citationText}
                  highlightedRef={highlightedRef}
                />
              </div>
              {/* <Separator orientation="vertical" className="h-auto" /> */}
            </ResizablePanel>

            {showPdfViewer && (
              <>
                <ResizableHandle withHandle={true} />

                {/* Right Pane: PDF Viewer (iframe) */}
                <ResizablePanel defaultSize={100 - leftPanelSize}>
                  <div style={{ height: "calc(100vh - 120px)" }}>
                    {document.url ? (
                      <iframe
                        src={document.url}
                        title="PDF Viewer"
                        width="100%"
                        height="100%"
                        style={{ border: "none" }}
                      />
                    ) : (
                      <p className="p-4">No PDF available.</p>
                    )}
                  </div>
                </ResizablePanel>
              </>
            )}
          </ResizablePanelGroup>
        </>
      )}
    </div>
  );
};

export default DetailViewerLayout;
