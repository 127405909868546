/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { PlaceholderBox } from "@/components";

export default function NoAccessMessage() {
  return (
    <PlaceholderBox
      title="You do not have permission to access this page."
      subtitle="Please contact your organization administrator for access."
    />
  );
}
