import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { InvalidityCitation } from "../types/project";

export type FeedbackChartData = {
  [projectId: string]: {
    [referenceId: string]: {
      [claimNum: string]: InvalidityCitation[];
    };
  };
};

interface FeedbackChartDataStore {
  feedbackChartData: FeedbackChartData;
  addFeedbackCitation: (
    projectId: string,
    referenceId: string,
    claimId: string,
    newCitation: InvalidityCitation,
  ) => void;
  removeFeedbackCitation: (
    projectId: string,
    referenceId: string,
    claimId: string,
    citationId: string,
  ) => void;
  clearFeedbackChartDataByID: (projectId: string, referenceId?: string[]) => void;
}

export const useFeedbackChartDataStore = create<FeedbackChartDataStore>()(
  persist(
    (set) => ({
      feedbackChartData: {},
      addFeedbackCitation: (projectId, referenceId, claimId, newCitation) =>
        set((state) => {
          // TODO @christina: can we make this less ugly?
          const updatedFeedbackChartData = { ...state.feedbackChartData };

          if (!updatedFeedbackChartData[projectId]) {
            updatedFeedbackChartData[projectId] = {};
          }
          if (!updatedFeedbackChartData[projectId][referenceId]) {
            updatedFeedbackChartData[projectId][referenceId] = {};
          }
          if (!updatedFeedbackChartData[projectId][referenceId][claimId]) {
            updatedFeedbackChartData[projectId][referenceId][claimId] = [];
          }

          // updatedFeedbackChartData[projectId][referenceId][claimId] = [
          //   ...updatedFeedbackChartData[projectId][referenceId][claimId],
          //   newCitation,
          // ];
          // Ensure duplicate citations are not added
          const existingCitationIndex = updatedFeedbackChartData[projectId][
            referenceId
          ][claimId].findIndex((citation) => citation.id === newCitation.id);

          if (existingCitationIndex === -1) {
            // If the citation doesn't exist, add it to the array
            updatedFeedbackChartData[projectId][referenceId][claimId].push(newCitation);
          } else {
            // If the citation exists, update it
            updatedFeedbackChartData[projectId][referenceId][claimId][
              existingCitationIndex
            ] = newCitation;
          }
          return { feedbackChartData: updatedFeedbackChartData };
        }),
      removeFeedbackCitation: (projectId, referenceId, claimId, citationId) =>
        set((state) => {
          const updatedFeedbackChartData = { ...state.feedbackChartData };
          if (updatedFeedbackChartData[projectId]?.[referenceId]?.[claimId]) {
            updatedFeedbackChartData[projectId][referenceId][claimId] =
              updatedFeedbackChartData[projectId][referenceId][claimId].filter(
                (citation) => citation.id !== citationId,
              );
          }
          return { feedbackChartData: updatedFeedbackChartData };
        }),
      clearFeedbackChartDataByID: (projectId, referenceIds) =>
        set((state) => {
          const updatedFeedbackChartData = { ...state.feedbackChartData };
          if (referenceIds && referenceIds.length > 0) {
            if (updatedFeedbackChartData[projectId]) {
              referenceIds.forEach((referenceId) => {
                delete updatedFeedbackChartData[projectId][referenceId];
              });
            }
          } else {
            delete updatedFeedbackChartData[projectId];
          }
          return { feedbackChartData: updatedFeedbackChartData };
        }),
    }),
    {
      name: "feedback-chart-storage",
      storage: createJSONStorage(() => localStorage), // localStorage only stores strings
    },
  ),
);

export default useFeedbackChartDataStore;
