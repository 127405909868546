import { useAuthInfo } from "@propelauth/react";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";

export function useSentryReplay() {
  const { user, loading, orgHelper } = useAuthInfo();

  useEffect(() => {
    if (!loading && user) {
      const replay = Sentry.getReplay();

      const isProd = Sentry.getClient()?.getOptions()?.environment === "production";
      const userOrgs = orgHelper.getOrgs();
      const canRecordOrg = userOrgs.some(
        (org) => org.orgName.includes("AndAI") || org.orgName === "Fish",
      );

      if (canRecordOrg && isProd && replay) {
        if (!replay.getReplayId()) {
          replay.start();
        } else {
          replay.flush();
        }
      } else if (replay && replay.getReplayId()) {
        replay.stop();
      }
    }
  }, [user, loading, orgHelper]);
}
