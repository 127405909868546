/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ArrowUpDownIcon, GripVerticalIcon, XIcon } from "lucide-react";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { SortingState } from "@tanstack/react-table";
import { cn } from "@/lib/utils";

interface TableSortProps {
  table: any;
  multiSorting: SortingState;
  setMultiSorting: React.Dispatch<React.SetStateAction<SortingState>>;
}

/**
 * TableSort component for sorting table columns.
 */
export function TableSort({ table, multiSorting, setMultiSorting }: TableSortProps) {
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = multiSorting.findIndex((item) => item.id === active.id);
      const newIndex = multiSorting.findIndex((item) => item.id === over?.id);
      const newSorting = arrayMove(multiSorting, oldIndex, newIndex);
      setMultiSorting(newSorting);
    }
  };

  const handleClearAll = () => {
    setIsSortDropdownOpen(false);
    setMultiSorting([]);
  };

  return (
    <DropdownMenu open={isSortDropdownOpen} onOpenChange={setIsSortDropdownOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="h-9 px-3">
          <ArrowUpDownIcon className="h-4 w-4 md:mr-2" />
          <span className="hidden md:inline mr-2">Sort</span>
          {multiSorting.length > 0 && (
            <div className="ml-1 bg-primary text-background w-5 h-5 flex items-center justify-center rounded-full text-xs font-medium leading-none dark:text-white">
              {multiSorting.length}
            </div>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="overflow-hidden">
        <div className="p-2">
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToParentElement]}
          >
            <SortableContext
              items={multiSorting.map((sort) => sort.id)}
              strategy={verticalListSortingStrategy}
            >
              <ul className="max-h-[300px] overflow-y-auto">
                {multiSorting.map((sort, index) => (
                  <SortableItem
                    key={sort.id}
                    id={sort.id}
                    sort={sort}
                    index={index}
                    multiSorting={multiSorting}
                    setMultiSorting={setMultiSorting}
                    table={table}
                  />
                ))}
              </ul>
            </SortableContext>
          </DndContext>
          <div
            className={cn(
              " flex flex-row items-center space-x-1",
              multiSorting.length > 0 ? "mt-2" : "",
            )}
          >
            <Select
              onValueChange={(value) => {
                const newSort = { id: value, desc: false };
                setMultiSorting([...multiSorting, newSort]);
              }}
            >
              <SelectTrigger className="h-9 w-full">
                <span>Add sort</span>
              </SelectTrigger>
              <SelectContent>
                {table
                  .getAllColumns()
                  .filter(
                    (column) =>
                      column.getCanSort() &&
                      !multiSorting.some((sort) => sort.id === column.id),
                  )
                  .map((column) => (
                    <SelectItem key={column.id} value={column.id}>
                      {column.columnDef.header as string}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
            {multiSorting.length > 0 && (
              <Button variant="ghost" className="h-9 w-full" onClick={handleClearAll}>
                Clear All
              </Button>
            )}
          </div>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

interface SortableItemProps {
  id: string;
  sort: { id: string; desc: boolean };
  index: number;
  multiSorting: SortingState;
  setMultiSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  table: any;
}

const SortableItem: React.FC<SortableItemProps> = ({
  id,
  sort,
  index,
  multiSorting,
  setMultiSorting,
  table,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({ id });

  const style = {
    transform: transform ? `translate3d(0, ${transform.y}px, 0)` : undefined,
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="mb-2 flex items-center space-x-1 h-9"
    >
      <div {...listeners} className="mr-2 cursor-grab">
        <GripVerticalIcon className="h-4 w-4 text-gray-500" />
      </div>
      <div className="flex-1 space-x-1">
        <Select
          value={sort.id}
          onValueChange={(value) => {
            const newSorting = [...multiSorting];
            newSorting[index].id = value;
            setMultiSorting(newSorting);
          }}
        >
          <SelectTrigger className="w-full h-9">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {table
              .getAllColumns()
              .filter((column) => column.getCanSort())
              .map((column) => (
                <SelectItem key={column.id} value={column.id}>
                  {column.columnDef.header as string}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
      <Select
        value={sort.desc ? "desc" : "asc"}
        onValueChange={(value) => {
          const newSorting = [...multiSorting];
          newSorting[index].desc = value === "desc";
          setMultiSorting(newSorting);
        }}
      >
        <SelectTrigger className="h-9">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="asc">Ascending</SelectItem>
          <SelectItem value="desc">Descending</SelectItem>
        </SelectContent>
      </Select>
      <Button
        variant="ghost"
        size="sm"
        onClick={() => {
          const newSorting = multiSorting.filter((_, i) => i !== index);
          setMultiSorting(newSorting);
        }}
      >
        <XIcon className="h-4 w-4" />
      </Button>
    </li>
  );
};
