/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { UploadedFileDisplay } from "@/components";
import UploadFileModal from "@/components/files/UploadFileModal";
import { Button } from "@/components/ui/button";
import { RadioGroup, RadioGroupWithText } from "@/components/ui/radio-group";
import { useCreateProjectStore } from "@/store";
import React, { ChangeEvent, useEffect, useState } from "react";
import { CreateStepGroup, PublishedApplicationNumberInput } from ".";

interface ApplicationAddSubjectStepProps {
  startExpanded: boolean;
  onCompletionChange: (isComplete: boolean) => void;
}

/**
 * @description Add subject step in create application project
 * @param {boolean} startExpanded - Whether the step is expanded.
 */
const ApplicationAddSubjectStep: React.FC<ApplicationAddSubjectStepProps> = ({
  startExpanded,
  onCompletionChange,
}) => {
  // Global states
  const {
    files,
    patentState,
    subjectDetails,
    extractedApplicationDetails,
    setPatentState,
    updateFiles,
    updateSubjectDetails,
  } = useCreateProjectStore();
  // Local states
  const [textInput, setTextInput] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isStepComplete, setIsStepComplete] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const infoPopoverContent = "Upload the associated patent application.";

  // Upload Modal
  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleContextAddClick = (selectedFiles: File[]) => {
    if (selectedFiles && selectedFiles.length > 0) {
      // Only keep the first file if multiple are selected
      updateFiles([selectedFiles[0]]);
    }
  };

  const handleRemoveFile = () => {
    updateFiles([]);
  };

  // Unpublished patent section
  const handleCheckboxState = (checkedState) => {
    setIsChecked(checkedState);
  };

  useEffect(() => {
    // If the Office Action is removed, uncheck automatically
    if (Object.keys(extractedApplicationDetails).length === 0) {
      setIsChecked(false);
    }
  }, [extractedApplicationDetails]);

  const handleTextInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTextInput(event.target.value);
  };

  // Step reset, completion
  useEffect(() => {
    const newCompletionState =
      (patentState === "unpublished" && files.length > 0) ||
      (patentState === "published" && subjectDetails.length > 0);

    setIsStepComplete(newCompletionState);
    onCompletionChange(newCompletionState);
  }, [patentState, files, isChecked, subjectDetails, onCompletionChange]);

  const handleReset = () => {
    updateSubjectDetails([]);
    updateFiles([]);
    handleCheckboxState(false);
  };

  return (
    <CreateStepGroup
      stepName="Patent Application"
      isCompleted={isStepComplete}
      showSearchAgain={subjectDetails.length > 0}
      handleSearchAgain={handleReset}
      startExpanded={startExpanded}
      infoPopoverContent={infoPopoverContent}
    >
      <div className="space-y-4">
        <RadioGroup
          value={patentState}
          onValueChange={(value) =>
            setPatentState(value as "published" | "unpublished")
          }
          className="flex flex-wrap gap-4"
        >
          <div className="flex space-x-4">
            <RadioGroupWithText
              value="published"
              id="published"
              label="Published Application"
            ></RadioGroupWithText>
            <RadioGroupWithText
              value="unpublished"
              id="unpublished"
              label="Unpublished Application"
            ></RadioGroupWithText>
          </div>
        </RadioGroup>

        {patentState === "published" && (
          <PublishedApplicationNumberInput
            isChecked={isChecked}
            textInput={textInput}
            handleCheckboxState={handleCheckboxState}
            handleTextInputChange={handleTextInputChange}
          />
        )}

        {patentState === "unpublished" && (
          <div>
            <div className="pb-3">
              <Button onClick={handleOpenUploadModal} disabled={files.length > 0}>
                Upload File
              </Button>
            </div>
            <div>
              <UploadFileModal
                open={isUploadModalOpen}
                handleClose={handleCloseUploadModal}
                inContext={true}
                handleContextAddClick={handleContextAddClick}
              />
              {files.length > 0 && (
                <div>
                  <UploadedFileDisplay
                    file={files[0]}
                    onRemove={handleRemoveFile}
                    fullWidth={true}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </CreateStepGroup>
  );
};

export default ApplicationAddSubjectStep;
