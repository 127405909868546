/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import {
  AdminPageLayout,
  CurrentBillingDetailsCard,
  DeleteOrganizationModal,
  InvoicesTable,
  NoAccessMessage,
  OrganizationModal,
  OverviewDetailsCard,
  PlanDetailsCard,
  UsageLogTable,
  UsersTable,
} from "@/features/admin/components";
import { useAdminManagement } from "@/hooks";
import { useAdminStore, useAppStateStore, useUserStore } from "@/store";
import { Organization, RoleType } from "@/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

/**
 * @description Super admin page component
 */
function SuperAdminOrganizationDetailsPage() {
  const navigate = useNavigate();
  const {
    updateOrganization,
    deleteOrganization,
    getAdminDetailsForOrg,
    getAdminOverviewDetailsForOrgByMonth,
  } = useAdminManagement();

  // Global state from store
  const { role } = useUserStore();
  const {
    invoices,
    projects,
    plan,
    clearOrgLevelStore,
    superAdminSelectedOrganization,
  } = useAdminStore();
  const { addErrorMessage, addSuccessMessage } = useAppStateStore();

  // Local state setup
  const [showEditOrgModal, setShowEditOrgModal] = useState(false);
  const [showRemoveOrgModal, setShowRemoveOrgModal] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [selectedYear, setSelectedYear] = useState<string | null>(null);
  const [showMonthlyOverview, setShowMonthlyOverview] = useState(false);

  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = 2024; year <= currentYear; year++) {
    years.push({ value: year.toString(), label: year.toString() });
  }

  const handleConfirm = async () => {
    if (selectedMonth && selectedYear) {
      const date = `${selectedYear}-${selectedMonth}`;
      const res = await getAdminOverviewDetailsForOrgByMonth(
        superAdminSelectedOrganization.organizationId,
        date,
      );
      setShowMonthlyOverview(true);
    }
  };

  const handleReset = () => {
    setSelectedMonth(null);
    setSelectedYear(null);
    setShowMonthlyOverview(false);
  };

  const isSuperAdmin = useMemo(() => role === RoleType.OWNER, [role]);
  const updateOrg = useCallback(
    async (newOrg: Organization) => {
      if (newOrg !== superAdminSelectedOrganization) {
        const response = await updateOrganization(
          superAdminSelectedOrganization,
          newOrg,
        );
        if (!response.success) {
          addErrorMessage(response.message || "");
          return;
        } else {
          await getAdminDetailsForOrg(superAdminSelectedOrganization.organizationId);
          addSuccessMessage(
            `Organization ${superAdminSelectedOrganization.organizationName} updated successfully`,
          );
        }
      }
    },
    [updateOrganization, addErrorMessage],
  );

  const removeOrg = useCallback(
    async (confirmOrgName: string) => {
      if (superAdminSelectedOrganization.organizationName === confirmOrgName) {
        const response = await deleteOrganization(
          superAdminSelectedOrganization.organizationName,
          superAdminSelectedOrganization.organizationId,
        );
        if (!response.success) {
          addErrorMessage(response.message || "");
          setShowRemoveOrgModal(false);
          return;
        } else {
          addSuccessMessage(
            `Organization ${superAdminSelectedOrganization.organizationName} deleted.`,
          );
          clearOrgLevelStore();
          setShowRemoveOrgModal(false);
          navigate("/superadmin/organizations");
        }
      }
    },
    [
      superAdminSelectedOrganization,
      deleteOrganization,
      addErrorMessage,
      addSuccessMessage,
      clearOrgLevelStore,
      navigate,
    ],
  );

  const handleBackClick = () => {
    clearOrgLevelStore();
    navigate("/superadmin/organizations");
  };

  const [detailsFetched, setDetailsFetched] = useState(false);

  useEffect(() => {
    if (!detailsFetched && superAdminSelectedOrganization?.organizationId) {
      getAdminDetailsForOrg(superAdminSelectedOrganization.organizationId);
      setDetailsFetched(true);
    }
  }, [getAdminDetailsForOrg, superAdminSelectedOrganization, detailsFetched]);

  return (
    <AdminPageLayout isSuperAdmin={true} pageName="Super Admin - Organization Details">
      {isSuperAdmin ? (
        <div className="space-y-8 mt-2">
          {invoices === null ? (
            <Loader />
          ) : (
            <div className="space-y-4">
              <div className="flex justify-between">
                <Breadcrumb className="hidden md:flex">
                  <BreadcrumbList>
                    <BreadcrumbItem onClick={handleBackClick}>
                      <BreadcrumbLink>Organizations</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      {superAdminSelectedOrganization.organizationName}
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>
                <div className="flex gap-2">
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button variant="outline">
                        {selectedMonth
                          ? months.find((m) => m.value === selectedMonth)?.label
                          : "Select Month"}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <div className="grid grid-cols-3 gap-2">
                        {months.map((month) => (
                          <Button
                            key={month.value}
                            onClick={() => setSelectedMonth(month.value)}
                            variant="ghost"
                          >
                            {month.label}
                          </Button>
                        ))}
                      </div>
                    </PopoverContent>
                  </Popover>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button variant="outline">
                        {selectedYear
                          ? years.find((y) => y.value === selectedYear)?.label
                          : "Select Year"}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <div className="grid gap-2">
                        {years.map((year) => (
                          <Button
                            key={year.value}
                            onClick={() => setSelectedYear(year.value)}
                            variant="ghost"
                          >
                            {year.label}
                          </Button>
                        ))}
                      </div>
                    </PopoverContent>
                  </Popover>

                  <Button onClick={handleReset}>Reset</Button>
                  <Button onClick={handleConfirm}>Confirm</Button>
                  <Button variant="outline" onClick={() => setShowEditOrgModal(true)}>
                    Edit
                  </Button>
                  <Button
                    variant="destructive"
                    onClick={() => setShowRemoveOrgModal(true)}
                  >
                    Delete
                  </Button>
                </div>
              </div>

              {showMonthlyOverview ? (
                <OverviewDetailsCard monthly={true} />
              ) : (
                <OverviewDetailsCard />
              )}
              <div className="flex gap-3">
                <PlanDetailsCard />
                <CurrentBillingDetailsCard />
              </div>

              {invoices?.length > 0 && <InvoicesTable invoices={invoices} />}
              <UsersTable
                orgName={superAdminSelectedOrganization.organizationName}
                orgId={superAdminSelectedOrganization.organizationId}
              />
              {projects?.length > 0 && <UsageLogTable projects={projects} />}
            </div>
          )}
          <OrganizationModal
            open={showEditOrgModal}
            onClose={() => setShowEditOrgModal(false)}
            org={plan}
            onSave={updateOrg}
            isEdit={true}
          />
          <DeleteOrganizationModal
            open={showRemoveOrgModal}
            onClose={() => setShowRemoveOrgModal(false)}
            org={superAdminSelectedOrganization}
            removeOrg={removeOrg}
          />
        </div>
      ) : (
        <NoAccessMessage />
      )}
    </AdminPageLayout>
  );
}

export default SuperAdminOrganizationDetailsPage;
