/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, ProjectPage, UploadFilesModal } from "@/components";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { H4 } from "@/components/ui/typography";
import {
  DismissDocumentStatusModal,
  UpdateElementsModal,
} from "@/features/project/components";
import { ProcessReferencesModal } from "@/features/project/components/ProcessReferencesModal";
import { useProcessReferences, useProject, useViz } from "@/hooks";
import { useAppStateStore, useProcessStore, useProjectStore } from "@/store";
import { DocumentStatus, ElementType, Patent, ProcessType, ProjectType } from "@/types";
import { PlusIcon } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import {
  AddReferenceByNumberModal,
  DocumentsTable,
  PatchReferencesModal,
  PruneReferencesModal,
  SearchReferencesModal,
} from "./components";

/**
 * @description Reference details page for invalidity project
 */
const ReferencesPage: React.FC = () => {
  const { deleteReferencesFromProject, updateProjectDetails, getProjectReferences } =
    useProject();
  const {
    updateProjectReferenceNickname,
    updateProjectReferenceNote,
    updateDocumentStatuses,
  } = useViz();
  const { reprocessDocuments } = useProcessReferences();

  // Global state from store
  const references = useProjectStore((state) => state.currentProject.references);
  const {
    updateCurrentProject,
    currentProjectId,
    currentProject,
    currentPortfolioId,
    currentPortfolio,
  } = useProjectStore();
  const { areProcessesPending } = useProcessStore();
  const {
    addErrorMessage,
    addSuccessMessage,
    addLoadingMessage,
    removeLoadingMessage,
    isReferencesLoading,
  } = useAppStateStore();

  // Block adding references if recharting or downloading chart is in progress
  const isReferenceDisabled =
    areProcessesPending({
      types: [
        ProcessType.RECHART,
        ProcessType.GENERATE_CONTEXT,
        ProcessType.DOWNLOAD_CHART,
      ],
      projectId: currentProjectId,
    }) ||
    areProcessesPending({
      types: [ProcessType.RECHART, ProcessType.GENERATE_CONTEXT],
      portfolioId: currentPortfolioId,
    });

  // Set page name
  const pageName = currentProject.name
    ? `${currentProject.name} - References`
    : "References";

  // Local state setup
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddClaimsModal, setShowAddClaimsModal] = useState(false);
  const [showEditClaimsModal, setShowEditClaimsModal] = useState(false);
  const [showProcessReferencesModal, setShowProcessReferencesModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showPatchReferencesModal, setShowPatchReferencesModal] = useState(false);
  const [showPruneModal, setShowPruneModal] = useState(false);
  const [showDismissStatusesModal, setShowDismissStatusesModal] = useState(false);
  const [selectedReferencesToDismiss, setSelectedReferencesToDismiss] = useState<
    Patent[]
  >([]);
  const [isReferenceLoading, setIsReferenceLoading] = useState<string[]>([]);
  const [selectedReferences, setSelectedReferences] = useState<Patent[]>([]);
  const [selectedReferencesToReprocess, setSelectedReferencesToReprocess] = useState<
    Patent[]
  >([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDeleteSelectedLoading, setIsDeleteSelectedLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsReferenceLoading([]);
  }, [currentProjectId]);

  // Fetch references on every page load
  useEffect(() => {
    const loadData = async () => {
      if (currentProjectId) {
        setIsLoading(true);
        await getProjectReferences(currentProjectId);
        setIsLoading(false);
      }
    };
    loadData();
  }, [currentProjectId]);

  /**
   * @param {reference object} reference - the reference to be deleted
   * @description Removeds a reference from the project
   */
  const handleRemoveReferenceFromProject = useCallback(
    async (reference: Patent) => {
      const refId = reference.id || reference.referenceId;
      if (refId && currentProjectId) {
        const response = await deleteReferencesFromProject(currentProjectId, [refId]);
        if (!response.success) {
          addErrorMessage(`Error deleting prior art ${reference.number}`);
        }
      }
    },
    [currentProjectId, references, isReferenceLoading],
  );

  const handleSearchReferencesClick = () => {
    if (currentProject?.claims?.length > 0) {
      setShowSearchModal(true);
    } else {
      setShowAddClaimsModal(true);
    }
  };

  const handlePatchReferencesClick = () => {
    setShowPatchReferencesModal(!showPatchReferencesModal);
  };

  const handlePruneReferencesClick = () => {
    setShowPruneModal(!showPruneModal);
  };

  const handleAddPublishedPatentsClick = () => {
    setShowAddModal(!showAddModal);
  };

  const handleAddOtherDocumentsClick = () => {
    setShowUploadModal(!showUploadModal);
  };

  const handleDismissReferenceStatuses = async () => {
    const selectedIds = selectedReferencesToDismiss.map((reference) => reference.id);
    await updateDocumentStatuses(selectedIds, DocumentStatus.PROCESSED);
    setSelectedReferencesToReprocess([]);
    setSelectedReferences([]);
    setShowDismissStatusesModal(false);
  };

  const handleReprocessReferences = async () => {
    setShowProcessReferencesModal(false);
    await reprocessDocuments(selectedReferencesToReprocess, false);
    setSelectedReferencesToReprocess([]);
  };

  const handleTagUpdate = async (selectedRows: Patent[], selectedTags: string[]) => {
    if (selectedTags) {
      const referenceIds = selectedRows.map((row) => row.id);
      const updatedReferences = [...currentProject.references];

      referenceIds.forEach((id) => {
        updatedReferences.find((ref) => ref.id === id).tags = selectedTags;
      });

      updateCurrentProject({
        ...currentProject,
        references: updatedReferences,
      });

      const newTags: { [key: string]: string[] } = {};

      selectedRows.forEach((row) => {
        newTags[row.id] = selectedTags;
      });

      const response = await updateProjectDetails(currentProjectId, {
        new_tags: newTags,
      });

      if (!response.success) {
        addErrorMessage(response.message || "An error occurred while saving the tags.");
      }
    }
  };

  const handleSaveName = async (reference: Patent, newName: string) => {
    const response = await updateProjectReferenceNickname(
      currentProjectId,
      reference.id,
      newName,
    );
    if (!response.success) {
      addErrorMessage(response.message || "An error occurred while saving the name.");
    }
  };

  const handleSaveNote = async (reference: Patent, newNote: string) => {
    const response = await updateProjectReferenceNote(
      currentProjectId,
      reference.id,
      newNote,
    );
    if (!response.success) {
      addErrorMessage(response.message || "An error occurred while saving the note.");
    }
  };

  const handleReprocessClick = () => {
    setSelectedReferencesToReprocess(
      selectedReferences.filter(
        (ref) =>
          ref.status === DocumentStatus.RECHART ||
          ref.status === DocumentStatus.REPROCESS,
      ),
    );
    setShowProcessReferencesModal(true);
  };

  const handleDismissSelected = () => {
    setSelectedReferencesToDismiss(
      selectedReferences.filter(
        (ref) =>
          ref.status === DocumentStatus.RECHART ||
          ref.status === DocumentStatus.REPROCESS,
      ),
    );
    setShowDismissStatusesModal(true);
  };

  const handleRowSelection = (selectedRows: Patent[]) => {
    setSelectedReferences(selectedRows);
  };

  const handleDeleteSelected = async () => {
    setIsDeleteSelectedLoading(true);
    addLoadingMessage(
      `Removing ${selectedReferences.length} reference(s) from project`,
      "delete-reference",
    );

    const referenceIds = selectedReferences.map((reference) => reference.id);
    try {
      await deleteReferencesFromProject(currentProjectId, referenceIds);
      removeLoadingMessage("delete-reference");
      addSuccessMessage(
        `Successfully deleted ${selectedReferences.length} reference(s).`,
      );
    } catch (error) {
      removeLoadingMessage("delete-reference");
      console.error(`Error deleting reference ${referenceIds}:`, error);
      addErrorMessage(
        "An unexpected error occurred while deleting references. Please try again.",
      );
    } finally {
      setIsDeleteSelectedLoading(false);
    }
  };

  const isStandardsPortfolio = currentPortfolio?.type === ProjectType.SEP;

  return (
    <ProjectPage pageName={pageName}>
      {isLoading || isReferencesLoading ? (
        <div className="flex justify-center items-center flex-col mt-12">
          <Loader message="Loading..." />
        </div>
      ) : references?.length > 0 ? (
        <DocumentsTable
          references={references}
          onDeleteRow={handleRemoveReferenceFromProject}
          height="calc(100vh - 100px)"
          onRowSelection={handleRowSelection}
          onTagUpdate={handleTagUpdate}
          onDeleteSelected={handleDeleteSelected}
          onSaveName={handleSaveName}
          onSaveNote={handleSaveNote}
          onReprocessSelected={handleReprocessClick}
          onDismissSelected={handleDismissSelected}
          enableRowDelete={true}
          isSearch={false}
          isReference={true}
          isDeleteSelectedLoading={isDeleteSelectedLoading}
        >
          {!isStandardsPortfolio && (
            <div className="flex gap-2 ml-2">
              <Button
                variant="outline"
                onClick={handlePatchReferencesClick}
                disabled={isReferenceDisabled}
                className="h-9"
              >
                Patch
              </Button>
              {references?.length > 0 && (
                <Button
                  variant="outline"
                  onClick={handlePruneReferencesClick}
                  disabled={isReferenceDisabled}
                  className="h-9"
                >
                  Prune
                </Button>
              )}
              <Button
                variant="outline"
                onClick={handleSearchReferencesClick}
                disabled={isReferenceDisabled}
                className="h-9"
              >
                Search
              </Button>
            </div>
          )}
          <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
            <DropdownMenuTrigger asChild>
              <Button
                className="h-9"
                disabled={isReferenceDisabled}
                onClick={() => setDropdownOpen(true)}
              >
                <span className="hidden lg:inline">Add References</span>
                <PlusIcon className="w-4 h-4 md:ml-0 lg:ml-2" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onClick={() => {
                  handleAddPublishedPatentsClick();
                  setDropdownOpen(false);
                }}
              >
                Add published patents/applications
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  handleAddOtherDocumentsClick();
                  setDropdownOpen(false);
                }}
              >
                Upload other documents
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </DocumentsTable>
      ) : (
        <div className="flex justify-center items-center flex-col mt-12">
          <H4>This project has no references.</H4>

          <div className="flex gap-2 mt-3">
            <Button
              variant="outline"
              onClick={handleSearchReferencesClick}
              disabled={isReferenceDisabled}
              className="h-9"
            >
              Search
            </Button>
            <Button
              variant="outline"
              onClick={handleAddPublishedPatentsClick}
              disabled={isReferenceDisabled}
              className="h-9"
            >
              Add by Number
            </Button>
            <Button
              variant="outline"
              onClick={handleAddOtherDocumentsClick}
              disabled={isReferenceDisabled}
              className="h-9"
            >
              Upload Files
            </Button>
          </div>
        </div>
      )}

      <AddReferenceByNumberModal
        open={showAddModal}
        handleClose={handleAddPublishedPatentsClick}
        general={false}
      />
      <SearchReferencesModal
        open={showSearchModal}
        handleClose={() => setShowSearchModal(false)}
      />
      <UploadFilesModal
        open={showUploadModal}
        handleClose={handleAddOtherDocumentsClick}
      />
      <PatchReferencesModal
        open={showPatchReferencesModal}
        handleClose={handlePatchReferencesClick}
      />
      <PruneReferencesModal open={showPruneModal} onOpenChange={setShowPruneModal} />

      <Dialog open={showAddClaimsModal} onOpenChange={setShowAddClaimsModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add claims to search</DialogTitle>
            <DialogDescription>
              You must add claims to search for prior art.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowAddClaimsModal(false)}>
              Cancel
            </Button>
            <Button
              variant="default"
              onClick={() => {
                setShowAddClaimsModal(false);
                setShowEditClaimsModal(true);
              }}
            >
              Add Claims
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <UpdateElementsModal
        open={showEditClaimsModal}
        onClose={() => setShowEditClaimsModal(false)}
        itemType={ElementType.CLAIM}
      />
      <ProcessReferencesModal
        isOpen={showProcessReferencesModal}
        onOpenChange={setShowProcessReferencesModal}
        onConfirm={handleReprocessReferences}
        documentNames={selectedReferencesToReprocess
          .filter(
            (reference) =>
              reference.status === DocumentStatus.RECHART ||
              reference.status === DocumentStatus.REPROCESS,
          )
          .map(
            (reference) =>
              currentProject.documentsToNicknames[reference.id] ||
              reference.name ||
              reference.title,
          )}
        actions={selectedReferencesToReprocess.map((reference) => reference.status)}
      />
      <DismissDocumentStatusModal
        isOpen={showDismissStatusesModal}
        onOpenChange={setShowDismissStatusesModal}
        onConfirm={handleDismissReferenceStatuses}
        documentNames={selectedReferencesToDismiss
          .filter(
            (reference) =>
              reference.status === DocumentStatus.RECHART ||
              reference.status === DocumentStatus.REPROCESS,
          )
          .map(
            (reference) =>
              currentProject.documentsToNicknames[reference.id] ||
              reference.name ||
              reference.title,
          )}
      />
    </ProjectPage>
  );
};

export default ReferencesPage;
