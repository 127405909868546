import { Button } from "@/components/ui/button";
import { ClaimStatusReason } from "@/types/project";
import { memo, useMemo } from "react";

interface CitedArtContentProps {
  status: string;
  reasons: ClaimStatusReason[];
  selectedReason: ClaimStatusReason | null;
  onToggleVisibility: (e: React.MouseEvent) => void;
  isSectionVisible: boolean;
}

export const CitedArtContent = memo(
  ({
    status,
    reasons,
    selectedReason,
    onToggleVisibility,
    isSectionVisible,
  }: CitedArtContentProps) => {
    const content = useMemo(() => {
      let references = "-";

      if (status.toLowerCase() === "objected") {
        const allNicknames = reasons
          .flatMap((reason) => reason.referenceCitations)
          .map((citation) => citation.referenceNickname);
        references = Array.from(new Set(allNicknames)).join(", ") || "-";
      } else if (status.toLowerCase() === "rejected") {
        const selectedNicknames =
          selectedReason?.referenceCitations?.map(
            (citation) => citation.referenceNickname,
          ) || [];
        references = Array.from(new Set(selectedNicknames)).join(", ") || "-";
      }

      return references;
    }, [status, reasons, selectedReason]);

    if (content === "-") {
      return <span>{content}</span>;
    }

    return (
      <div className="flex items-center gap-2">
        <span>{content}</span>
        <Button
          variant="outline"
          className="h-6 px-2 text-sm"
          onClick={onToggleVisibility}
          size="sm"
        >
          {isSectionVisible ? "Hide citations" : "Show citations"}
        </Button>
      </div>
    );
  },
);

CitedArtContent.displayName = "CitedArtContent";
