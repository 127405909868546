/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Paragraph } from "@/components/ui/typography";
import React from "react";
import { Spinner } from "../ui/spinner";

interface LoaderProps {
  message?: string;
}

/**
 * @description Spinning loader component
 */
const Loader: React.FC<LoaderProps> = ({ message }) => {
  return (
    <div
      className="flex flex-col items-center justify-center m-2 mt-12"
      aria-busy="true"
      aria-live="polite"
      role="alert"
    >
      <Spinner />
      <Paragraph className="mt-1 text-sm">
        {message === undefined || message === null ? "" : message}
      </Paragraph>
    </div>
  );
};

export default Loader;
