/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { CreateStepGroup, PatentNumbersInputCreate } from ".";
import { useCreateProjectStore } from "@/store";

/**
 * @description Invalidity subject step displayed in create project flow
 * @param {boolean} isCompleted - Whether the step is completed.
 * @param {boolean} startExpanded - Whether the step is expanded.
 */
const InvaliditySubjectStep: React.FC<{
  isCompleted: boolean;
  startExpanded?: boolean;
}> = ({ startExpanded }) => {
  const {
    updateSubjectDetails,
    subjectDetails,
    updateSubjectMode,
    updateSubjectNumbers,
    subjectNumbers,
  } = useCreateProjectStore();
  const sourceContent =
    "Published patent/application and claim language entry will yield both feature and claim charts upon project creation. Feature language entry will only yield feature charts upon project creation. You can add claims later to populate claim charts.";

  const handleReset = () => {
    updateSubjectDetails([]);
    updateSubjectNumbers([]);
    updateSubjectMode("patent");
  };

  return (
    <CreateStepGroup
      stepName="Subject"
      isCompleted={subjectNumbers.length > 0}
      showSearchAgain={subjectDetails.length > 0}
      handleSearchAgain={handleReset}
      startExpanded={startExpanded}
      infoPopoverContent={sourceContent}
    >
      <div className="mt-4">
        <PatentNumbersInputCreate isInvalidity={true} />
      </div>
    </CreateStepGroup>
  );
};

export default InvaliditySubjectStep;
