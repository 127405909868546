import { create } from "zustand";
import { Process, ProcessType } from "@/types";

type ProcessStore = {
  processes: Process[];
  addProcess: (process: Omit<Process, "status">) => void;
  updateProcess: (id: string, status: Process["status"]) => void;
  removeProcess: (id: string) => void;
  isProcessPending: ({
    type,
    portfolioId,
    projectId,
  }: {
    type: ProcessType;
    portfolioId?: string;
    projectId?: string;
  }) => boolean;
  areProcessesPending: ({
    types,
    portfolioId,
    projectId,
  }: {
    types: ProcessType[];
    portfolioId?: string;
    projectId?: string;
  }) => boolean;
};

export const useProcessStore = create<ProcessStore>((set, get) => ({
  processes: [],
  addProcess: (process) =>
    set((state) => ({
      processes: [...state.processes, { ...process, status: "pending" }],
    })),
  updateProcess: (id, status) =>
    set((state) => ({
      processes: state.processes.map((p) => (p.id === id ? { ...p, status } : p)),
    })),
  removeProcess: (id) =>
    set((state) => ({
      processes: state.processes.filter((p) => p.id !== id),
    })),

  isProcessPending: ({
    type,
    portfolioId,
    projectId,
  }: {
    type: ProcessType;
    portfolioId?: string;
    projectId?: string;
  }) => {
    return get().processes.some(
      (p) =>
        p.type === type &&
        p.status === "pending" &&
        ((portfolioId && p.portfolioId === portfolioId) ||
          (projectId && p.projectId === projectId)),
    );
  },
  areProcessesPending: ({
    types,
    portfolioId,
    projectId,
  }: {
    types: ProcessType[];
    portfolioId?: string;
    projectId?: string;
  }) => {
    return get().processes.some(
      (p) =>
        types.includes(p.type as ProcessType) &&
        p.status === "pending" &&
        ((portfolioId && p.portfolioId === portfolioId) ||
          (projectId && p.projectId === projectId)),
    );
  },
}));

export default useProcessStore;
