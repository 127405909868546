/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCellCondensed,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { H4 } from "@/components/ui/typography";
import { useAdminManagement } from "@/hooks";
import { useAdminStore, useAppStateStore } from "@/store";
import { User } from "@/types";
import {
  CheckCircledIcon,
  CheckIcon,
  CrossCircledIcon,
  Pencil1Icon,
  PlusIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import { useState } from "react";
import {
  ActivateUserModal,
  AddUserModal,
  DeactivateUserModal,
  DeleteUserModal,
} from ".";

interface UsersTableProps {
  orgName: string;
  orgId: string;
}

/**
 * @description Table to display and manage users within an organization.
 * @param {string} orgName - Name of the organization to display users for.
 */
function UsersTable({ orgName, orgId }: UsersTableProps) {
  const {
    getAdminDetailsForOrg,
    deleteUser,
    updateUserRole,
    disableUser,
    enableUser,
    getUsers,
  } = useAdminManagement();
  const { users, updateUsers } = useAdminStore();

  // Global state from store
  const { addErrorMessage, addSuccessMessage } = useAppStateStore((state) => state);

  // Local state setup
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isDeactivateModalOpen, setDeactivateModalOpen] = useState<boolean>(false);
  const [isActivateModalOpen, setActivateModalOpen] = useState<boolean>(false);
  const [isAddUserModalOpen, setAddUserModalOpen] = useState<boolean>(false);
  const [isEditingRole, setIsEditingRole] = useState<string>("");
  const [newRole, setNewRole] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [error, setError] = useState<boolean>(false);

  /**
   * @description Handles the role change for a user
   * @param {string} email - The email address of the user to change the role for.
   */
  const handleRoleChange = async (user_id: string, email: string) => {
    const response = await updateUserRole(user_id, newRole, orgId);
    if (!response.success) {
      addErrorMessage(response.message || "Error changing user role");
      return;
    }

    // Update the users state with the new role
    const updatedUsers = users.map((user) => {
      if (user.id === user_id) {
        return { ...user, role: newRole };
      }
      return user;
    });
    updateUsers(updatedUsers);

    setNewRole("");
    setIsEditingRole("");
    addSuccessMessage("Role updated to " + newRole + " for " + email);
  };

  const handleDeleteUser = async (user: User) => {
    const response = await deleteUser(user.id, orgId);
    if (!response.success) {
      addErrorMessage(response.message || "Error deleting user");
      return;
    }
    addSuccessMessage("User deleted: " + user.email);
  };

  // Deactivates a user
  const removeUser = async (userEmail: string, userId: string) => {
    const response = await disableUser(userId, userEmail);
    if (response.success) {
      // Update user list and set status to "Disabled"
      const updatedUsers = users.map((user) => {
        if (user.id === userId) {
          return { ...user, enabled: false };
        }
        return user;
      });
      updateUsers(updatedUsers);
      addSuccessMessage("User deactivated: " + userEmail);
    } else {
      addErrorMessage("Error deactivating user: " + userEmail);
    }
  };

  // Activates a user
  const activateUser = async (userEmail: string, userId: string) => {
    const response = await enableUser(userId, userEmail);
    if (response.success) {
      // Update user list and set status to "Active"
      const updatedUsers = users.map((user) => {
        if (user.email === userEmail) {
          return { ...user, status: "Active" };
        }
        return user;
      });
      updateUsers(updatedUsers);
      addSuccessMessage("User activated: " + userEmail);
    } else {
      addErrorMessage("Error activating user: " + userEmail);
    }
  };

  const handleDisable = async (user: User) => {
    setSelectedUser(user);
    setDeactivateModalOpen(true);
  };

  const handleDelete = async (user: User) => {
    setSelectedUser(user);
    setDeleteModalOpen(true);
  };

  const handleActivate = async (user: User) => {
    setSelectedUser(user);
    setActivateModalOpen(true);
  };

  const handleAddUser = () => {
    setAddUserModalOpen(true);
  };

  const formatTimestamp = (timestamp: number | string | null): string => {
    if (!timestamp) return "N/A";
    const date = new Date(Number(timestamp) * 1000);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      timeZoneName: "short",
    };

    if (date.toDateString() === today.toDateString()) {
      return `Today, ${date.toLocaleTimeString(undefined, timeOptions)}`;
    } else if (date.toDateString() === yesterday.toDateString()) {
      return `Yesterday, ${date.toLocaleTimeString(undefined, timeOptions)}`;
    } else {
      return date.toLocaleString(undefined, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZoneName: "short",
      });
    }
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between">
          <H4>Users</H4>
          <div className="flex justify-end">
            <Button size="icon" onClick={handleAddUser}>
              <PlusIcon />
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div>
          {users === null ? (
            <Loader />
          ) : (
            <>
              {!error && users && users.length > 0 && (
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Email</TableHead>
                      <TableHead>Role</TableHead>
                      <TableHead>Status</TableHead>
                      <TableHead>Created At</TableHead>
                      <TableHead>Last Active At</TableHead>
                      <TableHead className="flex justify-end"></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow key={user.email}>
                        <TableCellCondensed>{user.email}</TableCellCondensed>
                        <TableCellCondensed>
                          {isEditingRole === user.email ? (
                            <Select
                              value={newRole}
                              onValueChange={(value) => setNewRole(value)}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select role" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Admin">Admin</SelectItem>
                                <SelectItem value="User">User</SelectItem>
                              </SelectContent>
                            </Select>
                          ) : (
                            user.role
                          )}
                        </TableCellCondensed>
                        <TableCellCondensed>
                          {!user.confirmed
                            ? "Pending email confirmation"
                            : user.enabled
                              ? "Active"
                              : "Disabled"}
                        </TableCellCondensed>
                        <TableCellCondensed>
                          {formatTimestamp(user.createdAt)}
                        </TableCellCondensed>
                        <TableCellCondensed>
                          {formatTimestamp(user.lastActiveAt)}
                        </TableCellCondensed>
                        <TableCellCondensed className="flex justify-end">
                          <div className="flex gap-1">
                            {isEditingRole === user.email ? (
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    size="icon"
                                    variant="outline"
                                    onClick={() =>
                                      handleRoleChange(user.id, user.email)
                                    }
                                  >
                                    <CheckIcon />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <p>Confirm role change</p>
                                </TooltipContent>
                              </Tooltip>
                            ) : (
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    size="icon"
                                    variant="outline"
                                    onClick={() => {
                                      setIsEditingRole(user.email);
                                      setNewRole(user.role);
                                    }}
                                  >
                                    <Pencil1Icon />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <p>Edit user role</p>
                                </TooltipContent>
                              </Tooltip>
                            )}
                            {user.enabled ? (
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    variant="outline"
                                    size="icon"
                                    onClick={() => handleDisable(user)}
                                  >
                                    <CrossCircledIcon />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <p>Deactivate user</p>
                                </TooltipContent>
                              </Tooltip>
                            ) : (
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    variant="outline"
                                    size="icon"
                                    onClick={() => handleActivate(user)}
                                  >
                                    <CheckCircledIcon />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <p>Activate user</p>
                                </TooltipContent>
                              </Tooltip>
                            )}
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  variant="outline"
                                  size="icon"
                                  onClick={() => handleDelete(user)}
                                >
                                  <TrashIcon />
                                </Button>
                              </TooltipTrigger>
                            </Tooltip>
                          </div>
                        </TableCellCondensed>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}

              {error && (
                <p className="text-red-500">
                  Error fetching users. Please try again later.
                </p>
              )}
            </>
          )}
        </div>
      </CardContent>
      <AddUserModal
        open={isAddUserModalOpen}
        onClose={() => setAddUserModalOpen(false)}
        users={users}
        orgName={orgName}
        orgId={orgId}
      />
      <DeleteUserModal
        open={isDeleteModalOpen}
        userEmail={selectedUser?.email || ""}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={() => {
          if (selectedUser) {
            handleDeleteUser(selectedUser);
          }
        }}
      />
      <DeactivateUserModal
        open={isDeactivateModalOpen}
        userEmail={selectedUser?.email || ""}
        onClose={() => setDeactivateModalOpen(false)}
        onDeactivate={() => {
          if (selectedUser) {
            removeUser(selectedUser.email, selectedUser.id);
            setDeactivateModalOpen(false);
          }
        }}
      />
      <ActivateUserModal
        open={isActivateModalOpen}
        userEmail={selectedUser?.email || ""}
        onClose={() => setActivateModalOpen(false)}
        onActivate={() => {
          if (selectedUser) {
            activateUser(selectedUser.email, selectedUser.id);
            setActivateModalOpen(false);
          }
        }}
      />
    </Card>
  );
}

export default UsersTable;
