/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React from "react";
import { CreateStepGroup } from ".";
import { InfoPopover } from "@/components";
import { useCreateProjectStore } from "@/store";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

/**
 * @description Project details step displayed in create project flow
 * @param {boolean} isPortfolio - Whether the project is a portfolio.
 * @param {boolean} startExpanded - Whether the step is expanded.
 */
const ProjectDetailsStep: React.FC<{
  isPortfolio?: boolean;
  startExpanded?: boolean;
}> = ({ isPortfolio, startExpanded }) => {
  const {
    projectName,
    updateProjectName,
    clientNumber,
    updateClientNumber,
    isProjectCreationInProgress,
  } = useCreateProjectStore();

  const clientNumberContent =
    "Adding a client matter ID will add this project or portfolio to a client-specific invoice provided for ease of billing. The same client matter ID must be used across the organization for successful invoice creation.";

  return (
    <CreateStepGroup
      stepName={isPortfolio ? "Portfolio Details" : "Project Details"}
      isCompleted={!!projectName}
      startExpanded={startExpanded}
    >
      <div className="flex flex-row gap-4">
        <div className="flex-1">
          <Label htmlFor="name">
            {" "}
            {isPortfolio ? "Portfolio Name" : "Project Name"}
          </Label>
          <Input
            id="project-name"
            className="mt-1"
            placeholder="Required"
            disabled={isProjectCreationInProgress}
            value={projectName || ""}
            onChange={(e) => updateProjectName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          />
        </div>

        <div className="flex-1">
          <div className="flex items-center justify-start">
            <Label htmlFor="client-number" className="mr-1 flex items-center">
              Client Matter ID
              <InfoPopover content={clientNumberContent} className="ml-1" />
            </Label>
          </div>
          <Input
            id="client-number"
            className="mt-1"
            placeholder="Optional"
            disabled={isProjectCreationInProgress}
            value={
              isProjectCreationInProgress && clientNumber === "" ? "None" : clientNumber
            }
            onChange={(e) => updateClientNumber(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          />
        </div>
      </div>
    </CreateStepGroup>
  );
};

export default ProjectDetailsStep;
