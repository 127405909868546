/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useAppStateStore,
  useUserStore,
  useProjectStore,
  useAdminStore,
} from "@/store";
import { useViz } from "@/hooks";
import { Button } from "@/components/ui/button";
import {
  HomeIcon,
  MagnifyingGlassIcon,
  PersonIcon,
  LockClosedIcon,
  MoonIcon,
  SunIcon,
  FileIcon,
  QuestionMarkCircledIcon,
} from "@radix-ui/react-icons";
import ProfileMenu from "./ProfileMenu";
import { RoleType } from "@/types";
import SidebarMenuItem from "./SidebarMenuItem";
import { useIntercom } from "@/hooks/useIntercom";
import { useTheme } from "@/styled/ThemeProvider";
import blueLogoImage from "@/assets/logomark_lightblue.png";
import navyLogoImage from "@/assets/logomark_navy.png";
/**
 * @description Sidebar component
 */
const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getSearchChatProjectId } = useViz();
  const { showLauncher, hideLauncher } = useIntercom();
  const { clearStore } = useAdminStore();

  useEffect(() => {
    hideLauncher(); // Hide the default launcher
    return () => showLauncher(); // Show the default launcher when component unmounts
  }, []);

  const handleIntercomClick = () => {
    const w = window as any;
    if (typeof w.Intercom === "function") {
      w.Intercom("show");
    }
  };

  // Global state from store
  const { theme, setTheme } = useTheme();
  const { updateCurrentProjectId, updateCurrentProject, updateCurrentPortfolioId } =
    useProjectStore();
  const { searchChatProjectId, updateSearchChatProjectId } = useAppStateStore();
  const { role } = useUserStore();

  // Check if the current pathname matches the path
  const isHomeActive = location.pathname === "/home";
  const isAdminActive = location.pathname.includes("/admin");
  const isSuperAdminActive = location.pathname.includes("/superadmin");
  const isExploreActive = location.pathname.includes("/explore");
  const isFilesActive = location.pathname.includes("/files");

  // Handle files click
  const handleFilesClick = () => {
    clearStore();
    if (
      location.pathname.includes("project") ||
      location.pathname.includes("explore")
    ) {
      updateCurrentProjectId("");
      updateCurrentPortfolioId("");
      navigate("/files");
    } else {
      navigate("/files");
    }
  };

  // Handle home click
  const handleHomeClick = () => {
    clearStore();
    if (
      location.pathname.includes("project") ||
      location.pathname.includes("explore")
    ) {
      updateCurrentProjectId("");
      updateCurrentPortfolioId("");
      navigate("/home");
    } else {
      navigate("/home");
    }
  };

  // Handle admin click
  const handleAdminClick = () => {
    clearStore();
    if (
      location.pathname.includes("project") ||
      location.pathname.includes("explore")
    ) {
      updateCurrentProjectId("");
      updateCurrentPortfolioId("");
      navigate("/admin");
    } else {
      navigate("/admin");
    }
  };

  // Handle explore click
  const handleExploreClick = async () => {
    clearStore();
    if (!location.pathname.includes("/explore")) {
      if (!searchChatProjectId) {
        const response = await getSearchChatProjectId();
        updateSearchChatProjectId(response.data.id);
        updateCurrentProjectId(response.data.id);
      } else {
        updateCurrentProjectId(searchChatProjectId);
      }
      updateCurrentProject({
        id: searchChatProjectId,
        name: "",
      });
      navigate("/explore");
    } else {
      navigate("/explore");
    }
  };

  // Handle super admin click
  const handleSuperAdminClick = () => {
    clearStore();
    if (
      location.pathname.includes("project") ||
      location.pathname.includes("explore")
    ) {
      updateCurrentProjectId("");
      updateCurrentPortfolioId("");
      navigate("/superadmin");
    } else {
      navigate("/superadmin");
    }
  };

  const handleDarkMode = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  return (
    <div className="flex flex-col h-screen bg-background border-r w-20">
      <div
        className={`px-2 py-4 flex items-center justify-center cursor-pointer`}
        onClick={handleHomeClick}
      >
        <img
          src={theme === "light" ? navyLogoImage : blueLogoImage}
          alt="AndAI Logo"
          className="h-10 w-10"
        />
      </div>

      <div className="flex-grow space-y-2 p-2">
        <div className="flex flex-col items-center gap-2">
          <SidebarMenuItem
            icon={HomeIcon}
            onClick={handleHomeClick}
            isActive={isHomeActive}
            label="Home"
          />

          <SidebarMenuItem
            icon={MagnifyingGlassIcon}
            onClick={handleExploreClick}
            isActive={isExploreActive}
            label="Explore"
          />

          <SidebarMenuItem
            icon={FileIcon}
            onClick={handleFilesClick}
            isActive={isFilesActive}
            label="Files"
          />

          {(role === RoleType.ADMIN || role === RoleType.OWNER) && (
            <SidebarMenuItem
              icon={PersonIcon}
              onClick={handleAdminClick}
              isActive={isAdminActive}
              label="Admin"
            />
          )}
          {role === RoleType.OWNER && (
            <SidebarMenuItem
              icon={LockClosedIcon}
              onClick={handleSuperAdminClick}
              isActive={isSuperAdminActive}
              label="Super Admin"
            />
          )}
        </div>
      </div>

      <div className="flex justify-center p-4">
        <div className="flex flex-col items-center gap-2">
          <Button variant="ghost" size="icon" onClick={handleDarkMode}>
            {theme === "light" ? (
              <MoonIcon className="h-5 w-5" />
            ) : (
              <SunIcon className="h-5 w-5" />
            )}
          </Button>

          <Button variant="ghost" size="icon" onClick={handleIntercomClick}>
            <QuestionMarkCircledIcon className="h-5 w-5" />
          </Button>
        </div>
      </div>

      <div className="m-auto px-2 py-4">
        <ProfileMenu />
      </div>
    </div>
  );
};

export default Sidebar;
