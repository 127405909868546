import { useMemo } from "react";
import { useFeedbackChartDataStore } from "./useFeedbackChartDataStore";

export const useFeedbackData = (
  projectId: string,
  referenceId: string,
  claimId: string,
) => {
  const feedbackChartData = useFeedbackChartDataStore(
    (state) => state.feedbackChartData,
  );

  return useMemo(() => {
    const citationData = feedbackChartData[projectId]?.[referenceId]?.[claimId] || [];
    return {
      positiveFeedbackData: citationData.filter((c) => c.feedback === "positive"),
      negativeFeedbackData: citationData.filter((c) => c.feedback === "negative"),
    };
  }, [feedbackChartData, projectId, referenceId, claimId]);
};

export const useClearFeedbackDataByID = () => {
  const clearFeedbackChartData = useFeedbackChartDataStore(
    (state) => state.clearFeedbackChartDataByID,
  );
  return clearFeedbackChartData;
};
