/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useEffect } from "react";
import { useAuthInfo } from "@propelauth/react";
import { useAdminManagement } from "@/hooks";
import { useUserStore } from "@/store";

interface IntercomSettings {
  api_base: string;
  app_id: string;
  name: string;
  email: string;
  user_hash: string;
}

/**
 * @description Hook to handle the Intercom setup
 */
export const useIntercom = () => {
  const { getUserHash } = useAdminManagement();
  const { user } = useAuthInfo();
  const { userHash, updateUserHash } = useUserStore();

  const userEmail = user?.email || "";
  const firstName = user?.firstName || user?.email?.split("@")[0] || "";
  const name = firstName ? firstName[0].toUpperCase() + firstName.slice(1) : "";

  useEffect(() => {
    if (user && !userHash) {
      fetchUserHash();
    }
  }, [user, userHash]);

  const showLauncher = () => {
    const w = window as any;
    if (typeof w.Intercom === "function") {
      w.Intercom("update", { hide_default_launcher: false });
    }
  };

  const hideLauncher = () => {
    const w = window as any;
    if (typeof w.Intercom === "function") {
      w.Intercom("update", { hide_default_launcher: true });
    }
  };

  const fetchUserHash = async () => {
    try {
      const response = await getUserHash();
      updateUserHash(response.data.user_hash);
    } catch (error) {
      console.error("Failed to fetch user hash", error);
    }
  };

  useEffect(() => {
    if (!userEmail || !userHash) return;

    const intercomSettings: IntercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "vftzsoeb",
      name: name,
      email: userEmail,
      user_hash: userHash,
    };

    const w = window as any;
    if (typeof w.Intercom === "function") {
      w.Intercom("update", intercomSettings);
    } else {
      w.intercomSettings = intercomSettings;
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://widget.intercom.io/widget/vftzsoeb";
      document.body.appendChild(script);
    }

    return () => {
      if (typeof w.Intercom === "function") {
        w.Intercom("shutdown");
      }
    };
  }, [userHash, userEmail, name]);

  return { showLauncher, hideLauncher };
};
