import React from "react";
import styled from "styled-components";

interface EvaluationData {
  claim: string;
  claimLanguage: string;
  modelReferences: number[];
  pdfReferences: number[];
  missingReferences: number[];
  incorrectReferences: number[];
  accuracy: number;
  precision: number;
  recall: number;
}

interface EvaluationResultsProps {
  data: Array<EvaluationData> | null;
  onClose: () => void;
}

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #f2f2f2;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`;

const CloseButton = styled.button`
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  float: right;
`;

const EvaluationResults: React.FC<EvaluationResultsProps> = ({ data, onClose }) => {
  if (!data) return null;

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <Th>Claim</Th>
            <Th>Claim Language</Th>
            <Th>Model References</Th>
            <Th>PDF References</Th>
            <Th>Missing References</Th>
            <Th>Incorrect References</Th>
            <Th>Accuracy</Th>
            <Th>Precision</Th>
            <Th>Recall</Th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <Td>{item.claim}</Td>
              <Td>{item.claimLanguage}</Td>
              <Td>{item.modelReferences.join(", ")}</Td>
              <Td>{item.pdfReferences.join(", ")}</Td>
              <Td>{item.missingReferences.join(", ")}</Td>
              <Td>{item.incorrectReferences.join(", ")}</Td>
              <Td>{item.accuracy.toFixed(2)}</Td>
              <Td>{item.precision.toFixed(2)}</Td>
              <Td>{item.recall.toFixed(2)}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
      <CloseButton onClick={onClose} style={{ float: "left" }}>
        Close
      </CloseButton>
    </div>
  );
};

export default EvaluationResults;
