/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import {
  ApplicationAddSubjectStep,
  ApplicationOfficeActionStep,
  CreateProjectPageLayout,
  ProjectDetailsStep,
} from "@/features/project/create/components";
import { useCreateProject } from "@/hooks";
import { useCreateProjectStore, useProjectStore } from "@/store";
import { ProcessType, ProjectType } from "@/types";
import React, { useEffect, useState } from "react";

/**
 * @description Renders the create application project page
 */
const CreateApplicationProjectPage: React.FC = () => {
  const { createOfficeActionParse, createProjectAfterOfficeActionProcess } =
    useCreateProject();
  // Global state from store
  const {
    isProjectCreationInProgress,
    projectName,
    supportingDocuments,
    processes,
    subjectNumbers,
    updateSpinnerText,
    resetCreateProjectStore,
    updateIsProjectCreationInProgress,
    updateProjectError,
    removeProcesses,
  } = useCreateProjectStore();

  const { currentProjectId } = useProjectStore();

  // const { generatePatent } = useLlm();

  // Local state setup
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [canCreateProject, setCanCreateProject] = useState<boolean>(false);
  const [isSubjectCompleted, setIsSubjectCompleted] = useState<boolean>(false);
  const [isDetailsCompleted, setIsDetailsCompleted] = useState<boolean>(false);
  const [isOfficeActionCompleted, setIsOfficeActionCompleted] =
    useState<boolean>(false);
  const [isOfficeActionExpanded, setIsOfficeActionExpanded] = useState<boolean>(false);
  const [isAddSubjectExpanded, setIsAddSubjectExpanded] = useState<boolean>(false);
  // const [patentNotFound, setPatentNotFound] = useState<boolean>(false);

  // Reset state on mount if create not in progress
  useEffect(() => {
    if (!isProjectCreationInProgress) {
      resetCreateProjectStore();
    }
  }, []);

  // useEffect(() => {
  // if (
  // (subjectMode === "claim" &&
  //   claims.length > 0 &&
  //   JSON.stringify(claims[0]) !== JSON.stringify({ "1": "" })) ||
  // (subjectMode === "feature" &&
  //   features.length > 0 &&
  //   JSON.stringify(features[0]) !== JSON.stringify({ "1": "" })) ||
  // (subjectMode === "disclosure" && disclosure_files.length > 0) // Updated condition
  // ) {
  //   setIsSubjectCompleted(true);
  // } else {
  //   setIsSubjectCompleted(false);
  // }
  // }, []);

  // useEffect(() => {
  //   const handleFileUpload = async () => {
  //     if (uploadedFile && subjectMode === "file") {
  //       try {
  //         const response = await generatePatent(uploadedFile);
  //         if ("data" in response) {
  //           updateClaims(response.data.claims);
  //           // If you have a function to update abstract in your store, uncomment the next line
  //           // updateAbstract(response.abstract);
  //           setIsSubjectCompleted(true);
  //         } else {
  //           console.error("Unexpected response format from generatePatent");
  //           setIsSubjectCompleted(false);
  //         }
  //       } catch (error) {
  //         console.error("Error generating patent from file:", error);
  //         setIsSubjectCompleted(false);
  //       }
  //     }
  //   };

  //   handleFileUpload();
  // }, [uploadedFile, subjectMode, generatePatent, updateClaims]);

  // Step completion
  useEffect(() => {
    if (projectName !== "") {
      setIsDetailsCompleted(true);
    }
  }, [projectName]);

  // Trigger office action parsing when OA is uploaded and not yet parsed
  useEffect(() => {
    if (isOfficeActionCompleted) {
      const officeActionDoc = supportingDocuments.find(
        (doc) => doc.documentType === "OFFICE_ACTION",
      );

      if (officeActionDoc) {
        // Check if there's an existing process for this document
        const existingProcess = processes.find(
          (process) =>
            process.id === officeActionDoc.fileName &&
            process.type === ProcessType.ADD_OFFICE_ACTION,
        );

        if (!existingProcess) {
          createOfficeActionParse().catch((error) => {
            console.error("Error processing office action:", error);
          });
        }
      }
    }
  }, [
    isOfficeActionCompleted,
    supportingDocuments,
    processes,
    createOfficeActionParse,
  ]);

  // Create project when all steps are completed
  useEffect(() => {
    setCanCreateProject(
      isDetailsCompleted && isSubjectCompleted && isOfficeActionCompleted,
    );
  }, [isDetailsCompleted, isSubjectCompleted, isOfficeActionCompleted]);

  const startProjectCreation = () => {
    setIsConfirmed(true);
    updateIsProjectCreationInProgress(true);
    updateSpinnerText("Creating project...");
  };

  useEffect(() => {
    if (isProjectCreationInProgress) {
      const erroredOAProcess = processes.some(
        (process) =>
          process.type === ProcessType.ADD_OFFICE_ACTION && process.status === "error",
      );

      const completedOAProcess = processes.some(
        (process) =>
          process.type === ProcessType.ADD_OFFICE_ACTION &&
          process.status === "completed",
      );

      if (erroredOAProcess) {
        updateIsProjectCreationInProgress(false);
        setIsConfirmed(false);
        setIsOfficeActionExpanded(true);
      }

      // Wait for OA parsing to finish before generating project
      if (completedOAProcess) {
        (async () => {
          try {
            await createProjectAfterOfficeActionProcess(ProjectType.APP);
          } catch (error) {
            updateProjectError(true);
          } finally {
            updateIsProjectCreationInProgress(false);
          }
        })();
      }
    }
  }, [isProjectCreationInProgress, processes]);

  return (
    <CreateProjectPageLayout
      isConfirmed={isConfirmed}
      resetState={resetCreateProjectStore}
      disabled={!canCreateProject}
      handleCreate={startProjectCreation}
      isPortfolio={false}
      title="New Office Action Project"
    >
      {/* Project details */}
      <ProjectDetailsStep startExpanded={true} isPortfolio={false} />

      {/* Office action */}
      <ApplicationOfficeActionStep
        startExpanded={isOfficeActionExpanded}
        onCompletionChange={setIsOfficeActionCompleted}
      />

      {/* Patent application */}
      <ApplicationAddSubjectStep
        startExpanded={false}
        onCompletionChange={setIsSubjectCompleted}
      />
    </CreateProjectPageLayout>
  );
};

export default CreateApplicationProjectPage;
