/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DragAndDropFileArea, UploadedFileDisplay } from "@/components";
import { useCreateProjectStore } from "@/store";
import React, { useState } from "react";
import { CreateStepGroup } from ".";

/**
 * @description Portfolio patents step displayed in create project flow
 * @param {function} handleSearchAgain - Function to handle the search again button click.
 * @param {function} setInputNumbers - Function to set the input numbers.
 * @param {string[]} inputNumbers - The list of input numbers.
 * @param {boolean} isCompleted - Whether the step is completed.
 */
const TechnicalSpecsStep: React.FC<{
  uploadedFiles: File[];
  setUploadedFiles: (files: File[]) => void;
}> = ({ uploadedFiles, setUploadedFiles }) => {
  const [findClicked, setFindClicked] = useState(false);
  const {
    subjectNumbers,
    subjectMode,
    updateSubjectDetails,
    updateSubjectMode,
    updateSubjectNumbers,
  } = useCreateProjectStore();

  const handleRemoveFile = (file: File) => {
    setUploadedFiles(uploadedFiles.filter((f) => f !== file));
  };

  const handleFileChange = (selectedFiles: File[]) => {
    if (selectedFiles && selectedFiles.length > 0) {
      const newValidFiles: File[] = [];
      let errorMessage = "";

      for (let selectedFile of selectedFiles) {
        const isFileTypeValid =
          selectedFile.type === "application/pdf" || selectedFile.type === "text/plain";

        if (isFileTypeValid) {
          newValidFiles.push(selectedFile);
        } else {
          errorMessage += `${selectedFile.name}: File type is not supported. Please upload a PDF or text file. `;
        }
      }
      setUploadedFiles([...uploadedFiles, ...newValidFiles]);
    }
  };

  return (
    <CreateStepGroup
      stepName="Technical Specifications"
      isCompleted={uploadedFiles?.length > 0}
    >
      <div>
        <div className="flex flex-row gap-2">
          {uploadedFiles.map((file) => (
            <UploadedFileDisplay
              file={file}
              onRemove={() => handleRemoveFile(file)}
              fullWidth={true}
            />
          ))}
        </div>
        <DragAndDropFileArea
          handleFiles={handleFileChange}
          supportedFileTypes={[".pdf"]}
        />
      </div>
    </CreateStepGroup>
  );
};

export default TechnicalSpecsStep;
