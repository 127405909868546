import { Document, Patent, Process, ProcessType } from "@/types";
import { create } from "zustand";

interface CreateProjectStore {
  subjectNumbers: string[];
  subjectDetails: Patent[];
  spinnerText: string;
  projectError: boolean;
  isProjectCreationInProgress: boolean;
  features: { [key: string]: string }[];
  claims: { [key: string]: string }[];
  abstract: string;
  subjectMode: string;
  clientNumber: string;
  projectName: string;
  files: File[];
  disclosure_files: File[]; // SWITCH TO USING SUPPORTING_DOCUMENTS IF WE USE THIS AGAIN
  disclosureMode: string; // SWITCH TO USING SUPPORTING_DOCUMENTS IF WE USE THIS AGAIN
  supportingDocuments: Document[];
  processes: Process[];
  patentState: "published" | "unpublished";
  extractedApplicationDetails: { [key: string]: string };
  updateSubjectDetails: (details: Patent[]) => void;
  updateSpinnerText: (text: string) => void;
  updateProjectError: (error: boolean) => void;
  updateIsProjectCreationInProgress: (inProgress: boolean) => void;
  updateSubjectNumbers: (numbers: string[]) => void;
  updateSubjectMode: (mode: string) => void;
  updateFeatures: (features: { [key: string]: string }[]) => void;
  updateClaims: (claims: { [key: string]: string }[]) => void;
  updateClientNumber: (number: string) => void;
  updateProjectName: (name: string) => void;
  resetCreateProjectStore: () => void;
  updateFiles: (files: File[]) => void;
  addFile: (file: File) => void;
  updateDisclosure: (disclosure: string) => void;
  updateDisclosureMode: (mode: string) => void;
  updateAbstract: (abstract: string) => void;
  updateDisclosureFiles: (files: File[]) => void; // SWITCH TO USING SUPPORTING_DOCUMENTS IF WE USE THIS AGAIN
  updateSupportingDocuments: (documents: Document[]) => void;
  updateProcesses: (newProcess: Process) => void;
  removeProcesses: (processType: ProcessType) => void;
  setPatentState: (state: "published" | "unpublished") => void;
  updateExtractedApplicationDetails: (details: { [key: string]: string }) => void;
}

const initialState: Omit<
  CreateProjectStore,
  | "updateSubjectNumbers"
  | "updateSubjectDetails"
  | "updateSpinnerText"
  | "updateProjectError"
  | "updateIsProjectCreationInProgress"
  | "updateAbstract"
  | "updateSubjectMode"
  | "updateFeatures"
  | "updateClaims"
  | "updateClientNumber"
  | "updateProjectName"
  | "resetCreateProjectStore"
  | "updateFiles"
  | "addFile"
  | "updateDisclosureFiles" // SWITCH TO USING SUPPORTING_DOCUMENTS IF WE USE THIS AGAIN
  | "updateDisclosureMode"
  | "updateAbstract"
  | "updateSupportingDocuments"
  | "updateProcesses"
  | "removeProcesses"
  | "setPatentState"
  | "updateExtractedApplicationDetails"
> = {
  subjectNumbers: [],
  subjectDetails: [],
  spinnerText: "",
  projectError: false,
  isProjectCreationInProgress: false,
  features: [{ "1": "" }],
  claims: [{ "1": "" }],
  abstract: "",
  subjectMode: "",
  clientNumber: "",
  projectName: "",
  files: [],
  disclosure_files: [],
  disclosureMode: "",
  supportingDocuments: [],
  processes: [],
  patentState: "published",
  extractedApplicationDetails: {},
  updateDisclosure: function (disclosure: string): void {
    // SWITCH TO USING SUPPORTING_DOCUMENTS IF WE USE THIS AGAIN
    throw new Error("Function not implemented.");
  },
};

const useCreateProjectStore = create<CreateProjectStore>((set) => ({
  ...initialState,

  resetCreateProjectStore: () => set(() => initialState),

  updateSubjectDetails: (details) => set(() => ({ subjectDetails: details })),

  updateSpinnerText: (text) => set(() => ({ spinnerText: text })),

  updateProjectError: (error) => set(() => ({ projectError: error })),

  updateIsProjectCreationInProgress: (inProgress) =>
    set(() => ({ isProjectCreationInProgress: inProgress })),

  updateSubjectNumbers: (numbers) => set(() => ({ subjectNumbers: numbers })),

  updateAbstract: (abstract) => set(() => ({ abstract: abstract })),

  updateSubjectMode: (mode) => set(() => ({ subjectMode: mode })),

  updateFeatures: (features) => set(() => ({ features: features })),

  updateClaims: (claims) => set(() => ({ claims: claims })),

  updateClientNumber: (number) => set(() => ({ clientNumber: number })),

  updateProjectName: (name) => set(() => ({ projectName: name })),

  updateFiles: (files) => set(() => ({ files: files })),

  addFile: (file) => set((state) => ({ files: [...state.files, file] })),

  updateDisclosureFiles: (files) => set(() => ({ disclosure_files: files })), // SWITCH TO USING SUPPORTING_DOCUMENTS IF WE USE THIS AGAIN

  updateDisclosureMode: (mode) => set(() => ({ disclosureMode: mode })), // SWITCH TO USING SUPPORTING_DOCUMENTS IF WE USE THIS AGAIN

  updateSupportingDocuments: (documents) =>
    set(() => ({ supportingDocuments: documents })),

  updateProcesses: (newProcess: Process) =>
    set((state) => ({
      processes: state.processes.some((p) => p.id === newProcess.id)
        ? state.processes.map((process) =>
            process.id === newProcess.id ? newProcess : process,
          )
        : [...state.processes, newProcess],
    })),
  removeProcesses: (processType: ProcessType) =>
    set((state) => ({
      processes: state.processes.filter((process) => process.type !== processType),
    })),
  setPatentState: (state: "published" | "unpublished") =>
    set(() => ({ patentState: state })),
  updateExtractedApplicationDetails: (details: { [key: string]: string }) =>
    set(() => ({ extractedApplicationDetails: details })),
}));

export default useCreateProjectStore;
