/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useEffect, useMemo } from "react";
import { useAdminStore, useUserStore } from "@/store";
import { useAdminManagement } from "@/hooks";
import { Loader } from "@/components";
import { RoleType } from "@/types";
import {
  InvoicesTable,
  AdminPageLayout,
  NoAccessMessage,
} from "@/features/admin/components";

/**
 * @description Super admin page component
 */
function SuperAdminBillingPage() {
  const { getSuperAdminDetails } = useAdminManagement();
  const { superAdminInvoices } = useAdminStore();

  // Global state from store
  const { role } = useUserStore();

  useEffect(() => {
    if (!superAdminInvoices) {
      getSuperAdminDetails();
    }
  }, [superAdminInvoices, getSuperAdminDetails]);

  const isSuperAdmin = useMemo(() => role === RoleType.OWNER, [role]);

  return (
    <AdminPageLayout isSuperAdmin={true} pageName="Super Admin - Billing">
      {isSuperAdmin ? (
        <div className="space-y-8 mt-2">
          {superAdminInvoices === null ? (
            <Loader />
          ) : (
            <InvoicesTable invoices={superAdminInvoices} />
          )}
        </div>
      ) : (
        <NoAccessMessage />
      )}
    </AdminPageLayout>
  );
}

export default SuperAdminBillingPage;
