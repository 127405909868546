/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { LoadingButton } from "@/components/ui/loading-button";

interface ActivateUserModalProps {
  userEmail: string;
  open: boolean;
  onClose: () => void;
  onActivate: () => void;
}

export const ActivateUserModal: React.FC<ActivateUserModalProps> = ({
  userEmail,
  open,
  onClose,
  onActivate,
}) => {
  const handleActivate = async () => {
    await Promise.resolve(onActivate());
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Activate user {userEmail}?</DialogTitle>
        </DialogHeader>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleActivate}>Activate</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

interface DeactivateUserModalProps {
  userEmail: string;
  open: boolean;
  onClose: () => void;
  onDeactivate: () => void;
}

export const DeactivateUserModal: React.FC<DeactivateUserModalProps> = ({
  userEmail,
  open,
  onClose,
  onDeactivate,
}) => {
  const handleDelete = async () => {
    await Promise.resolve(onDeactivate());
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Deactivate user {userEmail}?</DialogTitle>
          <DialogDescription>
            The user will immediately lose access to their account.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="destructive" onClick={handleDelete}>
            Deactivate
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

interface DeleteUserModalProps {
  userEmail: string;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  userEmail,
  open,
  onClose,
  onDelete,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    onClose();
    await Promise.resolve(onDelete());
    setIsDeleting(false);
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete {userEmail}?</DialogTitle>
          <DialogDescription>
            This user will be deleted from the organization. This action cannot be
            undone.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="destructive"
            onClick={handleDelete}
            loading={isDeleting}
          >
            {isDeleting ? "Deleting..." : "Delete"}
          </LoadingButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
