/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import {
  DocumentStatus,
  DocumentType,
  ParentType,
  Patent,
  ProcessType,
  StatusType,
} from "@/types";
import { nanoid } from "nanoid";
import { useLlm, useProject, useVector, useViz } from ".";
import { useAppStateStore, useProcessStore, useProjectStore } from "../store";
/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useProcessReferences = () => {
  const {
    currentProjectId,
    currentParent,
    currentPortfolioId,
    currentProject,
    currentPortfolio,
    updateCurrentProject,
  } = useProjectStore();
  const { updateLoadingGroupItem, addLoadingGroupItem, addLoadingGroup } =
    useAppStateStore();
  const { addProcess, removeProcess } = useProcessStore();
  const {
    processReferences,
    generateReferenceSummaryCitations,
    rerankReferences,
    uploadToVDBWithRetry,
    uploadToVDBOnlyWithRetry,
  } = useVector();
  const { addReferencesToProject, addPDFToProject, getProjectReferences } =
    useProject();
  const {
    addToUserFiles,
    addFilesToPortfolio,
    reprocessPatents,
    reprocessReferences,
    updateDocumentStatuses,
    getPortfolioReferences,
  } = useViz();
  const { generateReferenceSummaries } = useLlm();

  const processType = ProcessType.ADD_REFERENCE;

  /**
   * Chunks an array into smaller arrays, helper function for processReferences
   * @param {string[]} array - The array to chunk
   * @param {number} chunkSize - The size of the chunk to process
   * @returns An array of chunks
   */
  async function chunkArray(array: string[], chunkSize: number): Promise<string[][]> {
    const chunks: string[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

  const addAndProcessReferencesForPortfolio = async (
    projectId: string,
    refNums: string[],
    isCheckboxChecked: boolean,
    portfolioId: string,
    preprocessed: boolean = false,
  ) => {
    console.log("Starting addAndProcessReferencesForPortfolio");

    let successfulNumbersToReturn: string[] = [];
    let unsuccessfulNumbersToReturn: string[] = [];
    let failedIds: string[] = [];

    try {
      // Process all reference numbers together without chunking
      console.log("Processing references:", refNums);

      // Add references to project
      const response = await addReferencesToProject(
        projectId,
        refNums,
        false,
        true,
        true,
        portfolioId,
        preprocessed,
      );

      if (!response.success) {
        unsuccessfulNumbersToReturn.push(...refNums);
        return {
          data: {
            successfulNumbers: successfulNumbersToReturn,
            unsuccessfulNumbers: unsuccessfulNumbersToReturn,
            failedIds: failedIds,
          },
        };
      }

      const idToNumberMap = new Map<string, string>(
        response.data.map((doc: { reference_id: string; reference_number: string }) => [
          doc.reference_id,
          doc.reference_number,
        ]),
      );

      const successfulNumbers = new Set(
        response.data.map((doc: any) => doc.reference_number),
      );

      const unsuccessfulNumbers = refNums.filter(
        (number) => !successfulNumbers.has(number),
      );
      unsuccessfulNumbersToReturn.push(...unsuccessfulNumbers);

      const referenceIds = response.data.map(
        (ref: { reference_id: string }) => ref.reference_id,
      );

      // Upload to vector database
      const skipInvalidity = !isCheckboxChecked;
      const uploadResponse = await uploadToVDBWithRetry(
        projectId,
        referenceIds,
        skipInvalidity,
        true,
        false,
      );
      console.log("uploadResponse", uploadResponse);

      if (
        !uploadResponse?.data?.successfulIds &&
        !uploadResponse?.data?.failedIds &&
        uploadResponse.data.successfulIds.length === 0 &&
        uploadResponse.data.failedIds.length === 0
      ) {
        console.error(
          "Upload response is missing required data",
          projectId,
          uploadResponse,
        );
        // Mark all references as failed since we can't determine success
        unsuccessfulNumbersToReturn.push(...Array.from(idToNumberMap.values()));
        failedIds.push(...referenceIds);
        return {
          data: {
            successfulNumbers: successfulNumbersToReturn,
            unsuccessfulNumbers: unsuccessfulNumbersToReturn,
            failedIds: failedIds,
          },
        };
      }

      successfulNumbersToReturn.push(
        ...uploadResponse.data.successfulIds.map(
          (id: string) => idToNumberMap.get(id)!,
        ),
      );
      unsuccessfulNumbersToReturn.push(
        ...uploadResponse.data.failedIds.map((id: string) => idToNumberMap.get(id)!),
      );
      failedIds.push(...uploadResponse.data.failedIds);

      return {
        data: {
          successfulNumbers: successfulNumbersToReturn,
          unsuccessfulNumbers: unsuccessfulNumbersToReturn,
          failedIds: failedIds,
        },
      };
    } catch (error) {
      console.error("Error in addAndProcessReferencesForPortfolio:", error);
      unsuccessfulNumbersToReturn.push(...refNums);
      return {
        data: {
          successfulNumbers: successfulNumbersToReturn,
          unsuccessfulNumbers: unsuccessfulNumbersToReturn,
          failedIds: failedIds,
        },
      };
    }
  };

  const addAndProcessReferences = async (
    projectId: string,
    projectName: string,
    refNums: string[],
    isCheckboxChecked: boolean,
    displayLoadingMessages: boolean = true,
    portfolioId?: string,
    preprocessed: boolean = false,
    processIdFromParent?: string,
  ) => {
    console.log("starting ");
    // Limit the number of references to process
    const processId = processIdFromParent || nanoid();

    if (displayLoadingMessages) {
      addProcess({
        id: processId,
        type: processType,
        projectId: projectId,
      });
      addLoadingGroup(processId, projectName, processType);
      refNums.forEach((number) => {
        addLoadingGroupItem(processId, processType, number, StatusType.PROCESSING);
      });
    }

    const chunkSize = 4;
    const chunks = await chunkArray(refNums, chunkSize);

    let successfulNumbersToReturn: string[] = [];
    let unsuccessfulNumbersToReturn: string[] = [];

    try {
      // Process chunks sequentially
      for (const chunk of chunks) {
        console.log("processing chunk", chunk);
        try {
          const result = await processReferencesInProjects(
            projectId,
            projectName,
            chunk, // Pass single chunk instead of all chunks
            isCheckboxChecked,
            displayLoadingMessages,
            successfulNumbersToReturn,
            unsuccessfulNumbersToReturn,
            preprocessed,
            processId,
            portfolioId ? true : false,
          );

          // If processReferencesInProjects returns a result, handle it
          if (result) {
            // console.log("result in chunk", result);
            const { successful, unsuccessful } = result;
            if (successful) successfulNumbersToReturn.push(...successful);
            if (unsuccessful) unsuccessfulNumbersToReturn.push(...unsuccessful);
          }
        } catch (error) {
          console.error(`Error processing chunk:`, error);
          unsuccessfulNumbersToReturn.push(...chunk);
          // Continue with next chunk instead of failing completely
        }
      }
      // await refetchDataOnCompletion(projectId, portfolioId);

      return {
        data: {
          successfulNumbers: successfulNumbersToReturn,
          unsuccessfulNumbers: unsuccessfulNumbersToReturn,
        },
      };
    } catch (error) {
      console.error("Error in addAndProcessReferences:", error);
      // In case of critical error, mark all remaining references as unsuccessful
      const remainingRefs = refNums.filter(
        (ref) =>
          !successfulNumbersToReturn.includes(ref) &&
          !unsuccessfulNumbersToReturn.includes(ref),
      );
      unsuccessfulNumbersToReturn.push(...remainingRefs);

      return {
        data: {
          successfulNumbers: successfulNumbersToReturn,
          unsuccessfulNumbers: unsuccessfulNumbersToReturn,
        },
      };
    } finally {
      console.log("starting to remove process", processId);
      // Only remove process and final fetch if not processing portfolio
      if (displayLoadingMessages) {
        await checkAndUpdateProcessingStatus(
          DocumentStatus.RECHART,
          portfolioId ? true : false,
        );
        await refetchDataOnCompletion(projectId, portfolioId);
        removeProcess(processId);
      }
    }
  };

  // Function to process references in other projects
  const processReferencesInProjects = async (
    projectId: string,
    projectName: string,
    chunks: string[],
    isCheckboxChecked: boolean,
    displayLoadingMessages: boolean,
    successfulNumbersToReturn: string[],
    unsuccessfulNumbersToReturn: string[],
    preprocessed: boolean = false,
    loadingGroupId: string,
    isProcessingPortfolio: boolean,
  ) => {
    console.log("in processReferencesInProjects", chunks);
    const chunkPromises = chunks.map((chunk) =>
      processChunk(
        projectId,
        projectName,
        [chunk],
        isCheckboxChecked,
        displayLoadingMessages,
        successfulNumbersToReturn,
        unsuccessfulNumbersToReturn,
        isProcessingPortfolio,
        preprocessed,
        loadingGroupId,
      ).catch((error) => {
        console.error("An error occurred during processing the chunk:", error);
      }),
    );

    await Promise.all(chunkPromises);
    // await refetchDataOnCompletion(targetId, targetId);

    return {
      successful: successfulNumbersToReturn,
      unsuccessful: unsuccessfulNumbersToReturn,
    };
  };

  // Generalized processChunk function
  const processChunk = async (
    targetId: string,
    targetName: string,
    chunk: string[],
    isCheckboxChecked: boolean,
    displayLoadingMessages: boolean,
    successfulNumbersToReturn: string[],
    unsuccessfulNumbersToReturn: string[],
    isProcessingPortfolio: boolean,
    preprocessed: boolean = false,
    loadingGroupId: string,
  ) => {
    // console.log("in processChunk targetId", targetId, "chunk", chunk);
    const response = await addReferencesToProject(
      targetId,
      chunk,
      false, // isCitation
      isProcessingPortfolio, // isPortfolioCreation
      isProcessingPortfolio, // isCurrentParentPortfolio
      isProcessingPortfolio ? targetId : undefined, // portfolioId
      preprocessed,
    );

    console.log("response", response);

    if (!response.success) {
      unsuccessfulNumbersToReturn.push(...chunk);
      if (displayLoadingMessages) {
        Array.from(chunk.values()).forEach((number) => {
          updateLoadingGroupItem(
            loadingGroupId,
            processType,
            number,
            StatusType.ERROR,
            "Failed to process reference",
          );
        });
      }

      // Refetch to make sure table is updated for failed set
      await refetchDataOnCompletion(targetId, targetId);
      return;
    }

    const idToNumberMap = new Map<string, string>(
      response.data.map((doc: { reference_id: string; reference_number: string }) => [
        doc.reference_id,
        doc.reference_number,
      ]),
    );

    const successfulNumbers = new Set(
      response.data.map((doc: any) => doc.reference_number),
    );

    const unsuccessfulNumbers = chunk.filter(
      (number) => !successfulNumbers.has(number),
    );
    unsuccessfulNumbersToReturn.push(...unsuccessfulNumbers);

    if (displayLoadingMessages) {
      unsuccessfulNumbers.forEach((number: string) => {
        updateLoadingGroupItem(
          loadingGroupId,
          processType,
          number,
          StatusType.ERROR,
          "Invalid reference number",
        );
      });
    }

    const referenceIds = response.data.map(
      (ref: { reference_id: string }) => ref.reference_id,
    );

    // if (!isProcessingPortfolio) {
    await updateDocumentStatuses(referenceIds, DocumentStatus.PROCESSING);
    // await refetchDataOnCompletion(targetId, targetId);
    // }

    const skipInvalidity = !isCheckboxChecked;
    const uploadResponse = await uploadToVDBWithRetry(
      targetId,
      referenceIds,
      skipInvalidity,
      true,
      false,
    );

    // Add error handling for undefined successful_ids
    if (
      !uploadResponse?.data?.successfulIds ||
      !uploadResponse?.data?.failedIds ||
      (uploadResponse.data.successfulIds.length === 0 &&
        uploadResponse.data.failedIds.length === 0)
    ) {
      console.error("Upload response is missing required data", uploadResponse);
      // Mark all references as failed since we can't determine success
      unsuccessfulNumbersToReturn.push(...Array.from(idToNumberMap.values()));

      if (displayLoadingMessages) {
        Array.from(idToNumberMap.values()).forEach((number) => {
          updateLoadingGroupItem(
            loadingGroupId,
            processType,
            number,
            StatusType.ERROR,
            "Failed to process reference",
          );
        });
      }
      // Update document statuses to RECHART when there's an error
      await updateDocumentStatuses(
        response.data.map((doc: { reference_id: string }) => doc.reference_id),
        DocumentStatus.RECHART,
      );
      return;
    }

    successfulNumbersToReturn.push(
      ...uploadResponse.data.successfulIds.map((id: string) => idToNumberMap.get(id)!),
    );
    unsuccessfulNumbersToReturn.push(
      ...uploadResponse.data.failedIds.map((id: string) => idToNumberMap.get(id)!),
    );

    // Update loading group items
    if (displayLoadingMessages) {
      // Update document statuses
      await updateDocumentStatuses(
        uploadResponse.data.failedIds,
        DocumentStatus.RECHART,
      );
      await updateDocumentStatuses(
        uploadResponse.data.successfulIds,
        DocumentStatus.PROCESSED,
      );
      successfulNumbersToReturn.forEach((number: string) => {
        updateLoadingGroupItem(loadingGroupId, processType, number, StatusType.SUCCESS);
      });

      unsuccessfulNumbersToReturn.forEach((number: string) => {
        updateLoadingGroupItem(
          loadingGroupId,
          processType,
          number,
          StatusType.ERROR,
          "Failed to process reference",
        );
      });
      await refetchDataOnCompletion(targetId, targetId);
    }
  };

  const addAndProcessFilesForProject = async (
    projectId: string,
    projectName: string,
    files: File[],
    isCheckboxChecked: boolean,
    portfolioId?: string,
  ) => {
    let unsuccessfulFilesToReturn: {
      name: string;
      error: string;
      status: "error" | "warning";
    }[] = [];

    const processId = nanoid();

    // Add loading group and items
    addProcess({
      id: processId,
      type: processType,
      projectId: projectId,
      portfolioId: portfolioId,
    });
    addLoadingGroup(processId, projectName, processType);
    files.forEach((file) => {
      addLoadingGroupItem(processId, processType, file.name, StatusType.UPLOADING);
    });

    const BATCH_SIZE = 4;
    const idToNameMap: Record<string, string> = {};

    // Process files in batches of 4
    for (let i = 0; i < files.length; i += BATCH_SIZE) {
      const batchFiles = files.slice(i, i + BATCH_SIZE);

      // Process the current batch of files concurrently
      const batchPromises = batchFiles.map((file) => ({
        file,
        promise: addPDFToProject(projectId, file, false),
      }));

      const results = await Promise.all(batchPromises.map(({ promise }) => promise));

      batchPromises.forEach(({ file }, index) => {
        const result = results[index];

        if (
          result.success &&
          result.data &&
          result.data.length > 0 &&
          result.data[0].status !== "warning"
        ) {
          // Update status to processing
          const referenceData = result.data[0];
          idToNameMap[referenceData.reference_id] = file.name;
        } else if (
          result.data &&
          result.data.length > 0 &&
          result.data[0].status === "warning"
        ) {
          const message = "Reference already exists in the project";
          updateLoadingGroupItem(
            processId,
            processType,
            file.name,
            StatusType.WARNING,
            message,
          );

          unsuccessfulFilesToReturn.push({
            name: file.name,
            error: message,
            status: "warning",
          });
        } else {
          const message = "Error uploading file";
          updateLoadingGroupItem(
            processId,
            processType,
            file.name,
            StatusType.ERROR,
            message,
          );

          unsuccessfulFilesToReturn.push({
            name: file.name,
            error: message,
            status: "error",
          });
        }
      });

      const validResults = results.filter(
        (result) =>
          result.success &&
          result.data &&
          result.data.length > 0 &&
          result.data[0].status !== "warning",
      );

      if (validResults.length === 0) {
        continue; // Move to next batch
      }

      const filesReferenceIds: string[] = validResults.map(
        (result) => result.data[0].reference_id,
      );

      await updateDocumentStatuses(filesReferenceIds, DocumentStatus.PROCESSING);
      await refetchDataOnCompletion(projectId, portfolioId);

      const skipInvalidity = !isCheckboxChecked;
      const uploadResponse = await uploadToVDBWithRetry(
        projectId,
        filesReferenceIds,
        skipInvalidity,
        true,
        false,
      );

      if (
        uploadResponse?.data?.successfulIds &&
        uploadResponse?.data?.failedIds &&
        (uploadResponse.data.successfulIds.length > 0 ||
          uploadResponse.data.failedIds.length > 0)
      ) {
        const successfulIds = uploadResponse.data.successfulIds || [];
        const failedIds = uploadResponse.data.failedIds || [];

        // Update loading group items for successful uploads
        successfulIds.forEach((id) => {
          updateLoadingGroupItem(
            processId,
            processType,
            idToNameMap[id],
            StatusType.SUCCESS,
          );
        });

        await updateDocumentStatuses(successfulIds, DocumentStatus.PROCESSED);

        // Update loading group items for failed uploads
        failedIds.forEach((id) => {
          updateLoadingGroupItem(
            processId,
            processType,
            idToNameMap[id],
            StatusType.ERROR,
            "Error processing file",
          );
        });

        unsuccessfulFilesToReturn = [
          ...unsuccessfulFilesToReturn,
          ...failedIds.map((id) => ({
            name: idToNameMap[id],
            error: "Error processing file",
            status: "error" as "error" | "warning",
          })),
        ];

        if (failedIds.length > 0) {
          await updateDocumentStatuses(failedIds, DocumentStatus.RECHART);
        }

        if (successfulIds.length > 0) {
          await addToUserFiles(successfulIds);
        }
      } else {
        console.error("Upload response is undefined or missing data");
        // Handle the case where uploadResponse or its data is undefined
        unsuccessfulFilesToReturn = [
          ...unsuccessfulFilesToReturn,
          ...filesReferenceIds.map((id) => ({
            name: idToNameMap[id],
            error: "Error processing file",
            status: "error" as "error" | "warning",
          })),
        ];

        // Update loading group items to error
        filesReferenceIds.forEach((id) => {
          updateLoadingGroupItem(
            processId,
            processType,
            idToNameMap[id],
            StatusType.ERROR,
            "Error processing file",
          );
        });

        await updateDocumentStatuses(filesReferenceIds, DocumentStatus.RECHART);
      }

      await checkAndUpdateProcessingStatus(DocumentStatus.RECHART, false);
      await refetchDataOnCompletion(projectId, portfolioId);
    }

    removeProcess(processId);

    return {
      data: {
        unsuccessfulFiles: unsuccessfulFilesToReturn,
      },
    };
  };

  const addAndProcessFilesForPortfolio = async (
    projectIds: string[],
    files: File[],
    portfolioId?: string,
  ) => {
    let unsuccessfulFilesToReturn: {
      name: string;
      error: string;
      status: "error" | "warning";
    }[] = [];
    let successfulFilesToReturn: {
      name: string;
      status: "success";
      id: string;
    }[] = [];
    const idToNameMap: Record<string, string> = {};

    try {
      const BATCH_SIZE = 4;
      const chunks: File[][] = [];
      for (let i = 0; i < files.length; i += BATCH_SIZE) {
        chunks.push(files.slice(i, i + BATCH_SIZE));
      }

      // Process each batch sequentially
      for (const chunk of chunks) {
        // Process files in parallel within each batch
        const batchPromises = chunk.map(async (file) => {
          try {
            // Step 1: Add file to portfolio
            const response = await addFilesToPortfolio(projectIds, [file]);
            const result = response.data[0];

            if (result.status === "success") {
              const referenceId = result.reference_id;
              idToNameMap[referenceId] = file.name;

              await updateDocumentStatuses([referenceId], DocumentStatus.PROCESSING);

              // Step 2: Upload to VDB
              const uploadResponse = await uploadToVDBOnlyWithRetry([referenceId]);

              if (
                uploadResponse?.data?.successfulIds &&
                uploadResponse?.data?.failedIds &&
                (uploadResponse.data.successfulIds.length > 0 ||
                  uploadResponse.data.failedIds.length > 0)
              ) {
                const successfulUploadIds = uploadResponse.data.successfulIds || [];
                const failedUploadIds = uploadResponse.data.failedIds || [];

                if (failedUploadIds.includes(referenceId)) {
                  // Handle failed upload
                  unsuccessfulFilesToReturn.push({
                    name: file.name,
                    error: "Error uploading file",
                    status: "error",
                  });
                  await updateDocumentStatuses([referenceId], DocumentStatus.RECHART);
                  return;
                }

                // Step 3: Process references for each project sequentially
                let processingSuccess = false;

                for (const projectId of projectIds) {
                  const processResult = await processReferences(projectId, [
                    referenceId,
                  ]);

                  if (processResult?.data?.successful_ids?.includes(referenceId)) {
                    processingSuccess = true;
                  }

                  // Optional: Add small delay between projects
                  if (projectIds.indexOf(projectId) < projectIds.length - 1) {
                    await new Promise((resolve) => setTimeout(resolve, 200));
                  }
                }

                if (processingSuccess) {
                  // Update status to PROCESSED and add to user files
                  await updateDocumentStatuses([referenceId], DocumentStatus.PROCESSED);
                  await addToUserFiles([referenceId]);

                  successfulFilesToReturn.push({
                    name: file.name,
                    status: "success",
                    id: referenceId,
                  });
                } else {
                  unsuccessfulFilesToReturn.push({
                    name: file.name,
                    error: "Error processing file",
                    status: "error",
                  });
                  await updateDocumentStatuses([referenceId], DocumentStatus.RECHART);
                }
              } else {
                // Handle missing upload response data
                unsuccessfulFilesToReturn.push({
                  name: file.name,
                  error: "Error uploading file",
                  status: "error",
                });
                await updateDocumentStatuses([referenceId], DocumentStatus.RECHART);
              }
            } else {
              // Handle upload warnings or errors
              const message =
                result.status === "warning"
                  ? "Reference already exists in the project"
                  : "Error uploading file";

              unsuccessfulFilesToReturn.push({
                name: file.name,
                error: message,
                status: result.status === "warning" ? "warning" : "error",
              });
            }

            await refetchDataOnCompletion(undefined, portfolioId);
          } catch (error) {
            console.error(
              `An error occurred while processing file ${file.name}:`,
              error,
            );
            // Handle unexpected errors for this file
            unsuccessfulFilesToReturn.push({
              name: file.name,
              error: "An unexpected error occurred",
              status: "error",
            });
          }
        });

        // Wait for all promises in the batch to complete
        await Promise.all(batchPromises);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle unexpected errors
      files.forEach((file) => {
        if (
          !successfulFilesToReturn.some((f) => f.name === file.name) &&
          !unsuccessfulFilesToReturn.some((f) => f.name === file.name)
        ) {
          unsuccessfulFilesToReturn.push({
            name: file.name,
            error: "An unexpected error occurred",
            status: "error",
          });
        }
      });
    } finally {
      // Final refetch to ensure latest data
      await checkAndUpdateProcessingStatus(DocumentStatus.RECHART, true);
      await refetchDataOnCompletion(undefined, portfolioId);
    }

    return {
      data: {
        unsuccessfulFiles: unsuccessfulFilesToReturn,
        successfulFiles: successfulFilesToReturn,
      },
    };
  };

  const refetchDataOnCompletion = async (projectId: string, portfolioId: string) => {
    if (currentProjectId && projectId === currentProjectId) {
      await getProjectReferences(currentProjectId, false);
    }

    if (currentParent === ParentType.PORTFOLIO && portfolioId === currentPortfolioId) {
      await getPortfolioReferences(currentPortfolioId);
    }
  };

  const generateSummaries = async (
    referenceIds: string[],
    projectId: string,
    parent: ParentType,
  ) => {
    try {
      await generateReferenceSummaries(referenceIds, projectId, parent);

      const summariesWithCitations = await generateReferenceSummaryCitations(
        referenceIds,
        projectId,
        parent,
      );

      if (projectId === currentProjectId) {
        const updatedProject = {
          ...currentProject,
          summaries: {
            ...currentProject?.summaries,
            ...summariesWithCitations.data,
          },
        };

        updateCurrentProject(updatedProject);
      }
    } catch (error) {
      console.error("Error generating reference summaries:", error);
    }
  };

  /**
   * Reprocess selected references for projects and portfolios.
   * @param selectedReferences - The references to reprocess.
   * @param options - Contains IDs and flags to determine if it's a project or portfolio.
   */
  const reprocessDocuments = async (
    selectedReferences: Patent[],
    isPortfolio: boolean,
  ) => {
    const processId = nanoid();
    const BATCH_SIZE = 4;
    const loadingGroupProcessType = ProcessType.RECHART;
    const currentParentName = isPortfolio
      ? currentPortfolio?.name
      : currentProject?.name;
    const addProcessOptions = isPortfolio
      ? { portfolioId: currentPortfolioId }
      : { projectId: currentProjectId };
    let documentsToNicknames;
    if (isPortfolio) {
      documentsToNicknames = currentPortfolio.references.reduce((acc, ref) => {
        acc[ref.id] = ref.name;
        return acc;
      }, {});
    } else {
      documentsToNicknames = currentProject.documentsToNicknames;
    }

    addProcess({
      id: processId,
      type: loadingGroupProcessType,
      ...addProcessOptions,
    });

    addLoadingGroup(processId, currentParentName, loadingGroupProcessType);

    // Map to store reference names for loading messages
    selectedReferences.forEach((reference) => {
      addLoadingGroupItem(
        processId,
        loadingGroupProcessType,
        documentsToNicknames[reference.id],
        StatusType.PROCESSING,
      );
    });

    // Initialize variables to hold IDs
    const documentIds = selectedReferences.map((reference) => reference.id);
    const failedProcessingIds = new Set<string>();
    const failedRerankingIds = new Set<string>();
    const successfullyProcessedIds = new Set<string>();

    try {
      // Update initial status to pending
      await updateDocumentStatuses(documentIds, DocumentStatus.PROCESSING);

      await refetchDataOnCompletion(currentProjectId, currentPortfolioId);

      // Define reusable processing functions
      const patentProcessFunction = async (batch: string[]) => {
        const response = await reprocessPatents(batch);
        return {
          data: response.data || { failedIds: [], successfulIds: [] },
          success: response.success,
        };
      };

      const referenceProcessFunction = async (batch: string[]) => {
        const response = await reprocessReferences(batch);
        return {
          data: response.data || { failedIds: [], successfulIds: [] },
          success: response.success,
        };
      };

      const rerankFunction = async (batch: string[]) => {
        const response = await rerankReferences(
          isPortfolio ? currentPortfolioId : currentProjectId,
          batch,
          isPortfolio,
        );
        return {
          data: response.data || { failedIds: [], successfulIds: [] },
          success: response.success,
        };
      };

      // First handle documents that only need reranking
      const documentIdsToRerank = selectedReferences
        .filter((ref) => ref.status === DocumentStatus.RECHART)
        .map((ref) => ref.id);

      // Rerank documents that only need reranking
      if (documentIdsToRerank.length > 0) {
        await processBatches(
          documentIdsToRerank,
          BATCH_SIZE,
          null, // No reprocess function
          rerankFunction,
          successfullyProcessedIds,
          failedProcessingIds,
          failedRerankingIds,
          documentsToNicknames,
          processId,
          loadingGroupProcessType,
        );
      }

      // Then handle documents that need reprocessing

      // Process patents
      const patentIdsToProcess = selectedReferences
        .filter(
          (ref) =>
            ref.status === DocumentStatus.REPROCESS && ref.type === DocumentType.PATENT,
        )
        .map((ref) => ref.id);

      if (patentIdsToProcess.length > 0) {
        await processBatches(
          patentIdsToProcess,
          BATCH_SIZE,
          patentProcessFunction,
          rerankFunction,
          successfullyProcessedIds,
          failedProcessingIds,
          failedRerankingIds,
          documentsToNicknames,
          processId,
          loadingGroupProcessType,
        );
      }

      // Process other references (non-patent documents)
      const refIdsToProcess = selectedReferences
        .filter(
          (ref) =>
            ref.status === DocumentStatus.REPROCESS &&
            ref.type === DocumentType.REFERENCE,
        )
        .map((ref) => ref.id);

      if (refIdsToProcess.length > 0) {
        await processBatches(
          refIdsToProcess,
          BATCH_SIZE,
          referenceProcessFunction,
          rerankFunction,
          successfullyProcessedIds,
          failedProcessingIds,
          failedRerankingIds,
          documentsToNicknames,
          processId,
          loadingGroupProcessType,
        );
      }

      // Update final statuses
      await refetchDataOnCompletion(currentProjectId, currentPortfolioId);
    } catch (error) {
      console.error("Error reprocessing references:", error);
    } finally {
      // Double check store and ensure no "Processing" statuses are left
      // Ensure no documents are left in reprocessing status
      const failedProcessingIdsArray = Array.from(failedProcessingIds);
      if (failedProcessingIdsArray.length > 0) {
        await updateDocumentStatuses(
          failedProcessingIdsArray,
          DocumentStatus.REPROCESS,
        );
      }

      const failedRerankingIdsArray = Array.from(failedRerankingIds);
      if (failedRerankingIdsArray.length > 0) {
        await updateDocumentStatuses(failedRerankingIdsArray, DocumentStatus.RECHART);
      }

      // Update successful documents status
      const successfulIdsArray = Array.from(successfullyProcessedIds);
      if (successfulIdsArray.length > 0) {
        await updateDocumentStatuses(successfulIdsArray, DocumentStatus.PROCESSED);
      }
      await refetchDataOnCompletion(currentProjectId, currentPortfolioId);
      removeProcess(processId);
    }
  };

  // Helper function to process batches
  const processBatches = async (
    documentIds: string[],
    batchSize: number,
    processFunction: (batch: string[]) => Promise<{
      data: { successfulIds?: string[]; failedIds?: string[] };
      success: boolean;
    } | null>,
    rerankFunction:
      | ((batch: string[]) => Promise<{
          data: { successfulIds?: string[]; failedIds?: string[] };
          success: boolean;
        } | null>)
      | null,
    successfullyProcessedIds: Set<string>,
    failedProcessingIds: Set<string>,
    failedRerankingIds: Set<string>,
    documentsToNicknames: Record<string, string>,
    processId: string,
    loadingGroupProcessType: ProcessType,
  ) => {
    let initialFailedProcessingIds = new Set<string>();

    // Helper function to process a batch
    const processBatch = async (batch: string[], isRetry: boolean) => {
      let successfulBatchIds = [];
      let failedBatchIds = [];

      if (processFunction) {
        // Call the processing function (reprocess or rerank)
        const processResult = await processFunction(batch);
        if (processResult?.success && processResult?.data) {
          // Collect failed IDs
          failedBatchIds = processResult?.data?.failedIds || [];
          successfulBatchIds = processResult?.data?.successfulIds || [];
        } else {
          failedBatchIds = batch;
        }

        if (failedBatchIds.length > 0) {
          failedBatchIds.forEach((id) => {
            failedProcessingIds.add(id);
            if (!isRetry) {
              initialFailedProcessingIds.add(id);
            }
          });
        }
      } else {
        successfulBatchIds = batch; // No processing function, so all are successfully processed already
      }

      // If rerankFunction is provided, rerank the successfully processed documents
      if (rerankFunction && successfulBatchIds.length > 0) {
        let rerankFailedIds = [];
        let rerankSuccessfulIds = [];
        const rerankResult = await rerankFunction(successfulBatchIds);
        if (rerankResult?.success && rerankResult?.data) {
          rerankFailedIds = rerankResult?.data?.failedIds || [];
          rerankSuccessfulIds = rerankResult?.data?.successfulIds || [];
        } else {
          rerankFailedIds = successfulBatchIds;
        }

        if (rerankFailedIds.length > 0) {
          rerankFailedIds.forEach((id) => {
            failedRerankingIds.add(id);
            if (!isRetry) {
              initialFailedProcessingIds.add(id);
            }
          });
        }

        // Update successfulBatchIds to only include IDs that were successfully reranked
        successfulBatchIds = successfulBatchIds.filter(
          (id) => !rerankFailedIds.includes(id),
        );
      }

      // // Add to the set of successfully processed IDs
      successfulBatchIds.forEach((id) => successfullyProcessedIds.add(id));

      // Update loading group items
      batch.forEach((id) => {
        let status;
        let message;

        if (successfulBatchIds.includes(id)) {
          status = StatusType.SUCCESS;
          message = undefined;
        } else if (isRetry) {
          // Only set status to error if this is the retry batch failing
          status = StatusType.ERROR;
          message = rerankFunction
            ? `Failed to reprocess document after retry`
            : `Failed to rechart document after retry`;
        } else {
          // For initial batch failures, keep status as processing
          status = StatusType.PROCESSING;
          message = undefined;
        }

        updateLoadingGroupItem(
          processId,
          loadingGroupProcessType,
          documentsToNicknames[id],
          status,
          message,
        );
      });

      await refetchDataOnCompletion(currentProjectId, currentPortfolioId);
    };

    // First pass: process initial batches
    for (let i = 0; i < documentIds.length; i += batchSize) {
      const batch = documentIds.slice(i, i + batchSize);
      await processBatch(batch, false);

      // Delay between batches
      if (i + batchSize < documentIds.length) {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }

    // Retry failed IDs once
    if (initialFailedProcessingIds.size > 0) {
      const failedIdsArray = Array.from(initialFailedProcessingIds);
      for (let i = 0; i < failedIdsArray.length; i += batchSize) {
        const batch = failedIdsArray.slice(i, i + batchSize);

        // Retry processing function
        await processBatch(batch, true);

        // Remove IDs that succeeded on retry from failedProcessingIds
        batch.forEach((id) => {
          if (successfullyProcessedIds.has(id)) {
            failedProcessingIds.delete(id);
            failedRerankingIds.delete(id);
            initialFailedProcessingIds.delete(id);
          }
        });

        // Delay between retry batches
        if (i + batchSize < failedIdsArray.length) {
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
      }
    }

    await checkAndUpdateProcessingStatus(
      DocumentStatus.RECHART,
      currentParent === ParentType.PORTFOLIO,
    );
    await refetchDataOnCompletion(currentProjectId, currentPortfolioId);
  };

  /**
   * Checks for and updates any references stuck in Processing status
   * @param references - List of Patent references to check
   */
  const checkAndUpdateProcessingStatus = async (
    status: DocumentStatus,
    isPortfolio: boolean,
  ) => {
    let references: Patent[] = [];
    if (isPortfolio && currentPortfolio && currentPortfolio.references) {
      references = currentPortfolio.references;
    } else if (!isPortfolio && currentProject && currentProject.references) {
      references = currentProject.references;
    }

    if (references.length === 0) return;

    const processingRefs = references.filter(
      (ref) => ref.status === DocumentStatus.PROCESSING,
    );

    const idsToUpdate = processingRefs.map((ref) => ref.id);

    await updateDocumentStatuses(idsToUpdate, status);
  };

  return {
    addAndProcessReferences,
    addAndProcessFilesForProject,
    addAndProcessFilesForPortfolio,
    generateSummaries,
    reprocessDocuments,
    addAndProcessReferencesForPortfolio,
  };
};

export default useProcessReferences;
