/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Spinner } from "@/components/ui/spinner";
import { Switch } from "@/components/ui/switch";
import { H4 } from "@/components/ui/typography";
import { useViz } from "@/hooks";
import { useAppStateStore } from "@/store";
import { ElementType, InvalidityCitation, Patent } from "@/types";
import { isLikelyPatentNumber } from "@/utils/dataUtils";
import { splitId } from "@/utils/projectUtils";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import he from "he";
import React, { useEffect, useRef, useState } from "react";
import ErrorMessage from "../alerts/ErrorMessage";

import { useProjectStore } from "@/store";
import { useLocation } from "react-router-dom";
import ChartViewerLayout from "./ChartViewerLayout";
import DetailViewerLayout from "./DetailViewerLayout";

interface DocumentViewerProps {
  open: boolean;
  handleClose: () => void;
  referenceId: string | null;
  citationText: string;
  selectedElement?: string;
  selectedElementType?: ElementType;
  citations?: InvalidityCitation[];
  startInChartMode?: boolean;
  nickname?: string;
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({
  open,
  handleClose,
  referenceId,
  citationText,
  selectedElement,
  selectedElementType,
  citations = [],
  startInChartMode,
  nickname,
}) => {
  const { getFullDocument } = useViz();
  const { addErrorMessage } = useAppStateStore();
  const { currentProject } = useProjectStore();
  const [patent, setPatent] = useState<Patent | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const highlightedRef = useRef<HTMLDivElement | HTMLTableRowElement | null>(null);
  const [viewerMode, setViewerMode] = useState<"viewer" | "editor">(
    startInChartMode ? "editor" : "viewer",
  );

  const location = useLocation();
  const showChartEditor = location.pathname.includes("/project");

  useEffect(() => {
    setViewerMode(startInChartMode ? "editor" : "viewer");
  }, [startInChartMode]);

  // Keep only the patent loading logic
  useEffect(() => {
    async function loadPatent() {
      if (!referenceId) return;

      setIsLoading(true);
      const response = await getFullDocument(referenceId);
      setIsLoading(false);

      if (response.success) {
        setPatent(response.data);
      } else {
        addErrorMessage("Error fetching reference details.");
        handleClose();
      }
    }

    if (open && referenceId) {
      loadPatent();
    } else {
      setPatent(null);
    }
  }, [open, referenceId]);

  // Scroll effect
  useEffect(() => {
    if (open && patent) {
      if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }
      if (patent.body && !patent.fullBody) {
        patent.fullBody = patent.body.map((item) => item.text);
      }

      // Delay the scroll to ensure the content is rendered
      setTimeout(() => {
        if (highlightedRef.current && containerRef.current) {
          const container = containerRef.current;
          const highlightedElement = highlightedRef.current;

          // Set the scroll position without animation
          highlightedElement.scrollIntoView({ block: "center" });

          // Adjust the scroll position
          container.scrollTop = container.scrollTop - 100;
        }
      }, 0);
    }
  }, [open, patent, citationText]);

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="max-w-[90vw] max-h-[90vh] flex flex-col p-0 overflow-hidden">
        <VisuallyHidden>
          <DialogTitle>
            <H4>{patent?.name}</H4>
          </DialogTitle>
        </VisuallyHidden>
        <VisuallyHidden asChild>
          <DialogDescription>{patent?.name} details</DialogDescription>
        </VisuallyHidden>
        {isLoading ? (
          <div className="flex items-center justify-center h-full m-12">
            <Spinner />
          </div>
        ) : patent && patent.name ? (
          <div className="flex flex-col h-full">
            {/* Fixed header */}
            <DialogHeader className="sticky top-0 z-10 bg-background py-2 px-3 border-b bg">
              <div className="flex justify-between items-center gap-2">
                <div className="flex items-center gap-2">
                  <H4>
                    {currentProject?.documentsToNicknames?.[patent.id] ||
                      nickname ||
                      he.decode(patent.name) ||
                      ""}
                  </H4>
                  <div className="flex items-center gap-2 flex-col">
                    {isLikelyPatentNumber(patent?.number || "") && (
                      <div className="flex items-center gap-1 bg-[#BBD6FF] text-black whitespace-nowrap px-1 py-0.75 pl-3 rounded-full ">
                        <span className="text-md">{splitId(patent.number)}</span>
                        <Button
                          variant="ghost"
                          size="icon"
                          asChild
                          className="text-black hover:bg-primary-foreground/10 flex-shrink-0"
                        >
                          <a
                            href={`https://patents.google.com/patent/${patent.number}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <ExternalLinkIcon className="h-4 w-4" />
                          </a>
                        </Button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  {showChartEditor && (
                    <div className="flex items-center gap-2">
                      <Switch
                        checked={viewerMode === "editor"}
                        onCheckedChange={(checked) =>
                          setViewerMode(checked ? "editor" : "viewer")
                        }
                      />
                      <span className="text-sm text-muted-foreground">Chart Mode</span>
                    </div>
                  )}
                </div>
              </div>
            </DialogHeader>

            {viewerMode === "viewer" ? (
              <DetailViewerLayout
                document={patent}
                mode={viewerMode}
                citationText={citationText}
                // highlightedRef={highlightedRef}
              />
            ) : (
              <ChartViewerLayout
                document={patent}
                citationText={citationText}
                citations={citations}
                selectedElement={selectedElement}
                selectedElementType={selectedElementType}
              />
            )}
          </div>
        ) : (
          <div className="flex items-center justify-center h-full m-12">
            <ErrorMessage />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DocumentViewer;
