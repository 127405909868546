import camelcaseKeys from "camelcase-keys";
/**
 * Utility function to convert snake_case keys to camelCase
 * @param {object} obj - The object to convert
 * @returns {object} - The object with camelCase keys
 */
export const toCamelCase = (obj: any, deep: boolean = true): any => {
  return camelcaseKeys(obj, { deep });
};

/**
 * Utility function to convert camelCase to snake_case
 * @param {object} obj - The object to convert
 * @returns {object} - The object with camelCase keys
 */
export const toSnakeCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => toSnakeCase(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)]: toSnakeCase(
          obj[key],
        ),
      }),
      {},
    );
  }
  return obj;
};

/**
 * @param {string} string - The string to capitalize
 * @returns {string} - The capitalized string
 */
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * @param {string} string - The comma-separated string to parse
 * @returns {array} - An array of strings
 * Separates by commas or periods and trims whitespace
 */
export const parseCommaSeparatedString = (string: string) => {
  return string.split(/[,.]/).map((s) => s.trim());
};

/**
 * @param {string} string - The string to check
 * @returns {boolean} - Whether the string is likely a patent number
 */
export const isLikelyPatentNumber = (string: string) => {
  if (!string) return false;
  const length = string.length;
  const hasCountryCode = /^[A-Z]{2,3}/.test(string);
  const isValidLength =
    (length >= 7 && /^[A-Z]{2,3}[0-9]+$/.test(string)) ||
    (length >= 9 && length <= 17 && /^[A-Z]{2,3}[A-Z0-9]+$/.test(string));
  return hasCountryCode && isValidLength;
};

/**
 * @param {string} string - The string to check
 * @returns {boolean} - Whether the string is likely an early publication number
 */
export const isLikelyUSEarlyPublicationNumber = (string) => {
  const length = string.length;
  const hasUSPrefix = /^US/.test(string);
  const isValidFormat = /^US\d{4}\d{5,7}A\d$/.test(string);
  const isValidLength = length >= 13 && length <= 15;

  return hasUSPrefix && isValidFormat && isValidLength;
};

/**
 * @param {string} string - The string to check
 * @returns {boolean} - Whether the string is likely a US patent application number
 */
export const isLikelyUSPatentApplicationNumber = (string: string) => {
  const eightDigitFormat = /^[0-9]{8}$/; // Format: 8 digits, e.g., 12345678
  const flexiblePre2001Format =
    /^[0-9]{2}(\/[0-9]{3},[0-9]{3}|\/[0-9]{7}|[0-9]{5},[0-9]{3})$/;
  // Matches:
  // - XX/XXX,XXX (e.g., 08/123,456)
  // - XX/XXXXXXX (e.g., 08/1234567)
  // - XXXXX,XXX (e.g., 12345,678)

  return eightDigitFormat.test(string) || flexiblePre2001Format.test(string);
};

export const formatCurrency = (
  value: string | number | null,
  inCents: boolean = false,
): string => {
  if (value === null) return;
  const numValue = typeof value === "string" ? parseFloat(value) : value;
  if (isNaN(numValue)) return "N/A";

  const dollars = inCents ? numValue / 100 : numValue;
  return dollars.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};
