/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import ImageScrollBox from "@/components/images/ImageScrollBox";
import { Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { CondensedParagraph, H4 } from "@/components/ui/typography";
import { Patent } from "@/types";
import he from "he";
import React from "react";
import { ReferenceImage } from "../index";

/**
 * @description Subject abstract and images component
 * @param {object} details - The details to display
 */
interface SubjectAbstractImagesProps {
  details: Patent;
  showTitle?: boolean;
  citationText?: string;
  highlightedRef?: React.RefObject<HTMLDivElement>;
}

const SubjectAbstractImages: React.FC<SubjectAbstractImagesProps> = ({
  details,
  showTitle = true,
  citationText,
  highlightedRef,
}) => {
  const figureUrls = details.figureUrls || [];

  return (
    <div>
      {showTitle && details.name && (
        <div className="mb-4">
          <H4>{he.decode(details.name)}</H4>
        </div>
      )}
      {details.abstract && (
        <div className="mb-4">
          <Label>Abstract</Label>
          <CondensedParagraph>
            {citationText
              ? details.abstract
                  .split(new RegExp(`(${citationText})`, "i"))
                  .map((part, i) =>
                    part.toLowerCase() === citationText?.toLowerCase() ? (
                      <span key={i} className="bg-blue-100">
                        {part}
                      </span>
                    ) : (
                      part
                    ),
                  )
              : details.abstract}
          </CondensedParagraph>
        </div>
      )}

      {figureUrls.length > 0 && (
        <div className="mb-4">
          <Label>Figures ({figureUrls.length})</Label>
          <ImageScrollBox>
            <div className="flex gap-2 p-2">
              {figureUrls.map((imageUrl) => (
                <Card
                  key={imageUrl}
                  className="shrink-0 hover:border-gray-400 inline-block"
                >
                  <ReferenceImage
                    imageUrl={imageUrl}
                    imageName={imageUrl.split("/").pop() || "Image"}
                  />
                </Card>
              ))}
            </div>
          </ImageScrollBox>
        </div>
      )}
    </div>
  );
};

export default SubjectAbstractImages;
