/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Cross2Icon, FileIcon } from "@radix-ui/react-icons";

interface UploadedFileDisplayProps {
  file: {
    name: string;
  } | null;
  onRemove: () => void;
  fullWidth?: boolean;
}

function UploadedFileDisplay({ file, onRemove, fullWidth }: UploadedFileDisplayProps) {
  if (!file) {
    return null;
  }

  const truncateFileName = (name: string) => {
    const maxLength = 45;
    return name.length > maxLength ? name.substring(0, maxLength) + "..." : name;
  };

  return (
    <Card
      className={`py-2 pl-3 pr-2 mb-2 shadow-none ${fullWidth ? "w-full" : "w-auto"}`}
      aria-label={`File: ${file.name}`}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <FileIcon className="text-primary h-5 w-5" />
          <span className="text-sm font-medium">{truncateFileName(file.name)}</span>
        </div>

        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              size="icon"
              onClick={onRemove}
              aria-label="Delete file"
              className="ml-2"
            >
              <Cross2Icon className="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Remove file</p>
          </TooltipContent>
        </Tooltip>
      </div>
    </Card>
  );
}

export default UploadedFileDisplay;
