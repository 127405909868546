/*
 * Copyright AndAI, Inc. 2024. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useState, useEffect } from "react";
import { useAuthInfo } from "@propelauth/react";
import { useProjectStore, useAppStateStore } from "@/store";
import { useProject, usePortfolio } from "@/hooks";
import { ParentType } from "@/types";
import { X, MoreVertical } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Badge } from "@/components/ui/badge";

interface ShareProjectModalProps {
  open: boolean;
  handleClose: () => void;
  projectTitle: string;
}

/**
 * @description Share project modal
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {string} projectTitle - The title of the project.
 */
const ShareProjectModal: React.FC<ShareProjectModalProps> = ({
  open,
  handleClose,
  projectTitle,
}) => {
  const { addUserToProject, removeUserFromProject, getUsersForProject } = useProject();
  const { addUserToPortfolio, removeUserFromPortfolio, getUsersForPortfolio } =
    usePortfolio();

  // Global state from store
  const { user } = useAuthInfo();
  const { currentProjectId, currentParent, currentPortfolioId } = useProjectStore();
  const { addErrorMessage, addSuccessMessage } = useAppStateStore();

  // Local state set up
  const [orgUsers, setOrgUsers] = useState<{ email: string; id: string }[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<{ email: string; id: string }[]>(
    [],
  );
  const [usersWithAccess, setUsersWithAccess] = useState<
    { email: string; id: string }[]
  >([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch data when modal opens
  useEffect(() => {
    const fetchData = async () => {
      if (open && (orgUsers.length === 0 || usersWithAccess.length === 0)) {
        let response;
        if (currentParent === ParentType.PROJECT) {
          response = await getUsersForProject(currentProjectId);
        } else {
          response = await getUsersForPortfolio(currentPortfolioId);
        }
        if (response.success) {
          const projectUsers = response.data;
          setOrgUsers(projectUsers.shareable_users);
          setUsersWithAccess(projectUsers.users_on_project);
        } else {
          addErrorMessage(
            response.message ||
              "An error occurred while fetching project users. Try again later.",
          );
        }
      }
    };

    fetchData();
  }, [open, currentParent, currentProjectId, currentPortfolioId]);

  const handleCloseModal = () => {
    setSelectedUsers([]);
    handleClose();
  };

  // Remove user from project or portfolio
  const handleRemoveUser = async (userItem: { email: string; id: string }) => {
    if (userItem) {
      let response;
      if (currentParent === ParentType.PROJECT) {
        response = await removeUserFromProject(currentProjectId, userItem.id);
      } else {
        response = await removeUserFromPortfolio(currentPortfolioId, userItem.id);
      }
      if (response.success) {
        setUsersWithAccess(usersWithAccess.filter((user) => user.id !== userItem.id));
        addSuccessMessage("User " + userItem.email + " removed from project.");
      } else {
        addErrorMessage(
          response.message ||
            "An error occurred while removing user from project. Try again later.",
        );
      }
    }
  };

  // Add selected users to project or portfolio
  const addSelectedUsers = async () => {
    for (const user of selectedUsers) {
      let response;
      if (currentParent === ParentType.PROJECT) {
        response = await addUserToProject(currentProjectId, user.id);
      } else {
        response = await addUserToPortfolio(currentPortfolioId, user.id);
      }
      if (response.success) {
        addSuccessMessage("User " + user.email + " added to project.");
      } else {
        addErrorMessage(
          "An error occurred while adding user to project. Try again later.",
        );
      }
    }
    handleCloseModal();
  };

  const filteredOrgUsers = orgUsers.filter((user) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Dialog open={open} onOpenChange={handleCloseModal}>
      <DialogContent className="sm:max-w-[650px]">
        <DialogHeader>
          <DialogTitle>Share "{projectTitle}"</DialogTitle>
        </DialogHeader>
        <div className="rounded-lg border shadow-md">
          <Input
            placeholder="Add people"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border-none focus:outline-none dark:bg-gray-700 dark:text-white"
          />
          <div className="max-h-[200px] overflow-y-auto">
            <div>
              {filteredOrgUsers.length > 0 ? (
                filteredOrgUsers.map((user) => (
                  <div
                    key={user.id}
                    className="flex items-center justify-between p-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                    onClick={() => {
                      setSelectedUsers((prev) =>
                        prev.some((u) => u.id === user.id)
                          ? prev.filter((u) => u.id !== user.id)
                          : [...prev, user],
                      );
                    }}
                  >
                    <span className="dark:text-white">{user.email}</span>
                    {selectedUsers.some((u) => u.id === user.id) && (
                      <Badge variant="outline">Selected</Badge>
                    )}
                  </div>
                ))
              ) : (
                <div className="py-6 text-center text-sm">No users found</div>
              )}
            </div>
            {/* <div className="h-2" /> */}
          </div>
        </div>
        <div className="flex flex-wrap gap-2 mt-1 overflow-auto">
          {selectedUsers?.map((user) => (
            <div
              key={user.id}
              className="text-secondary-foreground px-2 py-1 m-1 rounded border dark:border-gray-600 dark:text-gray-300"
            >
              {user.email}
              <Button
                variant="ghost"
                size="sm"
                className="ml-1 h-auto p-0 dark:text-gray-300"
                onClick={() =>
                  setSelectedUsers((prev) => prev.filter((u) => u.id !== user.id))
                }
              >
                <X className="h-3 w-3" />
              </Button>
            </div>
          ))}
        </div>
        {usersWithAccess?.length > 0 && (
          <div className="mt-4">
            <h4 className="mb-2 font-medium dark:text-white">People with access</h4>
            <ScrollArea className="max-h-[200px]">
              <div className="pb-4">
                {usersWithAccess.map((userItem) => (
                  <div
                    key={userItem.id}
                    className="flex items-center justify-between py-2"
                  >
                    <span className="dark:text-white">
                      {userItem.email === user?.email
                        ? `${userItem.email} (You)`
                        : userItem.email}
                    </span>
                    {userItem.email !== user?.email && (
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            variant="ghost"
                            size="sm"
                            className="dark:text-gray-300"
                          >
                            <MoreVertical className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="dark:bg-gray-800 dark:border-gray-700">
                          <DropdownMenuItem
                            onClick={() => handleRemoveUser(userItem)}
                            className="dark:text-white dark:focus:bg-gray-700"
                          >
                            Remove user
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    )}
                  </div>
                ))}
              </div>
            </ScrollArea>
          </div>
        )}

        <DialogFooter>
          <Button variant="outline" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button onClick={addSelectedUsers}>
            {selectedUsers.length === 0 ? "Done" : "Send"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ShareProjectModal;
